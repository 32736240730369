import { FC, Children } from "react";
import { List, ListItem } from "@material-ui/core";

interface CardListProps {
  disablePadding?: boolean;
}

const CardList: FC<CardListProps> = ({ disablePadding, children }) => (
  <List disablePadding={disablePadding}>
    {Children.map(children, (child, i) => (
      <ListItem key={i}>{child}</ListItem>
    ))}
  </List>
);

export default CardList;
