import { selectOrderWithDriverById } from "../../store/features/orders/orderSelectors";
import { calculateDistanceAndBearing } from "../../utils/calculateDistanceAndBearing";
import Card from "../Card/Card";
import OrderContextMenu from "./OrderContextMenu";
import OrderTooltipContent from "@App/modules/Map/components/Orders/OrderTooltipContent";
import { skopjeGeolocation } from "@Logistics/constants";
import  TrophyIcon from "@assets/icons/trophy.svg?react";
import { Avatar, Typography, makeStyles } from "@material-ui/core";
import {
  ArrowUpward as ArrowUpwardIcon,
  CreditCard as CreditCardIcon,
  MonetizationOn as MonetizationOnIcon,
  Storefront as StorefrontIcon,
  Warning,
} from "@material-ui/icons";
import PauseIcon from "@material-ui/icons/Pause";
import ClickableTooltip from "@shared/components/Tooltip/ClickableTooltip";
import { useConfirmationDialog } from "@shared/context/ConfirmationDialogContext";
import { useMemoSelector } from "@shared/hooks";
import { IOrderDto, OrderStatus, rescheduleOrderAsync } from "@shared/services/order.service";
import { insertPauseAsync } from "@shared/services/settings/workshift.service";
import { selectPaymentTypeById } from "@shared/store/selectors/sharedStateSelectors";
import { cutString } from "@shared/utils/cutString";
import clsx from "clsx";
import dayjs from "dayjs";
import { CSSProperties, FC, memo, useState } from "react";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    color: "white",
    alignItems: "center",
    background: "#333333",
    border: "1px solid #555555",
    borderRadius: "10px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  selectedCard: {
    background: "linear-gradient(to right, #1e88e5, #2196f3)",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1), 0px 8px 16px rgba(0, 0, 0, 0.1)",
    borderRadius: "15px",
    transform: "scale(1.02)",
    transition: "transform 0.2s",
    "& *": {
      color: "white",
    },
    "&:hover": {
      cursor: "pointer",
    },
  },
  icon: {
    color: "#FF6F00",
  },
  directionContainer: {
    "& *": {
      color: "black",
    },
  },
  typography: {
    fontSize: "1rem",
    fontWeight: "bold",
  },
  hover: {
    cursor: "pointer",
  },
}));

const isOnlinePayment = (paymentTypeName) => {
  const cleanName = paymentTypeName.toLowerCase();
  return (
    cleanName.includes("online") || cleanName.includes("онлајн") || cleanName.includes("картичка")
  );
};

interface OrderCardProps {
  order: IOrderDto;
  pickup?: String;
  style?: CSSProperties;
}

const OrderCard: FC<OrderCardProps> = ({ order, pickup, style }) => {
  const classes = useStyles();

  const formatTimeLeft = (minutes: number) => {
    return minutes <= 0 ? `+${Math.abs(minutes)}` : minutes * -1;
  };

  const pickupTime = dayjs.utc(order.pickUpTime).local();
  const timeLeft = pickupTime?.diff(dayjs(), "minutes");

  const pointFrom = calculateDistanceAndBearing(skopjeGeolocation, {
    latitude: order.pickUpLatitude,
    longitude: order.pickUpLongitude,
  });

  const pointTo = calculateDistanceAndBearing(skopjeGeolocation, {
    latitude: order.dropOffLatitude,
    longitude: order.dropOffLongitude,
  });

  const orderWithDriver = useMemoSelector((state) => selectOrderWithDriverById(state, order.id));

  const [contextMenuPosition, setContextMenuPosition] = useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);

  const handleContextMenu = (event: React.MouseEvent) => {
    event.preventDefault();
    setContextMenuPosition(
      contextMenuPosition === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : null
    );
  };

  const handleClose = () => {
    setContextMenuPosition(null);
  };

  const paymentType = useMemoSelector((state) => selectPaymentTypeById(state, order.paymentTypeFK));
  const isOnline = isOnlinePayment(paymentType?.name);
  const isSelected = useSelector(({ appState }: IGlobalState) =>
    appState.logistics.filters.orders.selectedIds?.includes(order.id)
  );

  const confirmationDialog = useConfirmationDialog();

  const handleRejectedOrder = () => {
  confirmationDialog.close();

  const driver = orderWithDriver && orderWithDriver.driver;

  confirmationDialog.open({
    icon: <Warning fontSize="large" style={{ color: "orange" }} />,
    title: "Одбиена нарачка",
    body: `Нарачката е одбиена од ${
      driver ? (
        `${driver.firstName} ${driver.lastName}`

        
      ) : 'шофер'
    }`,
    confirmText: "Продолжи",
    denyText: "Паузирај 10 мин",
    onConfirm: () => {
      rescheduleOrderAsync(order.id);
      confirmationDialog.close();
    },
    onDeny: () => {
      insertPauseAsync(order.employeeFk, dayjs().add(10, "minute").toISOString());
      rescheduleOrderAsync(order.id);
      confirmationDialog.close();
    },
  });
};

const rejectedOrdersPopUp = () => {
  if (order && order.orderStatus === OrderStatus.RejectByDriver) {
    handleRejectedOrder();
  }
};


  return (
    <>
      <ClickableTooltip
        arrow={true}
        placement="right"
        type="order"
        content={<OrderTooltipContent order={orderWithDriver} />}
      >
        <Card
          className={clsx({ [classes.selectedCard]: isSelected })}
          style={{ ...style, borderRadius: "15px", cursor: "pointer" }}
          background={order.employeeFk ? "warning" : "white"}
          onContextMenu={handleContextMenu}
          onClickCapture={rejectedOrdersPopUp}
        >
          <Avatar
            title={order.partnerName}
            src={order.partnerImageSrc}
            alt={order.partnerName}
            variant="circular"
            className={classes.avatar}
          >
            <StorefrontIcon fontSize="small" />
          </Avatar>

          {isOnline ? (
            <CreditCardIcon color={"secondary"} />
          ) : (
            <MonetizationOnIcon color={"inherit"} />
          )}

          {order?.displayOrderNumber ? (
            <Typography
              title={order?.displayOrderNumber}
              color={!isOnline ? "inherit" : "secondary"}
            >
              {order.displayOrderNumber}
            </Typography>
          ) : (
            <Typography
              title={order?.externalOrderNumber}
              color={!isOnline ? "inherit" : "secondary"}
            >
              #{cutString(order?.externalOrderNumber, 3).padStart(3, "0")}
            </Typography>
          )}

          <div>
            {/* <Typography component="span">{pickupTime?.format("HH:mm")}&nbsp;</Typography> */}
            <Typography component="span">
              <b>({formatTimeLeft(timeLeft)})</b>
            </Typography>
          </div>
          {order?.orderStatus === OrderStatus.Paused ? (
            <PauseIcon style={{ color: "red" }} />
          ) : null}
          {order?.isPriority && (
            <TrophyIcon
              style={{ width: "20px", fill: "orange" }}
              onDragStart={(handleDrop) => handleDrop.preventDefault()}
            />
          )}
          {pickup && <Typography component="span">{pickup}</Typography>}
          <div
            className={classes.directionContainer}
            style={{
              display: "flex",
              gap: "0.5rem",
              border: "1px solid black",
              borderRadius: "25px",
              padding: "2px 10px",
              backgroundColor: "white",
            }}
          >
            {/* From direction */}
            <ArrowUpwardIcon style={{ transform: `rotate(${pointFrom.bearing}deg)` }} />
            <Typography>{Math.round(pointFrom.distance)}</Typography>

            <Typography style={{ marginLeft: "5px", marginRight: "5px" }}>/</Typography>

            {/* To direction */}
            <ArrowUpwardIcon style={{ transform: `rotate(${pointTo.bearing}deg)` }} />
            <Typography>{Math.round(pointTo.distance)}</Typography>
          </div>
        </Card>
      </ClickableTooltip>

      <OrderContextMenu
        order={order}
        contextMenuPosition={contextMenuPosition}
        onClose={handleClose}
      />
    </>
  );
};

export default memo(OrderCard);
