import httpService from "../http.service";
import { ISalaryConfigurationDto } from "../salary-configuration/dtos/salary-types/salaryConfigurationDto";
import { ICreateEmployeeRequestDto } from "./dtos/createEmployeeRequestDto";
import { IEmployeeAreaDto } from "./dtos/employeeAreaDto";
import { IEmployeeDto } from "./dtos/employeeDto";
import { IEmployeeSalaryConfigurationRequestDto } from "./dtos/employeeSalaryConfigurationRequestDto";
import { IEmployeeSupplementDto } from "./dtos/employeeSupplementDto";
import { IEmployeeSupplementRequestDto } from "./dtos/employeeSupplementRequestDto";
import { IEmployeeUserDto } from "./dtos/employeeUserDto";
import { IRegisterUserRequestDto } from "./dtos/registerUserRequestDto";
import { IUpdateEmployeeAreaRequestDto } from "./dtos/updateEmployeeAreaRequestDto";
import { IUpdateEmployeeGeneralInfoRequestDto } from "./dtos/updateEmployeeGeneralInfoRequestDto";
import { IUpdateUserRequestDto } from "./dtos/updateUserRequestDto";
import { SalaryType } from "./enums/salaryType";

export type IUpdateEmployeeRequestDto = ICreateEmployeeRequestDto;

export const getEmployees = () => {
  return httpService.get<IEmployeeDto[]>("/api/employees");
};

export const getEmployee = (id: number) => {
  return httpService.get<IEmployeeDto>(`/api/employees/${id}`);
};

export const getEmployeeByUserId = (id: string) => {
  return httpService.get<IEmployeeDto>(`/api/employees/users/${id}`);
};

export const createEmployee = (request: ICreateEmployeeRequestDto) => {
  return httpService.post<void, ICreateEmployeeRequestDto>("/api/employees", request);
};

export const updateEmployee = (id: number, request: IUpdateEmployeeRequestDto) => {
  return httpService.patch<void, IUpdateEmployeeRequestDto>(`/api/employees/${id}`, request);
};

export const deleteEmployee = (id: number) => {
  return httpService.delete<void>(`/api/employees/${id}`);
};

export const getEmployeeSalaryConfiguration = (id: number) => {
  return httpService.get<ISalaryConfigurationDto[]>(`/api/employees/${id}/salary-configurations`);
};

export const updateEmployeeSalaryConfiguration = (
  employeeId: number,
  salaryConfigurationId: number,
  request: IEmployeeSalaryConfigurationRequestDto
) => {
  return httpService.put<void, IEmployeeSalaryConfigurationRequestDto>(
    `/api/employees/${employeeId}/salary-configurations/${salaryConfigurationId}`,
    request
  );
};

export const insertEmployeeSalaryConfiguration = (
  employeeId: number,
  request: IEmployeeSalaryConfigurationRequestDto
) => {
  return httpService.post<void, IEmployeeSalaryConfigurationRequestDto>(
    `/api/employees/${employeeId}/salary-configurations`,
    request
  );
};

export const deletetEmployeeSalaryConfiguration = (
  employeeId: number,
  salaryConfigurationId: number
) => {
  return httpService.delete(
    `/api/employees/${employeeId}/salary-configurations/${salaryConfigurationId}`
  );
};

export const getEmployeeAreas = (employeeId: number) => {
  return httpService.get<IEmployeeAreaDto[]>(`/api/employees/${employeeId}/areas`);
};

export const updateEmployeeArea = (
  employeeId: number,
  employeeAreaId: number,
  request: IUpdateEmployeeAreaRequestDto
) => {
  return httpService.put<void, IUpdateEmployeeAreaRequestDto>(
    `/api/employees/${employeeId}/areas/${employeeAreaId}`,
    request
  );
};

export const getEmployeeUser = (employeeId: number, userId: string) => {
  return httpService.get<IEmployeeUserDto>(`/api/employees/${employeeId}/users/${userId}`);
};

export const createEmployeeUser = (employeeId: number, request: IRegisterUserRequestDto) => {
  return httpService.post<IEmployeeUserDto, IRegisterUserRequestDto>(
    `/api/employees/${employeeId}/users`,
    request
  );
};

export const updateEmployeeUser = (
  employeeId: number,
  employeeUserId: string,
  request: IUpdateUserRequestDto
) => {
  return httpService.put<IEmployeeUserDto, IUpdateUserRequestDto>(
    `/api/employees/${employeeId}/users/${employeeUserId}`,
    request
  );
};

export const updateEmployeeGeneralInformation = (
  employeeId: number,
  request: IUpdateEmployeeGeneralInfoRequestDto & { status: boolean }
) => {
  return httpService.patch<void, IUpdateEmployeeGeneralInfoRequestDto & { status: boolean }>(
    `/api/employees/${employeeId}/general-information`,
    request
  );
};


export const getEmployeeGeneralInformation = (employeeId: number) => {
  return httpService.get<IUpdateEmployeeGeneralInfoRequestDto>(
    `/api/employees/${employeeId}/general-information`
  );
};

export const getEmployeeSupplementsAsync = (employeeId: number) => {
  return httpService.get<IEmployeeSupplementDto[]>(`/api/employees/${employeeId}/supplements`);
};

export const insertEmployeeSupplementAsync = (
  employeeId: number,
  request: IEmployeeSupplementRequestDto
) => {
  return httpService.post<void, IEmployeeSupplementRequestDto>(
    `/api/employees/${employeeId}/supplements`,
    request
  );
};

export const updateEmployeeSupplementAsync = (
  employeeId: number,
  supplementId: number,
  request: IEmployeeSupplementRequestDto
) => {
  return httpService.put<void, IEmployeeSupplementRequestDto>(
    `/api/employees/${employeeId}/supplements/${supplementId}`,
    request
  );
};

export const deleteEmployeeSupplementAsync = (employeeId: number, supplementId: number) => {
  return httpService.delete(`/api/employees/${employeeId}/supplements/${supplementId}`);
};

export const getEmployeeDeductionsAsync = (employeeId: number) => {
  return httpService.get<IEmployeeSupplementDto[]>(`/api/employees/${employeeId}/deductions`);
};

export const insertEmployeeDeductionAsync = (
  employeeId: number,
  request: IEmployeeSupplementRequestDto
) => {
  return httpService.post<void, IEmployeeSupplementRequestDto>(
    `/api/employees/${employeeId}/deductions`,
    request
  );
};

export const updateEmployeeDeductionAsync = (
  employeeId: number,
  deductionId: number,
  request: IEmployeeSupplementRequestDto
) => {
  return httpService.put<void, IEmployeeSupplementRequestDto>(
    `/api/employees/${employeeId}/deductions/${deductionId}`,
    request
  );
};

export const deleteEmployeeDeductionAsync = (employeeId: number, deductionId: number) => {
  return httpService.delete(`/api/employees/${employeeId}/deductions/${deductionId}`);
};

export const translateSalaryType = (salaryType: SalaryType): string => {
  const salaryTypeLabelMap = new Map([
    [SalaryType.None, ""],
    [SalaryType.Full, "Редовно вработен"],
    [SalaryType.Contract, "Хонорарно"],
  ]);

  if (salaryTypeLabelMap.has(salaryType)) {
    return salaryTypeLabelMap.get(salaryType);
  } else if (salaryType in SalaryType) return SalaryType[salaryType];
  else return salaryType.toString();
};