import { useSelector } from "react-redux";
import { isEqual as _isEqual } from "lodash";

const isEqual = (prev: unknown, next: unknown) => {
  return _isEqual(prev, next);
};

/**
 * A memoized version of `useSelector`
 * @param fn the selector function
 * @returns the selected state
 */
export function useMemoSelector<T>(fn: (state: IGlobalState) => T): T {
  return useSelector(fn, isEqual);
}

export default useMemoSelector;
export const __test__ = {
  isEqual,
};
