import { EmployeeGeneralInfoValidationSchema } from "../../schemas/EmployeeGeneralInfoValidationSchema";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import CustomDateField from "@shared/components/CustomFormControls/CustomDateField";
import ErrorMessage from "@shared/components/ErrorMessage/ErrorMessage";
import { IEmployeeDto } from "@shared/services/employees/dtos/employeeDto";
import {
  getEmployeeGeneralInformation,
  updateEmployeeGeneralInformation,
} from "@shared/services/employees/employees.service";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import React, { FC, memo, useEffect, useState } from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
  })
);

interface Props {
  employee: IEmployeeDto;
  employeeUserUpdated: (employee) => void;
}

const initialValues = {
  accountNumber: "",
  bankName: "",
  address: "",
  sickPayDay: null,
  minimalContribution: null,
  employeeNumber: null,
  embg: "",
  employmentContractNumber: "",
  employmentDate: "",
  status: false,
  level: 1,
  active: false,
};

export const EmployeeGeneralInfoTab: FC<Props> = ({ employee, employeeUserUpdated }) => {
  const classes = useStyles();

  const [errorState, setErrorState] = useState<{ error: string }>({
    error: "",
  });

  const [loadingState, setLoadingState] = useState<boolean>(false);

  const { enqueueSnackbar } = useSnackbar();

  const formik = useFormik({
    initialValues,
    validationSchema: EmployeeGeneralInfoValidationSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  const { setValues, handleChange: handleFormikChange } = formik;

  useEffect(() => {
    getEmployeeGeneralInformation(employee.id).then((res) => {
      setValues({ ...res });
    });
  }, [employee.id, setValues]);

  function handleSubmit() {
    if (employee && employee.id) {
      setLoadingState(true);
      updateEmployeeGeneralInformation(employee.id, {
        ...formik.values,
      })
        .then(() => {
          enqueueSnackbar("Успешно ажурирано", { variant: "success" });
          setErrorState({ ...errorState, error: "" });
          employeeUserUpdated(employee);
          getEmployeeGeneralInformation(employee.id).then((res) => {
            setValues({ ...res });
          });
        })
        .catch((e) => {
          setErrorState({ ...errorState, error: e.response?.data?.message });
        })
        .finally(() => {
          setLoadingState(false);
        });
    }
  }

  return (
    <>
      <Box padding={2} paddingTop={2} paddingBottom={2} component={Paper}>
        {employee ? (
          <form onSubmit={formik.handleSubmit}>
            <Grid style={{ maxHeight: "500px" }} spacing={2} container>
              <Grid item xs={4}>
                <Grid spacing={2} style={{ paddingTop: "10px", paddingBottom: "10px" }} container>
                  <Grid item xs={12}>
                    <FormLabel component="legend">Информации за вработениот</FormLabel>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="employeeNumber"
                      label="Број на вработен"
                      variant="outlined"
                      size="small"
                      value={formik.values.employeeNumber}
                      InputLabelProps={{ shrink: !!formik.values.employeeNumber }}
                      disabled={true}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="embg"
                      label="Единствен матичен број"
                      variant="outlined"
                      size="small"
                      value={formik.values.embg}
                      onInput={handleFormikChange}
                      error={formik.touched.embg && Boolean(formik.errors.embg)}
                      helperText={formik.touched.embg && formik.errors.embg}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="employmentContractNumber"
                      label="Број на договор"
                      variant="outlined"
                      size="small"
                      value={formik.values.employmentContractNumber}
                      onInput={handleFormikChange}
                      error={
                        formik.touched.employmentContractNumber &&
                        Boolean(formik.errors.employmentContractNumber)
                      }
                      helperText={
                        formik.touched.employmentContractNumber &&
                        formik.errors.employmentContractNumber
                      }
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <CustomDateField
                      name="employmentDate"
                      label="Почеток на договор"
                      size="small"
                      value={formik.values.employmentDate}
                      onChange={(e) => {
                        formik.setFieldValue("employmentDate", e);
                      }}
                      errorMessage={formik.errors.employmentDate}
                      touched={formik.touched.employmentDate}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Grid spacing={2} style={{ paddingTop: "10px", paddingBottom: "10px" }} container>
                  <Grid item xs={12}>
                    <FormLabel component="legend">&nbsp;</FormLabel>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="bankName"
                      label="Име на банка"
                      variant="outlined"
                      size="small"
                      value={formik.values.bankName}
                      onInput={handleFormikChange}
                      error={formik.touched.bankName && Boolean(formik.errors.bankName)}
                      helperText={formik.touched.bankName && formik.errors.bankName}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="accountNumber"
                      label="Тековна сметка"
                      variant="outlined"
                      size="small"
                      value={formik.values.accountNumber}
                      onInput={handleFormikChange}
                      error={formik.touched.accountNumber && Boolean(formik.errors.accountNumber)}
                      helperText={formik.touched.accountNumber && formik.errors.accountNumber}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="address"
                      label="Адреса"
                      variant="outlined"
                      size="small"
                      value={formik.values.address}
                      onInput={handleFormikChange}
                      error={formik.touched.address && Boolean(formik.errors.address)}
                      helperText={formik.touched.address && formik.errors.address}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <FormControlLabel
                        control={
                          <Switch
                            name="active"
                            color="primary"
                            checked={formik.values.active}
                            onChange={handleFormikChange}
                            size="medium"
                          />
                        }
                        label={
                          <Typography variant="body1" color="textSecondary" style={{ fontSize: '15px' }}>
                            Статус
                          </Typography>
                        }
                        labelPlacement="top"
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Grid spacing={2} style={{ paddingTop: "10px", paddingBottom: "10px" }} container>
                  <Grid item xs={12}>
                    <FormLabel component="legend">&nbsp;</FormLabel>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="sickPayDay"
                      label="Платено отсуство"
                      variant="outlined"
                      size="small"
                      value={formik.values.sickPayDay}
                      InputLabelProps={{ shrink: !!formik.values.sickPayDay }}
                      onInput={handleFormikChange}
                      error={formik.touched.sickPayDay && Boolean(formik.errors.sickPayDay)}
                      helperText={formik.touched.sickPayDay && formik.errors.sickPayDay}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="minimalContribution"
                      label="Минимум придонеси"
                      variant="outlined"
                      size="small"
                      value={formik.values.minimalContribution}
                      InputLabelProps={{ shrink: !!formik.values.minimalContribution }}
                      onInput={handleFormikChange}
                      error={
                        formik.touched.minimalContribution &&
                        Boolean(formik.errors.minimalContribution)
                      }
                      helperText={
                        formik.touched.minimalContribution && formik.errors.minimalContribution
                      }
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormLabel component="legend">Level</FormLabel>
                    <RadioGroup
                      row
                      name="level"
                      value={formik.values.level.toString()}
                      onChange={handleFormikChange}
                    >
                      <FormControlLabel
                        value="1"
                        control={<Radio style={{ color: "#18ba9b" }} />}
                        label={<span style={{ marginLeft: "10px" }}>Level 1</span>}
                        labelPlacement="end"
                      />
                      <FormControlLabel
                        value="2"
                        control={<Radio style={{ color: "#18ba9b" }} />}
                        label={<span style={{ marginLeft: "10px" }}>Level 2</span>}
                        labelPlacement="end"
                      />
                      <FormControlLabel
                        value="3"
                        control={<Radio style={{ color: "#18ba9b" }} />}
                        label={<span style={{ marginLeft: "10px" }}>Level 3</span>}
                        labelPlacement="end"
                      />
                    </RadioGroup>
                  </Grid>
                </Grid>
              </Grid>
              {errorState && errorState.error ? (
                <Grid item xs={12}>
                  <ErrorMessage key="form-error-message" error={errorState.error}></ErrorMessage>
                </Grid>
              ) : null}
              <Grid item xs={12}>
                <Button
                  style={{ float: "right" }}
                  type="submit"
                  disabled={loadingState}
                  variant="contained"
                  color="primary"
                >
                  Ажурирај
                </Button>
              </Grid>
            </Grid>
          </form>
        ) : (
          <Grid style={{ minHeight: "300px" }} spacing={2} container>
            <Grid alignContent="center" item xs={12}>
              Прво мора да го додатете вработениот па тогаш да креирате корисник за истиот.
            </Grid>
          </Grid>
        )}
      </Box>
    </>
  );
};

export default memo(EmployeeGeneralInfoTab);
