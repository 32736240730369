import React from 'react';

const ChatIframe: React.FC = () => {
  return (
    
      <iframe
        src="https://dashboard.tawk.to/"
        style={{border: 'none', position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}}
        title="Chat Widget"
      />

  );
}

export default ChatIframe;
