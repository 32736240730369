import { InputAdornment, TextField, createStyles, makeStyles } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { FC } from "react";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      margin: theme.spacing(2),
    },
  })
);

interface SearchFilterProps {
  onChange: (searchTerm: string) => void;
}

const SearchFilter: FC<SearchFilterProps> = ({ onChange }) => {
  const classes = useStyles();

  return (
    <div
    style= 
      {{display:"flex",
      flexDirection:"column",
      alignItems:"center",
      justifyContent:"end"}}
      className={classes.root}
    >
      <TextField
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        label="Пребарај"
        onBlur={(e) => onChange(e.target.value)}
      />
    </div>
  );
};

export default SearchFilter;
