import { Box, CardContent, Typography, createStyles, makeStyles } from "@material-ui/core";
import { ICompanyDto } from "@shared/services/settings/dtos/companies/companyDto";
import { FC } from "react";

const useStyles = makeStyles((theme) =>
  createStyles({
    contentItem: {
      display: "flex",
      justifyContent: "space-between",
      paddingTop: theme.spacing(1),
      paddingBotton: theme.spacing(1),
    },
  })
);

interface CompanyCardContentDisplayProps {
  company: ICompanyDto;
}

const CompanyCardContentDisplay: FC<CompanyCardContentDisplayProps> = ({ company }) => {
  const classes = useStyles();

  return (
    <CardContent>
      <Box className={classes.contentItem}>
        <Typography>Кратенка: </Typography>
        <Typography>{company.abbreviation}</Typography>
      </Box>
      <Box className={classes.contentItem}>
        <Typography>Адреса: </Typography>
        <Typography>{company.address}</Typography>
      </Box>
      <Box className={classes.contentItem}>
        <Typography>Емаил: </Typography>
        <Typography>{company.email}</Typography>
      </Box>
      <Box className={classes.contentItem}>
        <Typography>Банкарска сметка: </Typography>
        <Typography>{company.bankAccount}</Typography>
      </Box>
      <Box className={classes.contentItem}>
        <Typography>Даночен број: </Typography>
        <Typography>{company.vatId}</Typography>
      </Box>
    </CardContent>
  );
};

export default CompanyCardContentDisplay;
