import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import { IPayrollPeriodSumDto } from "@shared/services/payroll/dtos/payrollPeriodSumDto";
import { FC } from "react";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      minWidth: theme.spacing(48),
      minHeight: theme.spacing(36),
      margin: theme.spacing(1),
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.grey[100],
    },
    contentItem: {
      display: "flex",
      justifyContent: "space-between",
      paddingTop: theme.spacing(1),
      paddingBotton: theme.spacing(1),
    },
  })
);

interface PeriodSumCardProps {
  periodSum: IPayrollPeriodSumDto;
}

const PeriodSumCard: FC<PeriodSumCardProps> = ({ periodSum }) => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardHeader title="Сума" />
      <CardContent>
        <Box className={classes.contentItem}>
          <Typography>Заработка: </Typography>
          <Typography>
            {periodSum.rawPeriodSum} <em>мкд</em>
          </Typography>
        </Box>
        <Box className={classes.contentItem}>
          <Typography>Заработка од нарачки: </Typography>
          <Typography>
            {periodSum.orderesDeliveredSum} <em>мкд</em>
          </Typography>
        </Box>
          <Box className={classes.contentItem}>
          <Typography>Пенали (рачно): </Typography>
          <Typography>
            {periodSum.manualPenaltiesSum} <em>мкд</em>
          </Typography>
        </Box>
        <Box className={classes.contentItem}>
          <Typography>Бонуси (рачно): </Typography>
          <Typography>
            {periodSum.manualBonusesSum} <em>мкд</em>
          </Typography>
        </Box>
        <Box className={classes.contentItem}>
          <Typography>Вкупно: </Typography>
          <Typography>
            {periodSum.totalSum} <em>мкд</em>
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export default PeriodSumCard;
