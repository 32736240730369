import ErrorFallback from "./error";
import App from "./modules/App/App";
import Auth from "./modules/Auth/Auth";
import Drivers from "./modules/Drivers/Drivers";
import { getCurrentUser, isUserDriver } from "@shared/services/auth/auth.service";
import { FC, useEffect } from "react";
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
  useNavigate,
} from "react-router-dom";

const RootFallback = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const currentUser = getCurrentUser();

    if (!currentUser) {
      navigate("/auth/login");
      return;
    }
    if (isUserDriver(currentUser?.isDriver)) {
      navigate("/drivers/shift");
      return;
    }
    navigate("/app/logistics");
  }, []);

  return null;
};

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/*" errorElement={<ErrorFallback />}>
      <Route path="auth/*" element={<Auth />} />
      <Route path="app/*" element={<App />} />
      <Route path="drivers/*" element={<Drivers />} />

      <Route path="*" element={<RootFallback />} />
    </Route>
  )
);

const Main: FC = () => {
  return <RouterProvider router={router} />;
};

export default Main;
