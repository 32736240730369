import {
  CreateEmployeeUserValidationSchema,
  UpdateEmployeeUserValidationSchema,
} from "../../schemas/EmployeeUserValidationSchema";
import {
  Box,
  Button,
  FormLabel,
  Grid,
  Paper,
  TextField,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import ErrorMessage from "@shared/components/ErrorMessage/ErrorMessage";
import {
  createEmployeeUser,
  getEmployeeUser,
  updateEmployeeUser,
} from "@shared/services/employees/employees.service";
import { useQuery } from "@tanstack/react-query";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { FC, memo, useEffect, useRef, useState } from "react";

const useStyles = makeStyles((theme: Theme) => createStyles({}));

interface Props {
  employeeId: number;
  employeeUserId: string;
}

const initialValues = {
  userName: "",
  password: "",
  confirmPassword: "",
};

export const EmployeeUserTab: FC<Props> = ({ employeeId, employeeUserId }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [error, setError] = useState("");

  const employeeUserIdRef = useRef(employeeUserId);

  useEffect(() => {
    employeeUserIdRef.current = employeeUserId;
  }, [employeeUserId]);

  const handleSubmit = () => {
    if (!employeeId) {
      return;
    }

    if (employeeUserIdRef.current) {
      updateEmployeeUser(employeeId, employeeUserIdRef.current, formik.values)
        .then(() => {
          enqueueSnackbar("Успешно ажурирано", { variant: "success" });
          setError("");
        })
        .catch((e) => {
          setError(e.response?.data?.message);
        })
        .finally(() => {
          refetchEmployeeUser();
        });
    } else {
      createEmployeeUser(employeeId, formik.values)
        .then((user) => {
          enqueueSnackbar("Успешно креирано", { variant: "success" });
          setError("");

          employeeUserIdRef.current = user.id;
          refetchEmployeeUser();
        })
        .catch((e) => {
          setError(e.response?.data?.message);
        });
    }
  };

  const {
    setValues,
    handleChange: handleFormikChange,
    ...formik
  } = useFormik({
    initialValues,
    validationSchema: employeeUserIdRef.current
      ? UpdateEmployeeUserValidationSchema
      : CreateEmployeeUserValidationSchema,
    onSubmit: handleSubmit,
  });

  const {refetch: refetchEmployeeUser } = useQuery(
    ["employeeUser", employeeId],
    () => getEmployeeUser(employeeId, employeeUserIdRef.current),
    {
      
      enabled: !!employeeUserIdRef.current,
      retry: () => false,
      onSuccess: (user) => {
        if (!user) return;

        formik.resetForm();
        formik.setFieldValue("userName", user.userName);
      },
      onError: (e: any) => {
        setError(e?.message || e);
      },
    }
  );

  return (
    <Box padding={2} paddingTop={2} paddingBottom={2} component={Paper}>
      {employeeId ? (
        <form onSubmit={formik.handleSubmit}>
          <Grid style={{ maxHeight: "500px" }} spacing={2} container>
            <Grid item xs={4}>
              <Grid spacing={2} style={{ paddingTop: "10px", paddingBottom: "10px" }} container>
                <Grid item xs={12}>
                  <FormLabel component="legend">
                    {(
                      "Ажурирај безбедносни информации"
                    ) }
                    
                  </FormLabel>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    name="userName"
                    label="Корисничко име"
                    variant="outlined"
                    type="text"
                    size="small"
                    value={formik.values.userName}
                    onChange={handleFormikChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.userName && Boolean(formik.errors.userName)}
                    helperText={formik.touched.userName && formik.errors.userName}
                    disabled={false}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="password"
                    label="Лозинка"
                    variant="outlined"
                    type="text"
                    size="small"
                    value={formik.values.password}
                    onChange={handleFormikChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.password && Boolean(formik.errors.password)}
                    helperText={formik.touched.password && formik.errors.password}
                    disabled={false}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="confirmPassword"
                    label="Потврди лозинка"
                    variant="outlined"
                    type="text"
                    size="small"
                    value={formik.values.confirmPassword}
                    onChange={handleFormikChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                    helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                    disabled={false}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>
            {error ? (
              <Grid item xs={12}>
                <ErrorMessage key="form-error-message" error={error} />
              </Grid>
            ) : null}
            <Grid item xs={12}>
              <Button style={{ float: "right" }} type="submit" variant="contained" color="primary">
                {employeeUserIdRef.current ? "Ажурирај" : "Креирај"}
              </Button>
            </Grid>
          </Grid>
        </form>
      ) : (
        <Grid style={{ minHeight: "300px" }} spacing={2} container>
          <Grid alignContent="center" item xs={12}>
            Прво мора да го додатете вработениот па тогаш да креирате корисник за истиот.
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default memo(EmployeeUserTab);