// Reducer imports
import appReducer from "@App/store";
import { Reducer, combineReducers, configureStore } from "@reduxjs/toolkit";
import sharedReducer from "@shared/store";

type StateKey = "appState" | "sharedState";

// TODO: Reducer<any> gets rid of the types
const reducers: Partial<{ [K in StateKey]: Reducer }> = {
  appState: appReducer,
  sharedState: sharedReducer,
};

// Create the store and inject middlewares
const store = configureStore({
  reducer: combineReducers(reducers),
});

export type AppRootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

/** A function to async inject a reducer into the store */
export const injectReducer = (key: StateKey, asyncReducer: Reducer) => {
  reducers[key] = asyncReducer;
  store.replaceReducer(combineReducers(reducers));
};

export default store;
export type status = "idle" | "loading" | "succeeded" | "failed";
