import React from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import { Order } from "./types/Order";
import { ITableHeaderCell } from "./types/ITableHeaderCell";

const useStyles = makeStyles(() =>
  createStyles({
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
  })
);

interface DriversTableProps {
  order: Order;
  orderBy: string;
  headerCells: ITableHeaderCell<unknown>[];
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
}

function TableHeader(props: DriversTableProps) {
  const { order, orderBy, headerCells, onRequestSort } = props;

  const createSortHandler = (property: string) => (
    event: React.MouseEvent<unknown>
  ) => {
    onRequestSort(event, property);
  };

  const classes = useStyles();
  return (
    <TableHead>
      <TableRow>
        {headerCells.map((header) => (
          <TableCell
            key={header.id}
            sortDirection={
              !header.disableSorting && orderBy === header.id ? order : false
            }
            align={header.numeric ? "right" : "left"}
            padding={header.disablePadding ? "none" : "default"}
          >
            {header.disableSorting ? (
              <>{header.label}</>
            ) : (
              <TableSortLabel
                active={orderBy === header.id}
                direction={orderBy === header.id ? order : "asc"}
                onClick={createSortHandler(header.id)}
              >
                {header.label}

                {orderBy === header.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default TableHeader;
