import CompanyCardContentEdit from "./CompanyCardContentEdit";
import { Box, Button, Card, CardHeader, createStyles, makeStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { insertNewCompany } from "@shared/services/settings/companies.service";
import { ICompanyDto } from "@shared/services/settings/dtos/companies/companyDto";
import { useMutation } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { FC, useState } from "react";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      minWidth: theme.spacing(48),
      minHeight: theme.spacing(36),
      margin: theme.spacing(1),
    },
    addNew: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: theme.spacing(36),
      margin: theme.spacing(1),
    },
  })
);

interface NewCompanyCardProps {
  onAdd: (company: ICompanyDto) => void;
}

const AddCompanyCard: FC<NewCompanyCardProps> = ({ onAdd }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [addMode, setAddMode] = useState(false);

  const { mutate: addCompanyMutate } = useMutation(insertNewCompany, {
    onSuccess: (company) => {
      setAddMode(false);
      onAdd(company);
    },
    onError: () => {
      enqueueSnackbar(
        "Се случи грешка. Ве молиме обидете се повторно или контактирајте го тимот за поддршка."
      );
    },
  });

  return (
    <>
      {!addMode && (
        <Box className={classes.addNew}>
          <Button variant="contained" color="primary" onClick={() => setAddMode(true)}>
            + Додади нова компанија
          </Button>
        </Box>
      )}
      {addMode && (
        <Card className={classes.root}>
          <CardHeader
            action={
              <Button onClick={() => setAddMode(false)}>
                <CloseIcon />
              </Button>
            }
          />
          <CompanyCardContentEdit submitLabel="Додади" onSubmit={addCompanyMutate} />
        </Card>
      )}
    </>
  );
};

export default AddCompanyCard;
