import ErrorFallback from "../../../../../error";
import CitiesFilter from "../../shared/components/CitiesFilter";
import EmployeePositionsFilter from "../../shared/components/EmployeePositionsFilter";
import PeriodToDateFilter from "../../shared/components/PeriodToFilter";
import SearchFilter from "../../shared/components/SearchFilter";
import EmployeePayoutsHistory from "./components/EmployeePayoutsHistory";
import usePayoutsColumns from "./hooks/usePayoutsColumns";
import { Box, Button, Grid, Typography, createStyles, makeStyles } from "@material-ui/core";
import AddBoxIcon from "@material-ui/icons/AddBox";
import GetAppIcon from "@material-ui/icons/GetApp";
import Loading from "@shared/components/Loading/Loading";
import MaterialTable from "@shared/components/MaterialTable/MaterialTable";
import { IEmployeePayoutDto } from "@shared/services/payout/dtos/employeePayoutDto";
import { createPayout, exportPayout, getAllPayouts } from "@shared/services/payout/payout.service";
import { getNextPageParam } from "@shared/utils/infiniteQuery";
import { useInfiniteQuery, useMutation } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { FC, useMemo, useState } from "react";

const useStyles = makeStyles((theme) =>
  createStyles({
    heading: {
      marginBottom: theme.spacing(4),
    },
    filters: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    summaryCards: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    periodTable: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  })
);

const PayrollCreate: FC = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [periodToFilter, setPeriodToFilter] = useState<Date>(null);
  const [citiesFilter, setCitiesFilter] = useState<number[]>([]);
  const [employeePositionsFilter, setEmployeePositionsFilter] = useState<number[]>([]);
  const [searchFilter, setSearchFilter] = useState<string>("");

  const payoutsQuery = useInfiniteQuery(
    ["getAllPayouts", periodToFilter, citiesFilter, employeePositionsFilter, searchFilter],
    ({ pageParam = 0 }) =>
      getAllPayouts({
        currentPage: pageParam,
        itemsPerPage: 10,
        filters: {
          cities: citiesFilter,
          employeePositions: employeePositionsFilter,
          periodTo: periodToFilter,
          search: searchFilter,
        },
        items: [],
        totalItemsCount: 0,
      }),
    {
      keepPreviousData: true,
      getNextPageParam: (lastPage) =>
        getNextPageParam(lastPage.currentPage, lastPage.itemsPerPage, lastPage.totalItemsCount),
    }
  );

  const createPayoutMutation = useMutation(createPayout, {
    onSuccess: () => {
      enqueueSnackbar("Исплатата е успешно креирана.");
    },
    onError: () => {
      enqueueSnackbar(
        "Се случи грешка. Ве молиме обидете се повторно или контактирајте го тимот за поддршка."
      );
    },
  });

  const exportPayoutMutation = useMutation(exportPayout, {
    onSuccess: () => {
      enqueueSnackbar("Исплатата е успешно експортирана.");
    },
    onError: () => {
      enqueueSnackbar(
        "Се случи грешка. Ве молиме обидете се повторно или контактирајте го тимот за поддршка."
      );
    },
  });

  const payoutsColumns = usePayoutsColumns(payoutsQuery.refetch);
  const payoutsData = useMemo(
    () =>
      payoutsQuery.data?.pages?.flatMap((pageData) =>
        pageData.items.map((item) => ({ ...item, id: item.employeeId } as IEmployeePayoutDto))
      ) ?? [],
    [payoutsQuery.data]
  );
  const [selectedEmployees, setSelectedEmployees] = useState<number[]>([]);

  if (payoutsQuery.isError) return <ErrorFallback error={payoutsQuery.error} />;
  if (payoutsQuery.isLoading) return <Loading />;

  return (
    <Grid container>
      <Grid container className={classes.heading}>
        <Grid item>
          <Typography variant="h5">Исплата на вработени</Typography>
        </Grid>
      </Grid>

      <Grid container className={classes.filters}>
        <Box display="flex">
          <PeriodToDateFilter onChange={(to) => setPeriodToFilter(to)} />
          <CitiesFilter onChange={(citiesIds) => setCitiesFilter(citiesIds)} />
          <EmployeePositionsFilter onChange={(roleIds) => setEmployeePositionsFilter(roleIds)} />
          <SearchFilter onChange={(searchTerm) => setSearchFilter(searchTerm)} />
        </Box>
      </Grid>

      <Grid container justifyContent="flex-end">
        <Button
          style={{ marginLeft: 4, marginRight: 4, color: "white" }}
          variant="contained"
          color="primary"
          startIcon={<GetAppIcon />}
          onClick={() =>{
            console.log(periodToFilter);
            if(selectedEmployees.length === 0){
              enqueueSnackbar("Изберете вработени!", { variant: "warning" });
            }
            else{
              createPayoutMutation.mutate({
                employeeIds: selectedEmployees,
                periodTo: periodToFilter,
              })}
            }
           
          }
        >
          Креирај
        </Button>

        {/* <Button
          style={{ marginLeft: 4, marginRight: 4, color: "white" }}
          variant="contained"
          color="primary"
          startIcon={<AddBoxIcon />}
          onClick={() =>
            createPayoutMutation.mutate({
              employeeIds: selectedEmployees,
              periodTo: periodToFilter,
            })
          }
        >
          Креирај
        </Button> */}
      </Grid>

      <MaterialTable<IEmployeePayoutDto>
        title="Исплата"
        className={classes.periodTable}
        columns={payoutsColumns}
        data={payoutsData}
        options={{
          search: false,
          maxColumnSort: 1,
          pageSize: 500,
          selection: true,
          emptyRowsWhenPaging: false,

          selectionProps: rowData => ({
            disabled: rowData.frozen.isFrozen,
          }),
                }}
        onSelectionChange={(selectedRows) => {
          const selectedEmployeeIds = selectedRows.map((s) => s.employeeId);
          setSelectedEmployees(selectedEmployeeIds);
        }}
        detailPanel={({ rowData }) => {
          return <EmployeePayoutsHistory employeePayout={rowData} />;
        }}
        isLoading={payoutsQuery.isFetching}
        infiniteLoad={true}
        hasNextPage={payoutsQuery.hasNextPage}
        fetchNextPage={payoutsQuery.fetchNextPage}
      />
    </Grid>
  );
};
export default PayrollCreate;
