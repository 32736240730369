import UserPreferencesDialog from "./UserPreferencesDialog/UserPreferencesDialog";
import { Avatar, Badge, IconButton, ListItem, ListItemIcon, withStyles } from "@material-ui/core";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import { MeetingRoom, Tune } from "@material-ui/icons";
import { getCurrentUser } from "@shared/services/auth/auth.service";
import { useModal } from "mui-modal-provider";
import { FC } from "react";
import { Link } from "react-router-dom";

const StyledBadge = withStyles((theme: Theme) =>
  createStyles({
    badge: {
      backgroundColor: "#44b700",
      color: "#44b700",
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      "&::after": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        border: "1px solid currentColor",
      },
    },
  })
)(Badge);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawerIcon: {
      paddingLeft: "8px",
      paddingRight: "8px",
      [theme.breakpoints.up("sm")]: {
        paddingLeft: "16px",
        paddingRight: "16px",
      },
    },
  })
);

export interface ProfileListItemProps {
  onClick: () => void;
}

const ProfileListItem: FC<ProfileListItemProps> = ({ onClick }) => {
  const classes = useStyles();

  const user = getCurrentUser();

  const { showModal } = useModal({ disableAutoDestroy: true });
  const handlePreferencesClick = () => {
    const userPreferencesDialog = showModal(UserPreferencesDialog, {
      onClose: () => userPreferencesDialog.hide(),
    });
  };

  return (
    <ListItem className={classes.drawerIcon} onClick={onClick}>
      <ListItemIcon title={user.email}>
        <StyledBadge
          overlap="circular"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          variant="dot"
        >
          <Avatar />
        </StyledBadge>
      </ListItemIcon>
      <IconButton
        edge="end"
        style={{ marginLeft: "auto", marginRight: "-6px" }}
        onClick={handlePreferencesClick}
      >
        <Tune />
      </IconButton>

      <IconButton edge="end" component={Link} to="/auth/logout">
        <MeetingRoom />
      </IconButton>
    </ListItem>
  );
};

export default ProfileListItem;

