import ErrorFallback from "../../../../error";
import DailyCashEmployeeDay from "./DailyCashEmployeeDay/DailyCashEmployeeDay";
import DailyCashEmployeePeriod from "./DailyCashEmployeePeriod/DailyCashEmployeePeriod";
import DailyCashEmployeesOverview from "./DailyCashEmployeesOverview/DailyCashEmployeesOverview";
import { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

const DailyCash: FC = () => {
  return (
    <Routes>
      <Route errorElement={<ErrorFallback />}>
        <Route index element={<DailyCashEmployeesOverview />} />
        <Route path=":employeeId" element={<DailyCashEmployeePeriod />} />
        <Route path=":employeeId/:date" element={<DailyCashEmployeeDay />} />

        <Route path="*" element={<DailyCashFallback />} />
      </Route>
    </Routes>
  );
};

const DailyCashFallback: FC = () => <Navigate to="" />;

export default DailyCash;
