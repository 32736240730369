import { Column } from "@material-table/core";
import { IPayrollEmployeeOverviewDto } from "@shared/services/payroll/dtos/payrollEmployeeOverviewDto";

const useDailyCashColumns = (): Column<IPayrollEmployeeOverviewDto>[] => {
  return [
    {
      title: "Име",
      field: "firstName",
    },
    {
      title: "Презиме",
      field: "lastName",
    },
    {
      title: "Позиција",
      field: "employeePosition",
      render: (data) => data.employeePosition.name,
    },
    {
      title: "Заработка",
      field: "earnings",
    },
    {
      title: "KM",
      field: "kilometers",
    },
    {
      title: "Промет (Кеш)",
      field: "cashTurnover",
    },
    {
      title: "Број на нарачки",
      field: "orderCount",
    },

    
  ];
};

export default useDailyCashColumns;
