import React, { FC, memo, ReactNode } from "react";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl, { FormControlProps } from "@material-ui/core/FormControl";
import Select, { SelectProps } from "@material-ui/core/Select";
import { Checkbox, FormHelperText } from "@material-ui/core";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      minWidth: 200,
    },
  },
  getContentAnchorEl: null,
};

export type SelectOption<T> = { text: string | ReactNode; value: T };

interface Props extends SelectProps, Pick<FormControlProps, "size"> {
  selectOptions: SelectOption<any>[];
  value: any;
  onChange?: (value: any) => void;
  touched?: boolean;
  disableEmpty?: boolean;
  errorMessage?: string;
  dense?: boolean;
}

const CustomSelect: FC<Props> = ({
  value,
  errorMessage,
  touched,
  onChange,
  size,
  dense,
  variant,
  label,
  selectOptions,
  multiple,
  required,
  fullWidth = true,
  disableEmpty,
  ...rest
}) => {
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    if (onChange) onChange(event.target.value);
  };

  const renderValue = (selected: unknown) => {
    const selectedItem = Array.isArray(selected)
      ? selected.map((x) => selectOptions.find((y) => y.value === x)?.text).join(", ")
      : selectOptions.find((x) => x.value === selected)?.text;

    return selectedItem;
  };

  return selectOptions ? (
    <FormControl
      variant={variant}
      size={size}
      error={touched && Boolean(errorMessage)}
      fullWidth={fullWidth}
      required={required}
    >
      {label ? <InputLabel>{label}</InputLabel> : null}

      <Select
        {...rest}
        multiple={multiple}
        label={label}
        value={value ?? (multiple ? [] : null)}
        onChange={handleChange}
        MenuProps={MenuProps}
        fullWidth
        renderValue={renderValue}
      >
        {!disableEmpty && !multiple ? <MenuItem value={0}>&nbsp;</MenuItem> : null}

        {selectOptions.map((opt) => (
          <MenuItem
            key={opt.value}
            value={opt.value}
            selected={multiple ? value?.some((x) => x === opt.value) ?? false : value === opt.value}
            dense={dense}
          >
            {multiple ? (
              <Checkbox
                size={dense ? "small" : "medium"}
                checked={value?.some((x) => x === opt.value) ?? false}
                disableRipple
              />
            ) : null}

            {opt.text}
          </MenuItem>
        ))}
      </Select>

      {touched && errorMessage ? <FormHelperText>{touched && errorMessage}</FormHelperText> : null}
    </FormControl>
  ) : null;
};

export default memo(CustomSelect);
