import ErrorFallback from "../../../../../../error";
import useEmployeePayoutsHistoryColumns from "../hooks/useEmployeePayoutsHistoryColumns";
import MaterialTable from "@shared/components/MaterialTable/MaterialTable";
import { IEmployeePayoutDto } from "@shared/services/payout/dtos/employeePayoutDto";
import { IEmployeePayoutHistoryDto } from "@shared/services/payout/dtos/employeePayoutHistoryDto";
import { getEmployeePayoutsHistory } from "@shared/services/payout/payout.service";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { FC } from "react";
import { useNavigate } from "react-router-dom";

type Props = {
  employeePayout: IEmployeePayoutDto;
};

const EmployeePayoutsHistory: FC<Props> = ({ employeePayout }) => {
  const navigate = useNavigate();

  const {
    data: employeePayoutsHistory,
    isError,
    error,
    isFetching,
  } = useQuery([employeePayout.employeeId], () =>
    getEmployeePayoutsHistory(employeePayout.employeeId)
  );

  const columns = useEmployeePayoutsHistoryColumns();

  if (isError) return <ErrorFallback error={error} />;

  return (
    <div>
      <MaterialTable<IEmployeePayoutHistoryDto>
        title={`Историја на исплата за ${employeePayout.firstName} ${employeePayout.lastName}`}
        options={{ search: false, maxColumnSort: 1 }}
        columns={columns}
        data={employeePayoutsHistory}
        isLoading={isFetching}
        onRowClick={(_, rowdata) => {
          const from = dayjs(rowdata.from).format("YYYY-MM-DD");
          const to = dayjs(rowdata.to).format("YYYY-MM-DD");
          navigate(`/app/payroll/${employeePayout.employeeId}?from=${from}&to=${to}`);
        }}
      />
    </div>
  );
};

export default EmployeePayoutsHistory;
