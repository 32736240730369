import OrderLogDetails from "./OrderLogDetails";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  makeStyles,
} from "@material-ui/core";
import { InfoOutlined, Refresh } from "@material-ui/icons";
import CustomSelect from "@shared/components/CustomFormControls/CustomSelect";
import DialogTitle from "@shared/components/Dialog/DialogTitle";
import { useMemoSelector } from "@shared/hooks";
import { ExternalSystem } from "@shared/services/auth/enums/externalSystem";
import { translateExternalSystem } from "@shared/services/auth/system-connections.service";
import { getEmployees } from "@shared/services/employees/employees.service";
import { IOrderDto } from "@shared/services/orders/dtos/orders/orderDto";
import { getOrders, translateOrderStatus } from "@shared/services/orders/orders.service";
import { IDeliveryTypeDto } from "@shared/services/settings/dtos/delivery-types/deliveryTypeDto";
import { IPaymentTypeDto } from "@shared/services/settings/dtos/payment-types/paymentTypeDto";
import { exportWorkShifts } from "@shared/services/settings/workshift.service";
import { useMutation, useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { isNumber } from "lodash";
import { useSnackbar } from "notistack";
import React, { FC, useState } from "react";

interface Column {
  id: keyof IOrderDto | "info" | "assign" | "driver";
  label: string;
  minWidth?: number;
  padding?: "default" | "checkbox" | "none";
  align?: "right" | "left" | "center";
  format?: (value: any, searchInData?: any[]) => string;
}

interface IFilterState {
  externalOrderNumber: string;
  clientID: string;
  customerNumber: string;
  partnerID: string;
  displayOrderNumber: string;
  // assign: string;
  employeeFk: string;
}

const columns: Column[] = [
  { id: "id", label: "", padding: "checkbox", minWidth: 50 },

  {
    id: "displayOrderNumber",
    label: "Број на нарачка",
  },
  {
    id: "isPriority",
    label: "Приоритетна",
    format: (value: boolean) => (value ? "Да" : "Не"),
  },
  {
    id: "externalOrderNumber",
    label: "Број на нарачка",
  },
  {
    id: "clientID",
    label: "Клиент",
    format: (value: string) => translateExternalSystem(parseInt(value)),
  },
  {
    id: "assign",
    label: "Доделување",
  },
  {
    id: "employeeFk",
    label: "Возач",
  },
  {
    id: "pickUpTime",
    label: "Време на подигање",
    format: (value: string) => dayjs.utc(value).local().format("DD.MM.YYYY HH:mm"),
  },
  {
    id: "pickUpAddress",
    label: "Адреса на подигање",
  },
  {
    id: "dropOffTime",
    label: "Време на достава",
    format: (value: string) => dayjs.utc(value).local().format("DD.MM.YYYY HH:mm"),
  },
  {
    id: "dropOffAddress",
    label: "Адреса на достава",
  },
  {
    id: "paymentTypeFK",
    label: "Тип на наплата",
    format: (value: number, paymentTypes: IPaymentTypeDto[]): string =>
      paymentTypes.find((x) => x.id === value)?.name,
  },
  {
    id: "deliveryTypeFK",
    label: "Тип на достава",
    format: (value: number, deliveryTypes: IDeliveryTypeDto[]): string =>
      deliveryTypes.find((x) => x.id === value)?.name,
  },
  {
    id: "orderStatus",
    label: "Статус",
    format: (value: number) => translateOrderStatus(value),
  },
];

const useStyles = makeStyles({
  root: {
    width: "100%",
    marginTop: 10,
  },
  container: {
    height: "80vh",
  },
});

const OrdersLog: FC = () => {
  const { enqueueSnackbar } = useSnackbar();

  const exportWorkShiftsMutation = useMutation(exportWorkShifts, {
    onSuccess: () => {
      enqueueSnackbar("Успешно експортирано.");
    },
    onError: () => {
      enqueueSnackbar(
        "Се случи грешка. Ве молиме обидете се повторно или контактирајте го тимот за поддршка."
      );
    },
  });

  const {
    data: orders,
    isLoading,
    isFetching,
    refetch,
  } = useQuery(["allOrders"], getOrders, {
    refetchInterval: 30000,
  });

  const { paymentTypes, deliveryTypes } = useMemoSelector((state) => ({
    paymentTypes: state.sharedState?.paymentTypes?.data,
    deliveryTypes: state.sharedState?.deliveryTypes?.data,
  }));

  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [selectedOrder, setSelectedOrder] = useState(0);
  const [sortedEmployees, setSortedEmployees] = useState([]);
  const {
    data: employees,
    isLoading: isLoadingEmployees,
    error: errorEmployees,
  } = useQuery(["employees"], getEmployees);
  const [filter, setFilterState] = useState<IFilterState>({
    displayOrderNumber: "",
    externalOrderNumber: "",
    clientID: "",
    customerNumber: "",
    partnerID: "",
    // assign: "",
    employeeFk: "",
  });

  const orderNumber = filter.displayOrderNumber
    ? filter.displayOrderNumber
    : filter.externalOrderNumber;

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleRowClick = (orderId: number) => {
    setSelectedOrder(orderId);
  };

  const handleFilterChange = (filterKey: keyof IFilterState, value: string) => {
    setPage(0);

    const cleanValue = value?.toString().trim();
    setFilterState((state) => ({ ...state, [filterKey]: cleanValue }));
  };

  const searchOrders = (orders: IOrderDto[]): IOrderDto[] => {
    return orders
      ?.filter((order) =>
        Object.keys(filter)
          ?.map((key) =>
            order[key]?.toString().toLowerCase().includes(filter[key]?.toString().toLowerCase())
          )
          ?.every((x) => x === true)
      )
      .sort((a, b) => {
        return a.id - b.id;
      });
  };

  React.useEffect(() => {
    if (employees) {
      const sortedEmployees = employees.sort((a, b) => {
        const firstnameA = a.firstName.toLowerCase();
        const firstnameB = b.firstName.toLowerCase();
        if (firstnameA < firstnameB) return -1;
        if (firstnameA > firstnameB) return 1;
        return 0;
      });
      // Example of how to store them in a state, assuming you have useState hook
      setSortedEmployees(sortedEmployees);
    }
  }, [employees]);

  const filteredOrders = searchOrders(orders);

  if (isLoadingEmployees) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Paper style={{ padding: "5px" }}>
            <Box display="flex" flexDirection="row">
              <TextField
                style={{ margin: 5 }}
                size="small"
                type="search"
                id={filter.displayOrderNumber ? "displayOrderNumber" : "externalOrderNumber"}
                label="Број на нарачка"
                value={
                  filter.displayOrderNumber ? filter.displayOrderNumber : filter.externalOrderNumber
                }
                onChange={(e) =>
                  handleFilterChange(
                    filter.displayOrderNumber ? "displayOrderNumber" : "externalOrderNumber",
                    e.target.value
                  )
                }
                variant="outlined"
                autoComplete="off"
              />
              <TextField
                style={{ margin: 5 }}
                size="small"
                type="search"
                id="customerNumber"
                label="Број на корисник"
                value={filter.customerNumber}
                onChange={(e) => handleFilterChange("customerNumber", e.target.value)}
                variant="outlined"
                autoComplete="off"
              />
              <TextField
                style={{ margin: 5 }}
                size="small"
                type="search"
                id="partnerID"
                label="Број на партнер"
                value={filter.partnerID}
                onChange={(e) => handleFilterChange("partnerID", e.target.value)}
                variant="outlined"
                autoComplete="off"
              />

              <div style={{ margin: 5, minWidth: "150px" }}>
                <CustomSelect
                  label="Клиент"
                  value={~~filter.clientID || ""} // Convert to number, if NaN return 0, and fallback to empty string
                  touched={false}
                  onChange={(e) => handleFilterChange("clientID", e || "")}
                  size="small"
                  variant="outlined"
                  selectOptions={Object.values(ExternalSystem)
                    .filter((value) => !isNumber(value))
                    .map((value) => ({
                      text: translateExternalSystem(ExternalSystem[value]),
                      value: ExternalSystem[value],
                    }))}
                  multiple={false}
                  id="client_select"
                />
              </div>
              <div style={{ margin: 5, minWidth: "150px" }}>
                <CustomSelect
                  label="Возач"
                  value={~~filter.employeeFk || ""} // Convert to number, if NaN return 0, and fallback to empty string
                  touched={false}
                  onChange={(e) => handleFilterChange("employeeFk", e || "")}
                  size="small"
                  variant="outlined"
                  selectOptions={sortedEmployees?.map((employee) => ({
                    text: `${employee.firstName} ${employee.lastName}`,
                    value: employee.id,
                  }))}
                  multiple={false}
                  id="client_select"
                />
              </div>
              <div style={{ margin: 5, minWidth: "150px" }}>
                <Button
                  style={{ marginLeft: 4, marginRight: 4, color: "white" }}
                  variant="contained"
                  color="primary"
                  onClick={() => exportWorkShiftsMutation.mutate()}
                >
                  Eкспортирај смени
                </Button>
              </div>
              {/*<div style={{ margin: 5, minWidth: "150px" }}>
                <CustomSelect
                  label="Доделување"
                  value={~~filter.assign || ""} // Convert to number, if NaN return 0, and fallback to empty string
                  touched={false}
                  onChange={(e) => handleFilterChange("assign", e || "")}
                  size="small"
                  variant="outlined"
                  selectOptions={
                    [
                      { text: "Рачно", value: "manual" },
                      { text: "Алгоритам", value: "algorithm" },

                    ]
                  }
                  multiple={false}
                  id="client_select"
                />
              </div> */}
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.root}>
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        padding={column.padding}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                    <TableCell
                      title="Освежи податоци"
                      align="center"
                      padding="checkbox"
                      key="globalrefetch"
                    >
                      <IconButton onClick={() => (!isLoading || !isFetching ? refetch() : null)}>
                        {isLoading || isFetching ? <CircularProgress size={25} /> : <Refresh />}
                      </IconButton>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredOrders?.length > 0
                    ? filteredOrders
                        .reverse()
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row) => {
                          return (
                            <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                              {columns.map((column) => {
                                const value = row[column.id];
                                return (
                                  <TableCell key={column.id} align={column.align}>
                                    {column.format
                                      ? column.format(
                                          value,
                                          column.id === "deliveryTypeFK"
                                            ? deliveryTypes
                                            : column.id === "paymentTypeFK"
                                            ? paymentTypes
                                            : null
                                        )
                                      : value}
                                  </TableCell>
                                );
                              })}
                              <TableCell
                                align="center"
                                padding="checkbox"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  handleRowClick(row.id);
                                }}
                              >
                                <IconButton size="small">
                                  <InfoOutlined color="primary" />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          );
                        })
                    : null}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[15, 30, 60]}
              component="div"
              count={filteredOrders?.length || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Grid>
      </Grid>

      <Dialog
        fullWidth={true}
        maxWidth="lg"
        onClose={() => {
          setSelectedOrder(0);
        }}
        aria-labelledby="order-details-dialog"
        open={selectedOrder ? true : false}
      >
        <DialogTitle
          icon={true}
          onClose={() => {
            setSelectedOrder(0);
          }}
          id="customized-dialog-title"
        >
          Детали за нарачка #{selectedOrder}
        </DialogTitle>
        <DialogContent>
          <OrderLogDetails orderId={selectedOrder} />
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => {
              setSelectedOrder(0);
            }}
            color="primary"
          >
            Затвори
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default OrdersLog;

