import httpService from "../http.service";
import { IEmployeePayoutHistoryDto } from "./dtos/employeePayoutHistoryDto";
import { PayoutHistoryDto } from "./dtos/payoutHistory";
import { ICreatePayoutDto } from "./dtos/request/createPayoutDto";
import { IExportPayoutDto } from "./dtos/request/exportPayoutDto";
import { IFreezePayoutDto } from "./dtos/request/freezePayoutDto";
import { IPayoutsQueryDto } from "./dtos/request/payoutsQueryDto";
import { IUnfreezePayoutDto } from "./dtos/request/unfreezePayoutDto";

const controllerUrl = "/api/payouts";

export const getAllPayouts = (query: IPayoutsQueryDto) => {
  return httpService.post<IPayoutsQueryDto, IPayoutsQueryDto>(controllerUrl, query);
};


export const getPayoutsHistory = () => {
  return httpService.post<PayoutHistoryDto[]>(`${controllerUrl}/history`, {});
};

export const downloadPayoutHistory = (id: number) => {
  return httpService.getBlob(`${controllerUrl}/history/download?id=${id}`);
};

export const downloadXmlCarRent = (id: number) => {
  return httpService.getBlob(`${controllerUrl}/history/xml/car-rent?id=${id}`);
};

export const downloadXmlContract = (id: number) => {
  return httpService.getBlob(`${controllerUrl}/history/xml/contract?id=${id}`);
};


export const deletePayoutHistory = (id: number) => {
  return httpService.delete(`${controllerUrl}/history/${id}`);
};
export const getEmployeePayoutsHistory = (employeeId: number) => {
  return httpService.get<IEmployeePayoutHistoryDto[]>(controllerUrl + `/${employeeId}/history`);
};

export const freezeEmployee = (employeeId: number, payload: IFreezePayoutDto) => {
  return httpService.post<void, IFreezePayoutDto>(controllerUrl + `/${employeeId}/freeze`, payload);
};

export const unfreezeEmployee = (employeeId: number, payload: IUnfreezePayoutDto) => {
  return httpService.post<void, IUnfreezePayoutDto>(
    controllerUrl + `/${employeeId}/unfreeze`,
    payload
  );
};

export const createPayout = (createPayout: ICreatePayoutDto) => {
  return httpService.post<void, ICreatePayoutDto>(controllerUrl + "/create", createPayout);
};

export const exportPayout = (exportPayout: IExportPayoutDto) => {
  return httpService.getBlobPost(controllerUrl + "/export", exportPayout);
};
