import httpService from "../http.service";
import type { IEmployeeSalaryBonusDto } from "./dtos/employee-salary-configuration/employeeSalaryBonusesDto";
import type { IEmployeeSalaryConfigurationDto } from "./dtos/employee-salary-configuration/employeeSalaryConfigurationDto";

const controllerUrl = "/api/employee-salary-configuration";

export const getAllEmployeeSalaries = () => {
  return httpService
    .get<IEmployeeSalaryConfigurationDto[]>(controllerUrl)
    .then<IEmployeeSalaryConfigurationDto[]>((data) =>
      data.map((item) => ({
        ...item,
        validFrom: item.validFrom ? new Date(item.validFrom) : undefined,
        validTo: item.validTo ? new Date(item.validTo) : undefined,
      }))
    );
};

export const getEmployeeSalaryById = (id: number) => {
  return httpService
    .get<IEmployeeSalaryConfigurationDto>(`${controllerUrl}/${id}`)
    .then<IEmployeeSalaryConfigurationDto>((data) => ({
      ...data,
      validFrom: data.validFrom ? new Date(data.validFrom) : undefined,
      validTo: data.validTo ? new Date(data.validTo) : undefined,
    }));
};

export const getEmployeeSalaryByEmployeeId = (employeeId: number) => {
  return (
    httpService
      // .get<IEmployeeSalaryConfigurationDto>(controllerUrl + `/employee/${employeeId}`) //! BE needs to implmenet this endpoint
      .get<IEmployeeSalaryConfigurationDto>(`${controllerUrl}/${employeeId}`)
      .then<IEmployeeSalaryConfigurationDto>((data) => ({
        ...data,
        validFrom: data.validFrom ? new Date(data.validFrom) : undefined,
        validTo: data.validTo ? new Date(data.validTo) : undefined,
      }))
  );
};

export const updateEmployeeSalary = (employeeSalary: IEmployeeSalaryConfigurationDto) => {
  return httpService
    .put<IEmployeeSalaryConfigurationDto, IEmployeeSalaryConfigurationDto>(
      controllerUrl,
      employeeSalary
    )
    .then<IEmployeeSalaryConfigurationDto>((data) => ({
      ...data,
      validFrom: data.validFrom ? new Date(data.validFrom) : undefined,
      validTo: data.validTo ? new Date(data.validTo) : undefined,
    }));
};

export const addEmployeeSalaryBonus = (bonus: IEmployeeSalaryBonusDto) => {
  return httpService
    .post<IEmployeeSalaryBonusDto>(
      `${controllerUrl}/bonus`,
      bonus
    );
};

export const updateEmployeeSalaryBonus = (bonus: IEmployeeSalaryBonusDto) => {
  return httpService
    .put<IEmployeeSalaryBonusDto>(
      `${controllerUrl}/bonus/${bonus.id}`,
      bonus
    );
};

export const deleteEmpoyeeSalaryBonus = (id: number) => {
  return httpService
    .delete<IEmployeeSalaryBonusDto>(`${controllerUrl}/bonus/${id}`);
};