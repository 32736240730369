import Receipt from "../Receipt/Receipt";
import {
  Avatar,
  Button,
  Chip,
  Divider,
  Grid,
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  Typography,
} from "@material-ui/core";
import { createStyles, makeStyles, withStyles } from "@material-ui/core/styles";
import {
  CheckOutlined,
  CreditCard,
  Directions,
  ExpandMore,
  Info,
  LocalShipping,
  Lock,
  PanTool,
  Person,
  Phone,
  Schedule,
  Storefront,
} from "@material-ui/icons";
import ChipContainer from "@shared/components/ChipContainer/ChipContainer";
import ColoredButton from "@shared/components/ColoredButton/ColoredButton";
import { useMemoSelector } from "@shared/hooks";
import { IDriverRoutePointDto } from "@shared/services/orders/dtos/orders/driverRoutePointDto";
import { cutString } from "@shared/utils/cutString";
import { getDisplayValue } from "@shared/utils/getDisplayValue";
import dayjs from "dayjs";
import { FC, useEffect, useState } from "react";

const Accordion = withStyles({
  root: {
    width: "100%",
    border: "1px solid rgba(0, 0, 0, .125)",
    divShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: 0,
    // padding: theme.spacing(2),
    flexDirection: "column",
  },
}))(MuiAccordionDetails);

const useStyles = makeStyles((theme) =>
  createStyles({
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: "normal",
      display: "flex",
      justifyContent: "center",
      gap: theme.spacing(1.5),
    },
    routePointContainer: {
      padding: theme.spacing(1),
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1),
    },
    addressContainer: {},
    addressText: {
      whiteSpace: "normal",
      fontWeight: "normal",
      backgroundColor: "#dadada",
      color: "rgba(0, 0, 0, 0.87)",
      padding: theme.spacing(0.5),
    },
    chipIcon: {
      backgroundColor: "transparent !important",
    },
    restaurantContainer: {
      display: "flex",
      gap: theme.spacing(1),
      alignItems: "center",
    },
    restaurantImage: {
      width: "60px",
      height: "60px",
    },
    restaurantNameContainer: {
      overflow: "auto",
    },
    buttonContainer: {
      padding: theme.spacing(1),
    },
    button: {
      cursor: "pointer",
    },
    buttonIcon: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    confirmButton: {
      borderRadius: 0,
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      color: "white",
      fontWeight: "bold",
      fontSize: "1.1rem",
    },
  })
);

interface PickUpRoutePointProps {
  routePointPackage: IDriverRoutePointDto[];
  isLocked?: boolean;
  onActionButtonClick: (
    orderId: number,
    mergeGuid: string,
    packageGuid: string,
    action: "pick-up" | "drop-off"
  ) => void;
}

const PickUpRoutePoint: FC<PickUpRoutePointProps> = ({
  routePointPackage,
  isLocked,
  onActionButtonClick,
}) => {
  const classes = useStyles();

  const { paymentTypes, deliveryTypes } = useMemoSelector(({ sharedState }) => ({
    paymentTypes: sharedState.paymentTypes.data,
    deliveryTypes: sharedState.deliveryTypes.data,
  }));

  const getPaymentTypeText = (FK: number) => getDisplayValue(paymentTypes, FK);
  const getDeliveryTypeText = (FK: number) => getDisplayValue(deliveryTypes, FK);

  const [expanded, setExpanded] = useState(true);
  const handleChange = (_, isExpanded: boolean) => {
    setExpanded(isExpanded);
  };

  // Close the accordion if the route point becomes locked
  useEffect(() => {
    setExpanded(!isLocked);
  }, [isLocked]);

  if (routePointPackage.length === 0) return null;
  const baseRoutePoint = routePointPackage[0];

  const { orderId, mergeGuid, packageGuid, isPickup, latitude, longitude, phoneNumber, isCallAllowed} =
    baseRoutePoint;

  const type = isPickup ? "pick-up" : "drop-off";

  const parsedPhoneNumber = phoneNumber?.split(",")?.[0] || "";

  return (
    <Accordion disabled={isLocked} expanded={expanded} onChange={handleChange} square>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography className={classes.heading}>
          {/* Status icon */}
          {baseRoutePoint.onLocationAt ? (
            <CheckOutlined style={{ color: "green" }} />
          ) : isLocked ? (
            <Lock />
          ) : (
            <LocalShipping style={{ color: "orange" }} />
          )}

          <span>{type === "pick-up" ? "Подигнување" : "Достава"}</span>
        </Typography>
      </AccordionSummary>

      {!isLocked && (
        <AccordionDetails>
          {routePointPackage.map((routePoint) => (
            <>
              {/* Order descriptors */}
              <ChipContainer>
                {getPaymentTypeText(routePoint.paymentTypeFK) ? (
                  <Chip
                    size="small"
                    label={getPaymentTypeText(routePoint.paymentTypeFK)}
                    avatar={<CreditCard />}
                    variant="outlined"
                    component="li"
                    title="Тип на наплата"
                  />
                ) : null}

                {getDeliveryTypeText(routePoint.deliveryTypeFK) ? (
                  <Chip
                    size="small"
                    label={getDeliveryTypeText(routePoint.deliveryTypeFK)}
                    avatar={<LocalShipping />} // TODO: Switch icon depending on type
                    variant="outlined"
                    component="li"
                    title="Тип на пратка"
                  />
                ) : null}

                {routePoint.isPriority ? (
                  <Chip
                    data-testid="priority-indicator"
                    size="small"
                    label="Приоритетна"
                    avatar={<Info />}
                    classes={{
                      avatar: classes.chipIcon,
                    }}
                    variant="default"
                    color="secondary"
                    component="li"
                    title="Приоритетна"
                  />
                ) : null}

                {routePoint.isContactless ? (
                  <Chip
                    data-testid="contactless-indicator"
                    size="small"
                    label="Бесконтактна"
                    avatar={<PanTool />}
                    classes={{
                      avatar: classes.chipIcon,
                    }}
                    variant="default"
                    color="secondary"
                    component="li"
                    title="Бесконтактна достава"
                  />
                ) : null}
              </ChipContainer>

              <Divider />

              {/* Pick up */}
              <div className={classes.routePointContainer}>
                {/* Restaurant information */}
                <div className={classes.restaurantContainer}>
                  <Avatar
                    variant="rounded"
                    src={routePoint?.photo}
                    className={classes.restaurantImage}
                  >
                    {routePoint.isPickup ? <Storefront /> : <Person fontSize="large" />}
                  </Avatar>

                  <div className={classes.restaurantNameContainer}>
                    <Typography variant="h6">
                      <Typography color="primary" variant="h6" display="inline">
                        {routePoint?.displayOrderNumber ? (
                          <strong>{routePoint?.displayOrderNumber}</strong>
                        ) : (
                          <strong>
                            #{cutString(routePoint?.externalOrderNumber.toString(), 3)}
                          </strong>
                        )}
                      </Typography>{" "}
                      - {routePoint.name}
                    </Typography>

                    <ChipContainer disableHorizontalPadding disableVerticalPadding>
                      <Chip
                        size="small"
                        label={dayjs.utc(routePoint.time).local().format("HH:mm")}
                        variant="outlined"
                        avatar={<Schedule />}
                        component="li"
                        title="Време на подигнување"
                      />
                    </ChipContainer>
                  </div>
                </div>
                <Typography variant="h6" className={classes.addressText} noWrap>
                  {/* {routePoint.address?.apartment && <div>{routePoint.address?.apartment}.</div>} */}
                  {routePoint.address?.formatted && (
                    <div>
                      {routePoint.address?.formatted
                        .replace(/,\s*\/\s*,/g, ",")
                        .replace(/,\s*,/g, ",")
                        .replace(/^,|,$/g, "")
                        .trim()}
                    </div>
                  )}
                  {/* {routePoint.address?.street && <div>{routePoint.address?.street},</div>} */}
                  {/* {routePoint.address?.streetNumber && `${routePoint.address?.streetNumber} `} */}
                </Typography>
              </div>

              <Divider />

              {routePoint.driverComment && (
                <>
                  <div style={{ padding: 1, margin: 1, backgroundColor: "lightcoral" }}>
                    <Typography variant="caption">
                      <b>КОМЕНТАР:</b> {routePoint.driverComment}
                    </Typography>
                  </div>

                  <Divider />
                </>
              )}

              <Receipt orderDetails={routePoint.orderDetails} />

              <Divider />
            </>
          ))}

          <Grid spacing={2} container className={classes.buttonContainer}>
            <Grid xs item>
              <ColoredButton
                color="info"
                variant="outlined"
                className={classes.button}
                classes={{ label: classes.buttonIcon }}
                href={`google.navigation:q=${latitude},${longitude}`}
                fullWidth
              >
                <Directions fontSize="large" />
                Мапа
              </ColoredButton>
            </Grid>

            {/* Call restaurant button */}
            <Grid xs item>
              <ColoredButton
                color="success"
                variant="outlined"
                className={classes.button}
                classes={{ label: classes.buttonIcon }}
                fullWidth
                href={`tel:${parsedPhoneNumber}`}
                disabled={ isCallAllowed ? !parsedPhoneNumber : true}
              >
                <Phone fontSize="large" />
                {type === "pick-up" ? "Ресторан" : "Корисник"}
              </ColoredButton>
            </Grid>

            {/* Cancel order button */}
            {/* <Grid xs item>
              <ColoredButton
                color="error"
                variant="outlined"
                className={classes.button}
                classes={{ label: classes.buttonIcon }}
                fullWidth
                disabled
              >
                <Cancel fontSize="large" />
                Откажи
              </ColoredButton>
            </Grid> */}
          </Grid>

          <Divider />

          <Button
            variant="contained"
            color="primary"
            startIcon={<CheckOutlined />}
            className={classes.confirmButton}
            onClick={() => onActionButtonClick(orderId, mergeGuid, packageGuid, type)}
            fullWidth
          >
            {type === "pick-up" ? "ПОДИГНИ" : "ДОСТАВИ"}
          </Button>
        </AccordionDetails>
      )}
    </Accordion>
  );
};

export default PickUpRoutePoint;

