import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import Table from "./Table";
import TableToolbar from "./TableToolbar";
import TableHeader from "./TableHeader";
import TableRows from "./TableRows";
import { Order } from "./types/Order";
import { ITableHeaderCell } from "./types/ITableHeaderCell";
import { CircularProgress, IconButton, Size } from "@material-ui/core";
import { Refresh } from "@material-ui/icons";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2),
    },
    button: {
      whiteSpace: "nowrap",
      minWidth: "auto",
    },
    refreshButton: {
      marginRight: theme.spacing(1),
    },
    "& th, & td": {
      paddingRight: "5px",
      paddingLeft: "5px",
      fontSize: "14px",
    }
  })
);

export interface TableViewProps {
  title: string;
  addButtonText: string;
  headers: ITableHeaderCell<any>[];
  rows: any[];
  render: (row: any) => React.ReactNode;
  addDialog?: React.ReactNode;
  onAddButtonClick?: () => void;
  onRefreshClick?: () => void;
  isLoading?: boolean;
  size?: Size;
  stickyHeader?: boolean;
  disablePagination?: boolean;
}

const TableView: React.FC<TableViewProps> = (props) => {
  const classes = useStyles();

  const {
    headers,
    rows,
    render,
    addDialog,
    addButtonText,
    onAddButtonClick,
    onRefreshClick,
    title,
    isLoading,
    size = "medium",
    stickyHeader = false,
    disablePagination = false,
  } = props;

  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<string>("");

  // Current page
  const [page, setPage] = React.useState(0);
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  // How many rows per page
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (
    e: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableToolbar title={title}>
          {addDialog ? addDialog : null}

          {/* Loading spinner / Refresh button */}
          {isLoading || onRefreshClick ? (
            <IconButton
              className={classes.refreshButton}
              onClick={!isLoading ? onRefreshClick : undefined}
            >
              {isLoading ? <CircularProgress size={25} /> : <Refresh />}
            </IconButton>
          ) : null}

          {/* Dialog button */}
          {
            onAddButtonClick ? <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={onAddButtonClick}
            >
              {addButtonText}
            </Button> : null
          }

        </TableToolbar>

        <Divider />

        <Table size={size} strickyHeader={stickyHeader} title="driversTable">
          <TableHeader
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headerCells={headers}
          />

          <TableBody>
            <TableRows
              order={order}
              orderBy={orderBy}
              page={page}
              rows={rows}
              rowsPerPage={rowsPerPage}
            >
              {/* These are the cells of the row
                They are passed through the render prop */}
              {render}
            </TableRows>
          </TableBody>
        </Table>

        {disablePagination === false ? (
          <TablePagination
            rowsPerPageOptions={[20, 50, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        ) : null}
      </Paper>
    </div>
  );
};

export default TableView;
