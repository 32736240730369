import { groupBy } from "../utils";
import CalculateNextDestinationButton from "./CalculateNextDestinationButton";
import CardList from "./CardList";
import NewOrderCard from "./NewOrder/NewOrderCard";
import OrdersToolbar from "./Orders/OrdersToolbar";
import RoutePoint from "./RoutePoints/RoutePoint";
import { Divider, Paper } from "@material-ui/core";
import red from "@material-ui/core/colors/red";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import { Warning } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import { ConfirmationDialogProps } from "@shared/components/ConfirmationDialog/ConfirmationDialog";
import { SelectOption } from "@shared/components/CustomFormControls/CustomMenuSelect";
import ErrorMessage from "@shared/components/ErrorMessage/ErrorMessage";
import { useConfirmationDialog } from "@shared/context/ConfirmationDialogContext";
import { getCurrentUser } from "@shared/services/auth/auth.service";
import { IDriverRoutePointDto } from "@shared/services/orders/dtos/orders/driverRoutePointDto";
import { OrderStatus } from "@shared/services/orders/enums/orderStatus";
import { getDriverPendingAndActiveOrders } from "@shared/services/orders/orders.service";
import {
  driverAcceptsGroupedOrdersAsync,
  driverAcceptsMergedOrdersAsync,
  driverAcceptsOrderAsync,
  driverConfirmsMergedOrdersDropOffAsync,
  driverConfirmsMergedOrdersPickUpAsync,
  driverConfirmsOrderDropOffAsync,
  driverConfirmsOrderPickUpAsync,
  driverRejectsMergedOrdersAsync,
  driverRejectsPackageOrdersAsync,
  driverRejectsOrderAsync,
} from "@shared/services/orders/orders.service";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { FC, useState } from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      display: "inline-block",
      width: "100%",
    },
    errorMessage: {
      backgroundColor: red[900],
      color: "white",
      padding: theme.spacing(1),
      textAlign: "center",

      position: "sticky",
      top: 0,
      zIndex: 100,
    },
    processingAlert: {
      borderRadius: 0,
    },
  })
);

type OrderAction = "pick-up" | "drop-off" | "accept" | "reject";

const getDriverOrders = () => {
  const currentUser = getCurrentUser();

  if (!currentUser || !currentUser.employeeId)
    throw new Error("Employee Id for the user is not found");

  return getDriverPendingAndActiveOrders(currentUser.employeeId);
};

const ActiveOrders: FC = () => {
  const classes = useStyles();

  const [errorMessage, setErrorMessage] = useState("");

  const {
    data: orders,
    isLoading,
    isFetching,
    refetch,
    error,
  } = useQuery(["pendingAndActiveDriverOrders"], getDriverOrders, {
    refetchInterval: 15000,
    refetchOnReconnect: true,
    retry: (count) => count < 2,
    onSuccess: () => {
      setErrorMessage("");
    },
    onError: () => {
      setErrorMessage("");
    },
  });

  const handleConfirmationDialogAction = (
    orderId: number,
    mergeGuid: string,
    action: OrderAction,
    orderRejectReasonFK?: number,
    packageGuid?: string
  ) => {
    const currentUser = getCurrentUser();

    if (!currentUser || !currentUser.employeeId)
      return setErrorMessage("Employee Id for the user is not found");

    const APIPromises: Record<OrderAction, () => Promise<unknown>> = {
      accept: () => driverAcceptsOrderAsync(currentUser.employeeId, orderId),
      reject: () =>
        driverRejectsOrderAsync(currentUser.employeeId, orderId, {
          orderRejectReasonFK,
        }),
      "pick-up": () => driverConfirmsOrderPickUpAsync(currentUser.employeeId, orderId),
      "drop-off": () => driverConfirmsOrderDropOffAsync(currentUser.employeeId, orderId),
    };

    const mergedAPIPromises: Record<OrderAction, () => Promise<unknown>> = {
      accept: () => driverAcceptsMergedOrdersAsync(currentUser.employeeId, mergeGuid),
      reject: () =>
        driverRejectsMergedOrdersAsync(currentUser.employeeId, mergeGuid, {
          orderRejectReasonFK,
        }),
      "pick-up": () => driverConfirmsMergedOrdersPickUpAsync(currentUser.employeeId, mergeGuid),
      "drop-off": () => driverConfirmsMergedOrdersDropOffAsync(currentUser.employeeId, mergeGuid),
    };

    const packageAPIPromises: Record<OrderAction, () => Promise<unknown>> = {
      accept: () => driverAcceptsGroupedOrdersAsync(currentUser.employeeId, packageGuid),
      reject: () =>
        driverRejectsPackageOrdersAsync(currentUser.employeeId, packageGuid, {
          orderRejectReasonFK,
        }),
      "pick-up": () => driverConfirmsOrderPickUpAsync(currentUser.employeeId, orderId),
      "drop-off": () => driverConfirmsOrderDropOffAsync(currentUser.employeeId, orderId),
      // reject: () =>
      //   driverRejectsMergedOrdersAsync(currentUser.employeeId, mergeGuid, {
      //     orderRejectReasonFK,
      //   }),
      // "pick-up": () => driverConfirmsMergedOrdersPickUpAsync(currentUser.employeeId, mergeGuid),
      // "drop-off": () => driverConfirmsMergedOrdersDropOffAsync(currentUser.employeeId, mergeGuid),
    };

    // const APIPromise = mergeGuid ? mergedAPIPromises[action] : APIPromises[action];
    let APIPromise = null;
    if (mergeGuid) {
      APIPromise = mergedAPIPromises[action];
    } else if (packageGuid) {
      APIPromise = packageAPIPromises[action];
    } else {
      APIPromise = APIPromises[action];
    }
    if (!APIPromise) {
      return setErrorMessage("An error occured, please try again");
    }

    setErrorMessage("");
    confirmationDialog.setLoading(true);

    APIPromise()
      .then((response) => {
        refetch();

        setErrorMessage("");
        confirmationDialog.close();
      })
      .catch((error) => {
        if (error.response) {
          setErrorMessage(error.response?.data?.message);
        } else {
          setErrorMessage(error.message);
        }

        confirmationDialog.close();
      });
  };

  const confirmationDialog = useConfirmationDialog();

  const handleActionButtonClick = (
    orderId: number,
    mergeGuid: string,
    action: OrderAction,
    packageGuid?: string,
    rejectReason?: SelectOption<number>
  ) => {
    const dialogContents: Record<OrderAction, ConfirmationDialogProps> = {
      accept: {
        title: "Прифати нарачка?",
        body: `Дали сте сигурни дека сакате да ја прифатите нарачката со број ${orderId}?`,
      },
      reject: {
        title: "Одбиј нарачка?",
        body: `Дали сте сигурни дека сакате да ја одбиете нарачката со број ${orderId} поради ${rejectReason?.name}?  За секоја нелегитимно одбиена нарачка следи пенал.`,
      },
      "pick-up": {
        title: "Потврди подигнување?",
        body: `Дали сте сигурни дека сакате да го потврдите подигнувањето на нарачката со број ${orderId}?`,
      },
      "drop-off": {
        title: "Потврди достава?",
        body: `Дали сте сигурни дека сакате да ја потврдите доставата на нарачката со број ${orderId}?`,
      },
    };

    confirmationDialog.open({
      icon: <Warning fontSize="large" style={{ color: "orange" }} />,
      ...dialogContents[action],
      onConfirm: () =>
        handleConfirmationDialogAction(orderId, mergeGuid, action, rejectReason?.id, packageGuid),
      onDeny: () => confirmationDialog.close(),
    });
  };

  const pendingOrdersCount = orders?.pendingOrders?.length || 0;
  const activeOrdersCount =
    orders?.routePoints?.filter(
      (x, i, self) => self.findIndex((y) => y.orderId === x.orderId) === i
    )?.length || 0;

  const isRoutePointLocked = (routePoint: IDriverRoutePointDto) => {
    // When to lock the route points for the order
    // Pick-up point: it's already picked up
    // Drop-off point: it isn't picked up yet

    return routePoint.isPickup
      ? routePoint.orderStatus === OrderStatus.PickedUp
      : routePoint.orderStatus !== OrderStatus.PickedUp;
  };

  const isDropOffAllowed = (routePoint: IDriverRoutePointDto) => {
    // check if the order is picked up
    const isPickedUp = routePoint.orderStatus === OrderStatus.PickedUp;
    if (isPickedUp === false) return false;

    // Find the matching pick-up point
    const pickUpRoutePoint = orders.routePoints?.find(
      (x) => x.orderId === routePoint.orderId && x.isPickup
    );

    // Check the pick-up time
    const pickUpTime = dayjs.utc(pickUpRoutePoint.onLocationAt);
    const minutesSincePickUp = dayjs.utc().diff(pickUpTime, "minutes");

    if (Math.abs(minutesSincePickUp) < 5) {
      const allowedDropOffTime = pickUpTime.add(5, "minutes");

      setErrorMessage(
        `Мора да почекате ${allowedDropOffTime.diff(
          dayjs.utc(),
          "minutes"
        )} минути пред да ја доставите нарачката. Нарачката може да ја доставите во ${allowedDropOffTime
          .local()
          .format("HH:mm")}`
      );

      // Clear the error message after 15 seconds
      setTimeout(() => {
        setErrorMessage("");
      }, 15 * 1000);

      return false;
    }

    return true;
  };

  const getPackages = <T,>(array: T[], key: keyof T, keyExclude: keyof T): T[][] => {
    if (!array) return [];

    let normalNewOrders = array.filter((order) => !order[key] && !order[keyExclude]);

    if (key === "packageGuid") {
      normalNewOrders = [];
    }
    const mergedNewOrders = array.filter((order) => !!order[key] && !order[keyExclude]);

    const mergedPackages = groupBy(mergedNewOrders, key);

    return [...Object.values(mergedPackages), ...normalNewOrders.map((order) => [order])] as T[][];
  };

  const pendingOrderPackages = getPackages(orders?.pendingOrders, "mergeGuid", "packageGuid");
  const pendingOrderPackages2 = getPackages(orders?.pendingOrders, "packageGuid", "mergeGuid");
  const routePointPackages = getPackages(orders?.routePoints, "mergeGuid", "packageGuid");
  const routePointPackages2 = getPackages(orders?.routePoints, "packageGuid", "mergeGuid");

  return (
    <Paper className={classes.paper} square>
      <OrdersToolbar
        title={`Нарачки (${pendingOrdersCount + activeOrdersCount})`}
        isLoading={isLoading || isFetching}
        onRefreshClick={() => {
          setErrorMessage("");
          refetch();
        }}
      />

      <Divider />

      {error ? (
        <ErrorMessage error={(error as Error)?.message} className={classes.errorMessage} />
      ) : orders ? (
        <>
          {errorMessage ? (
            <ErrorMessage error={errorMessage} className={classes.errorMessage} />
          ) : null}

          {orders?.isProcessing && (
            <>
              <Alert variant="filled" severity="info" className={classes.processingAlert}>
                Се бара следна дестинација, ве молиме почекајте
              </Alert>

              <CalculateNextDestinationButton />
            </>
          )}

          <CardList disablePadding={pendingOrderPackages?.length === 0}>
            {pendingOrderPackages?.map((ordersPackage, i) => (
              <NewOrderCard
                key={i}
                ordersPackage={ordersPackage}
                onConfirmClick={(orderId, mergeGuid, packageGuid) => {
                  handleActionButtonClick(orderId, mergeGuid, "accept", packageGuid);
                }}
                onDenyClick={(orderId, mergeGuid, packageGuid, rejectReason) => {
                  handleActionButtonClick(orderId, mergeGuid, "reject", packageGuid, rejectReason);
                }}
              />
            ))}
            {pendingOrderPackages2?.map((ordersPackage, i) => (
              <NewOrderCard
                key={i}
                ordersPackage={ordersPackage}
                onConfirmClick={(orderId, mergeGuid, packageGuid) => {
                  handleActionButtonClick(orderId, mergeGuid, "accept", packageGuid);
                }}
                onDenyClick={(orderId, mergeGuid, packageGuid, rejectReason) => {
                  handleActionButtonClick(orderId, mergeGuid, "reject", packageGuid, rejectReason);
                }}
              />
            ))}
          </CardList>

          {/* Route points */}
          {routePointPackages.map((routePointPackage, i) => (
            <RoutePoint
              key={i}
              routePointPackage={routePointPackage}
              isLocked={pendingOrderPackages?.length > 0}
              onActionButtonClick={(orderId, mergeGuid, packageGuid, action) => {
                // if (
                //   routePoint.isPickup === false &&
                //   isDropOffAllowed(routePoint) === false
                // )
                //   return;

                handleActionButtonClick(orderId, mergeGuid, action, packageGuid);
              }}
            />
          ))}
        </>
      ) : null}
    </Paper>
  );
};

export default ActiveOrders;
