import ErrorFallback from "../../../../../error";
import CitiesFilter from "../../shared/components/CitiesFilter";
import CompaniesFilter from "../../shared/components/CompaniesFilter";
import EmployeePositionsFilter from "../../shared/components/EmployeePositionsFilter";
import PeriodSingleDayFilter, { IPeriodSingleDayFilter } from "../../shared/components/PeriodSingleDayFilter";
import SearchFilter from "../../shared/components/SearchFilter";
import useDailyCashColumns from "./hooks/useDailyCashColumns";

import { Grid, Typography, createStyles, makeStyles } from "@material-ui/core";
import Loading from "@shared/components/Loading/Loading";
import MaterialTable, { ISort, sortHandler } from "@shared/components/MaterialTable/MaterialTable";
import { IPayrollEmployeeOverviewDto } from "@shared/services/payroll/dtos/payrollEmployeeOverviewDto";
import {
  getDailyCashEmployeesOverview,
} from "@shared/services/payroll/payroll.service";
import { getNextPageParam } from "@shared/utils/infiniteQuery";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { FC, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) =>
  createStyles({
    heading: {
      marginBottom: theme.spacing(4),
    },
    filters: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    summaryCards: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    overviewTable: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  })
);

const DailyCashEmployeesOverview: FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [periodFilter, setPeriodFilter] = useState<IPeriodSingleDayFilter>({ from: null, to: null });
  const [employeePositionsFilter, setEmployeePositionsFilter] = useState<number[]>([]);
  const [companiesFilter, setCompaniesFilter] = useState<number[]>([]);
  const [citiesFilter, setCitiesFilter] = useState<number[]>([]);
  const [searchFilter, setSearchFilter] = useState<string>("");
  const [sort, setSort] = useState<ISort>({ by: null, direction: null });


  const employeesOverviewQuery = useInfiniteQuery(
    [
      "getDailyCashEmployeesOverview",
      periodFilter,
      citiesFilter,
      employeePositionsFilter,
      companiesFilter,
      searchFilter,
      sort.by,
      sort.direction,
    ],
    ({ pageParam = 0 }) =>
      getDailyCashEmployeesOverview({
        currentPage: pageParam,
        itemsPerPage: 100,
        filters: {
          from: periodFilter.from,
          to: periodFilter.to,
          cities: citiesFilter,
          employeePositions: employeePositionsFilter,
          companies: companiesFilter,
          searchTerm: searchFilter,
        },
        sortBy: sort.by,
        sortDirection: sort.direction,
        items: [],
        totalItemsCount: 0,
      }),
    {
      keepPreviousData: true,
      getNextPageParam: (lastPage) =>
        getNextPageParam(lastPage.currentPage, lastPage.itemsPerPage, lastPage.totalItemsCount),
    }
  );

  const employeesOverviewColumns = useDailyCashColumns();
  const employeesOverviewData = useMemo(
    () => employeesOverviewQuery.data?.pages?.flatMap((pageData) => pageData.items) ?? [],
    [employeesOverviewQuery.data]
  );

  if (
    employeesOverviewQuery.isError
  )
    return (
      <ErrorFallback
        error={
          employeesOverviewQuery.error
        }
      />
    );

  if (
    employeesOverviewQuery.isLoading
  )
    return <Loading />;

  return (
    <Grid container>
      <Grid container className={classes.heading}>
        <Typography variant="h5">Дневен промет и рути</Typography>
      </Grid>

      <Grid container className={classes.filters}>
        <Grid container direction="row">
          <PeriodSingleDayFilter onChange={(from, to) => setPeriodFilter({ from, to })} />
        </Grid>
        <Grid container direction="row">
          <CitiesFilter onChange={(cities) => setCitiesFilter(cities)} />
          <EmployeePositionsFilter onChange={(roleIds) => setEmployeePositionsFilter(roleIds)} />
          <CompaniesFilter onChange={(companyIds) => setCompaniesFilter(companyIds)} />
          <SearchFilter onChange={(searchTerm) => setSearchFilter(searchTerm)} />
        </Grid>
      </Grid>

      {/* <Grid container className={classes.summaryCards}>
        <AllEmployeePositionsSummaryCard
          allEmployeePositionsSummary={allEmployeePositionsSummaryQuery.data}
        />
        {employeePositionsSummariesQuery.data.map((employeePositionSummary) => (
          <EmployeePositionSummaryCard
            key={employeePositionSummary.employeePosition.name}
            employeePositionSummary={employeePositionSummary}
          />
        ))}
      </Grid> */}

      <MaterialTable<IPayrollEmployeeOverviewDto>
        title="Вработени"
        className={classes.overviewTable}
        columns={employeesOverviewColumns}
        data={employeesOverviewData}
        options={{
          search: false,
          maxColumnSort: 1,
          paging: false
        }}
        isLoading={employeesOverviewQuery.isFetching}
        infiniteLoad={true}
        // hasNextPage={employeesOverviewQuery.hasNextPage}
        // fetchNextPage={employeesOverviewQuery.fetchNextPage}
        onOrderCollectionChange={(oc) => sortHandler(oc, employeesOverviewColumns, setSort)}
        onRowClick={(_, value) => navigate(`/app/dailyCash/${value.id}`)}
      />
    </Grid>
  );
};

export default DailyCashEmployeesOverview;
