import { Avatar, Divider, Link as MatLink, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Help } from "@material-ui/icons";
import { FC } from "react";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
    display: "inline-flex",
  },
  content: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1),
  },
  contentFooter: {
    paddingTop: theme.spacing(2),
  },
}));

const phoneNumber = {
  text: "02 3060 200",
  link: "tel:+38923060200",
};

const Logout: FC = () => {
  const classes = useStyles();

  return (
    <>
      <Avatar className={classes.avatar}>
        <Help />
      </Avatar>

      <Typography component="h1" variant="h5">
        Ја заборавивте лозинката?
      </Typography>

      <div className={classes.content}>
        <Typography component="p" variant="body1">
          Со цел да ја промените вашата лозинка, јавете се на администраторот на:{" "}
          <MatLink href={phoneNumber.link} underline="hover">
            {phoneNumber.text}
          </MatLink>
        </Typography>
      </div>

      <Divider />

      <div className={classes.contentFooter}>
        Се сетивте на лозинката?{" "}
        <MatLink component={Link} to="/auth/login">
          Најави се
        </MatLink>
      </div>
    </>
  );
};

export default Logout;
