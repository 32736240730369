import { CircularProgress } from "@material-ui/core";
import { OrderStatus } from "@shared/services/orders/enums/orderStatus";
import { FC, memo } from "react";
import { useSelector } from "react-redux";

const AlgorithmSpinner: FC = () => {
  const isProcessing = useSelector(({ appState }: IGlobalState) =>
    appState.logistics.orders.data?.some((order) => order.orderStatus === OrderStatus.Processing)
  );

  if (isProcessing) {
    return <CircularProgress size={25} />;
  }

  return null;
};

export default memo(AlgorithmSpinner);
