import { Filter } from "../../interfaces/Filter";
import DriverMarkers from "@Map/components/Drivers/DriverMarkers";
import AreasGeoJSON from "@Map/components/Map/AreasGeoJSON";
import RouteLines from "@Map/components/Map/RouteLines";
import RoutePointMarkers from "@Map/components/Map/RoutePointMarkers";
import UnassignedOrderMarkers from "@Map/components/Map/UnassignedOrderMarkers";
import LeafletMap from "@shared/components/Map/LeafletMap/LeafletMap";
import LeafletMapRefBinder from "@shared/components/Map/LeafletMap/LeafletMapRefBinder";
import MapController from "@shared/components/Map/LeafletMap/MapController";
import MapResizeObserver from "@shared/components/Map/LeafletMap/MapResizeObserver";
import { IMapDriver } from "@shared/components/Map/models/models";
import { useMemoSelector } from "@shared/hooks";
import { OrderStatus } from "@shared/services/order.service";
import { IDriverRouteResponseDto } from "@shared/services/orders/dtos/orders/driverRouteResponseDto";
import {
  isDriverBusy,
  isDriverFree,
  isDriverLost,
  isDriverPaused,
  isLowBattery,
} from "@shared/utils/drivers/driverStatus";
import {
  isOrderCompletelyLate,
  isOrderDelivered,
  isOrderDispatched,
  isOrderDropOffLate,
  isOrderNormal,
  isOrderPickUpLate,
} from "@shared/utils/orders/orderStatus";
import { Map } from "leaflet";
import { flatMap } from "lodash";
import { FC, Ref, memo, useEffect, useMemo, useState } from "react";
import { FeatureGroup, LayersControl, Pane } from "react-leaflet";
import { useSelector } from "react-redux";

/**
 * Check if the element is inside a group that has an active filter
 * @param filter The specific filter state
 * @param groups The groups of elements specific to the filter
 * @param predicate The predicate to check if the object is in an enabled group
 * @returns True if the object is in an enabled filter group
 */
const isInActiveFilterGroup = (
  filter: Filter<{ [key: string]: any }>,
  groups: { [key: string]: unknown[] },
  predicate: (x: any) => boolean
) => {

  // Get the names of the groups that have an active filter
  const activeGroups = Object.entries(filter.groups)
    // .filter(([_, enabled]) => enabled === true)
    // .map(([key]) => key);
    .map(([key, obj]) => {
      const keys = Object.keys(obj);
      return keys.filter(subKey => obj[subKey] === true);
    }).flat();

  // Check if the object is in an active group based on the predicate
  // return activeGroups.every((group) => groups[group].some(predicate));

  return activeGroups.map((group) => groups[group].some(predicate)).some((x) => x);
};


interface Props {
  forwardRef?: Ref<Map>;
}

const DriversMap: FC<Props> = ({ forwardRef }) => {
  const [isRouteLinesChecked, setIsRouteLinesChecked] = useState(true);
  const [isOrderSelected, setIsOrderSelected] = useState(true);

  const { drivers, driverRoutes, orders, filters } = useMemoSelector(({ appState }) => ({
    drivers: appState.logistics.drivers.data,
    driverRoutes: appState.logistics.driversRoutes.data,
    // TODO: FIX THIS
    orders: appState.logistics.orders.data as any,
    filters: appState.logistics.filters,
  }));
  const routePoints = flatMap(driverRoutes, (x) => x.routePoints);
  const unassignedOrders = orders.filter((x) => !isOrderDispatched(x));

  const handleToggleRouteLines = (checked: boolean) => {
    setIsRouteLinesChecked(checked);
    if (checked) {
      [isSelectedDriver];
    }
  };
  const isSelectedDriver = Boolean(
    useSelector(({ appState }: IGlobalState) =>
       appState.logistics.filters.drivers.selectedIds[0]
  )
  );

  useEffect(() => {
    setIsRouteLinesChecked(isSelectedDriver);
  }, [isSelectedDriver]);

  const isSelectedOrder = Boolean(
    useSelector(({ appState }: IGlobalState) =>
       appState.logistics.filters.orders.selectedIds[0]
  )
  );

  useEffect(() => {
    setIsOrderSelected(isOrderSelected);
  }, [isOrderSelected]);


  const driverGroups = useMemo(
    () => ({
      lost: drivers.filter((x) => isDriverLost(x)),
      lowBatteryDrivers: drivers.filter((x) => isLowBattery(x)),
      paused: drivers.filter((x) => isDriverPaused(x)),
      busy: drivers.filter((x) => isDriverBusy(x)),
      free: drivers.filter((x) => isDriverFree(x)),
      hasFiscalPrinter: drivers.filter((x) => x.hasFiscalPrinter),
    }),
    [drivers]
  );

  const driverRouteGroups = useMemo(
    () => ({
      completelyLateOrders: driverRoutes.filter((x) => isOrderCompletelyLate(x)),
      latePickUpOrders: driverRoutes.filter(
        (x) => !isOrderCompletelyLate(x) && isOrderPickUpLate(x)
      ),
      lateDropOffOrders: driverRoutes.filter(
        (x) => !isOrderCompletelyLate(x) && isOrderDropOffLate(x)
      ),
      normalOrders: driverRoutes.filter((x) => isOrderNormal(x)),
      deliveredOrders: driverRoutes.filter((x) => isOrderDelivered(x)),
    }),
    [driverRoutes]
  );

  const hasWaitingOrders = (driver: IMapDriver) => {
    return driver.orders.some(order => order.orderStatus === OrderStatus.WaitingForAccept);
  }

  const filteredDrivers = drivers
    .filter((driver) => {
      if (!filters) {
        return true;
      }

      if (filters.drivers?.mode === "disabled") {
        return true;
      }

      if (filters.drivers?.mode === "groups") {
        return isInActiveFilterGroup(
          filters.drivers,
          driverGroups,
          (x: IMapDriver) => x.employeeId === driver.employeeId
        );
      }

      if (filters.drivers?.mode === "selectedIds") {
        return filters.drivers.selectedIds.includes(driver.employeeId);
      }

      return true;
    })
    .filter((driver) => {
      if (!filters) {
        return true;
      }

      if (filters.drivers?.mode && filters.drivers.mode !== "disabled") {
        return true;
      }

      if (filters.driversRoutes?.mode === "groups") {
        return isInActiveFilterGroup(
          filters.driversRoutes,
          driverRouteGroups,
          (dr: IDriverRouteResponseDto) => dr.employeeId === driver.employeeId
        );
      }

      if (hasWaitingOrders(driver)) {
        return false;
      }

      return true;
    });

  const freeDrivers = filteredDrivers.filter((driver) => {
    if (!filters) {
      return true;
    }

    if (filters.drivers?.mode === "disabled") {
      return isDriverFree(driver);
    }

    return true;
  });

  const busyDrivers = filteredDrivers.filter((driver) => {
    if (!filters) {
      return true;
    }

    if (filters.drivers?.mode === "disabled") {
      return !isDriverFree(driver);
    }

    return true;
  });

  const filteredRoutePoints = routePoints
    .filter((rp) => {
      if (!filters) {
        return true;
      }

      if (filters.driversRoutes?.mode === "disabled") {
        return true;
      }

      if (filters.driversRoutes?.mode === "groups") {
        return isInActiveFilterGroup(
          filters.driversRoutes,
          driverRouteGroups,
          (x: IDriverRouteResponseDto) =>
            x.employeeId ===
            driverRoutes.find((dr) =>
              dr.routePoints.find((x) => x.orderId === rp.orderId && x.id === rp.id)
            ).employeeId
        );
      }

      return true;
    })
    .filter((rp) => {
      if (!filters) {
        return true;
      }

      // Skip this filter if the data is already filtered
      if (filters.driversRoutes?.mode && filters.driversRoutes.mode !== "disabled") {
        return true;
      }

      if (filters.drivers?.mode === "disabled") {
        return filters.orders.mode === "disabled";
      }

      if (filters.drivers?.mode === "groups") {
        return isInActiveFilterGroup(filters.drivers, driverGroups, (driver: IMapDriver) => {
          const dr = driverRoutes.find((dr) => dr.employeeId === driver.employeeId);
          return dr?.routePoints.some((x) => x.orderId === rp.orderId && x.id === rp.id);
        });
      }

      if (filters.drivers?.mode === "selectedIds") {
        return Object.values(driverRouteGroups).some((drg) =>
          drg
            .find((dr) => filters.drivers.selectedIds.includes(dr.employeeId))
            ?.routePoints.some((x) => x.orderId === rp.orderId && x.id === rp.id)
        );
      }

      return true;
    });

  const filteredDriverRoutes = driverRoutes.filter((driverRoute) => {
    if (!filters) {
      return true;
    }

    if (filters.driversRoutes?.mode === "groups") {
      return isInActiveFilterGroup(
        filters.driversRoutes,
        driverRouteGroups,
        (x: IDriverRouteResponseDto) => x.employeeId === driverRoute.employeeId
      );
    }

    if (filters.driversRoutes?.mode && filters.driversRoutes.mode !== "disabled") {
      return true;
    }

    if (filters.drivers?.mode === "disabled") {
      return filters.orders.mode === "disabled";
    }

    if (filters.drivers?.mode === "groups") {
      return isInActiveFilterGroup(
        filters.drivers,
        driverGroups,
        (x: IMapDriver) => x.employeeId === driverRoute.employeeId
      );
    }

    if (filters.drivers?.mode === "selectedIds") {
      return filters.drivers.selectedIds.includes(driverRoute.employeeId);
    }

    return true;
  });

  const filteredUnassignedOrders = unassignedOrders.filter((order) => {
    if (!filters) {
      return true;
    }

    if (filters?.orders?.mode === "disabled") {
      return filters.driversRoutes.mode === "disabled";
    }

    if (filters?.orders?.mode === "groups") {
      return filters.orders.groups.unassignedOrders;
    }

    if (filters?.orders?.mode === "selectedIds") {
      return filters.orders.selectedIds.includes(order.id);
    }

    return true;
  });


  return (
    <LeafletMap height="100%" defaultLocation={{ latitude: 41.9981, longitude: 21.4254 }}>
      <MapController
        geolocation={{
          latitude: 41.9981,
          longitude: 21.4254,
        }}
        zoom={13}
        animate
      />

      {/* Invalidate map size on resize */}
      <MapResizeObserver />

      {/* Allow the parent component to control the leaflet map */}
      <LeafletMapRefBinder ref={forwardRef} />

      <LayersControl>
        <LayersControl.Overlay name="Слободни возачи" checked={!isRouteLinesChecked}>
          <FeatureGroup>
            <DriverMarkers
              drivers={freeDrivers}
              // TODO: Fix this any
              driversFilter={filters?.drivers as any}
              driversRoutesFilter={filters?.driversRoutes}
            />
          </FeatureGroup>
        </LayersControl.Overlay>

        <LayersControl.Overlay name="Зафатени возачи" checked={isRouteLinesChecked}>
          <FeatureGroup>
            <DriverMarkers
              drivers={busyDrivers}
              // TODO: Fix this any
              driversFilter={filters?.drivers as any}
              driversRoutesFilter={filters?.driversRoutes}
            />
          </FeatureGroup>
        </LayersControl.Overlay>

        <Pane name="tooltip-pane" style={{ visibility: "hidden" }} />

        <LayersControl.Overlay name="Дестинации" checked={isRouteLinesChecked}>
          <FeatureGroup>
            <RoutePointMarkers routePoints={filteredRoutePoints} orders={orders} toggleRouteLines={handleToggleRouteLines} />
          </FeatureGroup>
        </LayersControl.Overlay>

        <LayersControl.Overlay name="Рути" checked={isRouteLinesChecked}>
          <FeatureGroup>
            <RouteLines drivers={drivers} driverRoutes={filteredDriverRoutes} />
          </FeatureGroup>
        </LayersControl.Overlay>

        <LayersControl.Overlay name="Недоделени нарачки" checked={!isRouteLinesChecked || isSelectedOrder}>
          <FeatureGroup>
            <UnassignedOrderMarkers orders={filteredUnassignedOrders} />
          </FeatureGroup>
        </LayersControl.Overlay>

        <AreasGeoJSON />
      </LayersControl>
    </LeafletMap>
  );
};

export default memo(DriversMap);
