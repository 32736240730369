import { Divider, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { IDriverRoutePointDto } from "@shared/services/orders/dtos/orders/driverRoutePointDto";
import { FC } from "react";

const useStyles = makeStyles((theme) => ({}));

interface ReceiptProps {
  orderDetails: IDriverRoutePointDto["orderDetails"];
}

const Receipt: FC<ReceiptProps> = ({ orderDetails }) => {
  const classes = useStyles();

  const price = orderDetails?.price ?? 0;
  const price10 = orderDetails?.price10 ?? 0;
  const deliveryPrice = orderDetails?.deliveryPrice ?? 0;
  const priorityPrice = orderDetails?.priorityPrice ?? 0;
  const total = price + deliveryPrice + priorityPrice;

  return (
    <div style={{ padding: 2 }}>
      {orderDetails.orderItems?.map((orderItem) => (
        <>
          <Grid container spacing={2}>
            <Grid item xs>
              <Typography variant="body2">
                <b>{orderItem.quantity} x</b> {orderItem.foodName?.trim()}
              </Typography>

              {orderItem.foodAdditions && orderItem.foodAdditions !== " - 0 ден." ? (
                <Typography variant="caption">
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <b>+</b> {orderItem.foodAdditions?.replace(/#/g, ", ")}
                </Typography>
              ) : null}
            </Grid>

            <Grid item>
              <Typography variant="body2">{orderItem.price} ден.</Typography>
            </Grid>
          </Grid>

          <Divider style={{ marginTop: "8px", marginBottom: "8px" }} />
        </>
      ))}

      <Grid container>
        <Grid item xs />

        <Grid item>
          <Grid container spacing={6} justifyContent="space-between">
            <Grid item>
              <Typography variant="body2">Цена 5% (02):</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2">{price} ден.</Typography>
            </Grid>
          </Grid>

          {price10 > 0 && (
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography variant="body2">Цена 10% (03):</Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2">{price10} ден.</Typography>
              </Grid>
            </Grid>
          )}

          {deliveryPrice > 0 && (
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography variant="body2">Достава (01):</Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2">{deliveryPrice} ден.</Typography>
              </Grid>
            </Grid>
          )}

          {priorityPrice > 0 && (
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography variant="body2">Приоритет:</Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2">{priorityPrice} ден.</Typography>
              </Grid>
            </Grid>
          )}

          <Grid container spacing={6} justifyContent="space-between">
            <Grid item>
              <Typography variant="body2">Вкупно:</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2" color="primary">
                <b>{total} ден.</b>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Receipt;

