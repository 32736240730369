import AreaOverview from "./components/AreaOverview";
import NewAreaTypeForm from "./components/NewAreaForm";
import { Button, IconButton, TableCell } from "@material-ui/core";
import { Box } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { Paper } from "@material-ui/core";
import { Delete, Edit, Warning } from "@material-ui/icons";
import TableView from "@shared/components/Table/TableView";
import { ITableHeaderCell } from "@shared/components/Table/types/ITableHeaderCell";
import { useConfirmationDialog } from "@shared/context/ConfirmationDialogContext";
import { useMemoSelector } from "@shared/hooks";
import { getAllAreas, removeArea } from "@shared/services/settings/area.service";
import { IAreaDto } from "@shared/services/settings/dtos/area/areaDto";
import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useSnackbar } from "notistack";
import { FC, useCallback, useEffect, useState } from "react";

export interface IAreaType {
  id: number;
  name: string;
  polygonData: string;
  cityFK: number;
  city: string;
  color: string;
  vehicleTypesIds: number[];
  employeesIds: number[];
  isForAllEmployees: boolean;
  createdOn: Date;
  onEditClick?: (id: number) => void;
  onDeleteClick?: (id: number) => void;
}

const createAreaType = (
  id: number,
  name: string,
  polygonData: string,
  cityFK: number,
  city: string,
  color: string,
  vehicleTypesIds: number[],
  employeesIds: number[],
  isForAllEmployees: boolean,
  createdOn?: Date,
  onEditClick?: (id: number) => void,
  onDeleteClick?: (id: number) => void
): IAreaType => ({
  id,
  name,
  polygonData,
  cityFK,
  city,
  color,
  vehicleTypesIds,
  employeesIds,
  isForAllEmployees,
  createdOn,
  onEditClick,
  onDeleteClick,
});

const headerCells: ITableHeaderCell<IAreaType>[] = [
  { id: "id", label: "ID" },
  { id: "name", label: "Име" },
  { id: "city", label: "Град" },
  { id: "editButton", label: "", disableSorting: true },
  { id: "deleteButton", label: "", disableSorting: true },
];

const createRow = (row: IAreaType) => (
  <>
    <TableCell>{row.id}</TableCell>
    <TableCell>{row.name}</TableCell>
    <TableCell>{row.city}</TableCell>
    <TableCell padding="checkbox">
      <IconButton size="small" onClick={() => (row.onEditClick ? row.onEditClick(row.id) : null)}>
        <Edit />
      </IconButton>
    </TableCell>
    <TableCell padding="checkbox">
      <IconButton
        size="small"
        onClick={() => (row.onDeleteClick ? row.onDeleteClick(row.id) : null)}
      >
        <Delete />
      </IconButton>
    </TableCell>
  </>
);

const AreaTypePage: FC = () => {
  const {
    data: areaTypes,
    isLoading,
    isFetching,
    error,
    refetch,
  } = useQuery(["areaTypes"], getAllAreas, {
    refetchInterval: 15000,
  });

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  useEffect(() => {
    if (!error || !(error instanceof Error)) return;

    enqueueSnackbar(`Се случи грешка, обидете се повторно. ${error.message}`, {
      variant: "error",
      persist: true,
      preventDuplicate: true,
      anchorOrigin: { horizontal: "center", vertical: "bottom" },
      action: function onDismiss(key) {
        return <Button onClick={() => closeSnackbar(key)}>OK</Button>;
      },
    });
  }, [error, enqueueSnackbar, closeSnackbar]);

  const confirmationDialog = useConfirmationDialog();

  const handleDeleteClick = (id: number) => {
    const row = rows.find((x: IAreaType) => x.id === id);

    if (row) {
      confirmationDialog.open({
        icon: <Warning fontSize="large" style={{ color: "orange" }} />,
        title: "Избриши зона",
        body: `Дали сте сигурни дека сакате да ја избришете зоната ${row.name} со ID ${row.id}?`,
        onConfirm: () => handleDelete(row.id),
        onDeny: () => confirmationDialog.close(),
      });
    }
  };

  const handleDelete = useCallback(
    (id: number) => {
      confirmationDialog.setLoading(true);

      removeArea(id)
        .then(() => {
          confirmationDialog.close();
          enqueueSnackbar("Успешно ја избришавте зоната", {
            variant: "success",
            autoHideDuration: 6000,
          });

          refetch();
        })
        .catch((error: AxiosError) => {
          confirmationDialog.close();
          enqueueSnackbar(`Не успеа да се отстрани зоната: ${error.message}`, {
            variant: "error",
            persist: true,
            preventDuplicate: true,
            anchorOrigin: { horizontal: "center", vertical: "bottom" },
            action: function onDismiss(key) {
              return <Button onClick={() => closeSnackbar(key)}>OK</Button>;
            },
          });
        });
    },
    [closeSnackbar, enqueueSnackbar, refetch]
  );

  const handleEditClick = (id: number) => {
    const row = rows.find((x: IAreaType) => x.id === id);

    if (row) setSelectedRow(row);
  };

  const [selectedRow, setSelectedRow] = useState<IAreaType | null>(null);
  const handleAddTypeClick = () => {
    // The craeteArea type parameters don't matter, what matters is the ID
    setSelectedRow(createAreaType(0, "", "", -1, "", "#ff0000", [], [], true));
  };

  const handleAddTypeDialogClose = () => {
    setSelectedRow(null);
  };

  const cityTypes = useMemoSelector((state) => state.sharedState?.cityTypes.data);
  const getCityName = (cityFK: number): string => {
    const city = cityTypes.find((x) => x.id === cityFK);
    return city?.name || cityFK.toString();
  };

  // Map data
  const rows: IAreaType[] = [];
  if (areaTypes) {
    areaTypes.forEach((x: IAreaDto) => {
      rows.push(
        createAreaType(
          x.id,
          x.name,
          x.polygonData,
          x.cityFK,
          getCityName(x.cityFK),
          x.color || "#ff0000",
          x.vehicleTypes.map((x) => x.id),
          x.employeesInfos.map((x) => x.id),
          x.isForAllEmployees,
          x.createdOn,
          handleEditClick,
          handleDeleteClick
        )
      );
    });
  }

  return (
    <Box component={Paper}>
      <Grid spacing={2} container>
        <Grid item xs={12} md={4}>
          <TableView
            title="Зони"
            addButtonText="Додади зона"
            onAddButtonClick={handleAddTypeClick}
            onRefreshClick={() => refetch()}
            headers={headerCells}
            rows={rows}
            render={createRow}
            isLoading={isLoading || isFetching}
            disablePagination
            addDialog={
              <NewAreaTypeForm
                title={selectedRow?.id ? "Промени зона" : "Додади зона"}
                addButtonText={selectedRow?.id ? "Зачувај" : "Додади"}
                open={selectedRow ? true : false}
                row={selectedRow?.id ? selectedRow : null}
                onClose={handleAddTypeDialogClose}
                onSuccess={() => refetch()}
              />
            }
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <AreaOverview areas={rows} onClick={(id) => (id ? handleEditClick(id) : null)} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default AreaTypePage;
