export const selectAllCities = (state: IGlobalState) => {
  return state.sharedState.cityTypes.data;
};

export const selectAllDeliveryTypes = (state: IGlobalState) => {
  return state.sharedState.deliveryTypes.data;
};

export const selectAllVehicleTypes = (state: IGlobalState) => {
  return state.sharedState.vehicleTypes.data;
};

export const selectAllPaymentTypes = (state: IGlobalState) => {
  return state.sharedState.paymentTypes.data;
};

export const selectAllEmployeePositionTypes = (state: IGlobalState) => {
  return state.sharedState.employeePositionTypes.data;
};

export const selectAllOrderRejectReasons = (state: IGlobalState) => {
  return state.sharedState.orderRejectReasons.data;
};

export const selectPaymentTypeById = (state: IGlobalState, id: number) => {
  return state.sharedState.paymentTypes.data.find((item) => item.id === id);
};
