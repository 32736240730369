import httpService from "../http.service";
import { ICreateRoleSalaryConfigurationDto } from "./dtos/role-salary-configurations/createRoleSalaryConfigurationDto";
import { IRoleSalaryConfigurationDto } from "./dtos/role-salary-configurations/roleSalaryConfigurationDto";

const controllerUrl = "/api/role-salary-configuration";

export const getAllRoleSalaries = () => {
  return httpService
    .get<IRoleSalaryConfigurationDto[]>(controllerUrl)
    .then<IRoleSalaryConfigurationDto[]>((data) =>
      data.map((item) => ({
        ...item,
        validFrom: item.validFrom ? new Date(item.validFrom) : undefined,
        validTo: item.validTo ? new Date(item.validTo) : undefined,
      }))
    );
};

export const getRoleSalaryById = (id: number) => {
  return httpService
    .get<IRoleSalaryConfigurationDto>(controllerUrl + `/${id}`)
    .then<IRoleSalaryConfigurationDto>((data) => ({
      ...data,
      validFrom: data.validFrom ? new Date(data.validFrom) : undefined,
      validTo: data.validTo ? new Date(data.validTo) : undefined,
    }));
};

export const createRoleSalary = (createRoleSalaryConfig: ICreateRoleSalaryConfigurationDto) => {
  return httpService
    .post<IRoleSalaryConfigurationDto, ICreateRoleSalaryConfigurationDto>(
      controllerUrl,
      createRoleSalaryConfig
    )
    .then<IRoleSalaryConfigurationDto>((data) => ({
      ...data,
      validFrom: data.validFrom ? new Date(data.validFrom) : undefined,
      validTo: data.validTo ? new Date(data.validTo) : undefined,
    }));
};

export const updateRoleSalary = (roleSalaryConfig: IRoleSalaryConfigurationDto) => {
  return httpService
    .put<IRoleSalaryConfigurationDto, IRoleSalaryConfigurationDto>(controllerUrl, roleSalaryConfig)
    .then<IRoleSalaryConfigurationDto>((data) => ({
      ...data,
      validFrom: data.validFrom ? new Date(data.validFrom) : undefined,
      validTo: data.validTo ? new Date(data.validTo) : undefined,
    }));
};

export const deleteRoleSalary = (id: number) => {
  return httpService
    .delete<IRoleSalaryConfigurationDto>(controllerUrl + `/${id}`)
    .then<IRoleSalaryConfigurationDto>((data) => ({
      ...data,
      validFrom: data.validFrom ? new Date(data.validFrom) : undefined,
      validTo: data.validTo ? new Date(data.validTo) : undefined,
    }));
};


export const propagateRoleSalaryConfiguration = async (id: number) => {
  return await httpService
    .post<number>(
      `${controllerUrl}/overwrite/${id}`
    );
};