import { DataGrid } from "@mui/x-data-grid";
import ErrorFallback from "../../../../../error";
import CitiesFilter from "../../shared/components/CitiesFilter";
import CompaniesFilter from "../../shared/components/CompaniesFilter";
import EmployeePositionsFilter from "../../shared/components/EmployeePositionsFilter";
import PeriodDateFilter, { IPeriodDateFilter } from "../../shared/components/PeriodDateFilter";
import SearchFilter from "../../shared/components/SearchFilter";
import AllEmployeePositionsSummaryCard from "./components/AllEmployeePositionsSummaryCard";
import EmployeePositionSummaryCard from "./components/EmployeePositionSummaryCard";
import useEmplyeesOverviewColumns from "./hooks/useEmployeesOverviewColumns";
import { Grid, Typography, createStyles, makeStyles } from "@material-ui/core";
import Loading from "@shared/components/Loading/Loading";
import MaterialTable, { ISort, sortHandler } from "@shared/components/MaterialTable/MaterialTable";
import { IPayrollEmployeeOverviewDto } from "@shared/services/payroll/dtos/payrollEmployeeOverviewDto";
import {
  getPayrollAllEmployeePositionsSummary,
  getPayrollEmployeePositionsSummaries,
  getPayrollEmployeesOverview,
} from "@shared/services/payroll/payroll.service";
import { getNextPageParam } from "@shared/utils/infiniteQuery";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { FC, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import dayjs, { Dayjs } from "dayjs";

const useStyles = makeStyles((theme) =>
  createStyles({
    heading: {
      marginBottom: theme.spacing(4),
    },
    filters: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    summaryCards: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    overviewTable: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  })
);

const PayrollOverview: FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [periodFilter, setPeriodFilter] = useState<IPeriodDateFilter>({ from: null, to: null });
  const [employeePositionsFilter, setEmployeePositionsFilter] = useState<number[]>([]);
  const [companiesFilter, setCompaniesFilter] = useState<number[]>([]);
  const [citiesFilter, setCitiesFilter] = useState<number[]>([]);
  const [searchFilter, setSearchFilter] = useState<string>("");
  const [sort, setSort] = useState<ISort>({ by: null, direction: null });

  const allEmployeePositionsSummaryQuery = useQuery(
    [
      "getPayrollAllEmployeePositionsSummary",
      periodFilter.from,
      periodFilter.to,
      citiesFilter,
      employeePositionsFilter,
      companiesFilter,
      searchFilter,
    ],
    () =>
      getPayrollAllEmployeePositionsSummary({
        from: dayjs(periodFilter.from).utc().toDate(),
        to: periodFilter.to,
        cities: citiesFilter,
        employeePositions: employeePositionsFilter,
        companies: companiesFilter,
        searchTerm: searchFilter,
      }),
    { keepPreviousData: true }
  );

  const employeePositionsSummariesQuery = useQuery(
    [
      "getPayrollEmployeePositionsSummaries",
      periodFilter,
      citiesFilter,
      employeePositionsFilter,
      companiesFilter,
      searchFilter,
    ],
    () =>
      getPayrollEmployeePositionsSummaries({
        from: dayjs(periodFilter.from).utc().toDate(),
        to: periodFilter.to,
        cities: citiesFilter,
        employeePositions: employeePositionsFilter,
        companies: companiesFilter,
        searchTerm: searchFilter,
      }),
    { keepPreviousData: true }
  );

  const employeesOverviewQuery = useInfiniteQuery(
    [
      "getPayrollEmployeesOverview",
      periodFilter,
      citiesFilter,
      employeePositionsFilter,
      companiesFilter,
      searchFilter,
      sort.by,
      sort.direction,
    ],
    ({ pageParam = 0 }) =>
      getPayrollEmployeesOverview({
        currentPage: pageParam,
        itemsPerPage: 10,
        filters: {
          from: dayjs(periodFilter.from).toDate(),
          to: periodFilter.to,
          cities: citiesFilter,
          employeePositions: employeePositionsFilter,
          companies: companiesFilter,
          searchTerm: searchFilter,
        },
        sortBy: sort.by,
        sortDirection: sort.direction,
        items: [],
        totalItemsCount: 0,
      }),
    {
      keepPreviousData: true,
      getNextPageParam: (lastPage) =>
        getNextPageParam(lastPage.currentPage, lastPage.itemsPerPage, lastPage.totalItemsCount),
    }
  );

  const employeesOverviewColumns = useEmplyeesOverviewColumns();
  const employeesOverviewData = useMemo(
    () => employeesOverviewQuery.data?.pages?.flatMap((pageData) => pageData.items) ?? [],
    [employeesOverviewQuery.data]
  );

  if (
    allEmployeePositionsSummaryQuery.isError ||
    employeePositionsSummariesQuery.isError ||
    employeesOverviewQuery.isError
  )
    return (
      <ErrorFallback
        error={
          allEmployeePositionsSummaryQuery.error ??
          employeePositionsSummariesQuery.error ??
          employeesOverviewQuery.error
        }
      />
    );

  if (
    allEmployeePositionsSummaryQuery.isLoading ||
    employeePositionsSummariesQuery.isLoading ||
    employeesOverviewQuery.isLoading
  )
    return <Loading />;

  return (
    <Grid container>
      <Grid container className={classes.heading}>
        <Typography variant="h5">Платен список</Typography>
      </Grid>

      <Grid container className={classes.filters}>
        <Grid container direction="row">
          <PeriodDateFilter onChange={(from, to) => setPeriodFilter({ from, to })} />
        </Grid>
        <Grid container direction="row">
          <CitiesFilter onChange={(cities) => setCitiesFilter(cities)} />
          <EmployeePositionsFilter onChange={(roleIds) => setEmployeePositionsFilter(roleIds)} />
          <CompaniesFilter onChange={(companyIds) => setCompaniesFilter(companyIds)} />
          <SearchFilter onChange={(searchTerm) => setSearchFilter(searchTerm)} />
        </Grid>
      </Grid>

      <Grid container className={classes.summaryCards}>
        <AllEmployeePositionsSummaryCard
          allEmployeePositionsSummary={allEmployeePositionsSummaryQuery.data}
        />
        {employeePositionsSummariesQuery.data.map((employeePositionSummary) => (
          <EmployeePositionSummaryCard
            key={employeePositionSummary.employeePosition.name}
            employeePositionSummary={employeePositionSummary}
          />
        ))}
      </Grid>


      <MaterialTable<IPayrollEmployeeOverviewDto>
        title="Вработени"
        className={classes.overviewTable}
        columns={employeesOverviewColumns}
        data={employeesOverviewData}
        options={{
          search: false,
          maxColumnSort: 1,
          pageSize: 500,
          emptyRowsWhenPaging: false,
        }}
        isLoading={employeesOverviewQuery.isFetching}
        infiniteLoad={true}
        hasNextPage={false}
        // fetchNextPage={employeesOverviewQuery.fetchNextPage}
        onOrderCollectionChange={(oc) => sortHandler(oc, employeesOverviewColumns, setSort)}
        onRowClick={(_, value) => navigate(`/app/payroll/${value.id}`)}
      />
    </Grid>
  );
};

export default PayrollOverview;
