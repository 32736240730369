import FreezeHistory from "../components/FreezeHistory";
import { Column } from "@material-table/core";
import { useTheme } from "@material-ui/core";
import { IEmployeePayoutDto } from "@shared/services/payout/dtos/employeePayoutDto";

const usePayoutsColumns = (refetch: any): Column<IEmployeePayoutDto>[] => {
  const theme = useTheme();

  return [
    {
      title: "Име",
      field: "firstName",
    },
    {
      title: "Презиме",
      field: "lastName",
    },
    {
      title: "Вкупна заработка",
      field: "totalEarnings",
    },
    {
      title: "Фиксна заработка",
      field: "fixedSalary",
    },
    {
      title: "Фиксна заработка (Компанија)",
      field: "fixedSalaryCompany",
      render: (data) => data.fixedSalaryCompany.name,
    },
    {
      title: "Верна",
      field: "verna",
    },
    {
      title: "Закуп на возило",
      field: "carRent",
    },
    {
      title: "Закуп на возило (Компанија)",
      field: "carRentCompany",
      render: (data) => data.carRentCompany.name,
    },
    {
      title: "Договор на дело",
      field: "contract",
    },
    {
      title: "Договор на дело (Компанија)",
      field: "contractCompany",
      render: (data) => data.contractCompany.name,
    },
    {
      title: "Салдо",
      field: "balance",
    },
    // {
    //   title: "Статус",
    //   field: "forzen",
    //   render: (data) => (
    //     <FreezeHistory
    //       employeeId={data.employeeId}
    //       frozen={data.frozen}
    //       onUpdate={() => refetch()}
    //     />
    //   ),
    // },
  ];
};

export default usePayoutsColumns;
