import httpService from "../http.service";
import { IActiveOrdersResponseDto } from "./dtos/orders/activeOrdersResponseDto";
import { IAlgorithmStatusResponseDto } from "./dtos/orders/algorithmStatusResponseDto";
import { IChangeOrderStatusRequestDto } from "./dtos/orders/changeOrderStatusRequestDto";
import { ICustomerDto } from "./dtos/orders/customerDto";
import { IDispatchUpdateOrderRequestDto } from "./dtos/orders/dispatchUpdateOrderRequestDto";
import { IDriverOrderStatusResponseDto } from "./dtos/orders/driverOrderStatusResponseDto";
import { IDriverRejectionReasonRequestDto } from "./dtos/orders/driverRejectionReasonRequestDto";
import { IDriverRouteResponseDto } from "./dtos/orders/driverRouteResponseDto";
import { IFullOrderDto } from "./dtos/orders/fullOrderDto";
import { IOrderDto } from "./dtos/orders/orderDto";
import { IOrderRequestDto } from "./dtos/orders/orderRequestDto";
import { IOrderTrackChangesResponseDto } from "./dtos/orders/orderTrackChangesResponseDto";
import { IPackageOrdersRequestDto } from "./dtos/orders/packageOrdersRequestDto";
import { IPackageOrdersResponseDto } from "./dtos/orders/packageOrdersResponseDto";
import { IPartnerDto } from "./dtos/orders/partnerDto";
import { IRoutePointDto } from "./dtos/orders/routePointDto";
import { IUpdateOrderRequestDto } from "./dtos/orders/updateOrderRequestDto";
import { AlgorithmMode } from "./enums/algorithmMode";
import { OrderChangeType } from "./enums/orderChangeType";
import { OrderStatus } from "./enums/orderStatus";

export const getOrders = () => {
  return httpService.get<IOrderDto[]>("/api/orders");
};

export const getOrderLog = (orderId: number) => {
  return httpService.get<IOrderTrackChangesResponseDto[]>(`/api/orders/${orderId}/logs`);
};

export const getActiveOrders = () => {
  // return httpService.get<IFullOrderDto[]>("/api/orders");
  return httpService.get<IFullOrderDto[]>("/api/orders/active");
};

export const getOrderDetailsAsync = (orderId: string) => {
  return httpService.get<IOrderDto>(`/api/orders/${orderId}`);
};

export const getOrderCustomerDetailsAsync = (orderId: number) => {
  return httpService.get<ICustomerDto>(`/api/orders/${orderId}/customer-info`);
};

export const getOrderPartnerDetailsAsync = (orderId: number) => {
  return httpService.get<IPartnerDto>(`/api/orders/${orderId}/partner-info`);
};

export const driverAcceptsOrderAsync = (driverId: number, orderId: number) => {
  return httpService.post<IDriverOrderStatusResponseDto>(
    `/api/drivers/${driverId}/orders/${orderId}/accept`
  );
};

export const driverRejectsOrderAsync = (
  driverId: number,
  orderId: number,
  request: IDriverRejectionReasonRequestDto
) => {
  return httpService.post<IDriverOrderStatusResponseDto, IDriverRejectionReasonRequestDto>(
    `/api/drivers/${driverId}/orders/${orderId}/reject`,
    request
  );
};

export const driverConfirmsOrderPickUpAsync = (driverId: number, orderId: number) => {
  return httpService.put<IDriverOrderStatusResponseDto>(
    `/api/drivers/${driverId}/orders/${orderId}/pick-up`
  );
};

export const driverConfirmsOrderDropOffAsync = (driverId: number, orderId: number) => {
  return httpService.put<IDriverOrderStatusResponseDto>(
    `/api/drivers/${driverId}/orders/${orderId}/drop-off`
  );
};

export const driverAcceptsMergedOrdersAsync = (driverId: number, mergeGuid: string) => {
  return httpService.post<IDriverOrderStatusResponseDto>(
    `/api/drivers/${driverId}/orders/${mergeGuid}/accept`
  );
};
export const driverAcceptsGroupedOrdersAsync = (driverId: number, packageGuid: string) => {
  return httpService.post<IDriverOrderStatusResponseDto>(
    `/api/drivers/${driverId}/package-orders/${packageGuid}/accept`
  );
};
export const driverRejectsMergedOrdersAsync = (
  driverId: number,
  mergeGuid: string,
  request: IDriverRejectionReasonRequestDto
) => {
  return httpService.post<IDriverOrderStatusResponseDto, IDriverRejectionReasonRequestDto>(
    `/api/drivers/${driverId}/orders/${mergeGuid}/reject`,
    request
  );
};

export const driverRejectsPackageOrdersAsync = (
  driverId: number,
  packageGuid: string,
  request: IDriverRejectionReasonRequestDto
) => {
  return httpService.post<IDriverOrderStatusResponseDto, IDriverRejectionReasonRequestDto>(
    `/api/drivers/${driverId}/package-orders/${packageGuid}/reject`,
    request
  );
};

export const driverConfirmsMergedOrdersPickUpAsync = (driverId: number, mergeGuid: string) => {
  return httpService.put<IDriverOrderStatusResponseDto>(
    `/api/drivers/${driverId}/orders/${mergeGuid}/pick-up`
  );
};

export const driverConfirmsMergedOrdersDropOffAsync = (driverId: number, mergeGuid: string) => {
  return httpService.put<IDriverOrderStatusResponseDto>(
    `/api/drivers/${driverId}/orders/${mergeGuid}/drop-off`
  );
};

export const resetOrderManuallyAsync = (
  orderId: number,
  request: IDispatchUpdateOrderRequestDto
) => {
  return httpService.put<IDispatchUpdateOrderRequestDto, IDispatchUpdateOrderRequestDto>(
    `/api/orders/${orderId}/reset`,
    request
  );
};

export const assignOrderManuallyAsync = (orderId: number, driverId: number) => {
  return httpService.post<void>(`/api/orders/${orderId}/assign/${driverId}`);
};

export const rescheduleOrderAsync = (orderId: number) => {
  return httpService.post<void>(`/api/orders/${orderId}/reschedule`);
};

export const createOrderAsync = (request: IOrderRequestDto) => {
  return httpService.post<void, IOrderRequestDto>(`/api/orders`, request);
};

export const updateOrderAsync = (orderId: number, request: IUpdateOrderRequestDto) => {
  return httpService.put<void, IUpdateOrderRequestDto>(`/api/orders/${orderId}`, request);
};

export const getAllDriverOrders = (driverId: number) => {
  return httpService.get<IOrderDto[]>(`/api/drivers/${driverId}/orders`);
};

export const getDriversRoutesAsync = () => {
  return httpService.get<IDriverRouteResponseDto[]>(`/api/drivers/routes`);
};

export const getDriversEstimatedRoutesAsync = () => {
  return httpService.get<IDriverRouteResponseDto[]>(`/api/drivers/estimated-routes`);
};

export const getDriverOrder = (driverId: number, orderId: string) => {
  return httpService.get<IOrderDto>(`/api/drivers/${driverId}/orders/${orderId}`, {});
};

export const pauseOrderManuallyAsync = (orderId: number) => {
  return httpService.put<void>(`/api/orders/${orderId}/pause`);
};

export const getDriverPendingAndActiveOrders = (driverId: number) => {
  return httpService.get<IActiveOrdersResponseDto>(
    `/api/drivers/${driverId}/orders/pending-and-active-routes`
  );
};

export const deleteOrderManuallyAsync = (orderId: number) => {
  return httpService.delete(`/api/orders/${orderId}`);
};

export const getRecommendedPickUpDelay = () => {
  return httpService.get<number>(`/api/orders/recommended-pickup-delay`);
};

export const executeAlgorithmManually = () => {
  return httpService.post<void>(`/api/orders/schedule-processing`);
};

export const deleteRoutePoint = (driverId: number, routePointId: number) => {
  return httpService.delete(`/api/drivers/${driverId}/routes/routePoints/${routePointId}`, {});
};

export const changeOrderStatus = (orderId: number, request: IChangeOrderStatusRequestDto) => {
  return httpService.put<void, IChangeOrderStatusRequestDto>(
    `/api/orders/${orderId}/status`,
    request
  );
};

export const getDriverAndOrderRoutePoints = (driverId: number, orderId?: number) => {
  return httpService.get<IDriverRouteResponseDto>(
    `/api/drivers/${driverId}/routes/generate?orderId=${orderId}`
  );
};

export const modifyDriverRoutePoints = (
  driverId: number,
  routeId: number,
  routePoints: IRoutePointDto[]
) => {
  return httpService.put<void, IRoutePointDto[]>(
    `/api/drivers/${driverId}/routes/${routeId}/modify`,
    routePoints
  );
};

export const manuallyChargeTaximeter = (orderId: number, paymentType: string) => {
  return httpService.post<void>(`/api/orders/${orderId}/charge?paymentType=${paymentType}`);
};

export const getAlgorithmMode = () => {
  return httpService.get<IAlgorithmStatusResponseDto>(`/api/orders/algorithm-execution-mode`);
};

export const setAlgorithmMode = (mode: AlgorithmMode) => {
  return httpService.put<void, { algorithmExecutionMode: AlgorithmMode }>(
    `/api/orders/algorithm-execution-mode`,
    {
      algorithmExecutionMode: mode,
    }
  );
};
export const getGroupOrdersAsync = (orderGroupId: string) => {
  return httpService.get<IPackageOrdersResponseDto>(`/api/package-orders/${orderGroupId}`, {});
};
export const generateGroupOrdersAsync = (orderId1: number, orderId2: number) => {
  return httpService.get<IPackageOrdersResponseDto>(
    `/api/package-orders/${orderId1}/generate?appendOrderId=${orderId2}`,
    {}
  );
};
export const groupOrdersAsync = (request: IPackageOrdersRequestDto) => {
  return httpService.post<IPackageOrdersRequestDto, IPackageOrdersRequestDto>(
    `/api/package-orders`,
    request
  );
};
export const updateGroupOrdersAsync = (groupId: number, request: IPackageOrdersRequestDto) => {
  return httpService.post<IPackageOrdersRequestDto, IPackageOrdersRequestDto>(
    `/api/package-orders/${groupId}`,
    request
  );
};
export const assignOrderGroupManuallyAsync = (orderGroupId: string, driverId: number) => {
  return httpService.post<void>(`/api/orders/${orderGroupId}/assign/${driverId}`);
};

export const ungroupGroupedOrders = (packageGuid: string) => {
  return httpService.delete(`/api/package-orders/${packageGuid}`);
};

export const translateOrderStatus = (status: OrderStatus): string => {
  const statusLabelMap = new Map([
    [OrderStatus.WaitingForProcessing, "Чека на процесирање"],
    [OrderStatus.Processing, "Се процесира"],
    [OrderStatus.WaitingForAccept, "Чека на одобрување"],
    [OrderStatus.Assigned, "Доделена"],
    [OrderStatus.PickedUp, "Подигната"],
    [OrderStatus.Delivered, "Доставена"],
    [OrderStatus.HandleByDispatch, "Потребна акција од диспечер"],
    [OrderStatus.RejectByDriver, "Одбиена од возач"],
    [OrderStatus.ResetByDispatch, "Ресетирана од диспечер"],
    [OrderStatus.Paused, "Паузирана"],
    [OrderStatus.PreAssigned, "Закажана"],
    [OrderStatus.RejectByCustomer, "Одбиена од клиент"],
    [OrderStatus.WaitingForAcceptByCustomer, "Чека на одобрување од клиент"],
    [OrderStatus.AutoRejectedForCustomer, "Автоматски одбиена"],
    [OrderStatus.UnsuccessfulPayment, "Неуспешно плаќање"],
  ]);

  if (statusLabelMap.has(status)) return statusLabelMap.get(status);
  else if (status in OrderStatus) return OrderStatus[status];
  else return status.toString();
};

export const translateOrderChangeType = (changeType: OrderChangeType): string => {
  const statusLabelMap = new Map([
    [OrderChangeType.None, "Нова нарачка"],
    [OrderChangeType.Algorithm, "Алгоритам"],
    [OrderChangeType.Dispatch, "Диспечер"],
    [OrderChangeType.Driver, "Возач"],
    [OrderChangeType.Customer, "Клиент"],
  ]);

  if (statusLabelMap.has(changeType)) return statusLabelMap.get(changeType);
  else if (changeType in OrderChangeType) return OrderChangeType[changeType];
  else if (changeType.toString() !== "0") return changeType.toString();
  else return null;
};
