import { FC, memo } from "react";
import { makeStyles, Typography } from "@material-ui/core";
import {  ExpandMore as ExpandMoreIcon } from "@material-ui/icons";
import { useMemoSelector } from "@shared/hooks";
import { selectProblemOrdersUnassigned, selectProblemOrdersUnconfirmed, selectProblemOrdersRejected } from "../../store/features/orders/orderSelectors";
import OrderCard from "./OrderCard";
import PackageCard from "./PackageCard";
import Draggable from "../DnD/Draggable";
import Droppable from "../DnD/Droppable";
import Accordion from "../Accordion/Accordion";
import AccordionSummary from "../Accordion/AccordionSummary";
import AccordionDetails from "../Accordion/AccordionDetails";
import { IMapOrder } from "@shared/components/Map/models/models";

const useStyles = makeStyles((theme) => ({
  grid: {
    display: "flex",
    flexWrap: "wrap",
    gap: "0.5rem",
  },
}));

export interface OrdersProps {}

const Orders: FC<OrdersProps> = () => {
  const classes = useStyles();

  let unassignedOrders = useMemoSelector(selectProblemOrdersUnassigned);
  let ordersNotConfirmed = useMemoSelector(selectProblemOrdersUnconfirmed);
  const rejectedOrders = useMemoSelector(selectProblemOrdersRejected);

  const unassignedPackages: IMapOrder[][] = Object.values(
    unassignedOrders.reduce((acc, current) => {
      if(current.packageGuid !== null){
        acc[current.packageGuid] = acc[current.packageGuid] ?? [];
        acc[current.packageGuid].push(current);
      }
      return acc;
      
    }, {})
);


const notConfirmedPackages: IMapOrder[][] = Object.values(
  ordersNotConfirmed.reduce((acc, current) => {
    if(current.packageGuid !== null){
      acc[current.packageGuid] = acc[current.packageGuid] ?? [];
      acc[current.packageGuid].push(current);
    }
    return acc;

  }, {})
);

// unassignedPackages.pop();
// notConfirmedPackages.pop();

unassignedOrders = unassignedOrders.filter(order => order.packageGuid == null);
ordersNotConfirmed = ordersNotConfirmed.filter(order => order.packageGuid == null);

  return (
    <Accordion square defaultExpanded={true}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="body1">Нарачки</Typography>
      </AccordionSummary>

      {notConfirmedPackages.length > 0 ? (
      <AccordionDetails style={{ backgroundColor: "indianred" }}>
        <Typography variant="body2">Непотврдени пакети</Typography>
        <br />
          <div className={classes.grid}>
          {notConfirmedPackages.map((packageGroup, index) => (
            <Draggable key={packageGroup[0].packageGuid} id={`OrderGroup-${packageGroup[0].packageGuid}`}>
              <Droppable
                id={`OrderGroup-${packageGroup[0].packageGuid}`}
                styleDragOver={{
                  backgroundColor: "lightgreen",
                  outline: "2px solid grey",
                  borderRadius: "5px",
                }}
              >
                <PackageCard packageGroup={packageGroup} />
                
              </Droppable>
            </Draggable>
          ))}
        </div>
        </AccordionDetails>
      ): null}
        
        {ordersNotConfirmed.length > 0 ? (
        <AccordionDetails style={{ backgroundColor: "indianred" }}>
        <Typography variant="body2">Нарачки кои не се потврдени од возач!</Typography>

        <br />

        <div className={classes.grid}>
          {ordersNotConfirmed.map((order) => (
            <Draggable key={order.id} id={`Order-${order.id}`}>
              <Droppable
                id={`Order-${order.id}`}
                styleDragOver={{
                  backgroundColor: "lightgreen",
                }}
              >
                <OrderCard order={order} />
              </Droppable>
            </Draggable>
          ))}
          </div>
          <br />
        </AccordionDetails>
      ) : null}

      {unassignedPackages.length > 0 ? (
      <AccordionDetails style={{ backgroundColor: "lightgray" }}>
      <Typography variant="body2">Недоделени пакети</Typography>
        <br />
          <div className={classes.grid}>
          {unassignedPackages.map((packageGroup, index) => (
            <Draggable key={packageGroup[0].packageGuid} id={`OrderGroup-${packageGroup[0].packageGuid}`}>
              <Droppable
                id={`OrderGroup-${packageGroup[0].packageGuid}`}
                styleDragOver={{
                  backgroundColor: "lightgreen",
                  outline: "2px solid grey",
                  borderRadius: "5px",
                }}
              >
              <PackageCard packageGroup={packageGroup} />
              </Droppable>
            </Draggable>
          ))}
        </div>
        </AccordionDetails>
      ): null}

        {unassignedOrders.length > 0 ? (
         <AccordionDetails style={{ backgroundColor: "lightgray" }}>
        <Typography variant="body2">Недоделени нарачки</Typography>

        <br />

        <div className={classes.grid}>
          {unassignedOrders.map((order) => (
            <Draggable key={order.id} id={`Order-${order.id}`}>
              <Droppable
                id={`Order-${order.id}`}
                styleDragOver={{
                  backgroundColor: "lightgreen",
                }}
              >
                <OrderCard order={order} />
              </Droppable>
            </Draggable>
          ))}
          </div>
          <br />
      </AccordionDetails>
        ) : null}

        {rejectedOrders.length > 0 ? ( 
        <AccordionDetails style={{ backgroundColor: "#ffc400" }}>
           <Typography variant="body2" color="error">ОДБИЕНИ НАРАЧКИ!
           </Typography>
             <br />
               <div className={classes.grid}>
                 {rejectedOrders.map((order) => (
                   <OrderCard order={order} />
                   ))}
                </div>
              <br />
         </AccordionDetails>
        ) : null}
    </Accordion>
  );
};

export default memo(Orders);
