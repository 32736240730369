import { FC, MouseEvent } from "react";
import { Button } from "@material-ui/core";
import { LinearScale } from "@material-ui/icons";
import { useModal } from "mui-modal-provider";
import FullRoutesDialog from "@App/modules/Map/components/Map/FullRoutesDialog";

export interface EstimatedRoutesButtonProps {}

const EstimatedRoutesButton: FC<EstimatedRoutesButtonProps> = () => {
  const { showModal } = useModal({ disableAutoDestroy: true });

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    const editRouteModal = showModal(FullRoutesDialog, {
      onClose: () => editRouteModal.destroy(),
    });
  };

  return (
    <Button
      size="small"
      variant="outlined"
      startIcon={<LinearScale color="inherit" />}
      onClick={handleClick}
    >
      Рути
    </Button>
  );
};

export default EstimatedRoutesButton;
