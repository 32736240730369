import reverseGeocode from "../../utils/reverseGeocode";
import EditRouteDialog from "../Map/EditRouteDialog";
import CreateOrderFormDialog from "./CreateOrderFormDialog";
import DriverSelect from "./DriverSelect";
import OrderTooltipContent from "./OrderTooltipContent";
import UnsuccessfulPaymentMenu from "./UnsuccessfulPaymentMenu";
import { selectDriverByIdWithoutGeolocation } from "@App/modules/Logistics/store/features/drivers/driverSelectors";
import TrophyIcon from "@assets/icons/trophy.svg?react";
import {
  Avatar,
  Box,
  Checkbox,
  CircularProgress,
  IconButton,
  TableCell,
  TableRow,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  DeleteOutline,
  EditOutlined,
  InfoOutlined,
  PauseOutlined,
  Storefront,
} from "@material-ui/icons";
import { Warning } from "@material-ui/icons";
import { IMapOrder } from "@shared/components/Map/models/models";
import ClickableTooltip from "@shared/components/Tooltip/ClickableTooltip";
import { useConfirmationDialog } from "@shared/context/ConfirmationDialogContext";
import { useMemoSelector } from "@shared/hooks";
import { IDriverDto } from "@shared/services/drivers/dtos/driverDto";
import { OrderStatus } from "@shared/services/orders/enums/orderStatus";
import {
  assignOrderManuallyAsync,
  deleteOrderManuallyAsync,
  pauseOrderManuallyAsync,
  rescheduleOrderAsync,
  translateOrderStatus,
} from "@shared/services/orders/orders.service";
import { cutString } from "@shared/utils/cutString";
import dayjs from "dayjs";
import { useModal } from "mui-modal-provider";
import { useSnackbar } from "notistack";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { Subscription, timer } from "rxjs";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    margin: "2px",
    flexBasis: "32%",
  },
  label: {
    fontSize: "12px",
    padding: "2px 2px",
  },
}));

interface Props {
  order: IMapOrder;
  drivers: IDriverDto[];
  isSelected?: boolean;
  onSelect: (event: ChangeEvent<HTMLInputElement>) => void;
  onOrderDeleted: (orderId: number) => void;
}

const t = timer(0, 10000);

const UnassignedOrderCard: React.FC<Props> = ({
  order,
  drivers,
  onOrderDeleted: orderDeleted,
  isSelected,
  onSelect,
}) => {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();
  const confirmationDialog = useConfirmationDialog();

  const [lateColor, setLateColor] = useState("");
  const [timeLeft, setTimeLeft] = useState<number>();
  const [loadingState, setLoadingState] = useState({
    pause: false,
  });

  const [updateOrderDialogActiveState, setUpdateOrderDialogActiveState] = useState(false);

  var timerSubscription = useRef<Subscription>(null);

  useEffect(() => {
    const unsubscribe = () => {
      if (timerSubscription.current) {
        timerSubscription.current?.unsubscribe();
        timerSubscription.current = null;
      }
    };

    unsubscribe();
    timerSubscription.current = t.subscribe(() => {
      checkOrderLate(order.pickUpTime);

      const pickupTime = dayjs.utc(order.pickUpTime).local();
      setTimeLeft(pickupTime?.diff(dayjs(), "minutes"));
    });

    return () => unsubscribe();
  }, [order.pickUpTime]);

  const [addressData, setAddressData] = useState({ pickUp: "", dropOff: "" });

  useEffect(() => {
    reverseGeocode(order.pickUpLatitude, order.pickUpLongitude)
      .then((address) => {
        setAddressData((state) => ({ ...state, pickUp: address }));
      })
      .catch((error) => console.error(error));
  }, [order.pickUpLatitude, order.pickUpLongitude]);

  useEffect(() => {
    reverseGeocode(order.dropOffLatitude, order.dropOffLongitude)
      .then((address) => {
        setAddressData((state) => ({ ...state, dropOff: address }));
      })
      .catch((error) => console.error(error));
  }, [order.dropOffLatitude, order.dropOffLongitude]);

  const checkOrderLate = (pickupTime: string) => {
    let pickUpLate = dayjs.utc(pickupTime).local().diff(dayjs(), "minutes");

    if (pickUpLate >= 1 && pickUpLate <= 10) {
      setLateColor("orange");
    } else if (pickUpLate < 1) {
      setLateColor("#ff718a");
    } else {
      setLateColor("");
    }
  };

  const cancelOrder = (orderId: number) => {
    confirmationDialog.setLoading(true);

    deleteOrderManuallyAsync(orderId)
      .then(() => {
        confirmationDialog.close();

        orderDeleted(orderId);
        enqueueSnackbar("Нарачката е успешно избришана.", {
          variant: "success",
          autoHideDuration: 6000,
        });
      })
      .catch((error) => {
        confirmationDialog.close();

        enqueueSnackbar(`Нарачката не е успешно избришана: ${error.message}`, {
          variant: "error",
          autoHideDuration: 6000,
        });
      });
  };

  const handleCancel = (orderId: number) => {
    confirmationDialog.open({
      icon: <Warning fontSize="large" style={{ color: "orange" }} />,
      title: "Откажи нарачка",
      body: `Дали сте сигурни дека сакате да ја откажете нарачката ${
        order.displayOrderNumber ? 
          `${order.displayOrderNumber}` : 
          `#${cutString(order.externalOrderNumber, 3)}`
      }?`,
      onConfirm: () => cancelOrder(orderId),
      onDeny: () => confirmationDialog.close(),
    });
  };

  const { showModal } = useModal({ disableAutoDestroy: true });
  const handleSelectedDriver = (driverId: number) => {
    const driver = drivers.find((d) => d.employeeId === driverId);

    // Check if the driver already has assigned orders
    const activeOrders = driver?.orders.filter(
      (o) => o.orderStatus !== OrderStatus.WaitingForAccept
    );
    if (driverId && activeOrders.length > 0) {
      const editRouteModal = showModal(EditRouteDialog, {
        selectedDriverId: driverId,
        selectedOrderId: order.id,
        onClose: () => editRouteModal.destroy(),
      });

      return;
    }

    confirmationDialog.open({
      icon: <Warning fontSize="large" style={{ color: "orange" }} />,
      title: "Додели нарачка",
      body:
        driverId != null
          ? `Дали сте сигурни дека сакате да ја доделите нарачката ${order.id}
            на возачот ${driver.firstName} ${driver.lastName}?`
          : `Дали сте сигурни дека сакате нарачката ${order.id} да ја вратите на повторна 
            обработка од системот?`,
      onConfirm: () => {
        driverId != null ? handleOrderAssign(driverId) : handleOrderReschedule();
      },
      onDeny: () => {
        confirmationDialog.close();
      },
    });
  };

  const closeNewOrderDialog = () => {
    setUpdateOrderDialogActiveState(false);
  };

  const openNewOrderDialog = () => {
    setUpdateOrderDialogActiveState(true);
  };

  const handleOrderReschedule = () => {
    confirmationDialog.setLoading(true);

    rescheduleOrderAsync(order.id)
      .then(() => {
        enqueueSnackbar("Нарачката е успешно вратена на повторна обработка.", {
          variant: "success",
          autoHideDuration: 6000,
        });
        confirmationDialog.close();
      })
      .catch((error) => {
        enqueueSnackbar(`Нарачката не е успешно вратена на повтрона обработка: ${error.message}`, {
          variant: "error",
          autoHideDuration: 6000,
        });
        confirmationDialog.close();
      });
  };

  const handleOrderAssign = (employeeFk: number) => {
    confirmationDialog.setLoading(true);

    assignOrderManuallyAsync(order.id, employeeFk)
      .then(() => {
        let driver = drivers.find((x) => x.employeeId == employeeFk);
        if (!driver) {
          return;
        }
        enqueueSnackbar(
          `Успешно пратена нотификација на ${driver.firstName} ${driver.lastName} за доделена нарачка.`,
          {
            variant: "success",
            autoHideDuration: 6000,
          }
        );
        confirmationDialog.close();
      })
      .catch((error) => {
        enqueueSnackbar(`Неуспешно испратена нотификација: ${error.response.data.message}`, {
          variant: "error",
          autoHideDuration: 6000,
        });
        confirmationDialog.close();
      });
  };

  const handlePauseOrder = (orderId: number) => {
    setLoadingState((state) => ({ ...state, pause: true }));

    pauseOrderManuallyAsync(orderId).then(
      () => {
        setLoadingState((state) => ({ ...state, pause: false }));
        enqueueSnackbar("Нарачката е успешно ставена на пауза.", {
          variant: "success",
          autoHideDuration: 6000,
        });
      },
      (error) => {
        setLoadingState((state) => ({ ...state, pause: false }));
        enqueueSnackbar(`Нарачката не е успешно ставена на пауза: ${error.message}`, {
          variant: "error",
          autoHideDuration: 6000,
        });
      }
    );
  };

  const formatTimeLeft = (minutes: number) => {
    return minutes <= 0 ? `+${Math.abs(minutes)}` : minutes * -1;
  };

  const pickupTime = dayjs.utc(order.pickUpTime).local();

  const selectedDriver = useMemoSelector((state) =>
    selectDriverByIdWithoutGeolocation(state, order?.employeeFk)
  );

  return (
    <>
      <TableRow style={{ background: lateColor }}>
        <TableCell rowSpan={2}>
          <Checkbox checked={isSelected} onChange={onSelect} />
        </TableCell>
        <TableCell align="center" rowSpan={2}>
          <DriverSelect drivers={drivers} onChange={handleSelectedDriver} />
        </TableCell>
        {order?.displayOrderNumber ? (
        <TableCell rowSpan={2} padding="checkbox">
          <strong>{order.displayOrderNumber}</strong>
        </TableCell>) : (
        <TableCell rowSpan={2} padding="checkbox">
          <strong>#{cutString(order.externalOrderNumber, 3)}</strong>
        </TableCell>)}
        <TableCell rowSpan={2} padding="checkbox">
          {translateOrderStatus(order.orderStatus)}
        </TableCell>
        <TableCell style={{ paddingTop: "10px", paddingBottom: "5px" }}>
          {order.pickUpTime ? (
            <>
              {pickupTime?.format("HH:mm")}&nbsp;
              <span>({formatTimeLeft(timeLeft)})</span>
            </>
          ) : (
            "N/A"
          )}
        </TableCell>
        <TableCell>
          <Box style={{ display: "flex" }} flexDirection="row">
            {order.isPriority ? (
              <TrophyIcon style={{ width: "30px", marginRight: "10px", fill: "orange"}}
              onDragStart={(handleDrop)=> handleDrop.preventDefault()} />
            ) : null}
          </Box>
        </TableCell>
        <TableCell>
          <Box style={{ display: "flex" }} flexDirection="row">
            <Avatar
              style={{
                width: "30px",
                height: "30px",
                marginRight: "8px",
              }}
              variant="circular"
              title={order?.partnerName}
              alt={order?.partnerName}
              src={order?.partnerImageSrc}
            >
              <Storefront fontSize="small" />
            </Avatar>
            {selectedDriver ? (
              <a href={`tel:${selectedDriver.phoneNumber}`}>
                <Avatar
                  style={{
                    width: "30px",
                    height: "30px",
                    marginRight: "8px",
                  }}
                  variant="circular"
                  alt={`${selectedDriver.firstName} ${selectedDriver.lastName}`}
                  title={`${selectedDriver.firstName ?? ""} ${selectedDriver.lastName ?? ""}`}
                  src={selectedDriver.imageSrc}
                >
                  {`${selectedDriver.firstName?.[0]}${selectedDriver.lastName?.[0]}`}
                </Avatar>
              </a>
            ) : null}
          </Box>
        </TableCell>
        <TableCell padding='checkbox' rowSpan={2}>
        <div style={{ display: "flex", flexDirection: "row" }}>
        <ClickableTooltip arrow={true} type="order" content={<OrderTooltipContent order={order} />} placement='right'>
              <IconButton size='small'>
                <InfoOutlined />
              </IconButton>
            </ClickableTooltip>

            {order.orderStatus === OrderStatus.UnsuccessfulPayment ? (
              <UnsuccessfulPaymentMenu orderId={order.id} />
            ) : null}

            {order.orderStatus === OrderStatus.Paused ? (
              <>
                <IconButton onClick={openNewOrderDialog} size="small">
                  <EditOutlined />
                </IconButton>
                <IconButton onClick={() => handleCancel(order.id)} size="small">
                  <DeleteOutline />
                </IconButton>
              </>
            ) : (
              <div>
                {order.orderStatus !== OrderStatus.RejectByCustomer &&
                order.orderStatus !== OrderStatus.AutoRejectedForCustomer ? (
                  <IconButton
                    disabled={loadingState.pause}
                    onClick={() => {
                      handlePauseOrder(order.id);
                    }}
                    size="small"
                  >
                    {loadingState.pause ? <CircularProgress size={20} /> : <PauseOutlined />}
                  </IconButton>
                ) : (
                  <IconButton onClick={() => handleCancel(order.id)} size="small">
                    <DeleteOutline />
                  </IconButton>
                )}
              </div>
            )}
          </div>
        </TableCell>
      </TableRow>
      <TableRow style={{ background: lateColor }}>
        <TableCell colSpan={3} align="center" style={{ paddingTop: "5px", paddingBottom: "5px" }}>
          <span title={order.pickUpAddress}>{addressData.pickUp}</span>
          &nbsp;&rarr;&nbsp;
          <span title={order.dropOffAddress}>{addressData.dropOff}</span>
        </TableCell>
      </TableRow>
      {updateOrderDialogActiveState ? (
        <CreateOrderFormDialog order={order} onClose={closeNewOrderDialog} />
      ) : null}
    </>
  );
};

export default UnassignedOrderCard;
