import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import { IPayrollAllEmployeePositionsSummaryDto } from "@shared/services/payroll/dtos/payrollAllEmployeePositionsSummaryDto";
import { FC } from "react";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      minWidth: theme.spacing(48),
      minHeight: theme.spacing(36),
      margin: theme.spacing(1),
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.grey[100],
    },
    contentItem: {
      display: "flex",
      justifyContent: "space-between",
      paddingTop: theme.spacing(1),
      paddingBotton: theme.spacing(1),
      cursor: "default",
    },
  })
);

interface AllEmployeePositionSummaryCardProps {
  allEmployeePositionsSummary: IPayrollAllEmployeePositionsSummaryDto;
}

const EmployeePositionSummaryCard: FC<AllEmployeePositionSummaryCardProps> = ({
  allEmployeePositionsSummary,
}) => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardHeader title="Сите позиции" />
      <CardContent>
        <Box className={classes.contentItem}>
          <Typography>Заработка: </Typography>
          <Typography>
            {allEmployeePositionsSummary.earnings} <em>мкд</em>
          </Typography>
        </Box>
        <Box className={classes.contentItem}>
          <Typography>Бонус: </Typography>
          <Typography>
            {allEmployeePositionsSummary.bonus} <em>мкд</em>
          </Typography>
        </Box>
        <Box className={classes.contentItem}>
          <Typography>Боледување: </Typography>
          <Typography>
            {allEmployeePositionsSummary.sick} <em>мкд</em>
          </Typography>
        </Box>
        <Box className={classes.contentItem}>
          <Typography>Одмор: </Typography>
          <Typography>
            {allEmployeePositionsSummary.holiday} <em>мкд</em>
          </Typography>
        </Box>
        <Box className={classes.contentItem}>
          <Typography>Пенали: </Typography>
          <Typography>
            {allEmployeePositionsSummary.penalties} <em>мкд</em>
          </Typography>
        </Box>
        <Box className={classes.contentItem}>
          <Typography>Заработено: </Typography>
          <Typography>
            {allEmployeePositionsSummary.salary} <em>мкд</em>
          </Typography>
        </Box>

        {/* <Divider style={{ marginTop: 8, backgroundColor: "#ffffff" }} /> */}

        {/* <Box className={classes.contentItem}>
          <Typography>Платено: </Typography>
          <Typography>
            {allEmployeePositionsSummary.paid} <em>мкд</em>
          </Typography>
        </Box>
        <Box className={classes.contentItem}>
          <Typography>Неплатено: </Typography>
          <Typography>
            {allEmployeePositionsSummary.unpaid} <em>мкд</em>
          </Typography>
        </Box> */}
      </CardContent>
    </Card>
  );
};

export default EmployeePositionSummaryCard;
