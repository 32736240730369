import httpService from "../http.service";
import { ICreatePayrollRequestDto } from "./dtos/old/createPayrollRequestDto";
import { IEmployeePayrollAbsenceDto } from "./dtos/old/employeePayrollAbsenceDto";
import { IEmployeePayrollAbsenceRequestDto } from "./dtos/old/employeePayrollAbsenceRequestDto";
import { IEmployeePayrollDeductionsDto } from "./dtos/old/employeePayrollDeductionsDto";
import { IEmployeePayrollDeductionsRequestDto } from "./dtos/old/employeePayrollDeductionsRequestDto";
import { IEmployeePayrollDto } from "./dtos/old/employeePayrollDto";
import { IEmployeePayrollGeneralInformationDto } from "./dtos/old/employeePayrollGeneralInformationDto";
import { IEmployeePayrollGeneralInformationRequestDto } from "./dtos/old/employeePayrollGeneralInformationRequestDto";
import { IEmployeePayrollSupplementsDto } from "./dtos/old/employeePayrollSupplementsDto";
import { IEmployeePayrollSupplementsRequestDto } from "./dtos/old/employeePayrollSupplementsRequestDto";
import { IPayrollDto } from "./dtos/old/payrollDto";
import { IPayrollStatisticsDto } from "./dtos/old/payrollStatisticsDto";

//!!!
export const getPayrolls = () => {
  return httpService.get<IPayrollDto[]>("/api/payrolls");
};

//!!!
export const getEmployeePayrollsForEmployee = (payrollId: number, month: number, year: number) => {
  return httpService.post<any>(`/api/payroll/period/driver/${payrollId}/${year}/${month}`);
};

export const getEmployeePayrolls = (payrollId: number) => {
  return httpService.get<IEmployeePayrollDto[]>(`/api/payrolls/${payrollId}`);
};

//!!!
export const recalculateEmplyoeePayroll = (payrollId: number, employeePayrollId: number) => {
  return httpService.put<void>(
    `/api/payrolls/${payrollId}/employee-payrolls/${employeePayrollId}/recalculate`
  );
};

export const recalculatePayroll = (payrollId: number) => {
  return httpService.put<void>(`/api/payrolls/${payrollId}/recalculate`);
};

export const getEmployeePayrollGeneralInformation = (
  payrollId: number,
  employeePayrollId: number
) => {
  return httpService.get<IEmployeePayrollGeneralInformationDto>(
    `/api/payrolls/${payrollId}/employee-payrolls/${employeePayrollId}/general-information`
  );
};

export const updateEmployeePayrollGeneralInformation = (
  payrollId: number,
  employeePayrollId: number,
  request: IEmployeePayrollGeneralInformationRequestDto
) => {
  return httpService.put<void, IEmployeePayrollGeneralInformationRequestDto>(
    `/api/payrolls/${payrollId}/employee-payrolls/${employeePayrollId}/general-information`,
    request
  );
};

export const getEmployeePayrollSupplementsAsync = (
  payrollId: number,
  employeePayrollId: number
) => {
  return httpService.get<IEmployeePayrollSupplementsDto[]>(
    `/api/payrolls/${payrollId}/employee-payrolls/${employeePayrollId}/supplements`
  );
};

export const getEmployeePayrollDeductionsAsync = (payrollId: number, employeePayrollId: number) => {
  return httpService.get<IEmployeePayrollDeductionsDto[]>(
    `/api/payrolls/${payrollId}/employee-payrolls/${employeePayrollId}/deductions`
  );
};

export const insertEmployeePayrollSupplementAsync = (
  payrollId: number,
  employeePayrollId: number,
  request: IEmployeePayrollSupplementsRequestDto
) => {
  return httpService.post<void, IEmployeePayrollSupplementsRequestDto>(
    `/api/payrolls/${payrollId}/employee-payrolls/${employeePayrollId}/supplements`,
    request
  );
};

export const insertEmployeePayrollDeductionAsync = (
  payrollId: number,
  employeePayrollId: number,
  request: IEmployeePayrollDeductionsRequestDto
) => {
  return httpService.post<void, IEmployeePayrollDeductionsRequestDto>(
    `/api/payrolls/${payrollId}/employee-payrolls/${employeePayrollId}/deductions`,
    request
  );
};

export const updateEmployeePayrollSupplementAsync = (
  payrollId: number,
  employeePayrollId: number,
  supplementId: number,
  request: IEmployeePayrollSupplementsRequestDto
) => {
  return httpService.put<void, IEmployeePayrollSupplementsRequestDto>(
    `/api/payrolls/${payrollId}/employee-payrolls/${employeePayrollId}/supplements/${supplementId}`,
    request
  );
};

export const updateEmployeePayrollDeductionAsync = (
  payrollId: number,
  employeePayrollId: number,
  deductionId: number,
  request: IEmployeePayrollDeductionsRequestDto
) => {
  return httpService.put<void, IEmployeePayrollDeductionsRequestDto>(
    `/api/payrolls/${payrollId}/employee-payrolls/${employeePayrollId}/deductions/${deductionId}`,
    request
  );
};

export const deleteEmployeePayrollSupplementAsync = (
  payrollId: number,
  employeePayrollId: number,
  supplementId: number
) => {
  return httpService.delete<void>(
    `/api/payrolls/${payrollId}/employee-payrolls/${employeePayrollId}/supplements/${supplementId}`
  );
};

export const deleteEmployeePayrollDeductionAsync = (
  payrollId: number,
  employeePayrollId: number,
  deductionId: number
) => {
  return httpService.delete<void>(
    `/api/payrolls/${payrollId}/employee-payrolls/${employeePayrollId}/deductions/${deductionId}`
  );
};

export const getEmployeePayrollAbsencesAsync = (payrollId: number, employeePayrollId: number) => {
  return httpService.get<IEmployeePayrollAbsenceDto[]>(
    `/api/payrolls/${payrollId}/employee-payrolls/${employeePayrollId}/absences`
  );
};

export const insertEmployeePayrollAbsenceAsync = (
  payrollId: number,
  employeePayrollId: number,
  request: IEmployeePayrollAbsenceRequestDto
) => {
  return httpService.post<void, IEmployeePayrollAbsenceRequestDto>(
    `/api/payrolls/${payrollId}/employee-payrolls/${employeePayrollId}/absences`,
    request
  );
};

export const updateEmployeePayrollAbsenceAsync = (
  payrollId: number,
  employeePayrollId: number,
  absenceId: number,
  request: IEmployeePayrollAbsenceRequestDto
) => {
  return httpService.put<void, IEmployeePayrollAbsenceRequestDto>(
    `/api/payrolls/${payrollId}/employee-payrolls/${employeePayrollId}/absences/${absenceId}`,
    request
  );
};

export const deleteEmployeePayrollAbsenceAsync = (
  payrollId: number,
  employeePayrollId: number,
  absenceId: number
) => {
  return httpService.delete<void>(
    `/api/payrolls/${payrollId}/employee-payrolls/${employeePayrollId}/absences/${absenceId}`
  );
};

export const createPayrollAsync = (request: ICreatePayrollRequestDto) => {
  return httpService.post<void, ICreatePayrollRequestDto>(`/api/payrolls`, request);
};

export const deletePayrollAsync = (payrollId: number) => {
  return httpService.delete<void>(`/api/payrolls/${payrollId}`);
};

//!!!
export const exportEmployeePayrollExcel = (payrollId: number, employeePayrollId: number) => {
  return httpService.getBlob(
    `/api/payrolls/${payrollId}/employee-payrolls/${employeePayrollId}/export`
  );
};

export const exportPayrollExcel = (payrollId: number) => {
  return httpService.getBlob(`/api/payrolls/${payrollId}/export`);
};

export const getPayrollStatistics = (payrollId: number) => {
  return httpService.get<IPayrollStatisticsDto>(`/api/payrolls/${payrollId}/statistics`);
};
