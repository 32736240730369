import { router } from "../../../Main";
import httpService from "../http.service";
import { ICurrentUserDto } from "./dtos/currentUserDto";
import { ILoginUserRequestDto } from "./dtos/loginUserRequestDto";
import { IRegisterUserRequestDto } from "./dtos/registerUserRequestDto";
import { ITokenDto } from "./dtos/tokenDto";
import { IUserManagerResponseDto } from "./dtos/userManagerResponseDto";
import { jwtDecode } from "jwt-decode";
import { Observable, Subscription, timer } from "rxjs";

export const sessionStorageKey = "access_token";

export const login = (request: ILoginUserRequestDto) => {

  return httpService
    .post<IUserManagerResponseDto, ILoginUserRequestDto>("/api/auth/login", request)
    .then((res) => {
      return res;
    });
};

export const register = (request: IRegisterUserRequestDto) => {
  return httpService
    .post<IUserManagerResponseDto, ILoginUserRequestDto>("/api/auth/register", request)
    .then((res) => {
      return res;
    });
};

export const getCurrentUser = (): ICurrentUserDto => {
  const token = getAccessToken();
  if (!token) {
    logout();
    return null;
  }

  const decoded_token = decodeToken(token);
  if (!decoded_token) {
    logout();
    return null;
  }

  return {
    id: decoded_token.Id,
    email: decoded_token.Email,
    isDriver: decoded_token.IsDriver,
    employeeId: decoded_token.EmployeeId,
  };
};

export const checkToken = () => {
  const token = getAccessToken();
  if (!token) {
    return logout();
  }

  const decoded_token = decodeToken(token);
  if (!decoded_token) return logout();

  if (decoded_token.exp < Date.now() / 1000) {
    return logout();
  }
};

var t: Observable<number> = timer(0, 1000);
var subscription: Subscription = null;

export const startTokenChecker = () => {
  if (!t) {
    t = timer(0, 1000);
  }

  if (subscription) {
    subscription.unsubscribe();
    subscription = null;
  }

  subscription = t.subscribe(() => {
    checkToken();
  });
};

export const logout = (): void => {
  sessionStorage.removeItem(sessionStorageKey);

  if (window.Android?.onLogOut) {
    window.Android.onLogOut();
  }

  router.navigate("/auth/login");
};

export const decodeToken = (token: string): ITokenDto => {
  let decoded_token = null;

  try {
    decoded_token = jwtDecode<ITokenDto>(token);
  } catch (e) {
    decoded_token = null;
  }

  return decoded_token;
};

export const getAccessToken = (): string => {
  return sessionStorage.getItem(sessionStorageKey);
};

export const setAccessToken = (accessToken: string): void => {
  sessionStorage.setItem(sessionStorageKey, accessToken);
};

export const isUserDriver = (isDriverString: string): boolean => {
  if (!isDriverString) return false;

  return isDriverString?.toLowerCase() === "true";
};
