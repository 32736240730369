import { FC, memo } from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { Marker } from "react-leaflet";
import { Icon as LeafletIcon, divIcon } from "leaflet";
import { Icon, makeStyles } from "@material-ui/core";
import DriverMarkerPopup from "./DriverMarkerPopup";
import { IMapDriver } from "@shared/components/Map/models/models";
import { isDriverPaused, isDriverBusy, driverHasFiscalPrinter } from "@shared/utils/drivers/driverStatus";
import { useMemoSelector } from "@shared/hooks";
import { getDisplayValue } from "@shared/utils/getDisplayValue";
import { getVehicleIconSVG } from "@shared/utils/getVehicleIconSVG";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: "50%",
    backgroundColor: "white",
    border: `4.5px solid`,
     },
  
  outlineFree: {
    borderColor: theme.palette.success.main,
  },
  outlineBusy: {
    borderColor: theme.palette.warning.main,
  },
  outlinePaused: {
    borderColor: theme.palette.error.main,
  },
  outlineFreeOnlinePaymentOnly: {
    background: '#2196f3',
  },
  vehicleTypeIcon: {
    pointerEvents: "none",
    borderRadius: "50%",
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    
  },
}));

export interface DriverMarkerProps {
  driver: IMapDriver;
  iconMode?: "vehicle" | "avatar";
}

const DriverMarker: FC<DriverMarkerProps> = ({ driver, iconMode = "vehicle" }) => {
  const classes = useStyles();

  const vehicleTypes = useMemoSelector((state) => state.sharedState?.vehicleTypes?.data);

  const driverIcon = new LeafletIcon({
    className: clsx(classes.root, classes.outlineFree, {
      [classes.outlinePaused]: isDriverPaused(driver),
      [classes.outlineBusy]: isDriverBusy(driver),
      [classes.outlineFreeOnlinePaymentOnly]: driverHasFiscalPrinter(driver) ,

    }),
    iconSize: [32, 32],
    iconAnchor: [16, 16],
    iconUrl: driver.imageSrc
      ? driver.imageSrc
      : driver.hasFiscalPrinter ? `https://eu.ui-avatars.com/api/?format=svg&bold=true&name=${driver.firstName}+${driver.lastName}`
      : `https://eu.ui-avatars.com/api/?format=svg&bold=true&background=2196f3&color=fff&name=${driver.firstName}+${driver.lastName}`,
  });
  const isFreeOnlinePayment = driverHasFiscalPrinter(driver);
  const vehicleTypeIcon = divIcon({
    className: clsx(classes.root, classes.outlineFree, {
      [classes.outlinePaused]: isDriverPaused(driver),
      [classes.outlineBusy]: isDriverBusy(driver),
      [classes.outlineFreeOnlinePaymentOnly]: !driverHasFiscalPrinter(driver) ,
    }),
    iconSize: [32, 32],
    iconAnchor: [16, 16],
    html: renderToStaticMarkup(
      <Icon className={classes.vehicleTypeIcon}>
  {getVehicleIconSVG(getDisplayValue(vehicleTypes, driver.vehicleTypeFk), !isFreeOnlinePayment)}
</Icon>
    ),
  });

  const defaultIcon = iconMode === "vehicle" ? vehicleTypeIcon : driverIcon;
  const hoverIcon = iconMode === "vehicle" ? driverIcon : vehicleTypeIcon;

  return (
    <Marker
      eventHandlers={{
        mouseover: (e) => e.target?.setIcon(hoverIcon),
        mouseout: (e) => e.target?.setIcon(defaultIcon),
      }}
      key={driver.employeeId}
      title={`${driver.firstName} ${driver.lastName}`}
      position={{
        lat: driver.geolocation.latitude,
        lng: driver.geolocation.longitude,
      }}
      icon={defaultIcon}
    >
      <DriverMarkerPopup driver={driver} />
    </Marker>
  );
};

export default memo(DriverMarker);
