import { selectedDriverId } from "@App/modules/Logistics/store/features/filters/filtersSlice";
import RoutePointMarker from "./RoutePointMarker";
import { IMapOrder } from "@shared/components/Map/models/models";
import { IRoutePointDto } from "@shared/services/orders/dtos/orders/routePointDto";
import { FC, memo, useEffect, useState } from "react";
import { useMap } from "react-leaflet";
import { useDispatch } from "react-redux"; 


interface RoutePointMarkersProps {
  routePoints: IRoutePointDto[];
  orders: IMapOrder[];
  toggleRouteLines: (checked: boolean, driverId: string) => void; 
}

const RoutePointMarkers: FC<RoutePointMarkersProps> = ({ routePoints, orders, toggleRouteLines }) => {
  const map = useMap();
  const dispatch = useDispatch();
  const [isRouteLinesChecked, setIsRouteLinesChecked] = useState(true);

  useEffect(() => {
    const handler = () => {
      const zoomLevel = map.getZoom();
      if (zoomLevel > 13) {
        map.getPane("tooltip-pane").style.visibility = "visible";
      } else {
        map.getPane("tooltip-pane").style.visibility = "hidden";
      }
    };

    map.on("zoomend", handler);
    return () => {
      map.off("zoomend", handler);
    };
  }, [map]);

  const handleMarkerClick = (routePoint: IRoutePointDto) => {
    const newIsRouteLinesChecked = !isRouteLinesChecked;
    setIsRouteLinesChecked(newIsRouteLinesChecked);

    const order = orders.find((x) => x.id === routePoint.orderId) as IMapOrder;
    const driverId = order?.driver ? String(order.driver.employeeId) : ""; 

  
    if (driverId) {
      dispatch(
        selectedDriverId({
          selectedId: Number(driverId),
          isEnabled: true,
        })
      );
    }

    toggleRouteLines(newIsRouteLinesChecked, driverId);
    console.log("Marker clicked:", routePoint, "Driver ID:", driverId, "Route lines checked:", newIsRouteLinesChecked);
  };
  
  return (
    <>
      {routePoints
        ?.filter((routePoint) => !routePoint.onLocationAt)
        .map((routePoint) => (
          <RoutePointMarker
            key={`${routePoint.orderId}-${routePoint.id}-${routePoint.isPickup}`}
            routePoint={routePoint}
            order={orders.find((x) => x.id === routePoint.orderId)}
            onClick={() => handleMarkerClick(routePoint)}
          />
        ))}
    </>
  );
};

export default memo(RoutePointMarkers);
