import { TaxSettingsValidationSchema } from "./schemas/TaxSettingsValidationSchema";
import {
  Button,
  Grid,
  TextField,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import { ITaxSettingsDto } from "@shared/services/settings/dtos/tax-settings/taxSettingsDto";
import { getTaxSettings, updateTaxSettings } from "@shared/services/settings/tax-settings.service";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { FC, useEffect, useState } from "react";

const initialValues: ITaxSettingsDto = {
  personalTaxPercentage: 0,
  taxRelease: 0,
  taxReleaseForBonuses: 0,
  contributions: 0,
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      width: "50%",
    },
  })
);

const TaxSettingsPage: FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const styles = useStyles();

  const [loadingState, setLoadingState] = useState<boolean>(false);

  const handleSubmit = () => {
    setLoadingState(true);
    updateTaxSettings({ ...formik.values })
      .then(() => {
        enqueueSnackbar("Успешно ги сочувавте информациите", {
          variant: "success",
          autoHideDuration: 6000,
        });
        setLoadingState(false);
      })
      .catch((e) => {
        enqueueSnackbar(`Се случи грешка: ${e.message}`, {
          variant: "error",
          autoHideDuration: 6000,
        });
        setLoadingState(false);
      });
  };

  useEffect(() => {
    setLoadingState(true);
    getTaxSettings().then((res) => {
      setValues(res);
      setLoadingState(false);
    });
  }, []);

  const {
    setValues,
    handleChange: handleFormikChange,
    ...formik
  } = useFormik({
    initialValues,
    validationSchema: TaxSettingsValidationSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  return (
    <>
      <form className={styles.form} onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5">Даноци за пресметка на плата</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="personalTaxPercentage"
              label="Персонален данок (%)"
              variant="outlined"
              size="small"
              value={formik.values.personalTaxPercentage}
              onChange={handleFormikChange}
              error={
                formik.touched.personalTaxPercentage && Boolean(formik.errors.personalTaxPercentage)
              }
              helperText={
                formik.touched.personalTaxPercentage && formik.errors.personalTaxPercentage
              }
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="taxReleaseForBonuses"
              label="Ослободување од данок за бонуси"
              variant="outlined"
              size="small"
              value={formik.values.taxReleaseForBonuses}
              onChange={handleFormikChange}
              error={
                formik.touched.taxReleaseForBonuses && Boolean(formik.errors.taxReleaseForBonuses)
              }
              helperText={formik.touched.taxReleaseForBonuses && formik.errors.taxReleaseForBonuses}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="taxRelease"
              label="Ослободување од данок"
              variant="outlined"
              size="small"
              value={formik.values.taxRelease}
              onChange={handleFormikChange}
              error={formik.touched.taxRelease && Boolean(formik.errors.taxRelease)}
              helperText={formik.touched.taxRelease && formik.errors.taxRelease}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="contributions"
              label="Придонеси"
              variant="outlined"
              size="small"
              value={formik.values.contributions}
              onChange={handleFormikChange}
              error={formik.touched.contributions && Boolean(formik.errors.contributions)}
              helperText={formik.touched.contributions && formik.errors.contributions}
              fullWidth
            />
          </Grid>
          <Grid item style={{ display: "flex", justifyContent: "flex-end" }} xs={12}>
            <Button
              style={{ float: "right" }}
              type="submit"
              disabled={loadingState}
              variant="contained"
              color="primary"
            >
              Зачувај
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default TaxSettingsPage;
