import { RGBColor } from "./hexToRGB";

/**
 * Returns a color from a gradient based on a step value.
 * @param gradient The gradient to use.
 * @param step The step value.
 * @returns The color from the gradient.
 *
 * @source https://gist.github.com/joocer/bf1626d38dd74fef9d9e5fb18fef517c
 */
export const getColorFromGradient = (gradient: RGBColor[], step: number) => {
  // Return input color if first or last element
  if (step >= 1) {
    return gradient[gradient.length - 1];
  } else if (step <= 0) {
    return gradient[0];
  }

  // Calculate interval and fade values
  let fade = step * (gradient.length - 1);
  let interval = Math.trunc(fade);
  fade = fade - interval;

  // Pick 2 colors that match the interval
  const color1 = gradient[interval];
  const color2 = gradient[interval + 1];

  // Calculate the difference between the two colors
  var diffRed = color2.r - color1.r;
  var diffGreen = color2.g - color1.g;
  var diffBlue = color2.b - color1.b;

  const color: RGBColor = {
    r: parseInt(Math.floor(color1.r + diffRed * fade).toString()),
    g: parseInt(Math.floor(color1.g + diffGreen * fade).toString()),
    b: parseInt(Math.floor(color1.b + diffBlue * fade).toString()),
  };

  return color;
};

/**
 * Generates a color gradient from the given colors.
 * @param count The number of colors to generate.
 * @param colors A list of colors to use for the gradient.
 * @returns A list of colors.
 */
export const generateColorGradient = (count: number, colors: RGBColor[]) => {
  return [...Array(count).keys()].map((i) => getColorFromGradient(colors, i));
};
