import { CircularProgress } from "@material-ui/core";
import { FC, ReactNode, useEffect, useState } from "react";

interface DebounceProps {
  loading: boolean;
  render?: boolean;
  delay?: number;
  loadingElement?: ReactNode;
}

const DebounceLoading: FC<DebounceProps> = ({
  loading,
  render = true,
  delay = 100,
  loadingElement = <CircularProgress />,
  children,
}) => {
  const [renderLoadingElement, setRenderLoadingElement] = useState<boolean>(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (loading) setRenderLoadingElement(true);
    }, delay);
    return () => {
      setRenderLoadingElement(false);
      clearTimeout(timer);
    };
  }, [loading, delay]);

  return (
    <>
      {loading && renderLoadingElement && loadingElement}
      {render && (!loading || !renderLoadingElement) && children}
    </>
  );
};

export default DebounceLoading;
