import EmployeeCard from "./EmployeeCard";
import { InputAdornment, List, Paper, TextField, Typography, Checkbox } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Search } from "@material-ui/icons";
import ErrorMessage from "@shared/components/ErrorMessage/ErrorMessage";
import { getEmployees } from "@shared/services/employees/employees.service";
import { useQuery } from "@tanstack/react-query";
import { ChangeEvent, FC, useState } from "react";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1.5),
  },
  employeeList: {
    maxHeight: "35vh",
    overflow: "auto",
  },
  selectAllCheckbox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    marginLeft: theme.spacing(-10),
  },
  selectAllLabel: {
    marginRight: theme.spacing(1),
  },
}));

interface DriversListProps {
  values: number[];
  onChange: (values: number[]) => void;
}

const DriversList: FC<DriversListProps> = ({ values, onChange }) => {
  const classes = useStyles();

  const { data: employees, isLoading, error } = useQuery(["employees"], getEmployees);

  const [searchString, setSearchString] = useState<string>("");

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchString(event.currentTarget.value);
  };

  const filterSearch = (string: string) => {
    const cleanSearchString = searchString.trim().toLowerCase();
    const cleanString = string.trim().toLowerCase();
    return cleanString.includes(cleanSearchString);
  };

  const handleSelectAll = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const allIds = employees.map((employee) => employee.id);
      onChange(allIds);
    } else {
      onChange([]);
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>, id: number) => {
    let newValues = [...values];
    if (event.currentTarget.checked) {
      if (!newValues.includes(id)) newValues.push(id);
    } else {
      newValues = newValues.filter((x) => x !== id);
    }

    onChange(newValues);
  };

  if (error) {
    return <ErrorMessage error={error.toString()} />;
  }

  if (isLoading) {
    return <Typography align="center">Се вчитува</Typography>;
  }

  return (
    <Paper variant="outlined" title={"Вработени"} className={classes.paper}>
      <TextField
        variant="outlined"
        margin="dense"
        placeholder="Барај..."
        value={searchString}
        onChange={handleSearch}
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
        }}
      />

      <List dense className={classes.employeeList}>
        <div className={classes.selectAllCheckbox}>
          <Typography className={classes.selectAllLabel}>Селектирај ги сите</Typography>
          <Checkbox
            checked={values.length === employees?.length}
            indeterminate={values.length > 0 && values.length < employees?.length}
            onChange={handleSelectAll}
            color="secondary"
            inputProps={{ "aria-label": "Select All" }}
          />
        </div>

        {employees?.map((employee) =>
          filterSearch(`${employee.firstName} ${employee.lastName}`) ? (
            <EmployeeCard
              key={employee.id}
              firstName={employee.firstName}
              lastName={employee.lastName}
              avatar={employee.imageSrc}
              checked={values?.includes(employee.id)}
              onChange={(event) => handleChange(event, employee.id)}
            />
          ) : null
        )}
      </List>
    </Paper>
  );
};

export default DriversList;
