import { IMapDriver } from "@shared/components/Map/models/models";
import { IDriverRouteResponseDto } from "@shared/services/orders/dtos/orders/driverRouteResponseDto";
import { FC } from "react";
import { Polyline } from "react-leaflet";

interface RouteLinesProps {
  drivers: IMapDriver[];
  driverRoutes: IDriverRouteResponseDto[];
}

const RouteLines: FC<RouteLinesProps> = ({ drivers, driverRoutes }) => {
  const getPositions = (driverRoute: IDriverRouteResponseDto) => {
    const positions = [
      ...driverRoute?.routePoints
        .filter((routePoint) => !routePoint.onLocationAt)
        .map((routePoint) => ({
          lat: routePoint?.latitude,
          lng: routePoint?.longitude,
        })),
    ];

    if (driverRoute.employeeId) {
      const driver = drivers.find((x) => x.employeeId === driverRoute.employeeId);

      if (driver) {
        positions.unshift({
          lat: driver?.geolocation?.latitude,
          lng: driver?.geolocation?.longitude,
        });
      }
    }

    return positions;
  };

  return (
    <>
      {driverRoutes.map((driverRoute, i) => (
        <Polyline
          key={i}
          positions={getPositions(driverRoute)}
          color="blue"
          pathOptions={{
            weight: 2.5,
            dashArray: "5 10",
          }}
        />
      ))}
    </>
  );
};

export default RouteLines;
