import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import ErrorMessage from "@shared/components/ErrorMessage/ErrorMessage";
import { getOrderLog, translateOrderStatus } from "@shared/services/orders/orders.service";
import { cutString } from "@shared/utils/cutString";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { FC } from "react";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      "& th, & td": {
        padding: "0 5px",
        fontSize: "12px",
      },
    },
    title: {
      backgroundColor: "lightgray",

      "& th": {
        padding: theme.spacing(0.5),
      },
    },
  })
);

export interface OrderLogCompactProps {
  orderId: number;
  externalOrderNumber?: string;
  displayOrderNumber?: string;
}

const OrderLogCompact: FC<OrderLogCompactProps> = ({ orderId, externalOrderNumber, displayOrderNumber }) => {
  const classes = useStyles();

  const {
    data: logs,
    error,
    isLoading,
  } = useQuery(["orderLog", orderId], () => getOrderLog(orderId), {
    select: (data) =>
      data.sort((a, b) => (dayjs(a.createdOn).isBefore(dayjs(b.createdOn)) ? 1 : -1)).slice(0, 5),
  });

  return (
    <TableContainer className={classes.root}>
      <Table size="small" aria-label="logs table">
        <TableHead>
          {/* Table title */}
          <TableRow className={classes.title}>
            <TableCell align="center" colSpan={5}>
              <Typography variant="caption" style={{ color: "rgba(0, 0, 0, 0.87)" }}>
                Записи за нарачка{" "}
              {displayOrderNumber ?  
              <strong>#{displayOrderNumber}</strong> :
              <strong>#{cutString(externalOrderNumber || orderId?.toString(), 3)}</strong>
              }
              </Typography>
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell align="left" key="orderStatus">
              Статус
            </TableCell>
            <TableCell align="left" key="modifiedBy">
              Променета од
            </TableCell>
            <TableCell align="left" key="assignedTo">
              Доделена на
            </TableCell>
            <TableCell align="left" key="datetime">
              Датум и време
            </TableCell>
            <TableCell align="left" key="description">
              Опис
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {error ? (
            <TableRow>
              <TableCell align="center" colSpan={5}>
                <ErrorMessage error={error.toString()} />
              </TableCell>
            </TableRow>
          ) : isLoading ? (
            <TableRow>
              <TableCell align="center" colSpan={5}>
                <CircularProgress size={25} />
              </TableCell>
            </TableRow>
          ) : logs?.length === 0 ? (
            <TableRow>
              <TableCell align="center" colSpan={5}>
                Нема записи
              </TableCell>
            </TableRow>
          ) : (
            <>
              {logs.map((log) => (
                <TableRow key={log.id}>
                  <TableCell>{translateOrderStatus(log.orderStatus)}</TableCell>
                  <TableCell>
                    {log.orderUpdatedFromFirstName} {log.orderUpdatedFromLastName}
                  </TableCell>
                  <TableCell>
                    {log.orderAssignToFirstName} {log.orderAssignToLastName}
                  </TableCell>
                  <TableCell>
                    {dayjs.utc(log.createdOn).local().format("DD.MM.YYYY HH:mm")}
                  </TableCell>
                  <TableCell>{log.description}</TableCell>
                </TableRow>
              ))}
            </>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default OrderLogCompact;
