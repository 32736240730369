import { DataGrid } from "@mui/x-data-grid";
import driverWeekToTable from "./utils/driverWeekToTable";
import generateDummyData from "./utils/generateDummyData";
import "./style.css";

const week = driverWeekToTable(generateDummyData(14));

const DriverMonthly = (): JSX.Element => {
  return (
      <div style={{ height: 1000, width: "100%", margin: "0 auto", marginTop: "20px" }}>
        <DataGrid rows={week.rows} columns={week.columns} columnHeaderHeight={0} />
      </div>
  );
};

export default DriverMonthly;

