import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import { IPayrollPeriodBonusConditionsDto } from "@shared/services/payroll/dtos/payrollPeriodBonusConditionsDto";
import { FC } from "react";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      minWidth: theme.spacing(48),
      minHeight: theme.spacing(36),
      margin: theme.spacing(1),
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.grey[100],
    },
    contentItem: {
      display: "flex",
      justifyContent: "space-between",
      paddingTop: theme.spacing(1),
      paddingBotton: theme.spacing(1),
    },
  })
);

interface BonusConditionsCardProps {
  bonusConditions: IPayrollPeriodBonusConditionsDto;
}

const BonusConditionsCard: FC<BonusConditionsCardProps> = ({ bonusConditions }) => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardHeader title="Услови за бонус" />
      <CardContent>
        <Box className={classes.contentItem}>
          <Typography>
            Доставени нарачки:{" "}
          </Typography>
          <Typography>{bonusConditions.ordersDelivered}</Typography>
        </Box>
        <Box className={classes.contentItem}>
          <Typography>
            Одбиени нарачки <em>({"< 3"})</em>:{" "}
          </Typography>
          <Typography>{bonusConditions.declinedOrders}</Typography>
        </Box>
        <Box className={classes.contentItem}>
          <Typography>
            Одработени саати <em>({"> 160"})</em>:{" "}
          </Typography>
          <Typography>{bonusConditions.workedHours}</Typography>
        </Box>
        <Box className={classes.contentItem}>
          <Typography>
            Пенали <em>({"< 1000"})</em>:{" "}
          </Typography>
          <Typography>
            {bonusConditions.penalties} <em>мкд</em>
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export default BonusConditionsCard;
