import { isVisible } from "../../utils/isVisible";
import Card from "../Card/Card";
import UpdatePackageDialog from "../Map/UpdatePackageDialog";
import { Category, isDropAllowed } from "./Droppable";
import GeneratePackageDialog from "@App/modules/Logistics/components/Map/GeneratePackageDialog";
import EditRouteDialog from "@App/modules/Map/components/Map/EditRouteDialog";
import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  KeyboardSensor,
  MouseSensor,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import { makeStyles } from "@material-ui/core";
import { Warning } from "@material-ui/icons";
import { useConfirmationDialog } from "@shared/context/ConfirmationDialogContext";
import { useMemoSelector } from "@shared/hooks";
import {
  OrderStatus,
  assignOrderGroupManuallyAsync,
  assignOrderManuallyAsync,
  groupOrdersAsync,
} from "@shared/services/order.service";
import { IPackageOrdersRequest } from "@shared/services/order.service";
import { ShortRoutePoint } from "@shared/services/order.service";
import { useModal } from "mui-modal-provider";
import { useSnackbar } from "notistack";
import { FC, useCallback, useState } from "react";
import { createPortal } from "react-dom";
import { useStore } from "react-redux";

interface IdObject {
  fullId: string;
  category: Category;
  id: number;
}

const useStyles = makeStyles((theme) => ({
  footerDroppablesContainer: {
    display: "flex",
    flexDirection: "row",
    gap: "8px",
    alignItems: "center",
    justifyContent: "center",
    position: "fixed",
    left: "50%",
    transform: "translateX(-50%)",
    bottom: 20,
  },
  footerDroppable: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 300,
    height: 60,
    borderRadius: 5,
    border: "1px solid",
    backgroundColor: "#2a2d3d",
    zIndex: 10000,
  },
}));

const parseId = (id: string): IdObject => {
  const target = id.split("-");
  const targetCategory = target[0] as Category;
  const targetId = parseInt(target[1], 10);

  return {
    fullId: id,
    id: targetId,
    category: targetCategory,
  };
};

const CustomDndContext: FC = (props) => {
  const state = useMemoSelector((state) => state);


  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 2,
      },
    }),
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const store = useStore();
  const [active, setActive] = useState<IdObject>();

  const handleDragStart = useCallback((event: DragStartEvent) => {
    const { active } = event;

    const target = parseId(active.id.toString());
    setActive(target);
  }, []);

  const { enqueueSnackbar } = useSnackbar();
  const { showModal } = useModal({ disableAutoDestroy: true });
  const confirmationDialog = useConfirmationDialog();

  const handleOrderAssign = useCallback(
    (orderId: number, employeeFk: number) => {
      confirmationDialog.setLoading(true);

      assignOrderManuallyAsync(orderId, employeeFk)
        .then(() => {
          enqueueSnackbar(`Успешно пратена нотификација за доделена нарачка.`, {
            variant: "success",
            autoHideDuration: 6000,
          });
        })
        .catch((error) => {
          enqueueSnackbar(`Неуспешно испратена нотификација: ${error.response.data.message}`, {
            variant: "error",
            autoHideDuration: 6000,
          });
        })
        .finally(() => {
          confirmationDialog.close();
        });
    },
    [confirmationDialog, enqueueSnackbar]
  );

  const handleOrderGroupAssign = useCallback(
    (orderGroupId: string, employeeFk: number) => {
      confirmationDialog.setLoading(true);

      assignOrderGroupManuallyAsync(orderGroupId, employeeFk)
        .then(() => {
          enqueueSnackbar(`Успешно пратена нотификација за доделена група.`, {
            variant: "success",
            autoHideDuration: 6000,
          });
          // Promise.all([
          //   dispatch(getActiveOrdersAsyncThunk()),
          //   dispatch(getAllDriversRoutesAsync()),
          //   ])
        })
        .catch((error) => {
          enqueueSnackbar(`Неуспешно испратена нотификација: ${error.response.data.message}`, {
            variant: "error",
            autoHideDuration: 6000,
          });
        })
        .finally(() => {
          confirmationDialog.close();
        });
    },
    [confirmationDialog, enqueueSnackbar]
  );

  const assignOrderToDriver = useCallback(
    (orderId: number, driverId: number) => {
      const driver = state.appState.logistics.drivers.data.find((d) => d.employeeId === driverId);

      // Check if the driver already has assigned orders
      const driverHasActiveOrders = driver?.orders.some(
        (o) => o.orderStatus !== OrderStatus.WaitingForAccept
      );

      if (driverHasActiveOrders) {
        const editRouteModal = showModal(EditRouteDialog, {
          selectedDriverId: driverId,
          selectedOrderId: orderId,
          onClose: () => editRouteModal.destroy(),
        });
      } else {
        confirmationDialog.open({
          icon: <Warning fontSize="large" style={{ color: "orange" }} />,
          title: "Додели нарачка",
          body: `Дали сте сигурни дека сакате да ја доделите нарачката ${orderId}
        на возачот ${driver.firstName} ${driver.lastName}?`,
          onConfirm: () => {
            handleOrderAssign(orderId, driverId);
          },
          onDeny: () => {
            confirmationDialog.close();
          },
        });
      }
    },
    [confirmationDialog, handleOrderAssign, showModal, store]
  );

  const assignOrderGroupToDriver = useCallback(
    (orderGroupId: string, driverId: number) => {
      const driver = state.appState.logistics.drivers.data.find((d) => d.employeeId === driverId);

      // Check if the driver already has assigned orders
      const driverHasActiveOrders = driver?.orders.some(
        (o) => o.orderStatus !== OrderStatus.WaitingForAccept
      );

      orderGroupId = orderGroupId.replace("OrderGroup-", "");
      {
        confirmationDialog.open({
          icon: <Warning fontSize="large" style={{ color: "orange" }} />,
          title: "Додели група",
          body: `Дали сте сигурни дека сакате да ја доделите групата ${orderGroupId}
        на возачот ${driver.firstName} ${driver.lastName}?`,
          onConfirm: () => {
            handleOrderGroupAssign(orderGroupId, driverId);
          },
          onDeny: () => {
            confirmationDialog.close();
          },
        });
      }
    },
    [confirmationDialog, handleOrderGroupAssign, store]
  );

  const handleCreateGroupOrder = useCallback(
    (orderId1: number, orderId2: number, PackageGuid: string) => {
      confirmationDialog.setLoading(true);

      const routePoint1: ShortRoutePoint = {
        orderId: orderId1,
        isPickup: true,
      };

      const routePoint2: ShortRoutePoint = {
        orderId: orderId2,
        isPickup: true,
      };
      const packagePost: IPackageOrdersRequest = {
        PackageGuid: PackageGuid,
        orders: [
          {
            orderId: orderId1,
          },
          {
            orderId: orderId2,
          },
        ],
        routePoints: [routePoint1, routePoint2],
      };
      groupOrdersAsync(packagePost)
        .then(() => {
          enqueueSnackbar(`Успешно групирани нарачки.`, {
            variant: "success",
            autoHideDuration: 6000,
          });
        })
        .catch((error) => {
          enqueueSnackbar(`Неуспешно групирани нарачки: ${error.message}`, {
            variant: "error",
            autoHideDuration: 6000,
          });
        })
        .finally(() => {
          confirmationDialog.close();
        });
    },
    [confirmationDialog, enqueueSnackbar]
  );

  const createOrderGroup = useCallback(
    (orderId1: number, orderId2: number) => {
      const generatePackageModal = showModal(GeneratePackageDialog, {
        orderId1: orderId1,
        orderId2: orderId2,
        onClose: () => generatePackageModal.destroy(),
      });
    },
    [showModal]
  );

  const updateOrderGroup = useCallback(
    (orderId1: number, orderGroupId: string) => {
      const generatePackageModal = showModal(UpdatePackageDialog, {
        orderId1: orderId1,
        orderGroupId: orderGroupId,
        onClose: () => generatePackageModal.destroy(),
      });
    },
    [showModal]
  );

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over, collisions } = event;

      setActive(null);

      if (!over) {
        return;
      }

      // Check if the over element is visible
      const overCollision = collisions.find((collision) => collision.id === over.id);
      const overNode = overCollision?.data?.droppableContainer?.node.current;

      if (overNode && isVisible(overNode) === false) {
        return;
      }

      if (!isDropAllowed(active, over)) {
        return;
      }

      const source = parseId(active.id.toString());
      const target = parseId(over.id.toString());

      let driverId: number = -1;
      let orderId: number = -1;
      let orderId2: number = -1;
      let DriverId: number = -1;
      let orderGroupId: string = "-1";

      if (source.id === target.id) {
        return;
      }

      if (source.category === "Order") {
        orderId = source.id;
      } else if (target.category === "Order") {
        orderId = target.id;
      }

      if (source.category === "Order" && target.category === "Order") {
        orderId = source.id;
        orderId2 = target.id;
      }
      if (source.category === "Order" && target.category === "OrderGroup") {
        orderGroupId = target.fullId.replace("OrderGroup-", "");
        orderId = source.id;
      }
      if (source.category === "OrderGroup" && target.category === "Order") {
        orderGroupId = source.fullId.replace("OrderGroup-", "");
        orderId = target.id;
      }

      if (source.category === "OrderGroup" && target.category === "Driver") {
        orderGroupId = source.fullId;
        driverId = target.id;
      }
      if (source.category === "Driver" && target.category === "OrderGroup") {
        orderGroupId = target.fullId;
        driverId = source.id;
      }

      if (source.category === "Driver" || source.category === "DriversRoute") {
        driverId = source.id;
      } else if (target.category === "Driver" || target.category === "DriversRoute") {
        driverId = target.id;
      }

      if (driverId !== -1 && orderId !== -1) {
        // Assign order to driver
        assignOrderToDriver(orderId, driverId);
      }
      if (orderId !== -1 && orderId2 !== -1) {
        // Update order group
        createOrderGroup(orderId, orderId2);
      }
      if (orderId !== -1 && orderGroupId !== "-1") {
        // Update order group
        updateOrderGroup(orderId, orderGroupId);
      }
      if (orderGroupId !== "-1" && driverId !== -1) {
        // Assign order group to driver
        assignOrderGroupToDriver(orderGroupId, driverId);
      }
    },
    [assignOrderToDriver]
  );

  return (
    <DndContext onDragStart={handleDragStart} onDragEnd={handleDragEnd} sensors={sensors}>
      {props.children}

      {createPortal(
        <DragOverlay dropAnimation={null}>
          {active?.fullId ? (
            <div>
              <Card background="normal" variant="rounded" style={{ pointerEvents: "none" }}>
                Selected: {active.fullId}
              </Card>
            </div>
          ) : null}
        </DragOverlay>,
        document.body
      )}
    </DndContext>
  );
};

export default CustomDndContext;

