import DriverRouteComponent from "./DriverRouteComponent";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ExpandMore } from "@material-ui/icons";
import { IMapOrder } from "@shared/components/Map/models/models";
import { IDriverDto } from "@shared/services/drivers/dtos/driverDto";
import { IDriverRouteResponseDto } from "@shared/services/orders/dtos/orders/driverRouteResponseDto";
import * as React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
}));

interface Props {
  title: string;
  data: IDriverRouteResponseDto[];
  drivers: IDriverDto[];
  orders: IMapOrder[];
  emptyStateText: string;
  isGroupSelected?: boolean;
  onGroupSelect?: (checked: boolean) => void;
  expanded: boolean;
  forceExpanded?: boolean;
}

const OrdersList: React.FC<Props> = ({
  title,
  data,
  emptyStateText,
  drivers,
  orders,
  isGroupSelected,
  onGroupSelect,
  expanded = false,
  forceExpanded = false,
}) => {
  const [expandedState, setExpandedState] = React.useState(false);
  const classes = useStyles();
  React.useEffect(() => {
    setExpandedState(expanded);
  }, [expanded]);

  return (
    <>
      <Accordion elevation={0} expanded={forceExpanded || expandedState} style={{ margin: 0 }}>
        <AccordionSummary
          style={{ padding: 0 }}
          IconButtonProps={{
            style: {
              marginRight: 2,
            },
            onClick: () => {
              setExpandedState(!expandedState);
            },
          }}
          expandIcon={<ExpandMore />}
          aria-label="Expand"
          aria-controls="additional-actions1-content"
          id="additional-actions1-header"
        >
          {onGroupSelect && (
            <Checkbox
              style={{ padding: "0px 8px" }}
              checked={isGroupSelected}
              onChange={(event) => onGroupSelect(event.currentTarget.checked)}
            />
          )}

          <Typography style={{ paddingRight: "10px" }} variant="body1" component="div">
            {title} {` [${data && data.length}]`}
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ padding: 0, width: "100%" }}>
          {data && data.length > 0 ? (
            <Box display="flex" width="100%" flexDirection="column" className={classes.root}>
              {data.map((x, index) => (
                <DriverRouteComponent key={index} orders={orders} drivers={drivers} data={x} />
              ))}
            </Box>
          ) : (
            <Typography variant="body2" align="center">
              {emptyStateText}
            </Typography>
          )}
        </AccordionDetails>
      </Accordion>
    </>
  );
};
export default OrdersList;
