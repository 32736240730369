import InfiniteLoadMore from "./InfiniteLoadMore";
import MaterialCoreTable, {
  Column,
  Icons,
  MTableEditField,
  MaterialTableProps,
  OrderByCollection,
} from "@material-table/core";
import { Grid } from "@material-ui/core";
import {
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Refresh,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
} from "@material-ui/icons";
import { UseInfiniteQueryResult } from "@tanstack/react-query";
import { forwardRef } from "react";

export interface ISort {
  by?: string;
  direction?: "asc" | "desc";
}

export const sortHandler = (
  orderByCollection: OrderByCollection[],
  columns: Column<any>[],
  setSort: (sort: ISort) => void
) => {
  const o = orderByCollection.find(() => true);
  setSort({
    by: !o ? null : (columns[o.orderBy].field as string),
    direction: !o ? null : (o.orderDirection as "asc" | "desc"),
  });
};

const tableIcons: Icons<any> = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} color="secondary" />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} color="primary" />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} color="error" />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} color="error" />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  Retry: forwardRef((props, ref) => <Refresh {...props} ref={ref} />),
};

type MaterialTableCustomProps = {
  infiniteLoad?: boolean;
  children?: never[];
  hasNextPage?: boolean;
  fetchNextPage?: UseInfiniteQueryResult<any>["fetchNextPage"];
  className?: string;
};

const MaterialTable = <T extends object>({
  children,
  infiniteLoad,
  hasNextPage,
  fetchNextPage,
  className,
  options,
  ...props
}: MaterialTableProps<T> & MaterialTableCustomProps) => {
  return (
    <Grid container className={className}>
      <Grid item xs={12}>
        <MaterialCoreTable
          components={{
            EditField: (fieldProps) => (
              <MTableEditField {...fieldProps} value={fieldProps.value || ""} autoComplete="off" />
            ),
          }}
          icons={tableIcons as Icons<T>}
          options={options}
          {...props}
        />
      </Grid>

    </Grid>
  );
};

export default MaterialTable;
