import { ChangeEvent, FC, useEffect, useRef, useState, memo } from "react";
import {
  Avatar,
  createStyles,
  makeStyles,
  Theme,
  Badge,
  withStyles,
} from "@material-ui/core";
import clsx from "clsx";

const StyledBadge = withStyles((theme: Theme) =>
  createStyles({
    badge: {
      backgroundColor: (props: { status: number }) =>
        props?.status ? "#44b700" : "#ff0000",
      color: (props: { status: number }) =>
        props?.status ? "#44b700" : "#ff0000",
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      "&::after": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        animation: "$ripple 1.2s infinite ease-in-out",
        border: "1px solid currentColor",
        content: '""',
      },
    },
    "@keyframes ripple": {
      "0%": {
        transform: "scale(.8)",
        opacity: 1,
      },
      "100%": {
        transform: "scale(2.4)",
        opacity: 0,
      },
    },
  })
)(Badge);

const useStyles = makeStyles(() =>
  createStyles({
    clickable: {
      cursor: "pointer",
    },
    fileInput: {
      display: "none",
    },
  })
);

export interface ImageUploadProps {
  imageSrc: string;
  onChange?: (imageData: string) => void;
  alt?: string;
  mode?: "upload" | "display";
  status?: boolean;
}

const ImageUpload: FC<ImageUploadProps> = ({
  imageSrc,
  alt,
  status = false,
  mode = "upload",
  onChange,
}) => {
  const classes = useStyles();

  const [image, setImage] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setImage(imageSrc);
  }, [imageSrc]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const img = new Image();

        img.onload = function () {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
          canvas.width = 80;
          canvas.height = canvas.width * (img.height / img.width);
          ctx?.drawImage(img, 0, 0, canvas.width, canvas.height);

          const data = canvas.toDataURL("image/png");
          setImage(data);

          if (data) onChange(data);
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(e.target.files[0]);
    } else {
      setImage("");
    }
  };

  const handleClick = () =>
    inputRef?.current ? inputRef.current.click() : null;

  return (
    <div
      className={clsx({ [classes.clickable]: mode === "upload" })}
      onClick={handleClick}
    >
      {mode === "display" ? (
        <StyledBadge
          status={status ? 1 : 0}
          overlap="circular"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          variant="dot"
        >
          <Avatar src={image} alt={alt} />
        </StyledBadge>
      ) : (
        <Avatar src={image} alt={alt} />
      )}

      <input
        ref={inputRef}
        type="file"
        accept="image/*"
        className={classes.fileInput}
        onChange={handleChange}
        disabled={mode === "display"}
      />
    </div>
  );
};

export default memo(ImageUpload);
