import { FC } from "react";
import { ShortRoutePoint } from "@shared/services/order.service";
import { Polyline } from "react-leaflet";
import { hashToColor } from "../../utils/hashToColor";

interface PackageRouteLinesProps {
  driverRoutes: ShortRoutePoint[];
}

const PackageRouteLines: FC<PackageRouteLinesProps> = ({ driverRoutes }) => {
  const getPositions = (driverRoute: ShortRoutePoint) => {

    const positions = driverRoutes.map((driverRoute) => {
      if(driverRoute.isPickup) {
          return {
            lat: driverRoute?.order.pickUpLatitude,
            lng: driverRoute?.order.pickUpLongitude,
          }
        
      }
      else{
          return {
            lat: driverRoute?.order.dropOffLatitude,
            lng: driverRoute?.order.dropOffLongitude,
          }
        
      }
    });

    return positions;

  };

  return (
    <>
      {driverRoutes.map((driverRoute, i) => (
        <Polyline
          key={i}
          positions={getPositions(driverRoute)}
          color={hashToColor(
            `order-${driverRoute.order.id + Math.pow((driverRoute.order.id ?? i) * 8, 2)}`
          )}
          pathOptions={{
            weight: 5,
            dashArray: "5 10",
          }}
        />
      ))
      }
    </>
  );
};

export default PackageRouteLines;
