import React, { FC, memo } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Menu, { MenuProps } from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import ColoredButton, { ColoredButtonProps } from '../ColoredButton/ColoredButton';

const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
    },
})((props: MenuProps) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles((theme) => ({
    root: {
    
    },
}))(MenuItem);

export interface SelectOption<T> {
    id: T;
    name: string;
}

export interface CustomMenuProps extends ColoredButtonProps {
    selectoptions: SelectOption<any>[];
    style?: any;
    className?: string;
    onSelected: (option: SelectOption<any>) => void;
    dense?: boolean;
}

const CustomMenuSelect: FC<CustomMenuProps> = ({
    children,
    selectoptions,
    color,
    variant,
    disabled,
    style,
    onSelected,
    className,
    fullWidth,
    dense,
    ...buttonProps
}) => {

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSelection = (option) => {
        onSelected(option);
        handleClose();
    }

    return (
        <>
            <ColoredButton
                aria-controls="customized-menu"
                aria-haspopup="true"
                variant={variant}
                color={color}
                onClick={handleClick}
                style={style}
                className={className}
                disabled={disabled}
                fullWidth={fullWidth}
                {...buttonProps}
            >
                {children}
            </ColoredButton>

            <StyledMenu
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                variant="menu"
            >
                {
                    selectoptions ?
                        selectoptions.map((option) =>
                            <StyledMenuItem key={option.id} dense={dense} onClick={() => { handleSelection(option); }}>
                                <ListItemText primary={option.name} />
                            </StyledMenuItem>
                        )
                        : null
                }
            </StyledMenu>
        </>
    );
}

export default memo(CustomMenuSelect);