import ErrorFallback from "../../error";
import AuthLayout from "./components/Layout";
import ForgotPasswordPage from "./modules/forgotPassword/ForgotPassword";
import LoginPage from "./modules/login/Login";
import LogoutPage from "./modules/logout/Logout";
import { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

const Auth: FC = () => {
  return (
    <Routes>
      <Route element={<AuthLayout />}>
        <Route errorElement={<ErrorFallback />}>
          <Route path="login" element={<LoginPage />} />
          <Route path="logout" element={<LogoutPage />} />
          <Route path="forgot-password" element={<ForgotPasswordPage />} />

          <Route path="*" element={<AuthFallback />} />
        </Route>
      </Route>
    </Routes>
  );
};

const AuthFallback: FC = () => <Navigate to="login" />;

export default Auth;
