import TaxSettingsPage from "./modules/Tax/Tax";
import Taximeter from "./modules/Taximeter/Taximeter";
import {
  Tab as MuiTab,
  Paper,
  Tabs,
  Theme,
  createStyles,
  makeStyles,
  withStyles,
} from "@material-ui/core";
import { Money, Timer } from "@material-ui/icons";
import { FC, useEffect, useState } from "react";
import { Link, Navigate, Route, Routes, useLocation } from "react-router-dom";

const Tab = withStyles((theme) => ({
  wrapper: {
    flexDirection: "row",
    gap: theme.spacing(1),
  },
  labelIcon: {
    minHeight: "auto",
  },
}))(MuiTab) as typeof MuiTab;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height: "100%",
    },
    content: {
      height: "100%",
      padding: theme.spacing(2),
      overflow: "auto",
    },
  })
);

type tabValue = "taxes" | "taximeter" | false;

const Parameters: FC = () => {
  const classes = useStyles();

  const [value, setValue] = useState<tabValue>(false);
  const handleChange = (_, newValue: tabValue) => {
    setValue(newValue);
  };

  const location = useLocation();
  useEffect(() => {
    const { pathname } = location;

    if (pathname === "/app/settings/parameters/taxes") {
      setValue("taxes");
    } else if (pathname === "/app/settings/parameters/taximeter") {
      setValue("taximeter");
    } else {
      setValue(false);
    }
  }, [location]);

  return (
    <Paper className={classes.root} square>
      <Paper square variant="outlined">
        <Tabs value={value} variant="scrollable" indicatorColor="primary" onChange={handleChange}>
          <Tab value="taxes" label="Данок" icon={<Money />} component={Link} to="taxes" />

          <Tab
            value="taximeter"
            label="Таксиметар"
            icon={<Timer />}
            component={Link}
            to="taximeter"
          />
        </Tabs>
      </Paper>

      <div className={classes.content}>
        <Routes>
          <Route path="taxes" element={<TaxSettingsPage />} />
          <Route path="taximeter" element={<Taximeter />} />

          <Route path="*" element={<ParametersFallback />} />
        </Routes>
      </div>
    </Paper>
  );
};

const ParametersFallback = () => <Navigate to="taxes" />;

export default Parameters;
