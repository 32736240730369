import {
  cleanupDrivers,
  driverCoordinatesUpdated,
  driverEndedShift,
  driverPauseStatusUpdated,
  driverStartedShift,
} from "../store/features/drivers/driversSlice";
import { getAllDriversThunk } from "../store/features/drivers/driversThunk";
import {
  cleanupDriversRoutes,
  driverRoutePointUpdated,
  driverRouteUpdated,
} from "../store/features/driversRoutes/driversRoutesSlice";
import { getAllDriversRoutesAsync } from "../store/features/driversRoutes/driversRoutesThunk";
import { cleanupFilters } from "../store/features/filters/filtersSlice";
import { orderDeleted, orderUpdated } from "../store/features/orders/orderActions";
import { cleanupOrders } from "../store/features/orders/ordersSlice";
import { getActiveOrdersAsyncThunk } from "../store/features/orders/ordersThunk";
import useSubscription from "@shared/hooks/useSubscription/useSubscription";
import {
  coordinatesReceived,
  disconnectDriversAndOrdersConnection,
  disconnectGeolocationConnection,
  driverEndedWorking,
  driverRoutePointUpdate,
  driverRouteUpdate,
  driverStartedOrEndedPause,
  driverStartedWorking,
  establishDriversAndOrdersConnection,
  establishGeolocationConnection,
  orderDeleted as orderDeletedSubject,
  orderUpdated as orderUpdatedSubject,
} from "@shared/services/signalR.service";
import { AppDispatch } from "@store/index";
import { FC, useEffect } from "react";
import { useDispatch } from "react-redux";

const DriversTrackingPage: FC = (props) => {
  const dispatch = useDispatch<AppDispatch>();

  useSubscription(driverStartedOrEndedPause, (data) => {
    dispatch(driverPauseStatusUpdated(data));
  });

  useSubscription(driverStartedWorking, (data) => {
    dispatch(driverStartedShift(data));
  });

  useSubscription(driverEndedWorking, (employeeId) => {
    dispatch(driverEndedShift(employeeId));
  });

  useSubscription(orderUpdatedSubject, (data) => {
    dispatch(orderUpdated(data));
  });

  useSubscription(driverRouteUpdate, (data) => {
    dispatch(driverRouteUpdated(data));
  });

  useSubscription(driverRoutePointUpdate, (data) => {
    dispatch(driverRoutePointUpdated(data));
  });

  useSubscription(coordinatesReceived, (data) => {
    dispatch(driverCoordinatesUpdated(data));
  });

  useSubscription(orderDeletedSubject, (orderId) => {
    dispatch(orderDeleted(orderId));
  });

  useEffect(() => {
    // Load initial data
    Promise.all([
      dispatch(getAllDriversThunk()),
      dispatch(getActiveOrdersAsyncThunk()),
      dispatch(getAllDriversRoutesAsync()),
    ]).then(async () => {
      // Establish realtime connections
      await establishDriversAndOrdersConnection();
      await establishGeolocationConnection();

      
    });

    return () => {
      disconnectDriversAndOrdersConnection();
      disconnectGeolocationConnection();

      // Cleanup store
      dispatch(cleanupOrders());
      dispatch(cleanupDrivers());
      dispatch(cleanupDriversRoutes());
      dispatch(cleanupFilters());
    };
  }, [dispatch]);

  return <>{props.children}</>;
};

export const withMapData = (Component) => {
  const HOC = () => (
    <DriversTrackingPage>
      <Component />
    </DriversTrackingPage>
  );

  return HOC;
};
export default DriversTrackingPage;
