import EmployeeAreasTableRow from "./EmployeeAreasTableRow";
import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import LeafletMap from "@shared/components/Map/LeafletMap/LeafletMap";
import { IEmployeeAreaDto } from "@shared/services/employees/dtos/employeeAreaDto";
import { IEmployeeDto } from "@shared/services/employees/dtos/employeeDto";
import { getEmployeeAreas } from "@shared/services/employees/employees.service";
import React, { FC, memo, useEffect, useState } from "react";
import { FeatureGroup, GeoJSON, LayersControl } from "react-leaflet";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
  })
);

interface Props {
  employee: IEmployeeDto;
}

export const EmployeeAreasTab: FC<Props> = ({ employee }) => {
  const classes = useStyles();

  const [employeeAreasState, setEmployeeAreasState] = useState<{
    employeeAreas: IEmployeeAreaDto[];
  }>({ employeeAreas: [] });
  const [selectedEmployeeAreaState, setSelectedEmployeeAreaState] = useState<{
    employeeArea: IEmployeeAreaDto;
  }>({ employeeArea: null });
  const [errorState, setErrorState] = useState<{ error: string }>({
    error: "",
  });
  const [loadingState, setLoadingState] = useState<{ loading: boolean }>({
    loading: false,
  });

  const loadEmployeeAreas = () => {
    setLoadingState({ ...loadingState, loading: true });
    getEmployeeAreas(employee.id)
      .then(
        (res) => {
          setEmployeeAreasState({ ...employeeAreasState, employeeAreas: res });
          setErrorState({ ...errorState, error: null });
        },
        (err) => {
          setErrorState({ ...errorState, error: err.message });
        }
      )
      .finally(() => {
        setLoadingState({ ...loadingState, loading: false });
      });
  };

  useEffect(() => {
    if (employee && employee.id) loadEmployeeAreas();
  }, [employee]);

  return (
    <>
      <Box paddingTop={1} paddingBottom={1} component={Paper}>
        <Grid style={{ maxHeight: "500px" }} spacing={2} container>
          <Grid item md={!selectedEmployeeAreaState.employeeArea ? 12 : 4}>
            <TableContainer style={{ maxHeight: "500px" }} component={Paper}>
              <Table stickyHeader={true} size="small" aria-label="collapsible table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left" padding="checkbox">
                      &nbsp;
                    </TableCell>
                    <TableCell align="left" key="area-city-header">
                      Град
                    </TableCell>
                    <TableCell align="left" key="area-name-header">
                      Назив
                    </TableCell>
                    <TableCell align="left" key="area-vehicle-types-header">
                      Дозволени возила
                    </TableCell>
                    <TableCell align="center" key="area-isPickUpAllowed-header">
                      Подигање
                    </TableCell>
                    <TableCell align="center" key="area-isDropOffAllowed-header">
                      Достава
                    </TableCell>
                    <TableCell align="center" key="area-checkimage-header">
                      Прикажи
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loadingState.loading ? (
                    <TableRow>
                      <TableCell colSpan={7} style={{ padding: "20px" }} align="center">
                        <Typography align="center" variant="h6">
                          Се вчитува
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ) : errorState.error ? (
                    <TableRow>
                      <TableCell
                        colSpan={7}
                        style={{ padding: "20px", color: "red" }}
                        align="center"
                      >
                        <Typography align="center" variant="h6">
                          {errorState.error}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ) : employeeAreasState.employeeAreas.length > 0 ? (
                    employeeAreasState.employeeAreas.map((employeeArea) => {
                      return (
                        <EmployeeAreasTableRow
                          isSelected={selectedEmployeeAreaState.employeeArea === employeeArea}
                          employeeArea={employeeArea}
                          onSelect={() => {
                            setSelectedEmployeeAreaState({
                              ...selectedEmployeeAreaState,
                              employeeArea: employeeArea,
                            });
                          }}
                          employeeId={employee.id}
                          key={employeeArea.areaName}
                        />
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell colSpan={7} style={{ padding: "20px" }} align="center">
                        <Typography align="center" variant="h6">
                          Не се пронајдени податоци
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          {selectedEmployeeAreaState &&
          selectedEmployeeAreaState.employeeArea &&
          selectedEmployeeAreaState.employeeArea.areaPolygonData ? (
            <Grid style={{ minHeight: "500px" }} item md={8}>
              <LeafletMap>
                <LayersControl>
                  <LayersControl.Overlay checked name="Зони">
                    <FeatureGroup>
                      <GeoJSON
                        key={selectedEmployeeAreaState.employeeArea.id}
                        data={JSON.parse(selectedEmployeeAreaState.employeeArea.areaPolygonData)}
                        style={{
                          color: selectedEmployeeAreaState.employeeArea.color || "red",
                        }}
                      ></GeoJSON>
                    </FeatureGroup>
                  </LayersControl.Overlay>
                </LayersControl>
              </LeafletMap>
            </Grid>
          ) : null}
        </Grid>
      </Box>
    </>
  );
};

export default memo(EmployeeAreasTab);
