import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
  Tooltip,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import moment from "moment";
import Timeline, { TimelineMarkers, TodayMarker } from "react-calendar-timeline";
import "react-calendar-timeline/lib/Timeline.css";
import "./style.css";
import PhoneIcon from "@mui/icons-material/Phone";
import { WorkingStatus, WorkingStatusMacedonian } from "../EmployeeWeekly/types";
import { Hour, WeekDayMacedonian } from "@App/Scheduler/genericTypes";
import { dayToEnglish } from "@App/Scheduler/utils/translateDay";
import { workingStatusToEnglish } from "@App/Scheduler/utils/translateWorkingStatus";
import { isValidTime, isWorkingStatus } from "@App/Scheduler/utils/typeGuards";
import useStyles from "@App/Scheduler/utils/useStyles";
import {
  getScheduleForAllEmployeesPerPeriod,
  insertComment,
  PatchScheduleDto,
} from "@shared/services/schedule.service";
import { GridCellParams, MuiEvent } from "@mui/x-data-grid";

const EmployeeDaily: React.FC = () => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [employee, setEmployee] = useState<string>("");
  const [weekDay, setWeekDay] = useState<string>("");
  const [startTime, setStartTime] = useState<Hour>("");
  const [endTime, setEndTime] = useState<Hour>("");
  const [workingStatus, setWorkingStatus] = useState<WorkingStatus>("True");
  const [scheduleData, setScheduleData] = useState(null);
  const [startDateFilter, setStartDateFilter] = useState<string>(dayjs().format("YYYY-MM-DD"));
  const [endDateFilter] = useState<string>(dayjs().add(14, "day").format("YYYY-MM-DD"));
  const [activeEmployeesPerHour, setActiveEmployeesPerHour] = useState<{ [key: number]: number }>(
    {}
  );
  const [comments, setComments] = useState<{ [key: string]: { text: string; timestamp: number } }>(
    {}
  );
  const [editingComment, setEditingComment] = useState<string | null>(null);

  const { data: schedule, isLoading } = useQuery(["schedule"], () =>
    getScheduleForAllEmployeesPerPeriod(startDateFilter, endDateFilter)  
  );

  const insertComments = (employeeId: string, data: PatchScheduleDto) => {
    insertComment(employeeId, data);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getScheduleForAllEmployeesPerPeriod(startDateFilter, endDateFilter);
        setScheduleData(data);
        const activeEmployeesPerHour = calculateActiveEmployees(data);
        setActiveEmployeesPerHour(activeEmployeesPerHour);
      } catch (error) {
        console.error(error);
      }
    };


    fetchData();
  }, [startDateFilter, endDateFilter]);

  
  const transformData = (data) => {
    const items = [];

    data.forEach((employee, index) => {
      const groupId = index + 2;
      employee.workWeek.forEach((workDay) => {
        if (workDay.workTimes.startTime && workDay.workTimes.endTime) {
          const isActive = employee.isActive;
          items.push({
            id: workDay.workTimes.id,
            group: groupId,
            title: `${workDay.workTimes.startTime} - ${workDay.workTimes.endTime}`,
            start_time: moment(
              workDay.date + " " + workDay.workTimes.startTime,
              "YYYY-MM-DD HH:mm"
            ),
            end_time: moment(workDay.date + " " + workDay.workTimes.endTime, "YYYY-MM-DD HH:mm"),
            className: !isActive ? "not-active-item" : "active-item",
            reason: workDay.workTimes.reason, 
          });
        }
      });
    });

    for (let hour = 0; hour < 24; hour++) {
      const startTime = moment().startOf("day").add(hour, "hours");
      const endTime = moment(startTime).add(1, "hour");
      const activeEmployees = activeEmployeesPerHour[hour] || 0;

      items.push({
        id: `hour-${hour}`,
        group: 1,
        title: `${activeEmployees}`,
        start_time: startTime,
        end_time: endTime,
        className: "hour-item no-background",
      });
    }

    return items;
  };

  const transformDataGroups = (data) => {
    const groups = [
      {
        id: 1,
        title: "Активни шофери",
        fullName: "Активни шофери",
        phoneNumber: "",
        employeeId: "",
      },
    ];

    data.forEach((employee, index) => {
      groups.push({
        id: index + 2,
        title: employee.employee,
        fullName: employee.employee,
        phoneNumber: employee.phoneNumber,
        employeeId: employee.employeeId,
      });
    });

    return groups;
  };

  const calculateActiveEmployees = (data) => {
    const activeEmployeesPerHour = {};
    const today = dayjs().format("YYYY-MM-DD");

    data.forEach((employee) => {
      employee.workWeek.forEach((workDay) => {
        if (dayjs(workDay.date).format("YYYY-MM-DD") === today) {
          const startHour = moment(workDay.workTimes.startTime, "HH:mm").hour();
          const endHour = moment(workDay.workTimes.endTime, "HH:mm").hour();

          for (let hour = startHour; hour < endHour; hour++) {
            if (!activeEmployeesPerHour[hour]) {
              activeEmployeesPerHour[hour] = 0;
            }
            activeEmployeesPerHour[hour]++;
          }
        }
      });
    });

    return activeEmployeesPerHour;
  };


  const handleStartDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStartDateFilter(event.target.value);
  };

  const handleCommentChange = (itemId: string, comment: string) => {
    setComments((prevComments) => ({
      ...prevComments,
      [itemId]: { text: comment, timestamp: Date.now() },
    }));
  };

  const handleCommentBlur = () => {
    setEditingComment(null);
  };

  const keyPress = (e, item) => {
    if (e.keyCode == 13) {
      let group = groups.find((g) => g.id === item.group);
      insertComments(group.employeeId, { id: item.id, reason: e.target.value });
    }
  };

  const handleCommentClick = (itemId: string) => {
    setEditingComment(itemId);
  };

  const classes = useStyles();

  const handleEvent = (
    params: GridCellParams<any>,
    event: MuiEvent<React.MouseEvent<HTMLElement>>
  ) => {
    const value = params.value as string;

    if (isWorkingStatus(value) || isValidTime(value.split("|")[0].trim())) {
      setEmployee(params.id as string);
      setWeekDay(dayToEnglish(params.field as WeekDayMacedonian));
      if (isWorkingStatus(value)) {
        setWorkingStatus(workingStatusToEnglish(value as WorkingStatusMacedonian));
      }
      if (isValidTime(value.split("|")[0].trim())) {
        setStartTime(value.split("|")[0].trim() as Hour);
        setEndTime(value.split("|")[1].trim() as Hour);
        setWorkingStatus("True");
      }
      setModalOpen(true);
    }
  };

  if (isLoading) return <div>Loading...</div>;
  if (scheduleData === null) return <div>Loading...</div>;
  const items = transformData(scheduleData);
  const groups = transformDataGroups(scheduleData);

  return (
    <div style={{ height: "100%", width: "100%", padding: "20px" }}>
      <Timeline
        groups={groups}
        items={items}
        sidebarContent={<div>Вработени</div>}
        defaultTimeStart={moment().startOf("day").add(8, "hours")}
        defaultTimeEnd={moment().endOf("day")}
        canMove={false}
        itemRenderer={({ item, itemContext, getItemProps, getResizeProps }) => {
          const { left: leftResizeProps, right: rightResizeProps } = getResizeProps();
          const textColor = item.reason && item.reason !== "dodadi komentar" ? "white" : "transparent";

          return (
            <div
              {...getItemProps({
                style: {
                  position: "relative",
                  background: item.className.includes("not-active-item")
                    ? "red"
                    : item.className.includes("no-background")
                      ? "transparent"
                      : "blue",
                  color: item.className.includes("not-active-item")
                    ? "white"
                    : item.className.includes("no-background")
                      ? "black"
                      : "white",
                  borderRadius: 4,
                  border: "none",
                },
              })}
            >
              {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : null}
              <div
                style={{
                  height: itemContext.dimensions.height,
                  overflow: "hidden",
                  paddingLeft: 3,
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {item.title}
                {!item.className.includes("no-background") && (
                  <div
                    style={{
                      position: "absolute",
                      bottom: 0,
                      left: 0,
                      width: "100%",
                      paddingLeft: 10,
                    }}
                  >
                    {editingComment === item.id ? (
                      <TextField
                        fullWidth
                        value={comments[item.id]?.text || ""}
                        onChange={(e) => handleCommentChange(item.id, e.target.value)}
                        onBlur={handleCommentBlur}
                        margin="normal"
                        autoFocus
                        onKeyDown={(e) => keyPress(e, item)}
                        InputProps={{
                          style: {
                            background: "white",
                            borderRadius: 4,
                            fontSize: "15px",
                          },
                        }}
                      />
                    ) : (
                      <div
                        className="comment-container"
                        onClick={() => handleCommentClick(item.id)}
                        style={{
                          color: item.reason? "white" : "transparent",
                          marginLeft: "75px",
                        }}
                      >
                        {item.reason || "dodadi komentar"}
                      </div>
                    )}
                  </div>
                )}
              </div>
              {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : null}
            </div>
          );
        }}
        groupRenderer={({ group }) => {
          if (group.id === 1) {
            return (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Tooltip title={group.fullName}>
                  <div 
                    style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
                  >
                    {group.title}
                  </div>
                </Tooltip>
              </div>
            );
          } else {
            return (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Tooltip title={group.fullName}>
                  <div
                   style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
                   >
                    {group.title}
                  </div>
                </Tooltip>
                {group.phoneNumber && (
                  <Tooltip title={group.phoneNumber}>
                    <a href={`tel:${group.phoneNumber}`} style={{ marginLeft: 8 }}>
                      <PhoneIcon style={{ color: "green" }} />
                    </a>
                  </Tooltip>
                )}
              </div>
            );
          }
        }}
      >
        <TimelineMarkers>
          <TodayMarker />
        </TimelineMarkers>
      </Timeline>
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <Box>
          <Typography variant="h6" gutterBottom>
            Update Employee Schedule
          </Typography>
          <FormControl fullWidth>
            <InputLabel id="working-status-label">Working Status</InputLabel>
            <Select
              labelId="working-status-label"
              value={workingStatus}
              onChange={(e) => setWorkingStatus(e.target.value as WorkingStatus)}
            >
              <MenuItem value="True">Active</MenuItem>
              <MenuItem value="False">Inactive</MenuItem>
            </Select>
          </FormControl>
          <TextField
            fullWidth
            label="Start Time"
            value={startTime}
            onChange={(e) => setStartTime(e.target.value as Hour)}
            margin="normal"
          />
          <TextField
            fullWidth
            label="End Time"
            value={endTime}
            onChange={(e) => setEndTime(e.target.value as Hour)}
            margin="normal"
          />
          <Button
            onClick={() => {
              setModalOpen(false);
            }}
            color="primary"
            variant="contained"
          >
            Update
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default EmployeeDaily;
