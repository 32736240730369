/**
 * Get the label from the global redux state for a given id
 * @param {unknown[]} data - The data to choose from.
 * @param {number} id - The id of the item.
 */
export const getDisplayValue = (data: unknown[], id: number) => {
  if (!data || !Array.isArray(data)) return id;

  const item = data.find((x) => x.id === id);
  if (!item) return id;

  return item.name || item.id;
};
