import { ICity } from "../City";
import { NewCitySchema } from "../schemas/NewCitySchema";
import { Button, CircularProgress, Dialog, TextField, Theme } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import DialogActions from "@shared/components/Dialog/DialogActions";
import DialogContent from "@shared/components/Dialog/DialogContent";
import DialogTitle from "@shared/components/Dialog/DialogTitle";
import ErrorMessage from "@shared/components/ErrorMessage/ErrorMessage";
import LeafletEvents from "@shared/components/Map/LeafletEvents/LeafletEvents";
import LeafletMap from "@shared/components/Map/LeafletMap/LeafletMap";
import { IApiErrorResponseData } from "@shared/services/http.client";
import { addCity, updateCity } from "@shared/services/settings/cities.service";
import { AxiosError } from "axios";
import { useFormik } from "formik";
import { LeafletEvent } from "leaflet";
import { useSnackbar } from "notistack";
import { FC, SyntheticEvent, memo, useEffect, useState } from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    dialogContent: {
      minWidth: "290px",
      overflow: "auto",
    },
    form: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      overflowY: "auto",
    },
    input: {
      marginBottom: theme.spacing(1.5),
    },
    colorPicker: {
      marginBottom: theme.spacing(2),
    },
    map: {
      marginTop: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        minWidth: "60vw",
      },
    },
    errorMessage: {
      marginTop: theme.spacing(1),
    },
    citySelect: {
      marginBottom: theme.spacing(2),
    },
  })
);

const initialValues = {
  name: "",
  latitude: null,
  longitude: null,
  zoom: null,
};

interface NewCityFormProps {
  title: string;
  addButtonText: string;
  open: boolean;
  row: ICity | null;
  onClose: () => void;
  onSuccess: () => void;
}

const NewCityForm: FC<NewCityFormProps> = (props) => {
  const classes = useStyles();
  const { title, addButtonText, open, row, onClose, onSuccess } = props;

  const { enqueueSnackbar } = useSnackbar();

  const [error, setError] = useState("");
  const handleSubmit = (values: typeof initialValues) => {
    const APICall = row && row.id ? updateCity(row.id, { ...values }) : addCity(values);

    APICall.then((res) => {
      // Reset formik
      formik.setSubmitting(false);
      formik.resetForm();

      // Show notification
      enqueueSnackbar("Успешно додадено", { variant: "success" });

      // TODO: Send back the data from the response here

      // Refetch data
      onSuccess();

      // Close the dialog
      onClose();
    }).catch((error: AxiosError<IApiErrorResponseData>) => {
      formik.setSubmitting(false);
      return setError(error.response?.data?.message);
    });
  };

  const {
    setValues,
    handleChange: handleFormikChange,
    ...formik
  } = useFormik({
    initialValues,
    validationSchema: NewCitySchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
    validateOnChange: false,
  });

  const handleClose = () => {
    formik.resetForm();
    setError("");

    if (onClose) onClose();
  };

  const handleChange = (event: SyntheticEvent) => {
    // Reset errors on input change
    if (error) setError("");

    handleFormikChange(event);
  };

  // Set initial values
  useEffect(() => {
    if (!row) return;

    const newInitialValues = {} as typeof initialValues;
    Object.keys(initialValues).forEach((key: string) => {
      newInitialValues[key] = row[key];
    });

    setValues(newInitialValues);
  }, [row, setValues]);

  const handleMove = (event: LeafletEvent) => {
    const center = event?.target?.getCenter();
    const zoom = event?.target?.getZoom();

    if (!center && !zoom) return;

    if (center.lat !== formik.values.latitude) {
      formik.setFieldValue("latitude", center.lat);
    }

    if (center.lng !== formik.values.longitude) {
      formik.setFieldValue("longitude", center.lng);
    }

    if (zoom !== formik.values.zoom) {
      formik.setFieldValue("zoom", zoom);
    }
  };

  return (
    <Dialog
      maxWidth={false}
      aria-labelledby="new-city-dialog"
      classes={{ paper: classes.dialog }}
      open={open}
      onClose={handleClose}
      data-testid="dialog"
    >
      <form className={classes.form} onSubmit={formik.handleSubmit}>
        <DialogTitle onClose={handleClose}>{title}</DialogTitle>

        <DialogContent className={classes.dialogContent} dividers>
          <TextField
            name="name"
            label="Име"
            variant="outlined"
            value={formik.values.name}
            onChange={handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            autoFocus
            fullWidth
            required
          />

          <div className={classes.map}>
            <LeafletMap
              height="60vh"
              defaultLocation={{
                latitude: formik.values.latitude,
                longitude: formik.values.longitude,
              }}
              defaultZoom={formik.values.zoom}
            >
              <LeafletEvents onMoveEnd={handleMove} onZoomEnd={handleMove} />
            </LeafletMap>

            {/* Validation error messages */}
            <ErrorMessage
              error={formik.errors.latitude?.toString()}
              className={classes.errorMessage}
            />
            <ErrorMessage
              error={formik.errors.longitude?.toString()}
              className={classes.errorMessage}
            />
            <ErrorMessage error={formik.errors.zoom?.toString()} className={classes.errorMessage} />
          </div>

          {/* Request error message */}
          <ErrorMessage error={error} className={classes.errorMessage} />
        </DialogContent>

        <DialogActions>
          {formik.isSubmitting ? (
            <CircularProgress size={25} />
          ) : (
            <Button type="submit" color="primary">
              {addButtonText}
            </Button>
          )}

          <Button onClick={handleClose}>Откажи</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default memo(NewCityForm);
