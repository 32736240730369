import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import CustomDateField from "@shared/components/CustomFormControls/CustomDateField";
import Loading from "@shared/components/Loading/Loading";
import dayjs, { Dayjs } from "dayjs";
import { FC, useEffect, useState } from "react";
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export interface IPeriodDateFilter {
  from?: Date;
  to?: Date;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      margin: theme.spacing(2),
    },
    monthPicker: {
      minWidth: theme.spacing(24),
    },
  })
);

interface PeriodDateFilterProps {
  initialValues?: { from?: Date; to?: Date };
  onChange: (from: Date, to: Date) => void;
}

const PeriodDateFilter: FC<PeriodDateFilterProps> = ({ initialValues, onChange }) => {
  const classes = useStyles();

  const [fromDate, setFromDate] = useState<Dayjs | null>(null);
  const [toDate, setToDate] = useState<Dayjs | null>(null);
  const [months, setMonths] = useState<Dayjs[]>([]);
  const [selectedMonth, setSelectedMonth] = useState<Dayjs | null>();

  useEffect(() => {

    onChange(

      fromDate?.utc().startOf("day").toDate() ?? new Date(),

      toDate?.utc().endOf("day").toDate() ?? new Date()

    );

  }, [fromDate, toDate]);

  useEffect(() => {
    const currentDate: Dayjs = dayjs().utc();
    const years: number[] = [0, 1, 2];
    const months: number[] = Array.from({ length: 12 }, (_, i) => i);

    const monthsList: Dayjs[] = years.flatMap((year) =>
      months.map((month) => currentDate.subtract(year, "year").subtract(month, "month"))
    );

    setMonths(monthsList);
    setSelectedMonth(monthsList.find(() => true));

    if (initialValues?.from && initialValues?.to) {
      Promise.resolve().then(() => {
        console.log(initialValues.from);
        setFromDate(dayjs.utc(initialValues.from));
        setToDate(dayjs.utc(initialValues.to));
      });
    }
  }, []);

  useEffect(() => {
    if (!selectedMonth) return;
    setFromDate(selectedMonth.utc().startOf("month"));
    setToDate(selectedMonth.endOf("month"));
  }, [selectedMonth]);

  if (!months.length) return <Loading />;

  return (
    <div style= {{display: "flex" ,flexDirection: "column"}} className={classes.root}>
      <Typography variant="h6">Период</Typography>

      <div style= {{display:"flex", alignItems:"end", height:"100%"}}>
        <CustomDateField
          variant="inline"
          label="Од"
          value={fromDate}
          onChange={(date) => {
            setFromDate(dayjs.utc(date).add(1, "day"));
          }}
          InputProps={{ disabled: true, style: { color: "black" } }}
          maxDate={toDate?.add(-1, "day")}
        />

        <CustomDateField
          variant="inline"
          label="До"
          value={toDate}
          onChange={(date) => {
            setToDate(dayjs.utc(date));
          }}
          InputProps={{ disabled: true, style: { color: "black" } }}
          minDate={fromDate?.add(1, "day")}
        />

        <FormControl>
          <InputLabel id="month-label">Месец</InputLabel>
          <Select
            labelId="month-label"
            value={selectedMonth.toISOString()}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 300,
                },
              },
            }}
            onChange={(event) => setSelectedMonth(dayjs(event.target.value as string))}
            SelectDisplayProps={{ className: classes.monthPicker }}
          >
            {months.map((month, index) => (
              <MenuItem key={index} value={month.toISOString()}>
                {month.format("YYYY - MMMM")}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </div>
  );
};

export default PeriodDateFilter;
