/**
 * Converts a hash into a color
 * @param hashStr some unique value
 * @returns Color string in RGB format
 */
export const hashToColor = (hashStr = "") => {
  let hash = 0;
  for (let i = 0; i < hashStr.length; i++) {
    hash = hashStr.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    color += ("00" + value.toString(16)).substr(-2);
  }

  return color;
};
