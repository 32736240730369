import httpService from "./http.service";

interface TimeSlot {
  startTime: string;
  endTime: string;
}

export interface ScheduleItem {
  date: string;
  isWorking: boolean;
  workTimes: TimeSlot;
  reason?: string;
}

export interface EmployeeDetails {
  id: number;
  employeeId: number;
  name: string;
  surname: string;
  employeePosition: number;
  phoneNumber: string;
}

export interface EmployeeScheduleResponse {
  map: any;
  employee: EmployeeDetails;
  schedules: ScheduleItem[];
}

export interface UpdateScheduleDto {
  id: number;
  employeeId: number;
  name: string;
  surname: string;
  startTime: string;
  endTime: string;
  isWorking: boolean;
  dateWork: string;
  reason: string;
  workPerDay: boolean;
  employeePosition: number;
}

export interface PatchScheduleDto {
  id: number;
  reason: string;
}

// for a day
export const getScheduleForEmployeePerDay = (
  employeeId: string,
  dateWork: string
): Promise<EmployeeScheduleResponse> => {
  return httpService.get<EmployeeScheduleResponse>(
    `/api/schedule/perDay/employee/${employeeId}?dateWork=${dateWork}`
  );
};

// for period
export const getScheduleForEmployeePerPeriod = (
  employeeId: string,
  startDateWork: string,
  endDateWork: string
): Promise<UpdateScheduleDto[]> => {
  return httpService.get<UpdateScheduleDto[]>(
    `/api/schedule/perPeriod/employee/${employeeId}?startDateWork=${startDateWork}&endDateWork=${endDateWork}`
  );
};

// for month
export const getScheduleForEmployeePerMonth = (
  employeeId: string,
  year: string,
  month: string
): Promise<EmployeeScheduleResponse> => {
  return httpService.get<EmployeeScheduleResponse>(
    `/api/schedule/perMonth/employee/${employeeId}?year=${year}&month=${month}`
  );
};

// for groups based on position per day
export const getScheduleForPositionPerDay = (
  employeePosition: string,
  dateWork: string
): Promise<EmployeeScheduleResponse> => {
  return httpService.get<EmployeeScheduleResponse>(
    `/api/schedule/perDay/employees/${employeePosition}?dateWork=${dateWork}`
  );
};

// groups based on position over a period
export const getScheduleForPositionPerPeriod = (
  employeePosition: string,
  startDateWork: string,
  endDateWork: string
): Promise<EmployeeScheduleResponse> => {
  return httpService.get<EmployeeScheduleResponse>(
    `/api/schedule/perPeriod/employees/${employeePosition}?startDateWork=${startDateWork}&endDateWork=${endDateWork}`
  );
};

// groups based on position per month
export const getScheduleForPositionPerMonth = (
  employeePosition: string,
  year: string,
  month: string
): Promise<EmployeeScheduleResponse> => {
  return httpService.get<EmployeeScheduleResponse>(
    `/api/schedule/perMonth/employees/${employeePosition}?year=${year}&month=${month}`
  );
};

// all employees for day
export const getScheduleForAllEmployeesPerDay = (
  dateWork: string
): Promise<EmployeeScheduleResponse> => {
  return httpService.get<EmployeeScheduleResponse>(
    `/api/schedule/perDay/all/employees?dateWork=${dateWork}`
  );
};

// all employees over period
export const getScheduleForAllEmployeesPerPeriod = (
  startDateWork: string,
  endDateWork: string
): Promise<EmployeeScheduleResponse> => {
  return httpService.get<EmployeeScheduleResponse>(
    `/api/schedule/perPeriod/employees/2?startDateWork=${startDateWork}&endDateWork=${endDateWork}`
  );
};
export const getNotActiveDriver = (): Promise<EmployeeScheduleResponse> => {
  return httpService.get<EmployeeScheduleResponse>(`/api/schedule/perPeriod/employees/2/notActive`);
};

// all employees for month
export const getScheduleForAllEmployeesPerMonth = async (
  year: string,
  month: string
): Promise<EmployeeScheduleResponse> => {
  return httpService.get<EmployeeScheduleResponse>(
    `/api/schedule/perMonth/all/employees?year=${year}&month=${month}`
  );
};

export const insertEmployeeSchedule = async (employeeId: number, request: any): Promise<void> => {
  return httpService.post<void, void>(`/api/schedule/${employeeId}`, request);
};

export const updateEmployeeSchedule = async (
  employeeId: string,
  request: UpdateScheduleDto
): Promise<void> => {
  return httpService.patch<void, UpdateScheduleDto>(`/api/schedule/${employeeId}`, request);
};

// inserting comment
export const insertComment = async (
  employeeId: string,
  commentData: PatchScheduleDto
): Promise<void> => {
  return httpService.patch<void, PatchScheduleDto>(
    `/api/schedule/${employeeId}/insertComment`,
    commentData
  );
};

