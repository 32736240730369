export type RGBColor = { r: number; g: number; b: number };

const fallbackColor: RGBColor = { r: 0, g: 0, b: 0 };

/**
 * Converts a hexadecimal color string to an RGB color object.
 * @param hex A hexadecimal color string.
 * @returns A RGB color object.
 */
export const hexToRGB = (hex: string): RGBColor => {
  // Check if it's a hex string
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  if (!result) return fallbackColor;

  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  return { r, g, b };
};
