import BonusesAndPenalties from "../components/BonusesAndPenaltiesTooltip";
import { Column } from "@material-table/core";
import { IPayrollPeriodDayOverviewDto } from "@shared/services/payroll/dtos/payrollPeriodDayOverviewDto";
import dayjs from "dayjs";

const usePayrollEmployeePeriodColumns = (refetch: any): Column<IPayrollPeriodDayOverviewDto>[] => {
  return [
    {
      title: "Ден",
      field: "date",
      render: (item) => dayjs(item.date).format("DD-MMM"),
    },
    {
      title: "Заработка",
      field: "earnings",
    },
    {
      title: "Боледување",
      field: "sick",
    },
    {
      title: "Одмор",
      field: "holiday",
    },
    {
      title: "+/-",
      field: "additionsAndDeductions",
      render: (item) => (
        <BonusesAndPenalties
          date={item.date}
          bonusesAndPenalties={item.bonusesAndPenalties}
          onBonusPenaltyUpdate={() => refetch()}
        />
      ),
    },
    {
      title: "Вкупно",
      field: "total",
    },
    {
      title: "Датум на исплата",
      field: "paidOutDate",
      render: (item) => (item.paidOutDate ? dayjs(item.paidOutDate).format("DD.MM.YYYY") : ""),
    },
  ];
};

export default usePayrollEmployeePeriodColumns;
