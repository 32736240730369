import { FC } from "react";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import { Checkbox } from "@material-ui/core";

export interface EmployeeCardProps {
  firstName: string;
  lastName: string;
  avatar?: string;
  checked: boolean;
  onChange: (event, id) => void;
}

const EmployeeCard: FC<EmployeeCardProps> = (props) => {
  const { firstName, lastName, avatar, onChange, checked } = props;
  return (
    <ListItem>
      <ListItemAvatar>
        <Avatar alt={`${firstName} ${lastName}`} src={avatar} />
      </ListItemAvatar>

      <ListItemText primary={`${firstName} ${lastName}`} />
      <ListItemSecondaryAction>
        <Checkbox edge="end" onChange={onChange} checked={checked} />
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default EmployeeCard;
