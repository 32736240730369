import httpService from "../http.service";
import { ITaximeterRatesDto } from "./dtos/taximeterRatesDto";
import { ITaximeterRatesRequestDto } from "./dtos/taximeterRatesRequestDto";

export const getTaximeterRates = (cityId: number, deliveryTypeId: number) => {
  return httpService.get<ITaximeterRatesDto>(
    `/api/taximeter-rates?cityId=${cityId}&deliveryTypeId=${deliveryTypeId}`
  );
};

export const updateTaximeterRates = (request: ITaximeterRatesRequestDto) => {
  return httpService.put<void, ITaximeterRatesRequestDto>(`/api/taximeter-rates`, request);
};
