import { combineReducers } from "@reduxjs/toolkit";

import ordersReducer from "./features/orders/ordersSlice";
import driversReducer from "./features/drivers/driversSlice";
import driversRoutesReducer from "./features/driversRoutes/driversRoutesSlice";
import filtersReducer from "./features/filters/filtersSlice";

const logisticsReducer = combineReducers({
  orders: ordersReducer,
  drivers: driversReducer,
  driversRoutes: driversRoutesReducer,
  filters: filtersReducer,
});

export default logisticsReducer;
export type LogisticsState = ReturnType<typeof logisticsReducer>;
