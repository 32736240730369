import CustomSelect from "../CustomFormControls/CustomSelect";
import CustomTextField from "../CustomFormControls/CustomTextField";
import { SystemConnectionsValidationSchema } from "./schemas/SystemConnectionsFormRow";
import { IconButton, TableCell, TableRow } from "@material-ui/core";
import { CheckCircleOutlineRounded, DeleteOutlineRounded } from "@material-ui/icons";
import { ICreateConnectionRequestDto } from "@shared/services/auth/dtos/createConnectionRequestDto";
import { IExternalSystemConnectionDto } from "@shared/services/auth/dtos/externalSystemConnectionDto";
import { ExternalSystem } from "@shared/services/auth/enums/externalSystem";
import { translateExternalSystem } from "@shared/services/auth/system-connections.service";
import { useFormik } from "formik";
import { FC, memo, useCallback, useEffect } from "react";

interface Props {
  item: IExternalSystemConnectionDto;
  onDeleteItem: (item: IExternalSystemConnectionDto) => void;
  onAddItem: (request: ICreateConnectionRequestDto) => void;
  onUpdateItem: (connectionId: number, request: ICreateConnectionRequestDto) => void;
}

const systems = [
  {
    value: ExternalSystem.KlikniJadiLegacy,
    text: translateExternalSystem(ExternalSystem.KlikniJadiLegacy),
  },
];

const initialValues = {
  systemId: ExternalSystem.KlikniJadiLegacy,
  internalSystemUserId: 0,
  externalSystemUserId: 0,
};

const BaseEmployeeSupplementsFormRow: FC<Props> = ({
  item,
  onDeleteItem,
  onAddItem,
  onUpdateItem,
}) => {
  const handleSubmit = () => {
    if (!item) {
      return;
    }

    if (item.id) {
      onUpdateItem(item.id, formik.values);
    } else {
      onAddItem(formik.values);
    }
  };

  const {
    setValues,
    handleChange: handleFormikChange,
    setFieldValue,
    ...formik
  } = useFormik({
    initialValues,
    validationSchema: SystemConnectionsValidationSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  useEffect(() => {
    const newInitialValues = {} as typeof initialValues;
    Object.keys(item).forEach((key) => {
      newInitialValues[key] = item[key] as any;
    });
    setValues(newInitialValues);
  }, [item]);

  return (
    <TableRow>
      <TableCell padding="default" align="left">
        <CustomSelect
          value={formik.values.systemId}
          errorMessage={formik.errors.systemId}
          touched={formik.touched.systemId}
          onChange={useCallback((e) => setFieldValue("systemId", e), [setFieldValue])}
          size="small"
          variant="standard"
          selectOptions={systems}
          multiple={false}
          id="systemId"
          disabled={systems.length === 1}
        />
      </TableCell>

      <TableCell padding="default" align="left">
        {formik.values.internalSystemUserId}
      </TableCell>

      <TableCell padding="default" align="left">
        <CustomTextField
          type="number"
          name="externalSystemUserId"
          variant="standard"
          margin="none"
          size="small"
          key="comment"
          value={formik.values.externalSystemUserId}
          onChange={handleFormikChange}
          touched={
            formik.touched.externalSystemUserId && Boolean(formik.errors.externalSystemUserId)
          }
          errorMessage={
            formik.touched.externalSystemUserId && (formik.errors.externalSystemUserId as string)
          }
          fullWidth
        />
      </TableCell>

      <TableCell
        align="center"
        padding="checkbox"
        style={{ cursor: "pointer" }}
        onClick={() => {
          formik.handleSubmit();
        }}
      >
        <IconButton style={{ cursor: "inherit" }} type="submit" size="small">
          <CheckCircleOutlineRounded style={{ color: "#18ba9b" }} />
        </IconButton>
      </TableCell>
      <TableCell
        align="center"
        padding="none"
        style={{ cursor: "pointer" }}
        onClick={() => {
          onDeleteItem(item);
        }}
      >
        <IconButton size="small">
          <DeleteOutlineRounded color="error" />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default memo(BaseEmployeeSupplementsFormRow);
