import { object, string } from "yup";

const confirmPasswordCheck = (values: string[], schema: any) =>
  schema.test({
    test: (confirmPassword) => values.find(() => true) === confirmPassword,
    message: "Лозинките не се совпаѓаат.",
  });

export const CreateEmployeeUserValidationSchema = object({
  userName: string().required("Задолжително"),
  password: string().required("Задолжително").min(6, "Лозинката мора да има најмалку 6 карактери"),
  confirmPassword: string().when("password", confirmPasswordCheck),
});

export const UpdateEmployeeUserValidationSchema = object({
  userName: string().required("Задолжително"),
  password: string().min(6, "Лозинката мора да има најмалку 6 карактери"),
  confirmPassword: string().when("password", confirmPasswordCheck),
});
