import type { WorkingStatus, WorkingStatusMacedonian } from "../modules/EmployeeWeekly/types";

export function workingStatusToMacedonian(weekDay: WorkingStatus): WorkingStatusMacedonian {
  switch (weekDay) {
    case "True":
      return "Работи";
    case "False":
      return "Слободен";
    case "Sick":
      return "Болен";
    case "Vacation":
      return "Одмор";
  }
}

export function workingStatusToEnglish(weekDay: WorkingStatusMacedonian): WorkingStatus {
  switch (weekDay) {
    case "Работи":
      return "True";
    case "Слободен":
      return "False";
    case "Болен":
      return "Sick";
    case "Одмор":
      return "Vacation";
  }
}

