import ConfirmationDialog, {
  ConfirmationDialogProps,
} from "../components/ConfirmationDialog/ConfirmationDialog";
import {
  FC,
  createContext,
  memo,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";

interface ConfirmationDialogContextValues {
  open: (content: ConfirmationDialogProps) => void;
  close: () => void;
  setLoading: (isLoading: boolean) => void;
}

const ConfirmationDialogContext = createContext<ConfirmationDialogContextValues>(null);

interface DialogProps {
  dialog: ConfirmationDialogProps;
  unSetDialog: () => void;
}

const Dialog: FC<DialogProps> = memo(({ dialog, unSetDialog }) => {
  const isLoading = useRef(dialog.isLoading);

  useEffect(() => {
    isLoading.current = dialog.isLoading;
  }, [dialog.isLoading]);

  useEffect(() => {
    const bind = (e) => {
      if (e.keyCode !== 27) {
        return;
      }

      if (document.activeElement && ["INPUT", "SELECT"].includes(document.activeElement.tagName)) {
        return;
      }

      if (isLoading.current) {
        return;
      }

      unSetDialog();
    };

    document.addEventListener("keyup", bind);
    return () => document.removeEventListener("keyup", bind);
  }, [unSetDialog]);

  return <ConfirmationDialog {...dialog} />;
});

const ConfirmationDialogProvider: FC = ({ children }) => {
  const [dialog, setDialog] = useState<ConfirmationDialogProps>();

  const unSetDialog = useCallback(() => {
    setDialog(null);
  }, [setDialog]);

  const setLoading = (isLoading: boolean) => {
    setDialog((dialog) => ({ ...dialog, isLoading: isLoading }));
  };

  const showConfirmationDialog = useSelector(
    ({ appState }: IGlobalState) => appState.preferences.showConfirmationDialog
  );
  const handleOpen = (content: ConfirmationDialogProps) => {
    if (showConfirmationDialog || content.forceShow) {
      setDialog(content);
    } else {
      content.onConfirm();
    }
  };

  return (
    <ConfirmationDialogContext.Provider
      value={{
        close: unSetDialog,
        open: handleOpen,
        setLoading: setLoading,
      }}
    >
      {children}
      {dialog && <Dialog dialog={dialog} unSetDialog={unSetDialog} />}
    </ConfirmationDialogContext.Provider>
  );
};

const useConfirmationDialog = () => {
  const context = useContext(ConfirmationDialogContext);
  if (context === undefined) {
    throw new Error("useConfirmationDialog must be used within a ConfirmationDialogProvider");
  }

  return context;
};

export { ConfirmationDialogProvider, useConfirmationDialog };

// TODO: UseDialog hook that takes a render prop, and a confirmation dialog hook that utilizes the useDialog hook
