import { FC, useEffect, memo, useRef } from "react";
import { LatLngExpression } from "leaflet";
import { useMap } from "react-leaflet";

export interface MapControllerProps {
  geolocation?: {
    latitude: number;
    longitude: number;
  };
  zoom?: number;
  animate?: boolean;
}

const MapController: FC<MapControllerProps> = (props) => {
  const map = useMap();

  const mapRef = useRef(map);
  useEffect(() => {
    mapRef.current = map;
  }, [map]);

  const flyTo = (
    geolocation: LatLngExpression,
    zoom: number,
    animate = false
  ) => {
    if (!mapRef.current) return;

    if (animate) {
      // ! If the property animate is set to false, the map will falsely jump around
      // ! In order to make the movement smooth, animation has to be enabled
      // ! this behaviour is undocumented on leaflet's site right now

      mapRef.current.flyTo(geolocation, zoom, { animate: true });
    } else {
      mapRef.current.setView(geolocation, zoom);
    }

    mapRef.current.invalidateSize();
  };

  useEffect(() => {
    if (
      !mapRef.current ||
      (!props.geolocation?.latitude &&
        !props.geolocation?.longitude &&
        !props.zoom)
    )
      return;

    const mapCenter = mapRef.current?.getCenter();
    const newCenter: LatLngExpression = {
      lat: props.geolocation?.latitude ?? mapCenter.lat,
      lng: props.geolocation?.longitude ?? mapCenter.lng,
    };

    const mapZoom = mapRef.current?.getZoom();
    const newZoom = props.zoom ?? mapZoom;

    if (
      mapCenter.lat !== newCenter.lat ||
      mapCenter.lng !== newCenter.lng ||
      mapZoom !== newZoom
    ) {
      flyTo(newCenter, newZoom, props.animate);
    }
  }, [props.geolocation, props.zoom, props.animate]);

  return null;
};

export default memo(MapController, (prev, next) => {
  return JSON.stringify(prev) === JSON.stringify(next);
});
