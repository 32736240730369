import NewVehicleTypeForm from "./components/NewVehicleTypeForm";
import { Button, IconButton, TableCell } from "@material-ui/core";
import { Delete, Edit, Warning } from "@material-ui/icons";
import TableView from "@shared/components/Table/TableView";
import { ITableHeaderCell } from "@shared/components/Table/types/ITableHeaderCell";
import { useConfirmationDialog } from "@shared/context/ConfirmationDialogContext";
import { IApiErrorResponseData } from "@shared/services/http.client";
import { IVehicleTypeDto } from "@shared/services/settings/dtos/vehicle-types/vehicleTypeDto";
import {
  getAllVehicleTypes,
  removeVehicleType,
} from "@shared/services/settings/vehicle-types.service";
import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import dayjs from "dayjs";
import { useSnackbar } from "notistack";
import { FC, useCallback, useEffect, useState } from "react";

export interface IVehicleType {
  id: number;
  name: string;
  createdOn?: string;
  onEditClick?: (id: number) => void;
  onDeleteClick?: (id: number) => void;
}

const createVehicleType = (
  id: number,
  name: string,
  createdOn?: string,
  onEditClick?: (id: number) => void,
  onDeleteClick?: (id: number) => void
): IVehicleType => ({
  id,
  name,
  createdOn,
  onEditClick,
  onDeleteClick,
});

const headerCells: ITableHeaderCell<IVehicleType>[] = [
  { id: "id", label: "ID" },
  { id: "name", label: "Име" },
  {
    id: "createdOn",
    label: "Датум и време на додавање",
  },
  { id: "editButton", label: "", disableSorting: true },
  { id: "deleteButton", label: "", disableSorting: true },
];

const createRow = (row: IVehicleType) => (
  <>
    <TableCell>{row.id}</TableCell>
    <TableCell>{row.name}</TableCell>
    <TableCell>{dayjs.utc(row.createdOn).local().format("LLL")}</TableCell>
    <TableCell padding="checkbox">
      <IconButton size="small" onClick={() => (row.onEditClick ? row.onEditClick(row.id) : null)}>
        <Edit />
      </IconButton>
    </TableCell>
    <TableCell padding="checkbox">
      <IconButton
        size="small"
        onClick={() => (row.onDeleteClick ? row.onDeleteClick(row.id) : null)}
      >
        <Delete />
      </IconButton>
    </TableCell>
  </>
);

const VehicleTypeViewPage: FC = () => {
  const {
    data: vehicleTypes,
    isLoading,
    isFetching,
    error,
    refetch,
  } = useQuery(["vehicleTypes"], getAllVehicleTypes, {
    refetchInterval: 15000,
  });

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  useEffect(() => {
    if (!error || !(error instanceof Error)) return;

    enqueueSnackbar(`Се случи грешка: ${error.message}`, {
      variant: "error",
      persist: true,
      preventDuplicate: true,
      anchorOrigin: { horizontal: "center", vertical: "bottom" },
      action: function onDismiss(key) {
        return <Button onClick={() => closeSnackbar(key)}>OK</Button>;
      },
    });
  }, [error, enqueueSnackbar, closeSnackbar]);

  const confirmationDialog = useConfirmationDialog();

  const handleDeleteClick = (id: number) => {
    const vehicleType = vehicleTypes.find((x: IVehicleTypeDto) => x.id === id);

    if (vehicleType) {
      confirmationDialog.open({
        icon: <Warning fontSize="large" style={{ color: "orange" }} />,
        title: "Избриши тип",
        body: `Дали сте сигурни дека сакате да го избришете типот ${vehicleType.name} со ID ${vehicleType.id}?`,
        onConfirm: () => handleDelete(vehicleType.id),
        onDeny: () => confirmationDialog.close(),
      });
    }
  };

  const handleDelete = useCallback(
    (id: number) => {
      confirmationDialog.setLoading(true);

      removeVehicleType(id)
        .then(() => {
          confirmationDialog.close();
          enqueueSnackbar("Успешно го избришавте типот на возило", {
            variant: "success",
            autoHideDuration: 6000,
          });

          refetch();
        })
        .catch((error: AxiosError<IApiErrorResponseData>) => {
          confirmationDialog.close();
          enqueueSnackbar(
            `Не успеа да се отстрани типот на возило: ${error.response?.data?.message}`,
            {
              variant: "error",
              persist: true,
              preventDuplicate: true,
              anchorOrigin: { horizontal: "center", vertical: "bottom" },
              action: function onDismiss(key) {
                return <Button onClick={() => closeSnackbar(key)}>OK</Button>;
              },
            }
          );
        });
    },
    [closeSnackbar, enqueueSnackbar, refetch]
  );

  const handleEditClick = (id: number) => {
    const vehicleType = vehicleTypes.find((x: IVehicleTypeDto) => x.id === id);

    if (vehicleType) setSelectedRow(vehicleType);
  };

  const [selectedRow, setSelectedRow] = useState<IVehicleType | null>(null);
  const handleAddTypeClick = () => {
    setSelectedRow(createVehicleType(0, ""));
  };

  const handleAddTypeDialogClose = () => {
    setSelectedRow(null);
  };

  // Map data
  const rows: IVehicleType[] = [];
  if (vehicleTypes) {
    vehicleTypes.forEach((x: IVehicleType) => {
      rows.push(createVehicleType(x.id, x.name, x.createdOn, handleEditClick, handleDeleteClick));
    });
  }

  return (
    <TableView
      title="Типови на возила"
      addButtonText="Додади тип"
      onAddButtonClick={handleAddTypeClick}
      onRefreshClick={() => refetch()}
      headers={headerCells}
      rows={rows}
      render={createRow}
      isLoading={isLoading || isFetching}
      addDialog={
        <NewVehicleTypeForm
          title={selectedRow?.id ? "Промени тип на возило" : "Додади тип на возило"}
          addButtonText={selectedRow?.id ? "Зачувај" : "Додади"}
          open={selectedRow ? true : false}
          row={selectedRow?.id ? selectedRow : null}
          onClose={handleAddTypeDialogClose}
          onSuccess={() => refetch()}
        />
      }
    />
  );
};

export default VehicleTypeViewPage;
