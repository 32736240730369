
export enum DriverRoutePointType {
  Pickup,
  Dropoff,
  Declined,
}
export interface IPayrollDayDriverRoutePointDto {
  id: number;
  latitude?: number;
  longitude?: number;
  partner?: string;
  type: DriverRoutePointType;
  time: Date;
  orderNumber: string;
  routePointEarnings: number; // combination of all related pickups/dropoffs
  km: number;
  ratePerKm: number;
  minutes: number;
  ratePerMinute: number;
  waitingMinutes: number;
  ratePerWaitingMinute: number;
  totalEarnings: number;
  additionPercentage: number;
  additionType: string;
  totalEarningsWithAdditions: number;
}
