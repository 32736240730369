import { FC, memo, Ref, useMemo } from "react";
import DriverMarkers from "@Map/components/Drivers/DriverMarkers";
import LeafletMap from "@shared/components/Map/LeafletMap/LeafletMap";
import { IMapDriver, IMapOrder } from "@shared/components/Map/models/models";
import RoutePointMarkers from "@Map/components/Map/RoutePointMarkers";
import { IDriverRouteResponse, PackageOrderGuid, ShortRoutePoint } from "@shared/services/order.service";
import MapController from "@shared/components/Map/LeafletMap/MapController";
import { FeatureGroup, LayersControl, Pane } from "react-leaflet";
import PackageUnassignedOrderMarkers from "@Map/components/Map/PackageUnassignedOrderMarkers";
import AreasGeoJSON from "@Map/components/Map/AreasGeoJSON";
import LeafletMapRefBinder from "@shared/components/Map/LeafletMap/LeafletMapRefBinder";
import { Filter } from "../../interfaces/Filter";
import {
  isDriverBusy,
  isDriverFree,
  isDriverLost,
  isDriverPaused,
  isLowBattery,
} from "@shared/utils/drivers/driverStatus";
import {
  isOrderCompletelyLate,
  isOrderDelivered,
  isOrderDispatched,
  isOrderDropOffLate,
  isOrderNormal,
  isOrderPickUpLate,
} from "@shared/utils/orders/orderStatus";
import { useMemoSelector } from "@shared/hooks";
import { flatMap } from "lodash";
import { Map } from "leaflet";
import MapResizeObserver from "@shared/components/Map/LeafletMap/MapResizeObserver";
import PackageRouteLines from "@App/modules/Map/components/Map/PackageRouteLines";
import { IOrderDto } from "@shared/services/orders/dtos/orders/orderDto";

interface Props {
  ordersI: IOrderDto[];
  routePointsI: ShortRoutePoint[];
  forwardRef?: Ref<Map>;
}

const GeneratePackageMap: FC<Props> = ({ routePointsI,ordersI,forwardRef }) => {
  const { driverRoutes, orders } = useMemoSelector(({ appState }) => ({
    driverRoutes: routePointsI,
    orders: ordersI,
  }));

  return (
    <LeafletMap height="100%" defaultLocation={{ latitude: 41.9981, longitude: 21.4254 }}>
      <MapController
        geolocation={{
          latitude: 41.9981,
          longitude: 21.4254,
        }}
        zoom={13}
        animate
      />

      {/* Invalidate map size on resize */}
      <MapResizeObserver />

      {/* Allow the parent component to control the leaflet map */}
      <LeafletMapRefBinder ref={forwardRef} />

      <LayersControl>

        <Pane name="tooltip-pane" style={{ visibility: "hidden" }} />

        <LayersControl.Overlay name="Дестинации" checked>
          <FeatureGroup>
            <PackageUnassignedOrderMarkers orders={orders} />

          </FeatureGroup>
        </LayersControl.Overlay>

        <LayersControl.Overlay name="Рути" checked>
          <FeatureGroup>
          <PackageRouteLines driverRoutes={driverRoutes} />

          </FeatureGroup>
        </LayersControl.Overlay>

        <AreasGeoJSON />
      </LayersControl>
    </LeafletMap>
  );
};

export default memo(GeneratePackageMap);
