import { styled } from "@material-ui/core/styles";
import { IRoutePointDto } from "@shared/services/orders/dtos/orders/routePointDto";
import { generateColorGradient, hexToRGB } from "@shared/utils/color";
import { clamp, scale } from "@shared/utils/math";
import { getDropOffTime, getPickupTime } from "@shared/utils/orders/orderTime";
import dayjs from "dayjs";
import { FC } from "react";
import { Tooltip } from "react-leaflet";

const CustomTooltip = styled(Tooltip)({
  background: "white",
  borderRadius: "15px",
  padding: "1px 6px",
  textAlign: "center",
  fontSize: "11px",
  "&::before": {
    display: "none",
  },
});

interface TimeTooltipProps {
  routePoint: IRoutePointDto;
}

const TimeTooltip: FC<TimeTooltipProps> = ({ routePoint }) => {
  const colors = generateColorGradient(15, [
    hexToRGB("#FF0000"),
    hexToRGB("#FFA500"),
    hexToRGB("#00FF00"),
  ]);

  const getColorIndex = (timeLeft: number, numOfColors: number, maxMinutes: number) => {
    const clampedTimeLeft = clamp(timeLeft, 0, maxMinutes);
    const index = scale(clampedTimeLeft, 0, maxMinutes, 0, numOfColors);
    return Math.round(index);
  };

  const getTimeLeft = (rp: IRoutePointDto) => {
    const time = rp.isPickup ? getPickupTime(rp) : getDropOffTime(rp);
    if (!time) return 0;
    return dayjs.utc(time).local().diff(dayjs(), "minutes");
  };

  const formatTimeLeft = (minutes: number) => {
    if (minutes < 0) {
      return `-${Math.abs(minutes)}`;
    } else if (minutes > 0) {
      return `+${minutes}`;
    }
    return "0";
  };

  const formatTime = (dateString: string) => {
    if (!dateString) return "Invalid Time";
    return dayjs(dateString).format("HH:mm");
  };

  const timeLeft = getTimeLeft(routePoint);
  const colorIndex = getColorIndex(timeLeft, colors.length - 1, 15);

  const onAddress = routePoint.onAddress ? formatTime(routePoint.onAddress) : "0";
  const differenceTime = routePoint.differenceTime ? formatTimeLeft(routePoint.differenceTime) : "0";
  const eta = routePoint.eta ? formatTime(routePoint.eta) : "0";

  if ((!routePoint.onAddress || onAddress === "0") && 
      (!routePoint.differenceTime || differenceTime === "0") && 
      (!routePoint.eta || eta === "0")) {
    return null;
  }

  let color = "black";
  let fontWeight = "normal";
  if (routePoint.differenceTime > 30) {
    color = "red";
    fontWeight = "bold";
  } else if (routePoint.differenceTime > 15) {
    color = "dark-orange";
    fontWeight = "bold";
  }

  return (
    <CustomTooltip
      pane="tooltip-pane"
      direction="bottom"
      offset={routePoint.isPickup ? [0, 12] : [0, 0]}
      permanent
    >
      <span style={{ color, fontWeight }}>
        {onAddress} / {eta} <br />
      </span>
      <span style={{ color, fontWeight }}>
        {differenceTime}
      </span>
    </CustomTooltip>
  );
};

export default TimeTooltip;
