import React from "react";
import TableRow from "@material-ui/core/TableRow";
import { stableSort } from "../../utils/stableSort";
import { Order } from "./types/Order";

export interface TableRowsProps {
  rows: any[];
  order: Order;
  orderBy: string;
  page: number;
  rowsPerPage: number;
  children: (row: unknown) => React.ReactNode;
}

const TableRows: React.FC<TableRowsProps> = (props) => {
  const { rows, order, orderBy, page, rowsPerPage, children } = props;

  function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key
  ): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string }
  ) => number {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  return (
    <>
      {stableSort(rows, getComparator(order, orderBy))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((row: unknown, i) => (
          <TableRow hover tabIndex={-1} key={i}>
            {children ? children(row) : null}
          </TableRow>
        ))}
    </>
  );
};

export default TableRows;
