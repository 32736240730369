import ErrorFallback from "../../../../../error";
import PeriodDateFilter, { IPeriodDateFilter } from "../../shared/components/PeriodDateFilter";
import BonusConditionsCard from "./components/BonusConditionsCard";
import PeriodSumCard from "./components/PeriodSumCard";
import usePayrollEmployeePeriodColumns from "./hooks/usePayrollEmployeePeriodColumns";
import { Button, Grid, Typography, createStyles, makeStyles } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Loading from "@shared/components/Loading/Loading";
import MaterialTable, { ISort, sortHandler } from "@shared/components/MaterialTable/MaterialTable";
import { getEmployee } from "@shared/services/employees/employees.service";
import { IPayrollPeriodDayOverviewDto } from "@shared/services/payroll/dtos/payrollPeriodDayOverviewDto";
import {
  getPayrollPeriod,
  getPayrollPeriodBonusConditions,
  getPayrollPeriodSum,
} from "@shared/services/payroll/payroll.service";
import { getNextPageParam } from "@shared/utils/infiniteQuery";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { FC, useMemo, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

const useStyles = makeStyles((theme) =>
  createStyles({
    heading: {
      marginBottom: theme.spacing(4),
    },
    filters: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    summaryCards: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    periodTable: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  })
);

type PayrollEmployeePeriodParams = {
  employeeId: string;
};

const PayrollEmployeePeriod: FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const params = useParams<PayrollEmployeePeriodParams>();
  const [searchParams] = useSearchParams();
  const employeeId = useMemo(() => Number(params.employeeId), [params]);
  const fromDate = searchParams.get("from")
    ? dayjs(searchParams.get("from"), "YYYY-MM-DD").toDate()
    : null;
  const toDate = searchParams.get("to")
    ? dayjs(searchParams.get("to"), "YYYY-MM-DD").toDate()
    : null;

  const [periodFilter, setPeriodFilter] = useState<IPeriodDateFilter>({ from: null, to: null });
  const [sort, setSort] = useState<ISort>({ by: null, direction: null });

  const employeeQuery = useQuery(["getEmployee", employeeId], () => getEmployee(employeeId), {
    keepPreviousData: true,
  });

  const payrollPeriodQuery = useInfiniteQuery(
    ["getPayrollPeriod", periodFilter.from, periodFilter.to, sort.by, sort.direction],
    ({ pageParam = 0 }) =>
      getPayrollPeriod(employeeId, {
        currentPage: pageParam,
        itemsPerPage: 10,
        filters: {
          from: dayjs(periodFilter.from).utc().toDate(),
          to: periodFilter.to,
        },
        sortBy: sort.by,
        sortDirection: sort.direction,
        items: [],
        totalItemsCount: 0,
      }),
    {
      keepPreviousData: true,
      getNextPageParam: (lastPage) =>
        getNextPageParam(lastPage.currentPage, lastPage.itemsPerPage, lastPage.totalItemsCount),
    }
  );

  const bonusConditionsQuery = useQuery(
    ["getPayrollPeriodBonusConditions", periodFilter.from, periodFilter.to],
    () =>
      getPayrollPeriodBonusConditions(employeeId, {
        from: dayjs(periodFilter.from).utc().toDate(),
        to: periodFilter.to,
      }),
    { keepPreviousData: true }
  );

  const periodSumQuery = useQuery(
    ["getPayrollPeriodSum", periodFilter.from, periodFilter.to],
    () =>
      getPayrollPeriodSum(employeeId, {
        from: dayjs(periodFilter.from).utc().toDate(),
        to: periodFilter.to,
      }),
    { keepPreviousData: true }
  );

  const payrollPeriodColumns = usePayrollEmployeePeriodColumns(payrollPeriodQuery.refetch);
  const payrollPeriodData = useMemo(
    () => payrollPeriodQuery.data?.pages?.flatMap((pageData) => pageData.items) ?? [],
    [payrollPeriodQuery.data]
  );

  if (
    employeeQuery.isError ||
    payrollPeriodQuery.isError ||
    bonusConditionsQuery.isError ||
    periodSumQuery.isError
  )
    return (
      <ErrorFallback
        error={
          employeeQuery.error ??
          payrollPeriodQuery.error ??
          bonusConditionsQuery.error ??
          periodSumQuery.error
        }
      />
    );

  if (
    employeeQuery.isLoading ||
    payrollPeriodQuery.isLoading ||
    bonusConditionsQuery.isLoading ||
    periodSumQuery.isLoading
  )
    return <Loading />;

  return (
    <Grid container>
      <Grid container className={classes.heading}>
        <Grid item>
          <Button onClick={() => navigate("/app/payroll")}>
            <ArrowBackIosIcon />
          </Button>
        </Grid>
        <Grid item>
          <Typography variant="h5">Платен список за вработен:</Typography>
          <Typography variant="h6">
            {employeeQuery.data.firstName} {employeeQuery.data.lastName}
          </Typography>
        </Grid>
      </Grid>

      <Grid container className={classes.filters}>
        <Grid container direction="row">
          <PeriodDateFilter
            initialValues={{ from: fromDate, to: toDate }}
            onChange={(from, to) => setPeriodFilter({ from, to })}
          />
        </Grid>
      </Grid>

      <MaterialTable<IPayrollPeriodDayOverviewDto>
        title="Денови"
        className={classes.periodTable}
        columns={payrollPeriodColumns}
        data={payrollPeriodData}
        options={{
          search: false,
          maxColumnSort: 1,
          pageSize: 500,
          emptyRowsWhenPaging: false,
        }}
        isLoading={payrollPeriodQuery.isFetching}
        infiniteLoad={true}
        hasNextPage={payrollPeriodQuery.hasNextPage}
        fetchNextPage={payrollPeriodQuery.fetchNextPage}
        onOrderCollectionChange={(oc) => sortHandler(oc, payrollPeriodColumns, setSort)}
        onRowClick={(_, value) =>
          navigate(`/app/payroll/${employeeId}/${dayjs(value.date).format("YYYY-MM-DD")}`)
        }
      />

      <Grid container className={classes.summaryCards}>
        <BonusConditionsCard bonusConditions={bonusConditionsQuery.data} />
        <PeriodSumCard periodSum={periodSumQuery.data} />
      </Grid>
    </Grid>
  );
};

export default PayrollEmployeePeriod;
