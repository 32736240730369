import { calculateDistanceAndBearing } from "../../utils/calculateDistanceAndBearing";
import Card from "../Card/Card";
import { skopjeGeolocation } from "@Logistics/constants";
import { Avatar, Typography, makeStyles } from "@material-ui/core";
import { ArrowUpward as ArrowUpwardIcon, Storefront as StorefrontIcon } from "@material-ui/icons";
import { useMemoSelector } from "@shared/hooks";
import { IRoutePointDto } from "@shared/services/orders/dtos/orders/routePointDto";
import { cutString } from "@shared/utils/cutString";
import { getDropOffTime, getPickupTime } from "@shared/utils/orders/orderTime";
import clsx from "clsx";
import dayjs from "dayjs";
import { FC, memo } from "react";

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: "white",
    color: "black",
  },
  avatar: {
    width: theme.spacing(3.5),
    height: theme.spacing(3.5),
  },
  red: {
    color: "#f53b57",
  },
  yellow: {
    color: "#ffa801",
  },
  green: {
    color: "#0be881",
  },
}));

export interface RoutePointProps {
  routePoint: IRoutePointDto;
  background?: string;
}

const formatTimeLeft = (minutes: number) => {
  return minutes <= 0 ? `+${Math.abs(minutes)}` : minutes * -1;
};

const RoutePoint: FC<RoutePointProps> = ({ routePoint }) => {
  const classes = useStyles();

  const order = useMemoSelector(({ appState }) =>
    appState.logistics.orders.data.find((order) => order.id === routePoint.orderId)
  );

  const originalTime = dayjs
    .utc(routePoint.isPickup ? getPickupTime(routePoint) : getDropOffTime(routePoint))
    .local();
  const onLocationAtTime = dayjs.utc(routePoint.onLocationAt).local();
  const estimatedTime = dayjs.utc(routePoint.estimatedTime).local();

  const timeLeft = Math.round(originalTime?.diff(dayjs(), "minutes"));

  const { bearing, distance } = calculateDistanceAndBearing(skopjeGeolocation, {
    latitude: routePoint.latitude,
    longitude: routePoint.longitude,
  });

  // if (!order) {
  //   return null;
  // }

  return (
    <Card variant="rounded" background="normal" className={classes.card}>
      {routePoint.imgSrc ? (
        <Avatar
          title={order?.partnerName}
          src={routePoint.imgSrc}
          alt={order?.partnerName}
          variant="circular"
          className={classes.avatar}
        >
          <StorefrontIcon fontSize="small" />
        </Avatar>
      ) : null}
      {order?.displayOrderNumber ? (
          <Typography title={order?.displayOrderNumber}>{order.displayOrderNumber}
            </Typography>) : (
      <Typography>#{cutString(order?.externalOrderNumber, 3)?.padStart(3, "0")}</Typography>)}
      <div>
        <Typography component="span">
          {routePoint.isPickup ? originalTime?.format("HH:mm") : estimatedTime?.format("HH:mm")}
        </Typography>
        <Typography component="span">&nbsp;/&nbsp;</Typography>
        <Typography component="span">
          <b
            className={clsx({
              [classes.green]: onLocationAtTime <= originalTime || timeLeft >= 10,
              [classes.yellow]: timeLeft < 10 && Math.round(timeLeft) >= 0,
              [classes.red]: onLocationAtTime > originalTime || Math.round(timeLeft) < 0,
            })}
          >
            {routePoint.onLocationAt
              ? onLocationAtTime.format("HH:mm")
              : `(${formatTimeLeft(timeLeft)})`}
          </b>
        </Typography>
      </div>

      <div
        style={{
          display: "flex",
          gap: "0.5rem",
          border: "1px solid black",
          borderRadius: "25px",
          padding: "2px 10px",
          // backgroundColor: "#f7ddde",
        }}
      >
        {/* From direction */}
        <ArrowUpwardIcon style={{ transform: `rotate(${bearing}deg)` }} />
        <Typography>{Math.round(distance)}</Typography>
      </div>
    </Card>
  );
};

export default memo(RoutePoint);
