import OrderRejectReasonForm from "./components/OrderRejectReasonForm";
import { Button, IconButton, TableCell } from "@material-ui/core";
import { Delete, Edit, Warning } from "@material-ui/icons";
import TableView from "@shared/components/Table/TableView";
import { ITableHeaderCell } from "@shared/components/Table/types/ITableHeaderCell";
import { useConfirmationDialog } from "@shared/context/ConfirmationDialogContext";
import { IApiErrorResponseData } from "@shared/services/http.client";
import { IOrderRejectReasonDto } from "@shared/services/orders/dtos/order-reject-reason/orderRejectReasonDto";
import {
  getAllOrderRejectReasonTypes,
  removeOrderRejectReasonType,
} from "@shared/services/orders/order-reject-reason.service";
import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import dayjs from "dayjs";
import { useSnackbar } from "notistack";
import { FC, useCallback, useEffect, useState } from "react";

export interface IOrderRejectReasonType {
  id: number;
  name: string;
  description: string;
  hasPenalty: boolean;
  createdOn?: string;
  onEditClick?: (id: number) => void;
  onDeleteClick?: (id: number) => void;
}

const createOrderRejectReasonType = (
  id: number,
  name: string,
  description: string,
  hasPenalty: boolean,
  createdOn?: string,
  onEditClick?: (id: number) => void,
  onDeleteClick?: (id: number) => void
): IOrderRejectReasonType => ({
  id,
  name,
  description,
  hasPenalty,
  createdOn,
  onEditClick,
  onDeleteClick,
});

const headerCells: ITableHeaderCell<IOrderRejectReasonType>[] = [
  { id: "id", label: "ID" },
  { id: "name", label: "Име" },
  { id: "description", label: "Опис" },
  // { id: "hasPenalty", label: "Се пенализира" },
  {
    id: "createdOn",
    label: "Датум на додавање",
  },
  { id: "editButton", label: "", disableSorting: true },
  { id: "deleteButton", label: "", disableSorting: true },
];

const createRow = (row: IOrderRejectReasonType) => (
  <>
    <TableCell>{row.id}</TableCell>
    <TableCell>{row.name}</TableCell>
    <TableCell>{row.description}</TableCell>
    {/* <TableCell>{row.hasPenalty}</TableCell> */}
    <TableCell>{dayjs.utc(row.createdOn).local().format("LLL")}</TableCell>
    <TableCell padding="checkbox">
      <IconButton size="small" onClick={() => (row.onEditClick ? row.onEditClick(row.id) : null)}>
        <Edit />
      </IconButton>
    </TableCell>
    <TableCell padding="checkbox">
      <IconButton
        size="small"
        onClick={() => (row.onDeleteClick ? row.onDeleteClick(row.id) : null)}
      >
        <Delete />
      </IconButton>
    </TableCell>
  </>
);

const OrderRejectReasonViewPage: FC = () => {
  const {
    data: orderRejectReasonTypes,
    isLoading,
    isFetching,
    error,
    refetch,
  } = useQuery(["orderRejectReasonTypes"], getAllOrderRejectReasonTypes, {
    refetchInterval: 15000,
  });

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  useEffect(() => {
    if (!error || !(error instanceof Error)) return;

    enqueueSnackbar(`Се случи грешка, обидете се повторно. ${error.message}`, {
      variant: "error",
      persist: true,
      preventDuplicate: true,
      anchorOrigin: { horizontal: "center", vertical: "bottom" },
      action: function onDismiss(key) {
        return <Button onClick={() => closeSnackbar(key)}>OK</Button>;
      },
    });
  }, [error, enqueueSnackbar, closeSnackbar]);

  const confirmationDialog = useConfirmationDialog();

  const handleDeleteClick = (id: number) => {
    const orderRejectReasonType = orderRejectReasonTypes.find(
      (x: IOrderRejectReasonDto) => x.id === id
    );

    if (orderRejectReasonType)
      confirmationDialog.open({
        icon: <Warning fontSize="large" style={{ color: "orange" }} />,
        title: "Избриши причина",
        body: `Дали сте сигурни дека сакате да ја избришете причината ${orderRejectReasonType.name} со ID ${orderRejectReasonType.id}?`,
        onConfirm: () => handleDelete(orderRejectReasonType.id),
        onDeny: () => confirmationDialog.close(),
      });
  };

  const handleDelete = useCallback(
    (id: number) => {
      confirmationDialog.setLoading(true);

      removeOrderRejectReasonType(id)
        .then(() => {
          confirmationDialog.close();
          enqueueSnackbar("Успешно ја избришавте причината", {
            variant: "success",
            autoHideDuration: 6000,
          });

          refetch();
        })
        .catch((error: AxiosError<IApiErrorResponseData>) => {
          confirmationDialog.close();
          enqueueSnackbar(`Не успеа да се отстрани причината: ${error.response?.data?.message}`, {
            variant: "error",
            persist: true,
            preventDuplicate: true,
            anchorOrigin: { horizontal: "center", vertical: "bottom" },
            action: function onDismiss(key) {
              return <Button onClick={() => closeSnackbar(key)}>OK</Button>;
            },
          });
        });
    },
    [closeSnackbar, enqueueSnackbar, refetch]
  );

  const handleEditClick = (id: number) => {
    const orderRejectReasonType = orderRejectReasonTypes.find(
      (x: IOrderRejectReasonDto) => x.id === id
    );

    if (orderRejectReasonType) setSelectedRow(orderRejectReasonType);
  };

  const [selectedRow, setSelectedRow] = useState<IOrderRejectReasonType | null>(null);
  const handleAddTypeClick = () => {
    setSelectedRow(createOrderRejectReasonType(0, "", "",true));
  };

  const handleAddTypeDialogClose = () => {
    setSelectedRow(null);
  };

  // Map data
  const rows: IOrderRejectReasonType[] = [];
  if (orderRejectReasonTypes) {
    for (const x of orderRejectReasonTypes) {
      rows.push(
        createOrderRejectReasonType(
          x.id,
          x.name,
          x.description,
          x.hasPenalty,
          x.createdOn,
          handleEditClick,
          handleDeleteClick
        )
      );
    }
  }

  return (
    <TableView
      title="Причини за откажана нарачка"
      addButtonText="Додај причина"
      onAddButtonClick={handleAddTypeClick}
      onRefreshClick={() => refetch()}
      headers={headerCells}
      rows={rows}
      render={createRow}
      isLoading={isLoading || isFetching}
      addDialog={
        <OrderRejectReasonForm
          title={selectedRow?.id ? "Промени причина" : "Додај причина"}
          addButtonText={selectedRow?.id ? "Зачувај" : "Додај"}
          open={!!selectedRow}
          row={selectedRow?.id ? selectedRow : null}
          onClose={handleAddTypeDialogClose}
          onSuccess={() => refetch()}
        />
      }
    />
  );
};

export default OrderRejectReasonViewPage;
