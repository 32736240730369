import { IFilterState } from "../filters/filtersSlice";
import { IMapDriver } from "@shared/components/Map/models/models";
import { IDriverDto } from "@shared/services/drivers/dtos/driverDto";
import {
  isDriverBusy,
  isDriverFree,
  isDriverLost,
  isDriverPaused,
  isLowBattery,
} from "@shared/utils/drivers/driverStatus";
import { clone } from "lodash";

const filterDrivers = (drivers: IMapDriver[], filters: IFilterState["drivers"]) => {
  if (filters.mode === "disabled") {
    return drivers;
  }

  return drivers.filter((driver) => {
    let isValid = true;

    if(filters.groups.availability.free && !isDriverFree(driver)) {
      isValid = false;
    }
    if(filters.groups.availability.busy && !isDriverBusy(driver)) {
      isValid = false;
    }
    if(filters.groups.availability.paused && !isDriverPaused(driver)) {
      isValid = false;
    }
    if(filters.groups.problem.problem && !(isDriverLost(driver) || isLowBattery(driver))) {
      isValid = false;
    }


    if (filters.groups.city.length > 0 && !filters.groups.city.includes(driver.cityFk)) {
      isValid = false;
    }

    if (
      filters.groups.vehicleType.length > 0 &&
      !filters.groups.vehicleType.includes(driver.vehicleTypeFk)
    ) {
      isValid = false;
    }

    if (
      filters.groups.deliveryType.length > 0 &&
      !driver.deliveryTypeFk.some(
        (deliveryType) => filters.groups.deliveryType.includes(deliveryType)
      )
    ) {
      isValid = false;
    }

    if (filters.groups.capabilities.hasFiscalPrinter && !driver.hasFiscalPrinter) {
      isValid = false;
    }

    // Check if the driver category matches

    return isValid;
  });
};

export const selectAllDrivers = ({ appState }: IGlobalState, filter: boolean = false) => {
  let drivers = appState.logistics.drivers.data;

  if (filter) {
    const filters = appState.logistics.filters.drivers;
    drivers = filterDrivers(drivers, filters);
  }

  return drivers;
};

export const selectAllRoutes = ({ appState }: IGlobalState, filter: boolean = false) => {
  let routes = appState.logistics.driversRoutes.data;

  // if (filter) {
  //   const filters = appState.logistics.filters.drivers;
  //   routes = filterDrivers(routes, filters);
  // }

  return routes;
};

export const selectAllDriverIds = ({ appState }: IGlobalState, filter: boolean = false) => {
  let drivers = appState.logistics.drivers.data;

  if (filter) {
    const filters = appState.logistics.filters.drivers;
    drivers = filterDrivers(drivers, filters);
  }

  return drivers.map((driver) => driver.employeeId);
};

export const selectAllDriversWithoutGeolocation = (
  { appState }: IGlobalState,
  filter: boolean = false
): IDriverDto[] => {
  let drivers = appState.logistics.drivers.data;

  if (filter) {
    const filters = appState.logistics.filters.drivers;
    drivers = filterDrivers(drivers, filters);
  }

  // TODO: There has to be a better way to memoize this
  return drivers.map((driver) => {
    const d = clone(driver);
    delete d.geolocation;
    delete d.latitude;
    delete d.longitude;
    delete d.recievedOn;
    d.batteryPercentage = d.batteryPercentage > 20 ? 21 : 0;
    return d;
  });
};

export const selectDriverById = ({ appState }: IGlobalState, employeeId: number) => {
  return appState.logistics.drivers.data.find((driver) => driver.employeeId === employeeId);
};

export const selectDriverByIdWithoutGeolocation = (
  state: IGlobalState,
  employeeId: number
): IDriverDto => {
  const driver = selectDriverById(state, employeeId);

  if (!driver) {
    return null;
  }

  const clonedDriver = clone(driver);
  delete clonedDriver.geolocation;
  delete clonedDriver.latitude;
  delete clonedDriver.longitude;
  delete clonedDriver.recievedOn;
  clonedDriver.batteryPercentage = clonedDriver.batteryPercentage > 20 ? 21 : 0;

  return clonedDriver;
};

export const selectActiveDrivers = ({ appState }: IGlobalState, filter: boolean = false) => {
  let drivers = appState.logistics.drivers.data
    .filter((driver) => isDriverFree(driver) || isDriverBusy(driver))
    .sort((a, b) => a.orders.length - b.orders.length);

  if (filter) {
    const filters = appState.logistics.filters.drivers;
    drivers = filterDrivers(drivers, filters);
  }

  return drivers;
};

export const selectFreeDrivers = ({ appState }: IGlobalState, filter: boolean = false) => {
  let drivers = appState.logistics.drivers.data.filter((driver) => isDriverFree(driver));

  if (filter) {
    const filters = appState.logistics.filters.drivers;
    drivers = filterDrivers(drivers, filters);
  }

  return drivers;
};

export const selectBusyDrivers = ({ appState }: IGlobalState, filter: boolean = false) => {
  let drivers = appState.logistics.drivers.data.filter((driver) => isDriverBusy(driver));

  if (filter) {
    const filters = appState.logistics.filters.drivers;
    drivers = filterDrivers(drivers, filters);
  }

  return drivers;
};

export const selectPausedDrivers = ({ appState }: IGlobalState, filter: boolean = false) => {
  let drivers = appState.logistics.drivers.data.filter((driver) => isDriverPaused(driver));

  if (filter) {
    const filters = appState.logistics.filters.drivers;
    drivers = filterDrivers(drivers, filters);
  }

  return drivers;
};

export const selectProblemDrivers = ({ appState }: IGlobalState) => {
  return appState.logistics.drivers.data.filter(
    (driver) => isDriverLost(driver) || isLowBattery(driver)
  );
};

export const selectLostLocationDrivers = ({ appState }: IGlobalState) => {
  return appState.logistics.drivers.data.filter((driver) => isDriverLost(driver));
};

export const selectDriversByCity = ({ appState }: IGlobalState, cityId: number) => {
  return appState.logistics.drivers.data.filter((driver) => driver.cityFk === cityId);
};

export const selectDriversByVehicleType = ({ appState }: IGlobalState, vehicleTypeId: number) => {
  return appState.logistics.drivers.data.filter((driver) => driver.vehicleTypeFk === vehicleTypeId);
};

export const selectDriversDifferenceTime = ({ appState }: IGlobalState, employeeId: number) => {
  let driverRoute = appState.logistics.driversRoutes.data.find((route) => route.employeeId == employeeId);
  let differenceTime = driverRoute?.routePoints[0]?.differenceTime || 0;

  const maxDifferenceTime = driverRoute?.routePoints.reduce((max, point) => 
    Math.max(max, point.differenceTime || 0), 0);

  return maxDifferenceTime;
};

export const selectDriversByDeliveryType = ({ appState }: IGlobalState, deliveryTypeId: number) => {
  return appState.logistics.drivers.data.filter((driver) =>
    driver.deliveryTypeFk?.includes(deliveryTypeId)
  );
};

// Category selectors
export const selectFreeDriverIds = (state: IGlobalState) => {
  return selectFreeDrivers(state).map((driver) => driver.employeeId);
};

export const selectBusyDriverIds = (state: IGlobalState) => {
  return selectBusyDrivers(state).map((driver) => driver.employeeId);
};

export const selectPausedDriverIds = (state: IGlobalState) => {
  return selectPausedDrivers(state).map((driver) => driver.employeeId);
};

export const selectNumberOfActiveDrivers = (state: IGlobalState) => {
  return selectActiveDrivers(state).length;
};
