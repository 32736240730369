import { FC, useState, ChangeEvent, memo, useEffect } from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import {
  FormControlLabel,
  FormControl,
  FormLabel,
  FormGroup,
  Checkbox,
  Theme,
  Paper,
  FormHelperText,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { FormikErrors } from "formik";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(1.5),
    },
    checkboxFormControl: {
      display: "block",
    },
    label: {
      display: "block",
      marginBottom: theme.spacing(1),
    },
  })
);

export interface IType {
  id: number;
  name: string;
}

export interface TypeSelectProps {
  name: string;
  title: string;
  errorText: string | string[] | FormikErrors<any>[];
  options?: IType[],
  value: number[];
  onChange: (name: string, data: number[]) => void;
  className?: string;
  isLoading: boolean;
}

const TypeSelect: FC<TypeSelectProps> = (props) => {
  const classes = useStyles();

  const { name, value, title, options, onChange, errorText, isLoading } = props;

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value: eventValue, checked } = event.currentTarget;

    const vals = [...value];
    if (checked) {
      const result = vals.find((x) => x == parseInt(eventValue));
      if (!result) vals.push(parseInt(eventValue));
    } else {
      vals.forEach((x: number, i) => {
        if (x === parseInt(eventValue)) vals.splice(i, 1);
      });
    }
    onChange(name, vals);
  };

  return (
    <Paper
      variant="outlined"
      title={title}
      className={clsx(classes.paper, props.className)}
    >
      <FormControl
        component="fieldset"
        className={classes.checkboxFormControl}
        error={errorText ? true : false}
      >
        <FormLabel className={classes.label}>{title}</FormLabel>
        {isLoading ? (
          <Skeleton
            variant="rect"
            style={{ width: "100%" }}
            height={36}
            animation="wave"
          />
        ) : (
          <FormGroup row aria-label="position" defaultValue="top">
            {options.map((option) => (
              <FormControlLabel
                key={option.name}
                label={option.name}
                labelPlacement="end"
                control={
                  <Checkbox
                    name={option.name}
                    value={option.id}
                    checked={value && value.some((x) => option.id === x)}
                    onChange={handleChange}
                    color="primary"
                  />
                }
              />
            ))}
          </FormGroup>
        )}
        <FormHelperText>{errorText}</FormHelperText>
      </FormControl>
    </Paper>
  );
};
export default memo(TypeSelect);
