import { Column } from "@material-table/core";
import {
  DriverRoutePointType,
  IPayrollDayDriverRoutePointDto,
} from "@shared/services/payroll/dtos/payrollDayDriverRoutePointDto";
import dayjs from "dayjs";

export const usePayrollDayDriverRoutePointColumns =
  (): Column<IPayrollDayDriverRoutePointDto>[] => {
    return [
      {
        title: "Партнер",
        field: "partner",
      },
      {
        title: "Време",
        field: "time",
        render: (value) => dayjs(value.time).format("HH.mm"),
      },
      {
        title: "Број на нарачка",
        field: "orderNumber",
        render: (value) =>
          value.type === DriverRoutePointType.Pickup
            ? `П - ${value.orderNumber}`
            : value.type === DriverRoutePointType.Dropoff
            ? `Д - ${value.orderNumber}`
            : "",
            
      },
      {
        title: "PU/DO",
        field: "routePointEarnings",
      },
      {
        title: "КМ",
        field: "km",
        editable: 'onUpdate',
      },
      {
        title: "Заработка по КМ",
        field: "ratePerKm",
      },
      {
        title: "Минути",
        field: "minutes",
      },
      {
        title: "Заработка по минута",
        field: "ratePerMinute",
      },
      {
        title: "Чекање (минути)",
        field: "waitingMinutes",
      },
      {
        title: "Заработка по чекање (по минута)",
        field: "ratePerWaitingMinute",
      },
      {
        title: "Вкупно",
        field: "totalEarnings",
      },
      {
        title: "Додатоци",
        field: "additionPercentage",
        render: (value) => `${value.additionPercentage}%`,
      },
      {
        title: "Вкупно (со додатоци)",
        field: "totalEarningsWithAdditions",
      },
    ];
  };
