import httpService from "../http.service";
import { ICityDto } from "./dtos/cities/cityDto";
import { ICreateCityRequestDto } from "./dtos/cities/createCityRequestDto";

export const getCities = () => {
  return httpService.get<ICityDto[]>("/api/cities");
};

export const getCity = (id: number) => {
  return httpService.get<ICityDto>(`/api/cities/${id}`);
};

export const addCity = (request: ICreateCityRequestDto) => {
  return httpService.post<ICityDto, ICreateCityRequestDto>(`/api/cities`, request);
};

export const updateCity = (id: number, request: ICreateCityRequestDto) => {
  return httpService.put<ICityDto, ICreateCityRequestDto>(`/api/cities/${id}`, request);
};

export const removeCity = (id: number) => {
  return httpService.delete<void>(`/api/cities/${id}`);
};
