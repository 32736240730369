import { FC, Key, useEffect } from "react";
import { useSnackbar } from "notistack";

const NetworkChangeWatcher: FC = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    let key: Key;

    window.addEventListener("online", () => {
      if (key) closeSnackbar(key);

      key = enqueueSnackbar("Се вративте он-лајн", {
        variant: "success",
        autoHideDuration: 6000,
        anchorOrigin: { horizontal: "center", vertical: "bottom" },
      });
    });
    window.addEventListener("offline", () => {
      if (key) closeSnackbar(key);

      key = enqueueSnackbar("Вие сте офлајн", {
        variant: "error",
        persist: true,
        anchorOrigin: { horizontal: "center", vertical: "bottom" },
      });
    });
  }, [enqueueSnackbar, closeSnackbar]);

  return null;
};

export default NetworkChangeWatcher;
