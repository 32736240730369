import { IDriverDto } from "@shared/services/drivers/dtos/driverDto";
import { OrderStatus } from "@shared/services/orders/enums/orderStatus";
import dayjs from "dayjs";

/**
 * Check if the driver is paused
 * @param driver The driver object
 * @returns {boolean} Whether the driver is paused
 */
export const isDriverPaused = (driver: IDriverDto): boolean => {
  return driver.pauseStatus && driver.pauseStatus.startTime ? true : false;
};

/**
 * Check if the driver has fiscal printer
 * @param driver The driver object
 * @returns {boolean} Whether the driver has fiscal printer
 */
export const driverHasFiscalPrinter = (driver: IDriverDto): boolean => {
  return driver.hasFiscalPrinter;
};


/**
 * Check if the driver is busy.
 * A driver is busy when they aren't paused and have at least one order with the status assigned or picked up
 * @param driver The driver object
 * @returns Whether the driver isn't paused and has active orders
 */
export const isDriverBusy = (driver: IDriverDto): boolean => {
  return (
    !isDriverPaused(driver) &&
    driver.orders?.filter(
      (x) => x.orderStatus === OrderStatus.Assigned || x.orderStatus === OrderStatus.PickedUp
    ).length > 0
  );
};

/**
 * Check if the driver is free. The driver is free when they aren't paused, and they don't have any active orders
 * @param driver The driver object
 * @returns Whether the driver isn't paused and doesn't have any active orders
 */
export const isDriverFree = (driver: IDriverDto): boolean => {
  return (
    !isDriverPaused(driver) &&
    driver.orders?.filter(
      (x) => x.orderStatus === OrderStatus.Assigned || x.orderStatus === OrderStatus.PickedUp
    ).length <= 0
  );
};

/**
 * Check if the drivers latest GPS location was received in the last 5 minutes
 * @param driver The driver object
 * @returns Whether the drivers location is lost
 */
export const isDriverLost = (driver: IDriverDto): boolean => {
  const lastReceivedOn = dayjs.utc(driver.recievedOn);
  return dayjs.utc().diff(lastReceivedOn, "minutes") >= 5;
};

/**
 * Check if the drivers battery percentage is lower than 20%
 * @param driver The driver object
 * @returns Whether the driver has a low battery percentage
 */
export const isLowBattery = (driver: IDriverDto): boolean => {
  return driver.batteryPercentage < 20;
};
