import Drawer from "./Drawer/Drawer";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import NetworkChangeListener from "@shared/components/NetworkChangeListener/NetworkChangeListener";
import { FC } from "react";
import { Outlet } from "react-router-dom";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    container: {
      flexGrow: 1,
      minHeight: "100vh",
      overflow: "auto",
      padding: theme.spacing(0),
    },
  })
);

const Layout: FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Drawer />

      <main className={classes.container}>
        <Outlet />
      </main>

      <NetworkChangeListener />
    </div>
  );
};

export default Layout;
