import { Column } from "@material-table/core";
import { IPayrollEmployeeOverviewDto } from "@shared/services/payroll/dtos/payrollEmployeeOverviewDto";

const useEmplyeesOverviewColumns = (): Column<IPayrollEmployeeOverviewDto>[] => {
  return [
    {
      title: "Име",
      field: "firstName",
    },
    {
      title: "Презиме",
      field: "lastName",
    },
    {
      title: "Позиција",
      field: "employeePosition",
      render: (data) => data.employeePosition.name,
    },
    {
      title: "Заработка",
      field: "earnings",
    },
    {
      title: "Бонус",
      field: "bonus",
    },
    {
      title: "Бр. нарачки",
      field: "orderCount",
    },
    {
      title: "Боледување",
      field: "sickDays",
    },
    {
      title: "Одмор",
      field: "holiday",
    },
    {
      title: "Пенали",
      field: "penalties",
    }
    
  ];
};

export default useEmplyeesOverviewColumns;

