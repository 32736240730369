import AddRoleSalaryConfigForm from "./AddRoleSalaryConfigForm";
import { Box, Button, Card, CardHeader, createStyles, makeStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { createRoleSalary } from "@shared/services/salary-configuration/role-salary-configuration.service";
import { useMutation } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { FC, useState } from "react";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      minWidth: theme.spacing(56),
      margin: theme.spacing(1),
    },
    addNew: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: theme.spacing(36),
      margin: theme.spacing(1),
    },
  })
);

interface AddRoleSalaryConfigCardProps {
  onAddRole: () => void;
}

const AddRoleSalaryConfigCard: FC<AddRoleSalaryConfigCardProps> = ({ onAddRole }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [addMode, setAddMode] = useState(false);

  const { mutate: createRoleSalaryMutate } = useMutation(createRoleSalary, {
    onSuccess: () => {
      setAddMode(false);
      onAddRole();
    },
    onError: () => {
      enqueueSnackbar(
        "Се случи грешка. Ве молиме обидете се повторно или контактирајте го тимот за поддршка."
      );
    },
  });

  return (
    <>
      {!addMode && (
        <Box className={classes.addNew}>
          <Button variant="contained" color="primary" onClick={() => setAddMode(true)}>
            + Додади нова конфигурација
          </Button>
        </Box>
      )}
      {addMode && (
        <Card className={classes.root}>
          <CardHeader
            title="Нова конфигурација"
            action={
              <Button onClick={() => setAddMode(false)}>
                <CloseIcon />
              </Button>
            }
          />
          <AddRoleSalaryConfigForm onSubmit={createRoleSalaryMutate} />
        </Card>
      )}
    </>
  );
};

export default AddRoleSalaryConfigCard;
