import ErrorFallback from "../../../../error";
import OrdersLog from "./Orders/OrdersLog";
import { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

const LogsView: FC = () => {
  return (
    <Routes>
      <Route errorElement={<ErrorFallback />}>
        <Route path="orders" element={<OrdersLog />} />

        <Route path="*" element={<LogsFallback />} />
      </Route>
    </Routes>
  );
};
const LogsFallback = () => <Navigate to="orders" />;
export default LogsView;
