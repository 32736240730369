import Main from "./Main";
import ThemeWrapper from "./ThemeWrapper";
import { setupViteErrorOverlay } from "./error";
import { setupLocalization } from "./localization";
import { ConfirmationDialogProvider } from "./shared/context/ConfirmationDialogContext";
import store from "./store";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import ModalProvider from "mui-modal-provider";
import { SnackbarProvider } from "notistack";
import { StrictMode } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

if (import.meta.env.VITE_LOGGING_ENABLED.toString() === "true") {
  // Sentry.init({
  //   dsn: "https://32b9de36246f4ede99c5197e311f20e2@o923085.ingest.sentry.io/5870172",
  //   integrations: [new Integrations.BrowserTracing()],
  //   environment: import.meta.env.MODE?.toString(),
  //   tracesSampleRate: 1.0, // Tweak this between 0 and 1
  // });
}

// React query config
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

setupViteErrorOverlay();
setupLocalization("mk");

ReactDOM.render(
  <StrictMode>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <ThemeWrapper>
          <SnackbarProvider>
            <ModalProvider>
              <ConfirmationDialogProvider>
                <Main />
              </ConfirmationDialogProvider>
            </ModalProvider>
          </SnackbarProvider>
        </ThemeWrapper>
        <ReactQueryDevtools />
      </QueryClientProvider>
    </Provider>
  </StrictMode>,
  document.getElementById("root")
);
