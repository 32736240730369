import { AccordionDetails as MuiAccordionDetails, withStyles } from "@material-ui/core";

const AccordionDetails = withStyles((theme) => ({
  root: {
    display: "block",
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

export default AccordionDetails;
