import { FC } from "react";
import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    error: {
      color: theme.palette.error.main,
    },
  })
);

export interface ErrorMessageProps {
  error: string;
  className?: string;
}

const ErrorMessage: FC<ErrorMessageProps> = ({ error, className }) => {
  const classes = useStyles();

  if (!error) return null;

  return (
    <Typography component="p" className={clsx(classes.error, className)}>
      {error}
    </Typography>
  );
};

export default ErrorMessage;
