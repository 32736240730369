import { createAsyncThunk } from "@reduxjs/toolkit";
import { getDrivers } from "@shared/services/drivers/drivers.service";

export const getAllDriversThunk = createAsyncThunk("getAllDriversThunk", async () => {
  const drivers = await getDrivers();

  return drivers.map((driver) => ({
    ...driver,
    geolocation: { longitude: driver.longitude, latitude: driver.latitude },
  }));
});
