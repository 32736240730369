import ErrorFallback from "../../error";
import { getAccessToken, startTokenChecker } from "../../shared/services/auth/auth.service";
import DriversLayout from "./components/Layout";
import OrderDetails from "./modules/Order/OrderDetails";
import Orders from "./modules/Order/Orders";
import Paycheck from "./modules/Paycheck/Paycheck";
import PaycheckDetails from "./modules/Paycheck/PaycheckDetails";
import ShiftPage from "./modules/Shift/Workshift";
import RevenueDetails from "./modules/Revenue/RevenueDetails";
import {
  getAllOrderRejectReasonTypesAsyncThunk,
  getDeliveryTypesAsyncThunk,
  getPaymentTypesAsyncThunk,
  getVehicleTypesAsyncThunk,
} from "@shared/store/async-thunks";
import { AppDispatch } from "@store/index";
import { FC } from "react";
import { useDispatch } from "react-redux";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { Subscription, timer } from "rxjs";
import ScheduleAsDriver from "../App/Scheduler/modules/ScheduleAsDriver/ScheduleAsDriverApp";
import path from "path";

export const RoutePaths = {
  Shift: "/drivers/shift",
  Orders: "/drivers/orders",
  OrderDetails: "/drivers/orders/:orderId",
  Schedule: "/drivers/schedule",
};

const t = timer(0, 15 * 60 * 1000); // 15 minutes
var subscription: Subscription;

const Webview: FC = () => {
  const access_token = getAccessToken();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const initApplicationStoreLoader = () => {
    if (subscription) {
      subscription.unsubscribe();
      subscription = null;
    }
    subscription = t.subscribe((x) => {
      dispatch(getDeliveryTypesAsyncThunk());
      dispatch(getVehicleTypesAsyncThunk());
      dispatch(getPaymentTypesAsyncThunk());
      dispatch(getAllOrderRejectReasonTypesAsyncThunk());
    });
  };

  if (!access_token) {
    navigate("/auth/login");
  } else {
    startTokenChecker();
    initApplicationStoreLoader();
  }

  return (
    <Routes>
      <Route element={<DriversLayout />}>
        <Route errorElement={<ErrorFallback />}>
          <Route path="shift" element={<ShiftPage />} />
          <Route path="orders" element={<Orders />} />
          <Route path="orders/:orderId" element={<OrderDetails />} />
          <Route path="paycheck" element={<Paycheck />} />
          <Route path="paycheck/:payrollId/:day" element={<PaycheckDetails />} />
          <Route path="revenue" element={<RevenueDetails />} />
          <Route path="schedule" element={<ScheduleAsDriver />} />
          <Route path="*" element={<DriversFallback />} />
        </Route>
      </Route>
    </Routes>
  );
};

const DriversFallback: FC = () => <Navigate to="shift" />;

export default Webview;

