import httpService from "../http.service";
import { IPaymentTypeDto } from "./dtos/payment-types/paymentTypeDto";
import { IPaymentTypeRequestDto } from "./dtos/payment-types/paymentTypeRequestDto";

export const getAllPaymentTypes = () => {
  return httpService.get<IPaymentTypeDto[]>("/api/payment-types");
};

export const getPaymentType = (id: number) => {
  return httpService.get<IPaymentTypeDto>(`/api/payment-types/${id}`);
};

export const addNewPaymentType = (request: IPaymentTypeRequestDto) => {
  return httpService.post<IPaymentTypeDto, IPaymentTypeRequestDto>(`/api/payment-types`, request);
};

export const updatePaymentType = (id: number, request: IPaymentTypeRequestDto) => {
  return httpService.put<IPaymentTypeDto, IPaymentTypeRequestDto>(
    `/api/payment-types/${id}`,
    request
  );
};

export const removePaymentType = (id: number) => {
  return httpService.delete<void>(`/api/payment-types/${id}`);
};
