export enum BonusPenaltyType {
  Bonus = 1,
  Penalty = 2,
}

export interface IBonusPenaltyDto {
  id: number;
  type: BonusPenaltyType;
  date?: string;
  author: string;
  amount: number;
  reason: string;
  deleted: boolean;
}

export interface IPayrollPeriodDayOverviewDto {
  id: number;
  date: Date;
  earnings: number;
  sick: number;
  holiday: number;
  bonusesAndPenalties?: IBonusPenaltyDto[];
  total?: number;
  paidOutDate?: Date;
}
