import React from "react";
import MatTable, { Size } from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";

export interface TableProps {
  title: string;
  size: Size;
  strickyHeader: boolean;
}

const Table: React.FC<TableProps> = ({ title, size, strickyHeader, children }) => {
  return (
    <TableContainer>
      <MatTable size={size} stickyHeader={strickyHeader} aria-labelledby={title} aria-label="enhanced table">
        {children}
      </MatTable>
    </TableContainer>
  );
};

export default Table;
