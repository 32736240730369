import { selectedOrderId } from "@App/modules/Logistics/store/features/filters/filtersSlice";
import { PopperPlacementType } from "@material-ui/core";
import React, { ReactElement, useState } from "react";
import CustomTooltip from "./CustomTooltip";
import { useDispatch, useSelector } from "react-redux";
import OrderTooltipContent from "@App/modules/Map/components/Orders/OrderTooltipContent";

interface Props {
  content: any;
  children: ReactElement;
  type: string;
  disabled?: boolean;
  arrow?: boolean;
  placement?: PopperPlacementType;
  style?: {};
}
//https://codesandbox.io/s/popper-with-arrow-58jhe
const ClickableTooltip = ({
  placement,
  arrow,
  type,
  content,
  children,
  disabled = false,
  style,
}: Props) => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  if (disabled) {
    return React.cloneElement(children, { ...children.props, disabled: true });
  }

  const existingOnClick = children.props.onClick;
  const newOnClick = (e: MouseEvent) => {
    if (type === "order") {
      switch (e.detail) {
        case 1:
          var id: number = content?.props?.order.id;
          // dispatch(selectedOrderId({selectedId: id, isEnabled: true}));
          dispatch(selectedOrderId({ selectedId: id, isEnabled: true }));
          break;
        case 2:
          e.stopPropagation();

          setOpen(!open);
          existingOnClick && existingOnClick(e);
          break;
      }
    } else {
      e.stopPropagation();
      setOpen(!open);
      existingOnClick && existingOnClick(e);
    }
  };

  const contentNode = typeof content === "function" ? content() : content;

  return (
    <CustomTooltip
      open={open}
      style={style}
      onClose={() => setOpen(false)}
      arrow={arrow}
      placement={placement}
      content={contentNode}
    >
      {React.cloneElement(children, { ...children.props, onClick: newOnClick })}
    </CustomTooltip>
  );
};

export default ClickableTooltip;
