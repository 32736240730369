import AddSubRoleConfigForm from "./AddSubRoleSalaryConfigForm";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Tooltip,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import SettingsIcon from "@material-ui/icons/Settings";
import { IRoleSalaryConfigurationDto } from "@shared/services/salary-configuration/dtos/role-salary-configurations/roleSalaryConfigurationDto";
import { createRoleSalary } from "@shared/services/salary-configuration/role-salary-configuration.service";
import { getEmployeePosition } from "@shared/services/settings/employee-positions.service";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      minWidth: theme.spacing(56),
      margin: theme.spacing(1),
    },
  })
);

interface RoleSalaryConfigCardProps {
  roleSalaryConfig: IRoleSalaryConfigurationDto;
  onAddSubRole: () => void;
}

const RoleSalaryConfigCard: FC<RoleSalaryConfigCardProps> = ({
  roleSalaryConfig,
  onAddSubRole,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [addSubRoleMode, setAddSubRoleMode] = useState(false);

  const {
    data: employeePosition,
    isLoading,
    isError,
  } = useQuery(["getEmployeePosition", roleSalaryConfig.employeePositionFK], () =>
    getEmployeePosition(roleSalaryConfig.employeePositionFK)
  );

  //! Should be updated to "createSubRoleSalary"
  const { mutate: createRoleSalaryMutate } = useMutation(createRoleSalary, {
    onSuccess: () => {
      setAddSubRoleMode(false);
      onAddSubRole();
    },
    onError: () => {
      enqueueSnackbar(
        "Се случи грешка. Ве молиме обидете се повторно или контактирајте го тимот за поддршка."
      );
    },
  });

  return (
    <Card className={classes.root}>
      <CardHeader
        title={
          <Box>
            <Typography variant="h5">Позиција:</Typography>
            {isLoading && <CircularProgress />}
            {!isLoading && (
              <Typography variant="subtitle1">{employeePosition?.name || ""}</Typography>
            )}
          </Box>
        }
        action={
          <Tooltip title="Детална конфигурација на позиција">
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate(`/app/settings/role-salary-config/${roleSalaryConfig.id}`)}
            >
              <SettingsIcon color="action" />
            </Button>
          </Tooltip>
        }
      />

      <CardContent>
        <List subheader={<ListSubheader>Под-позиција</ListSubheader>}>
          {roleSalaryConfig.subRoleSalaryConfigs?.map((subRoleSalaryConfig) => (
            <Tooltip
              key={subRoleSalaryConfig.id}
              title="Кликни за детална конфигурација на под-позиција"
            >
              <ListItem
                button
                onClick={() =>
                  navigate(`/app/settings/role-salary-config/${subRoleSalaryConfig.id}`)
                }
              >
                <ListItemText>{subRoleSalaryConfig.employeePositionFK}</ListItemText>
                <ListItemIcon>
                  <NavigateNextIcon />
                </ListItemIcon>
              </ListItem>
            </Tooltip>
          ))}

          <Divider />
          {!addSubRoleMode && (
            <ListItem button onClick={() => setAddSubRoleMode(true)}>
              <ListItemText>+ Додади под-конфигурација</ListItemText>
            </ListItem>
          )}
        </List>

        {addSubRoleMode && (
          <AddSubRoleConfigForm
            parentRoleSalaryConfig={roleSalaryConfig}
            onCancel={() => setAddSubRoleMode(false)}
            onSubmit={createRoleSalaryMutate}
          />
        )}
      </CardContent>
    </Card>
  );
};

export default RoleSalaryConfigCard;
