import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  parentDiv: {
    width: "95%",
    margin: "10px auto",
  },
  buttonDiv: {
    marginTop: "10px",
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    "&.MuiButton-containedPrimary": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  modalContent: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    borderRadius: "4px",
    outline: "none",
    [theme.breakpoints.down("xs")]: {
      width: "90%",
      maxHeight: "80%",
      overflowY: "auto",
    },
    [theme.breakpoints.up("sm")]: {
      width: "50%",
    },
  },
  accordion: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: "20px",
  },
  offAccordion: {
    border: `1px solid gray`,
    borderRadius: "20px",
  },
  toggle: {
    color: theme.palette.primary.main,
    "& .Mui-checked": {
      color: theme.palette.primary.main,
    },
    "& .Mui-checked + .MuiSwitch-track": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  expandIcon: {
    color: theme.palette.primary.main,
  },
  addTimeItem: {
    color: theme.palette.primary.main,
  },
  deleteTimeItem: {
    color: theme.palette.error.main,
  },
  primaryContained: {
    backgroundColor: `${theme.palette.primary.main} !important`,
  },
  primaryOutlined: {
    color: `${theme.palette.primary.main} !important`,
    borderColor: `${theme.palette.primary.main} !important`,
  },
  errorOutlined: {
    color: `${theme.palette.error.main} !important`,
    borderColor: `${theme.palette.error.main} !important`,
  },
}));

export default useStyles;
