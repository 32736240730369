import type { WeekDay, Hour } from "../../../genericTypes";
import type { Daily } from "../types";

function generateDummyData(daysCount: number): Daily[] {
  const dummyData: Daily[] = [];
  const days = ["Понеделник", "Вторник", "Среда", "Четврток", "Петок", "Сабота", "Недела"];

  for (let i = 0; i < daysCount; i++) {
    const date = new Date(2023, 3, 22 + i);
    const day: Daily = {
      date: `${date.getMonth() + 1}/${date.getDate()}`,
      day: days[date.getDay()] as WeekDay,
      timetable: [],
    };

    for (let hour = 9; hour <= 23; hour++) {
      const driversOnDuty = Math.floor(Math.random() * 10);
      const driversNeeded = driversOnDuty + Math.floor(Math.random() * 5);
      // const driversNeeded = driversOnDuty;

      day.timetable.push({
        time: `${hour}:00` as Hour,
        driversOnDuty,
        driversNeeded,
      });
    }

    dummyData.push(day);
  }

  console.log(dummyData);
  return dummyData;
}

export default generateDummyData;
