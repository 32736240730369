import React from "react";
import { Card, CardContent, IconButton, Typography, makeStyles } from "@material-ui/core";
import PhoneIcon from "@material-ui/icons/Phone";
import { useQuery } from "@tanstack/react-query";
import { EmployeeScheduleResponse, getNotActiveDriver } from "@shared/services/schedule.service";

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(1),
  },
  card: {
    flex: "0 0 calc(33% - 10px)",
    margin: "2px",
    padding: "2px 5px",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1), 0px 4px 8px rgba(0, 0, 0, 0.1)",
    borderRadius: "15px",
    transition: "transform 0.2s",
    height: "35px",
    backgroundColor: "salmon",
    '&:hover': {
      transform: "scale(1.02)",
    },
  },
  cardContent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "1px 4px", 
  },
  name: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '1.1rem',
    color: "white",
  },
  phoneIcon: {
    color: "green",
  }
}));

const NotActiveDriversCard: React.FC = () => {
  const classes = useStyles();
  const { data: schedule, isLoading } = useQuery<EmployeeScheduleResponse>(
    ["schedule"],
    getNotActiveDriver
  );

  if (isLoading) return <div>Loading...</div>;

  return (
    <div className={classes.container}>
      {schedule.map((item, index) => (
        <Card key={index} className={classes.card}>
          <CardContent className={classes.cardContent}>
            <Typography variant="body2" className={classes.name}>{item.employee}</Typography>
            <IconButton href={`tel:${item.phoneNumber}`} size="small">
              <PhoneIcon className={classes.phoneIcon} />
            </IconButton>
          </CardContent>
        </Card>
      ))}
    </div>
  );
};

export default NotActiveDriversCard;
