import { createSelector } from "@reduxjs/toolkit";
import { IMapOrder } from "@shared/components/Map/models/models";
import { IFullOrderDto } from "@shared/services/orders/dtos/orders/fullOrderDto";
import { OrderStatus } from "@shared/services/orders/enums/orderStatus";
import dayjs from "dayjs";

export const selectAllOrders = createSelector(
  (state: IGlobalState) => state.appState.logistics.orders,
  (orders): IFullOrderDto[] => orders.data
);

export const selectAllOrdersWithDrivers = createSelector(
  (state: IGlobalState) => state.appState.logistics.orders,
  (state: IGlobalState) => state.appState.logistics.drivers,
  (orders, drivers): IMapOrder[] =>
    orders.data.map((order) => ({
      ...order,
      driver: drivers.data.find((x) => x.employeeId === order.employeeFk),
    }))
);

export const selectFilteredOrders = createSelector(
  (state: IGlobalState) => state.appState.logistics.orders,
  (state: IGlobalState) => state.appState.logistics.drivers,
  (state: IGlobalState) => state.appState.logistics.filters,
  (orders, drivers, filters): IMapOrder[] =>
    orders.data
      .filter((order) => {
        const citiesFilter = filters.global.city;
        const deliveryTypesFilter = filters.global.deliveryType;

        if (citiesFilter.length === 0 && deliveryTypesFilter.length === 0) {
          return true;
        }

        // if (citiesFilter.includes(order.cityFk)) {
        //   return true;
        // }

        if (deliveryTypesFilter.includes(order.deliveryTypeFK)) {
          return true;
        }

        return false;
      })
      .sort((a, b) =>
        dayjs.utc(b.pickUpTime).local().diff(dayjs.utc(a.pickUpTime).local(), "milliseconds") > 0
          ? -1
          : 1
      )
      .map((order) => ({
        ...order,
        driver: drivers.data.find((x) => x.employeeId === order.employeeFk),
      }))
);

export const selectProblemOrdersUnassigned = createSelector(selectFilteredOrders, (orders) =>
  orders.filter(
    (order) =>
      order.orderStatus === OrderStatus.Paused ||
      order.orderStatus === OrderStatus.ResetByDispatch ||
      order.orderStatus === OrderStatus.HandleByDispatch ||
      // order.orderStatus === OrderStatus.RejectByDriver ||
      order.orderStatus === OrderStatus.WaitingForProcessing ||
      order.orderStatus === OrderStatus.Processing // TODO: This is temporary, show full route for drivers
  )
);

export const selectProblemOrdersRejected = createSelector(selectFilteredOrders, (orders) =>
orders.filter(
  (order) =>
  order.orderStatus === OrderStatus.RejectByDriver
  )
);

export const selectProblemOrdersUnconfirmed = createSelector(selectFilteredOrders, (orders) =>
  orders.filter((order) => order.orderStatus === OrderStatus.WaitingForAccept)
);

export const selectProblemOrdersUnconfirmedGrouped = createSelector(
  selectProblemOrdersUnconfirmed,
  (orders) => {
    const groupedOrders = orders.reduce((acc, order) => {
      const packageGuid = order.packageGuid;
      if (acc[packageGuid]) {
        acc[packageGuid].push(order);
      } else {
        acc[packageGuid] = [order];
      }
      return acc;
    }, {});
  }
);

export const selectOrderById = ({ appState }: IGlobalState, orderId: number) => {
  return appState.logistics.orders.data.find((order) => order.id === orderId);
};

export const selectOrderWithDriverById = (
  { appState }: IGlobalState,
  orderId: number
): IMapOrder => {
  const order = appState.logistics.orders.data.find((x) => x.id === orderId);
  const driver = appState.logistics.drivers.data.find((x) => x.employeeId === order.employeeFk);

  return { ...order, driver };
};

export const selectNumberOfActiveOrders = (state: IGlobalState) => {
  return state.appState.logistics.orders.data.length;
};
