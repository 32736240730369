import React, { FC, useState, useEffect, memo } from "react";
import {
  createStyles,
  makeStyles,
  Paper,
  Theme,
  Button,
} from "@material-ui/core";
import LeafletMap from "../../../../../../shared/components/Map/LeafletMap/LeafletMap";
import { IAreaType } from "../Area";
import {
  GeoJSON,
  FeatureGroup,
  LayersControl,
  Marker,
  Popup,
} from "react-leaflet";
import axios, { AxiosError } from "axios";
import { Icon } from "leaflet";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    map: {
      width: "100%",
      height: "90vh",
    },
  })
);

const RestaurantLogoIcon = (url: string) =>
  new Icon({
    iconUrl: url,
    iconSize: [36, 36],
    iconAnchor: [18, 36],
  });

export interface AreaOverviewProps {
  areas: IAreaType[];
  onClick: (id: number) => void;
}

const AreaOverview: FC<AreaOverviewProps> = ({ areas, onClick }) => {
  const classes = useStyles();

  // const [restaurants, setRestaurants] = useState({ skopje: [], ohrid: [] });
  // useEffect(() => {
  //   //=========================================================================================
  //   // THIS SHOLDNT E HERE
  //   //
  //   const getRestaurants = (url: string, authToken: string) => {
  //     return axios
  //       .get(url, {
  //         headers: {
  //           Authorization: `Bearer ${authToken}`,
  //         },
  //       })
  //       .catch((error: AxiosError) => {
  //       });
  //   };

  //   const getAll = async () => {
  //     const resultSkopje = await getRestaurants(
  //       "https://www.kliknijadi.mk/api/Restaurants/Get?apiKey=1e857bcb36",
  //       "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6IjU4ODA2IiwibmJmIjoxNjEwMzMwMjA3LCJleHAiOjE2MTI5MjIyMDcsImlhdCI6MTYxMDMzMDIwN30.uBXAjgQLMII7hlmqvCekVXc5KV5o3Y3uuVtuQEXSTgc"
  //     );
  //     const resultOhrid = await getRestaurants(
  //       "https://ohrid.kliknijadi.mk/api/Restaurants/Get?apiKey=1e857bcb36",
  //       "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6IjUwMjQ2IiwibmJmIjoxNjEyNzYzNTU4LCJleHAiOjE2MTUzNTU1NTgsImlhdCI6MTYxMjc2MzU1OH0.-oQsdtiLwQdkqsP9t1s_1UDMTHUgSs6oyJfQB_ncqiE"
  //     );

  //     setRestaurants({
  //       skopje: resultSkopje ? resultSkopje.data.Restaurants : [],
  //       ohrid: resultOhrid ? resultOhrid.data.Restaurants : [],
  //     });
  //   };
  //   getAll();
  // }, []);

  return (
    <Paper className={classes.map}>
      <LeafletMap>
        <LayersControl>
          {areas.map((area) => (
            <LayersControl.Overlay checked name={area.name}>
              <FeatureGroup>
                <GeoJSON
                  key={area.id}
                  data={JSON.parse(area.polygonData)}
                  style={{ color: area.color || "red" }}
                >
                  <Popup>
                    <p>
                      <b>Име:</b> {area.name}
                    </p>

                    <p>
                      <b>Град:</b> {area.cityFK}
                    </p>

                    <Button
                      variant="contained"
                      fullWidth
                      onClick={() => onClick && onClick(area.id)}
                    >
                      Промени
                    </Button>
                  </Popup>
                </GeoJSON>
              </FeatureGroup>
            </LayersControl.Overlay>
          ))}

          {/* <LayersControl.Overlay name="Ресторани - Скопје">
            <Restaurants
              restaurants={restaurants.skopje}
              logoBaseURL="https://www.kliknijadi.mk"
            />
          </LayersControl.Overlay>

          <LayersControl.Overlay name="Ресторани - Охрид">
            <Restaurants
              restaurants={restaurants.ohrid}
              logoBaseURL="https://ohrid.kliknijadi.mk"
            />
          </LayersControl.Overlay> */}
        </LayersControl>
      </LeafletMap>
    </Paper>
  );
};

// interface RestaurantsProps {
//   restaurants: any[];
//   logoBaseURL: string;
// }

// const Restaurants: FC<RestaurantsProps> = ({ restaurants, logoBaseURL }) => {
//   return (
//     <FeatureGroup>
//       {restaurants.map((restaurant) => (
//         <Marker
//           key={`${restaurant.Restaurant.Name}_${restaurant.Restaurant.ID}`}
//           position={[
//             restaurant.Restaurant.Latitude,
//             restaurant.Restaurant.Longitude,
//           ]}
//           icon={RestaurantLogoIcon(logoBaseURL + restaurant.Restaurant.Logo)}
//           title={restaurant.Restaurant.Name}
//         >
//           <Popup>
//             {[
//               restaurant.Restaurant.Latitude,
//               restaurant.Restaurant.Longitude,
//             ].join(" ")}
//           </Popup>
//         </Marker>
//       ))}
//     </FeatureGroup>
//   );
// };

export default memo(AreaOverview);
