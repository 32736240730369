import EmployeeTable from "./components/EmployeeTable";
import { NewEmployeeSchema } from "./schemas/NewEmployeeSchema";
import { Button, Typography, makeStyles } from "@material-ui/core";
import CollapsableAlert from "@shared/components/Alert/CustomAlert";
import { IEmployeeDto } from "@shared/services/employees/dtos/employeeDto";
import {
  createEmployee,
  deleteEmployee,
  getEmployees,
  updateEmployee,
} from "@shared/services/employees/employees.service";
import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useSnackbar } from "notistack";
import { FC, memo, useEffect, useState } from "react";

const useStyles = makeStyles((theme) => ({
  alert: {
    marginBottom: theme.spacing(1),
  },
  employeeGrid: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, 200px)",
  },
}));

const EmployeeViewPage: FC = () => {
  const classes = useStyles();
  const { data: employeeData, isLoading, error, refetch } = useQuery(["employees"], getEmployees);

  const [isInitializing, setInitializing] = useState<boolean>(true);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    // Wait for the base types to load
    // TODO: Refactor this to use the redux data loading status
    setTimeout(() => {
      setInitializing(false);
    }, 200);
  }, []);

  const handleDelete = async (item: IEmployeeDto) => {
    if (!item?.id) {
      return;
    }

    return await deleteEmployee(item.id)
      .then(() => {
        enqueueSnackbar("Успешно го избришавте вработениот", {
          variant: "success",
          autoHideDuration: 6000,
        });

        refetch();
      })
      .catch((error: AxiosError) => {
        enqueueSnackbar(`Не успеа да се отстрани вработениот: ${error.message}`, {
          variant: "error",
          persist: true,
          preventDuplicate: true,
          anchorOrigin: { horizontal: "center", vertical: "bottom" },
          action: function onDismiss(key) {
            return <Button onClick={() => closeSnackbar(key)}>OK</Button>;
          },
        });
      });
  };

  const handleUpdate = async (employee: IEmployeeDto) => {
    if (!employee?.id) {
      return;
    }

    return await updateEmployee(employee.id, employee)
      .then(() => {
        enqueueSnackbar("Успешно ги запишавте податоците", {
          variant: "success",
          autoHideDuration: 6000,
        });
        refetch();
      })
      .catch((err) => {
        enqueueSnackbar(`Неуспешно ги запишавте податоците: ${err.message}`, {
          variant: "error",
          persist: true,
          preventDuplicate: true,
          anchorOrigin: { horizontal: "center", vertical: "bottom" },
          action: function onDismiss(key) {
            return <Button onClick={() => closeSnackbar(key)}>OK</Button>;
          },
        });
      });
  };

  const handleAdd = async (employee: IEmployeeDto) => {
    if (!employee) {
      return;
    }

    // Validate before submitting
    try {
      NewEmployeeSchema.validateSync(employee, { stripUnknown: true });
    } catch (e: any) {
      enqueueSnackbar(`Валидацијата не успеа: ${e?.message}`, {
        variant: "error",
        persist: true,
        preventDuplicate: true,
        anchorOrigin: { horizontal: "center", vertical: "bottom" },
        action: function onDismiss(key) {
          return <Button onClick={() => closeSnackbar(key)}>OK</Button>;
        },
      });
      return;
    }

    return await createEmployee(employee)
      .then(() => {
        enqueueSnackbar("Успешно ги запишавте податоците", {
          variant: "success",
          autoHideDuration: 6000,
        });
        refetch();
      })
      .catch((err) => {
        enqueueSnackbar(`Неуспешно ги запишавте податоците: ${err.message}`, {
          variant: "error",
          persist: true,
          preventDuplicate: true,
          anchorOrigin: { horizontal: "center", vertical: "bottom" },
          action: function onDismiss(key) {
            return <Button onClick={() => closeSnackbar(key)}>OK</Button>;
          },
        });
      });
  };

  const employeesWithoutUsers = employeeData?.filter((employee) => !employee.userFK);

  return (
    <>
      {isInitializing || isLoading ? (
        <Typography align="center" variant="h6">
          Се вчитува
        </Typography>
      ) : error ? (
        <Typography align="center" variant="h6" color="error">
          Грешка: {(error as Error).message}
        </Typography>
      ) : (
        <>
          {employeesWithoutUsers?.length > 0 && (
            <CollapsableAlert
              severity="warning"
              title="Следните вработени немаат корисници:"
              className={classes.alert}
            >
              <ul className={classes.employeeGrid}>
                {employeesWithoutUsers.map((employee) => (
                  <li key={employee.id}>
                    {employee.firstName} {employee.lastName}
                  </li>
                ))}
              </ul>
            </CollapsableAlert>
          )}

          <EmployeeTable
            employeeData={employeeData}
            onAdd={handleAdd}
            onDelete={handleDelete}
            onUpdate={handleUpdate}
            onRefresh={() => refetch()}
          />
        </>
      )}
    </>
  );
};

export default memo(EmployeeViewPage);
