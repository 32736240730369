import { Box, Button, Dialog, DialogContent, DialogTitle, DialogContentText, DialogActions, Typography, createStyles, makeStyles, Divider } from "@material-ui/core";
import { OpenInNew } from "@material-ui/icons";
import { FC, useState } from "react";


const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
      minWidth: "100%",
      justifyContent: "space-between",
      textTransform: "lowercase",
    },
    earnings: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  })
);

interface RoutePointSummaryItemProps {
  item: any;
  index: number;
}

const RoutePointSummaryItem: FC<RoutePointSummaryItemProps> = ({
  item = null,
  index = 0,
}) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const classes = useStyles();
  const parseLines = (value) => value.replace(/(\\n)/g, "\n");

  const routeNumber = index + 1;

  return (
    <div>
      <Button size="large" className={classes.root} onClick={handleClickOpen}>
        <Divider variant="inset" component="button" />
        <Typography>Рута {routeNumber}</Typography>
        <Typography variant="subtitle1">{item.totalKm} KM</Typography>
        <Typography variant="subtitle1" className={classes.earnings}>
          {item.totalEarnings} ден
        </Typography>
        <OpenInNew />
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Детали за рута " + routeNumber}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography style={{ whiteSpace: 'pre-line' }}>
              {item.displayString}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Затвори
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default RoutePointSummaryItem;
