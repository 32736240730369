import { Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import NetworkChangeListener from "@shared/components/NetworkChangeListener/NetworkChangeListener";
import { FC } from "react";
import { Outlet } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    paddingTop: "8%",
    height: "100vh",
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",

    backgroundImage: "url(/images/bg-fullpage.jpg)",
    backgroundSize: "cover",
  },
  paper: {
    display: "inline-block",
    padding: theme.spacing(1.5),
    paddingTop: theme.spacing(2.5),
    textAlign: "center",
    width: "min(420px, 100vw)",
  },
}));

const Layout: FC = () => {
  const classes = useStyles();

  return (
    <>
      <NetworkChangeListener />

      <main className={classes.content}>
        <Paper className={classes.paper} elevation={6} square>
          <Outlet />
        </Paper>
      </main>
    </>
  );
};

export default Layout;
