import { Paper, Typography, createStyles, makeStyles } from "@material-ui/core";
import { FC } from "react";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2),
    },
    description: {
      color: theme.palette.grey[400],
      textTransform: "lowercase",
    },
  })
);

interface PayrollOrdersProps {
  totalEarnings: number;
  totalKm: number;
  totalBonuses: number;
  totalPenalties: number;
  // totalPickUps: number;
  // totalDropOffs: number;
}

const PayrollDayDetails: FC<PayrollOrdersProps> = ({  totalEarnings = 0, totalKm = 0, totalBonuses = 0, totalPenalties = 0 }) => {
  const classes = useStyles();

  return (
    <Paper square className={classes.root}>
      <div style= {{display:"flex", justifyContent:"space-around", textAlign:"center"}}>
        <div>
          <Typography variant="h5">{totalEarnings}</Typography>
          <Typography className={classes.description}>заработка од нарачки</Typography>
        </div>
        <div>
          <Typography variant="h5">{totalBonuses}</Typography>
          <Typography className={classes.description}>бонуси</Typography>
        </div>
        <div>
          <Typography variant="h5">{totalPenalties}</Typography>
          <Typography className={classes.description}>пенали</Typography>
        </div>
        <div>
          <Typography variant="h5">{totalKm}</Typography>
          <Typography className={classes.description}>KM</Typography>
        </div>
        {/* <div>
          <Typography variant="h5">{totalPickUps}</Typography>
          <Typography className={classes.description}>подигања</Typography>
        </div>
        <div>
          <Typography variant="h5">{totalDropOffs}</Typography>
          <Typography className={classes.description}>достави</Typography>
        </div> */}
        
      </div>
    </Paper>
  );
};

export default PayrollDayDetails;
