import {
  DirectionsCar,
  DirectionsBike,
  Motorcycle,
  LocalShipping,
  AirportShuttle,
  HelpOutline,
} from "@material-ui/icons";

const categories = [
  {
    tags: ["автомобил", "кола"],
    icon: DirectionsCar,
  },
  {
    tags: ["велосипед", "точак"],
    icon: DirectionsBike,
  },
  {
    tags: ["мотор"],
    icon: Motorcycle,
  },
  {
    tags: ["пикап", "камион"],
    icon: LocalShipping,
  },
  {
    tags: ["комбе"],
    icon: AirportShuttle,
  },
];

type FontSize = "medium" | "small" | "inherit" | "large";

export const getVehicleIcon = (vehicleTypeName: string, fontSize: FontSize = "medium") => {
  const name = vehicleTypeName?.toLowerCase?.() || "";

  const category = categories.find((x) => x.tags.some((tag) => name.includes(tag.toLowerCase())));

  const Icon = category?.icon || HelpOutline;
  return <Icon fontSize={fontSize} />;
};
