import CarIcon from "@assets/icons/vehicles/car.svg?react";
import BikeIcon from "@assets/icons/vehicles/bike.svg?react";
import MotorcycleIcon from "@assets/icons/vehicles/motorcycle.svg?react";
import TruckIcon from "@assets/icons/vehicles/truck.svg?react";
import VanIcon from "@assets/icons/vehicles/van.svg?react";

const categories = [
  {
    tags: ["автомобил", "кола"],
    icon: CarIcon,
  },
  {
    tags: ["велосипед", "точак"],
    icon: BikeIcon,
  },
  {
    tags: ["мотор"],
    icon: MotorcycleIcon,
  },
  {
    tags: ["пикап", "камион"],
    icon: TruckIcon,
  },
  {
    tags: ["комбе"],
    icon: VanIcon,
  },
];

export const getVehicleIconSVG = (vehicleTypeName: string, isFreeOnlinePayment: boolean) => {
  const name = vehicleTypeName?.toLowerCase?.() || "";

  const category = categories.find((category) =>
    category.tags.some((tag) => name.includes(tag.toLowerCase()))
  );
  const Icon = category?.icon;

  if (!Icon) {
    return null;
  }

  if (isFreeOnlinePayment) {
    return <Icon fill="#FFFFFF" />;
  }

  return <Icon />;
};

