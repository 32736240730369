import { Icon } from "leaflet";

export const redMarkerIcon = new Icon({
  iconUrl: "/images/icons/markers/marker-icon-red.png",
  iconSize: [20, 32],
  iconAnchor: [10, 32],
});

export const greenMarkerIcon = new Icon({
  iconUrl: "/images/icons/markers/marker-icon-green.png",
  iconSize: [20, 32],
  iconAnchor: [10, 32],
});

export const blueMarkerIcon = new Icon({
  iconUrl: "/images/icons/marker-icon.png",
  iconSize: [20, 32],
  iconAnchor: [10, 32],
});
