import { GridColDef, GridValidRowModel } from "@mui/x-data-grid";
import { Table, WeekDay, Hour, WorkingHours } from "../../../genericTypes";
import type { EmployeeWorkDay, SingleEmployeeWorkWeek } from "../types";
import { workingStatusToMacedonian } from "@App/Scheduler/utils/translateWorkingStatus";
import dayjs from "dayjs";
import { date } from 'yup';

type ResourceObject = {
  date: string;
  id: string;
};
type RowDefinition = {
  employeeId: string;
  infoCol: string;
  [date: string]: string | ResourceObject[]
  totalWorkHours: string;
};

const days: WeekDay[] = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

function parseEmployeeWorkTime(day: EmployeeWorkDay): string {
  if (typeof day !== "undefined") {
    if (day.workingStatus === "True") {
      return `${day.workTimes.startTime} | ${day.workTimes.endTime}`;
    } else return workingStatusToMacedonian(day.workingStatus);
  }
  else return "";
}

function calculateWorkingHours(workWeek: EmployeeWorkDay): string {
  let totalHours = 0;

  Object.values(workWeek)
  if (workWeek.workingStatus === "True") {
    const start: number = parseInt(workWeek.workTimes.startTime.split(":")[0]);
    const end: number = parseInt(workWeek.workTimes.endTime.split(":")[0]);

    totalHours += end - start;
  }


  return totalHours.toString();
}


function employeeWeekToTable(workWeek: SingleEmployeeWorkWeek[]): Table {
  if (!workWeek || workWeek.length === 0) {
    return { rows: [], columns: [] };
  }

  const firstItemWorkWeek = workWeek[0]?.workWeek;
  if (!firstItemWorkWeek) {
    return { rows: [], columns: [] };
  }

  const dates: string[] = Array.from(
    new Set(
      workWeek.flatMap((employeeWorkWeek) =>
        employeeWorkWeek.workWeek.map((employeeWorkDay) => employeeWorkDay.date)
      )
    )
  ).sort((a, b) => new Date(a).getTime() - new Date(b).getTime());
  console.log(dates);
  const columns: GridColDef[] = [
    {
      field: "infoCol",
      headerName: "",
      width: 220,
      cellClassName: "cols-pin shadow driver-data-cell",
    },
    ...dates.map((day) => ({
      field: day,
      headerName: "",
      width: 180,
      cellClassName: "driver-data-cell pointer",
    })),   
    {
      field: "totalWorkHours",
      headerName: "",
      width: 110,
      cellClassName: "driver-data-cell total-hours",
    },
  ];
  const rows: GridValidRowModel[] = [];

  const customHeader: RowDefinition[] = [
    {
      infoCol: "Датум",
      employeeId: "0",
      ...dates.reduce((acc, date) => ({ ...acc, [date]: dayjs(date).format('DD.MM.YYYY') }), {}),
      totalWorkHours: "Вкупно",
    },
    {
      infoCol: "Ден",
      employeeId: "0",
      ...dates.reduce((acc, date) => ({ ...acc, [date]: dayjs(date).format('dddd') }), {}),
      totalWorkHours: "",
    },
  ];
  const rowData: RowDefinition[] = [];
  workWeek.forEach((emp) => { 
    
      const recordIds: ResourceObject[] = emp.workWeek.map(day => ({
        date: day.date,
        id: day.workTimes.id.toString(),
      }));   
    rowData.push({
      infoCol: emp.employee,
      employeeId: emp.employeeId, 
      ...dates.reduce((acc, date) => ({ ...acc, [date]: parseEmployeeWorkTime(emp.workWeek.find(x => x.date == date)) }), {}),
      totalWorkHours: emp.totalWorkingHours.toString(),
      recordId:recordIds
    });
  });
  rows.push(...customHeader);
  rows.push(...rowData);

  return { rows, columns };
}

export default employeeWeekToTable;

