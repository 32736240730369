import { Grid, Icon, Link, Typography, createStyles, makeStyles } from "@material-ui/core";
import {
  AlarmOn,
  Battery20Outlined,
  Battery50Outlined,
  Battery80Outlined,
  Email,
  LocationOnOutlined,
  
  Phone,
} from "@material-ui/icons";
import { useMemoSelector } from "@shared/hooks";
import { IDriverDto } from "@shared/services/drivers/dtos/driverDto";
import { getDisplayValue } from "@shared/utils/getDisplayValue";
import { getVehicleIcon } from "@shared/utils/getVehicleIcon";
import dayjs from "dayjs";
import { FC, ReactElement } from "react";
import { Popup } from "react-leaflet";

const useStyles = makeStyles((theme) =>
  createStyles({
    popup: {
      textAlign: "center",
    },
    image: {
      height: "5rem",
      width: "5rem",
      margin: "auto",
    },
    section: {
      marginBottom: theme.spacing(2),
    },
    sectionTitle: {
      display: "block",
      fontWeight: "bold",
      marginBottom: theme.spacing(1),
    },
  })
);

interface TextWithIconProps {
  icon: ReactElement;
  title?: string;
}

const TextWithIcon: FC<TextWithIconProps> = ({ icon, title, children }) => (
  <Grid
    container
    alignItems="center"
    justifyContent="center"
    spacing={1}
    wrap="nowrap"
    title={title}
  >
    <Grid item>{icon}</Grid>
    <Grid xs item>
      <Typography variant="body2" component="span">
        {children}
      </Typography>
    </Grid>
  </Grid>
);

export interface DriverMarkerPopupProps {
  driver: IDriverDto;
}

const DriverMarkerPopup: FC<DriverMarkerPopupProps> = ({ driver }) => {
  const classes = useStyles();

  const vehicleTypes = useMemoSelector((state) => state.sharedState?.vehicleTypes?.data);

  return (
    <Popup className={classes.popup}>
      {/* <Avatar
        src={driver.imageSrc}
        alt={`${driver.firstName} ${driver.lastName}`}
        className={classes.image}
      >{`${driver.firstName[0]} ${driver.lastName[0]}`}</Avatar> */}
      <Typography
        align="center"
        variant="h6"
        component="p"
      >{`${driver.firstName} ${driver.lastName}`}</Typography>

      <div className={classes.section}>
        {/* <TextWithIcon icon={<Person color="primary" />} title="Идентификациски број">
          {driver.userId}
        </TextWithIcon> */}

        <Link href={`mailto:${driver.email}`}>
          <TextWithIcon icon={<Email color="primary" />}>{driver.email}</TextWithIcon>
        </Link>

        <Link href={`tel:${driver.phoneNumber}`}>
          <TextWithIcon icon={<Phone color="primary" />}>{driver.phoneNumber}</TextWithIcon>
        </Link>
      </div>

      <div className={classes.section}>
        <Typography variant="body1" component="span" className={classes.sectionTitle}>
          Смена
        </Typography>
        <TextWithIcon icon={<AlarmOn color="primary" />} title="Почеток на смена">
          {dayjs.utc(driver.workShiftStartTime).local().format("DD.MM.YYYY HH:mm")}
        </TextWithIcon>
        <TextWithIcon
          icon={
            <Icon color="primary">
              {getVehicleIcon(getDisplayValue(vehicleTypes, driver.vehicleTypeFk))}
            </Icon>
          }
          title="Тип на возило"
        >
          {getDisplayValue(vehicleTypes, driver.vehicleTypeFk)}
        </TextWithIcon>
      </div>
      <div>
        <Typography variant="body1" component="span" className={classes.sectionTitle}>
          Последно примен сигнал
        </Typography>
        <TextWithIcon icon={<LocationOnOutlined color="primary" />}>
          {driver.recievedOn
            ? dayjs.utc(driver.recievedOn).local().format("DD.MM.YYYY HH:mm")
            : "-"}
        </TextWithIcon>
        {driver.batteryPercentage <= 20 ? (
          <TextWithIcon icon={<Battery20Outlined color="error" />}>
            {driver.batteryPercentage}%
          </TextWithIcon>
        ) : driver.batteryPercentage > 20 && driver.batteryPercentage <= 50 ? (
          <TextWithIcon icon={<Battery50Outlined color="secondary" />}>
            {driver.batteryPercentage}%
          </TextWithIcon>
        ) : (
          <TextWithIcon icon={<Battery80Outlined color="primary" />}>
            {driver.batteryPercentage}%
          </TextWithIcon>
        )}
      </div>
    </Popup>
  );
};

export default DriverMarkerPopup;
