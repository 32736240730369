import {
  Avatar,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import { Storefront } from "@material-ui/icons";
import { IMapOrder } from "@shared/components/Map/models/models";
import OrderLogCompactTable from "@shared/components/OrderLogCompactTable/OrderLogCompactTable";
import { useMemoSelector } from "@shared/hooks";
import { OrderStatus } from "@shared/services/order.service";
import { translateOrderStatus } from "@shared/services/orders/orders.service";
import { cutString } from "@shared/utils/cutString";
import dayjs from "dayjs";
import { FC, memo } from "react";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      "& th, & td": {
        padding: "0 5px",
        fontSize: "12px",
      },
    },
    redText: {
      color: "red",
    },

    image: {
      height: "10rem",
      width: "10rem",
      margin: "auto",
    },
    sectionTitle: {
      marginBottom: theme.spacing(2),
      display: "block",
    },
    button: {
      margin: "0 4px",
      fontSize: "10px",
      padding: "2px 4px",
    },
  })
);

export interface Props {
  order: IMapOrder;
}

const OrderTooltipContent: FC<Props> = ({ order }) => {
  const classes = useStyles();

  const { deliveryTypes, paymentTypes } = useMemoSelector(({ sharedState }) => ({
    deliveryTypes: sharedState.deliveryTypes?.data,
    paymentTypes: sharedState.paymentTypes?.data,
  }));

  const pickupTime = dayjs.utc(order.pickUpTime).local();
  const dropOffTime = dayjs.utc(order.dropOffTime).local();

  return (
    <>
      {order ? (
        <>
          <Grid spacing={2} container>
            <Grid md={12} item>
              <div className={classes.sectionTitle}>
                <Typography variant="body1" component="span">
                  Детали за нарачка
                </Typography>
              </div>
              <TableContainer className={classes.root}>
                <Table stickyHeader={true} size="small" aria-label="collapsible table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left" key="orderStatus">
                        Статус
                      </TableCell>
                      <TableCell align="left" key="externalOrderNumber">
                        Број на нарачка
                      </TableCell>
                      <TableCell align="left" key="employeeName">
                        Возач
                      </TableCell>
                      {/* <TableCell align="left" key="clientID">
                        Клиент
                      </TableCell> */}
                      <TableCell align="left" key="partnerID">
                        Партнер
                      </TableCell>
                      {/* <TableCell align="left" key="customerNumber">
                        Корисник
                      </TableCell> */}
                      <TableCell align="left" key="pickUpAddress">
                        Подигање
                      </TableCell>
                      <TableCell align="left" key="dropOffAddress">
                        Достава
                      </TableCell>
                      <TableCell align="left" key="pickUpTime">
                        Од
                      </TableCell>
                      <TableCell align="left" key="dropOffTime">
                        До
                      </TableCell>
                      <TableCell align="left" key="paymentTypeFk">
                        Плаќање
                      </TableCell>
                      <TableCell align="left" key="deliveryTypeFk">
                        Достава
                      </TableCell>
                      <TableCell align="center" key="removeOrder">
                        &nbsp;
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>{translateOrderStatus(order.orderStatus)}</TableCell>
                      {order.displayOrderNumber ? (
                        <TableCell title={order.displayOrderNumber}>
                          {order.displayOrderNumber}
                        </TableCell>
                      ) : (
                        <TableCell title={order.externalOrderNumber}>
                          {cutString(order.externalOrderNumber, 3)}
                        </TableCell>
                      )}
                      <TableCell
                        className={
                          order?.orderStatus === OrderStatus.WaitingForAccept ? classes.redText : ""
                        }
                      >
                        {order?.driver
                          ? `${order?.driver?.firstName} ${order?.driver?.lastName}`
                          : "N/A"}
                      </TableCell>

                      {/* <TableCell>{translateExternalSystem(parseInt(order?.clientID))}</TableCell> */}
                      <TableCell
                        style={{
                          display: order?.partnerImageSrc ? "flex" : "table-cell",
                          alignItems: "center",
                          gap: "0.5em",
                        }}
                      >
                        {order?.partnerImageSrc && (
                          <Avatar
                            src={order.partnerImageSrc}
                            style={{ width: "30px", height: "30px" }}
                            title={order.partnerName}
                          >
                            <Storefront />
                          </Avatar>
                        )}
                        {order.partnerName}
                      </TableCell>
                      {/* <TableCell>
                        {order.customerNumber} - {order.customerName}
                      </TableCell> */}
                      <TableCell>{order.pickUpAddress}</TableCell>
                      <TableCell>{order.dropOffAddress}</TableCell>
                      <TableCell title={order?.pickUpTime && pickupTime.format("DD.MM.YYYY HH:mm")}>
                        {order.pickUpTime ? pickupTime.format("HH:mm") : "N/A"}
                      </TableCell>
                      <TableCell
                        title={order?.dropOffTime && dropOffTime.format("DD.MM.YYYY HH:mm")}
                      >
                        {order.dropOffTime ? dropOffTime.format("HH:mm") : "N/A"}
                      </TableCell>
                      <TableCell>
                        {paymentTypes?.find((x) => x.id === order.paymentTypeFK)?.name}
                      </TableCell>
                      <TableCell>
                        {deliveryTypes?.find((x) => x.id === order.deliveryTypeFK)?.name}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>

              <br />
              {order.displayOrderNumber ? (
                <OrderLogCompactTable
                  orderId={order.id}
                  displayOrderNumber={order.displayOrderNumber}
                />
              ) : (
                <OrderLogCompactTable
                  orderId={order.id}
                  externalOrderNumber={order.externalOrderNumber}
                  displayOrderNumber={order.displayOrderNumber}
                />
              )}
            </Grid>
          </Grid>
        </>
      ) : null}
    </>
  );
};

export default memo(OrderTooltipContent);

