import httpService from "../http.service";
import { IDeliveryTypeDto } from "./dtos/delivery-types/deliveryTypeDto";
import { IDeliveryTypeRequestDto } from "./dtos/delivery-types/deliveryTypeRequestDto";

export const getAllDeliveryTypes = () => {
  return httpService.get<IDeliveryTypeDto[]>("/api/delivery-types");
};

export const getDeliveryType = (id: number) => {
  return httpService.get<IDeliveryTypeDto>(`/api/delivery-types/${id}`);
};

export const addNewDeliveryType = (request: IDeliveryTypeRequestDto) => {
  return httpService.post<IDeliveryTypeDto, IDeliveryTypeRequestDto>(
    `/api/delivery-types`,
    request
  );
};

export const updateDeliveryType = (id: number, request: IDeliveryTypeRequestDto) => {
  return httpService.put<IDeliveryTypeDto, IDeliveryTypeRequestDto>(
    `/api/delivery-types/${id}`,
    request
  );
};

export const removeDeliveryType = (id: number) => {
  return httpService.delete<void>(`/api/delivery-types/${id}`);
};
