import { status } from "../../store";
import { ICityDto } from "../services/settings/dtos/cities/cityDto";
import { IDeliveryTypeDto } from "../services/settings/dtos/delivery-types/deliveryTypeDto";
import { IEmployeePositionDto } from "../services/settings/dtos/employee-positions/employeePositionDto";
import { IPaymentTypeDto } from "../services/settings/dtos/payment-types/paymentTypeDto";
import { IVehicleTypeDto } from "../services/settings/dtos/vehicle-types/vehicleTypeDto";
// import { IEmployee } from '@shared/services/employees.service';
import {
  getAllOrderRejectReasonTypesAsyncThunk,
  getCitiesThunkAsync,
  getDeliveryTypesAsyncThunk,
  getEmployeePositionTypesAsyncThunk,
  getPaymentTypesAsyncThunk, // getEmployeesAsyncThunk,
  getVehicleTypesAsyncThunk,
} from "./async-thunks";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IOrderRejectReasonDto } from "@shared/services/orders/dtos/order-reject-reason/orderRejectReasonDto";

interface IBaseType<T> {
  status: status;
  error: string;
  data: T;
}

export interface ISharedState {
  // employees: IBaseType<IEmployee[]>;
  cityTypes: IBaseType<ICityDto[]>;
  vehicleTypes: IBaseType<IVehicleTypeDto[]>;
  deliveryTypes: IBaseType<IDeliveryTypeDto[]>;
  paymentTypes: IBaseType<IPaymentTypeDto[]>;
  employeePositionTypes: IBaseType<IEmployeePositionDto[]>;
  orderRejectReasons: IBaseType<IOrderRejectReasonDto[]>;
}

const initialState: ISharedState = {
  // employees: {
  //     status: "idle",
  //     error: "",
  //     data: []
  // },
  cityTypes: {
    status: "loading",
    error: "",
    data: [],
  },
  vehicleTypes: {
    status: "idle",
    error: "",
    data: [],
  },
  deliveryTypes: {
    status: "idle",
    error: "",
    data: [],
  },
  paymentTypes: {
    status: "idle",
    error: "",
    data: [],
  },
  employeePositionTypes: {
    status: "idle",
    error: "",
    data: [],
  },
  orderRejectReasons: {
    status: "idle",
    error: "",
    data: [],
  },
};

function customPrepare<T>(data: T[]) {
  if (!data || (data && !Array.isArray(data))) {
    return { payload: [] };
  } else {
    return { payload: data };
  }
}

const sharedSlice = createSlice({
  name: "sharedState",
  initialState,
  reducers: {
    updateCityTypes: {
      reducer: (state, action: PayloadAction<ICityDto[]>) => {
        state.cityTypes.data = [...action.payload];
      },
      prepare: (data: ICityDto[]) => {
        return customPrepare<ICityDto>(data);
      },
    },
    updateVehicleTypes: {
      reducer: (state, action: PayloadAction<IVehicleTypeDto[]>) => {
        state.vehicleTypes.data = [...action.payload];
      },
      prepare: (data: IVehicleTypeDto[]) => {
        return customPrepare<IVehicleTypeDto>(data);
      },
    },
    updateDeliveryTypes: {
      reducer: (state, action: PayloadAction<IDeliveryTypeDto[]>) => {
        state.deliveryTypes.data = [...action.payload];
      },
      prepare: (data: IDeliveryTypeDto[]) => {
        return customPrepare<IDeliveryTypeDto>(data);
      },
    },
    updateEmployeePositionTypes: {
      reducer: (state, action: PayloadAction<IEmployeePositionDto[]>) => {
        state.employeePositionTypes.data = [...action.payload];
      },
      prepare: (data: IEmployeePositionDto[]) => {
        return customPrepare<IEmployeePositionDto>(data);
      },
    },
    updatePaymentTypes: {
      reducer: (state, action: PayloadAction<IPaymentTypeDto[]>) => {
        state.paymentTypes.data = [...action.payload];
      },
      prepare: (data: IPaymentTypeDto[]) => {
        return customPrepare<IPaymentTypeDto>(data);
      },
    },
    // updateEmployees: {
    //     reducer: (state, action: PayloadAction<IEmployee[]>) => {
    //         state.employees.data = [...action.payload];
    //     },
    //     prepare: (data: IEmployee[]) => {
    //         return customPrepare<IEmployee>(data);
    //     }
    // }
  },
  extraReducers: (builder) => {
    return (
      builder
        //Cities
        .addCase(getCitiesThunkAsync.fulfilled, (state, action) => {
          state.cityTypes = { status: "idle", error: "", data: [...action.payload] };
        })
        .addCase(getCitiesThunkAsync.pending, (state, action) => {
          state.cityTypes.status = "loading";
        })
        .addCase(getCitiesThunkAsync.rejected, (state, action) => {
          state.cityTypes.status = "failed";
          state.cityTypes.error = action.error.message;
        })

        //Payment types
        .addCase(getPaymentTypesAsyncThunk.fulfilled, (state, action) => {
          state.paymentTypes = { status: "idle", error: "", data: [...action.payload] };
        })
        .addCase(getPaymentTypesAsyncThunk.pending, (state, action) => {
          state.paymentTypes.status = "loading";
        })
        .addCase(getPaymentTypesAsyncThunk.rejected, (state, action) => {
          state.paymentTypes.status = "failed";
          state.paymentTypes.error = action.error.message;
        })

        //Vehicle types
        .addCase(getVehicleTypesAsyncThunk.fulfilled, (state, action) => {
          state.vehicleTypes = { status: "idle", error: "", data: [...action.payload] };
        })
        .addCase(getVehicleTypesAsyncThunk.pending, (state, action) => {
          state.vehicleTypes.status = "loading";
        })
        .addCase(getVehicleTypesAsyncThunk.rejected, (state, action) => {
          state.vehicleTypes.status = "failed";
          state.vehicleTypes.error = action.error.message;
        })

        //Delivery types
        .addCase(getDeliveryTypesAsyncThunk.fulfilled, (state, action) => {
          state.deliveryTypes = { status: "idle", error: "", data: [...action.payload] };
        })
        .addCase(getDeliveryTypesAsyncThunk.pending, (state, action) => {
          state.deliveryTypes.status = "loading";
        })
        .addCase(getDeliveryTypesAsyncThunk.rejected, (state, action) => {
          state.deliveryTypes.status = "failed";
          state.deliveryTypes.error = action.error.message;
        })

        //Employee position types
        .addCase(getEmployeePositionTypesAsyncThunk.fulfilled, (state, action) => {
          state.employeePositionTypes = { status: "idle", error: "", data: [...action.payload] };
        })
        .addCase(getEmployeePositionTypesAsyncThunk.pending, (state, action) => {
          state.employeePositionTypes.status = "loading";
        })
        .addCase(getEmployeePositionTypesAsyncThunk.rejected, (state, action) => {
          state.employeePositionTypes.status = "failed";
          state.employeePositionTypes.error = action.error.message;
        })

        // //Employees
        // .addCase(getEmployeesAsyncThunk.fulfilled, (state, action) => {
        //   state.employees = { status: "idle", error: "", data: [...action.payload] };
        // })
        // .addCase(getEmployeesAsyncThunk.pending, (state, action) => {
        //   state.employees.status = "loading";
        // })
        // .addCase(getEmployeesAsyncThunk.rejected, (state, action) => {
        //   state.employees.status = "failed";
        //   state.employees.error = action.error.message;
        // })

        //Order reject reasons
        .addCase(getAllOrderRejectReasonTypesAsyncThunk.fulfilled, (state, action) => {
          state.orderRejectReasons = { status: "idle", error: "", data: [...action.payload] };
        })
        .addCase(getAllOrderRejectReasonTypesAsyncThunk.pending, (state, action) => {
          state.orderRejectReasons.status = "loading";
        })
        .addCase(getAllOrderRejectReasonTypesAsyncThunk.rejected, (state, action) => {
          state.orderRejectReasons.status = "failed";
          state.orderRejectReasons.error = action.error.message;
        })
    );
  },
});

export const {
  updateCityTypes,
  updateDeliveryTypes,
  updateEmployeePositionTypes,
  updateVehicleTypes,
  updatePaymentTypes,
} = sharedSlice.actions;

export default sharedSlice.reducer;
