import DialogActions from "../../../../../../shared/components/Dialog/DialogActions";
import DialogContent from "../../../../../../shared/components/Dialog/DialogContent";
import DialogTitle from "../../../../../../shared/components/Dialog/DialogTitle";
import ErrorMessage from "../../../../../../shared/components/ErrorMessage/ErrorMessage";
import {
  createEmployeePosition,
  updateEmployeePosition,
} from "../../../../../../shared/services/settings/employee-positions.service";
import { IEmployeePositionType } from "../EmployeePosition";
import { NewEmployeePositionSchema } from "../schemas/NewEmployeePositionSchema";
import { Button, CircularProgress, Dialog, TextField } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { AxiosError } from "axios";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import React, { FC, SyntheticEvent, useEffect, useState } from "react";

const useStyles = makeStyles(() =>
  createStyles({
    dialogContent: {
      minWidth: "290px",
    },
    form: {
      width: "100%",
    },
  })
);

const initialValues = {
  name: "",
};

interface EmployeePositionFormTypeProps {
  title: string;
  addButtonText: string;
  open: boolean;
  row: IEmployeePositionType | null;
  onClose: () => void;
  onSuccess: () => void;
}

const EmployeePositionForm: FC<EmployeePositionFormTypeProps> = (props) => {
  const classes = useStyles();
  const { title, addButtonText, open, row, onClose, onSuccess } = props;

  const { enqueueSnackbar } = useSnackbar();

  const [error, setError] = useState("");

  const handleSubmit = (values: typeof initialValues) => {
    const APICall =
      row && row.id
        ? updateEmployeePosition(row.id, { ...row, ...values })
        : createEmployeePosition(values);

    APICall.then(() => {
      // Reset formik
      formik.setSubmitting(false);
      formik.resetForm();

      enqueueSnackbar("Успешно додадено", { variant: "success" });
      onSuccess();
      onClose();
    })
      .catch((error: AxiosError) => {
        formik.setSubmitting(false);

        // Show error message
        setError(error.message);
      })
      .catch((error: AxiosError) => {
        // Reset formik
        formik.setSubmitting(false);

        // Show error message
        setError(error.message);
      });
  };

  const {
    setValues,
    handleChange: handleFormikChange,
    ...formik
  } = useFormik({
    initialValues,
    validationSchema: NewEmployeePositionSchema,
    onSubmit: handleSubmit,
    enableReinitialize: false,
  });

  const handleClose = () => {
    formik.resetForm();
    setError("");

    if (onClose) onClose();
  };

  const handleChange = (event: SyntheticEvent) => {
    // Reset errors on input change
    if (error) setError("");

    handleFormikChange(event);
  };

  useEffect(() => {
    if (!row) return;

    const newInitialValues = {} as typeof initialValues;
    Object.keys(initialValues).forEach((key: string) => {
      if (key in row) {
        newInitialValues[key] = row[key];
      }
    });

    setValues(newInitialValues);
  }, [row, setValues]);

  return (
    <Dialog
      maxWidth={false}
      aria-labelledby="new-performance-bonus-type-dialog"
      open={open}
      onClose={handleClose}
    >
      <form className={classes.form} onSubmit={formik.handleSubmit}>
        <DialogTitle onClose={handleClose}>{title}</DialogTitle>

        <DialogContent className={classes.dialogContent} dividers>
          <TextField
            name="name"
            label="Позиција"
            variant="outlined"
            margin="normal"
            value={formik.values.name}
            onChange={handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            autoFocus
            fullWidth
            required
          />

          <ErrorMessage error={error} />
        </DialogContent>

        <DialogActions>
          {formik.isSubmitting ? (
            <CircularProgress size={25} />
          ) : (
            <Button type="submit" color="primary">
              {addButtonText}
            </Button>
          )}

          <Button onClick={handleClose}>Откажи</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default EmployeePositionForm;
