import PerformanceBonusForm from "../components/Bonus/PerformanceBonusForm";
import { Button, IconButton, TableCell } from "@material-ui/core";
import { Delete, Edit, Warning } from "@material-ui/icons";
import TableView from "@shared/components/Table/TableView";
import { ITableHeaderCell } from "@shared/components/Table/types/ITableHeaderCell";
import { useConfirmationDialog } from "@shared/context/ConfirmationDialogContext";
import { useMemoSelector } from "@shared/hooks";
import {
  getAllPerformanceBonusTypes,
  removePerformanceBonusType,
} from "@shared/services/salary-configuration/performance-bonuses.service";
import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useSnackbar } from "notistack";
import { FC, useCallback, useEffect, useState } from "react";

export interface IPerformanceBonusType {
  id: number;
  numberOfOrders: number;
  amount: number;
  cityFk: number;
  onEditClick?: (id: number) => void;
  onDeleteClick?: (id: number) => void;
}

const createPerformanceBonusType = (
  id: number,
  numberOfOrders: number,
  amount: number,
  cityFk: number,
  onEditClick?: (id: number) => void,
  onDeleteClick?: (id: number) => void
): IPerformanceBonusType => ({
  id,
  numberOfOrders,
  amount,
  cityFk,
  onEditClick,
  onDeleteClick,
});

const headerCells: ITableHeaderCell<IPerformanceBonusType>[] = [
  { id: "id", label: "ID" },
  { id: "numberOfOrders", label: "Број на нарачки" },
  { id: "amount", label: "Износ" },
  { id: "city", label: "Град" },
  { id: "editButton", label: "", disableSorting: true },
  { id: "deleteButton", label: "", disableSorting: true },
];

const createRow = (row: IPerformanceBonusType) => (
  <>
    <TableCell>{row.id}</TableCell>
    <TableCell>{row.numberOfOrders}</TableCell>
    <TableCell>{row.amount}</TableCell>
    <TableCell>{row.cityFk}</TableCell>
    <TableCell padding="checkbox">
      <IconButton size="small" onClick={() => (row.onEditClick ? row.onEditClick(row.id) : null)}>
        <Edit />
      </IconButton>
    </TableCell>
    <TableCell padding="checkbox">
      <IconButton
        size="small"
        onClick={() => (row.onDeleteClick ? row.onDeleteClick(row.id) : null)}
      >
        <Delete />
      </IconButton>
    </TableCell>
  </>
);

const PerformanceBonusPage: FC = () => {
  const {
    data: performanceBonusTypes,
    isLoading,
    isFetching,
    error,
    refetch,
  } = useQuery(["performanceBonusTypes"], getAllPerformanceBonusTypes, {
    refetchInterval: 15000,
  });

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const cityTypesState = useMemoSelector(({ sharedState }) => sharedState.cityTypes.data);

  useEffect(() => {
    if (!error || !(error instanceof Error)) return;

    enqueueSnackbar(`Се случи грешка: ${error.message}`, {
      variant: "error",
      persist: true,
      preventDuplicate: true,
      anchorOrigin: { horizontal: "center", vertical: "bottom" },
      action: function onDismiss(key) {
        return <Button onClick={() => closeSnackbar(key)}>OK</Button>;
      },
    });
  }, [error, enqueueSnackbar, closeSnackbar]);

  const confirmationDialog = useConfirmationDialog();

  const handleDeleteClick = (id: number) => {
    const performanceBonusType = performanceBonusTypes.find(
      (x: IPerformanceBonusType) => x.id === id
    );

    if (performanceBonusType) {
      confirmationDialog.open({
        icon: <Warning fontSize="large" style={{ color: "orange" }} />,
        title: "Избриши бонус",
        body: `Дали сте сигурни дека сакате да го избришете бонусот ${performanceBonusType.numberOfOrders} со ID ${performanceBonusType.id}?`,
        onConfirm: () => handleDelete(performanceBonusType.id),
        onDeny: () => confirmationDialog.close(),
      });
    }
  };

  const handleDelete = useCallback(
    (id: number) => {
      confirmationDialog.setLoading(true);

      removePerformanceBonusType(id)
        .then(() => {
          confirmationDialog.close();
          enqueueSnackbar("Успешно ја избришавте причината", {
            variant: "success",
            autoHideDuration: 6000,
          });

          refetch();
        })
        .catch((error: AxiosError) => {
          confirmationDialog.close();
          enqueueSnackbar(`Не успеа да се отстрани причината: ${error.message}`, {
            variant: "error",
            persist: true,
            preventDuplicate: true,
            anchorOrigin: { horizontal: "center", vertical: "bottom" },
            action: function onDismiss(key) {
              return <Button onClick={() => closeSnackbar(key)}>OK</Button>;
            },
          });
        });
    },
    [closeSnackbar, enqueueSnackbar, refetch]
  );

  const handleEditClick = (id: number) => {
    const performanceBonusType = performanceBonusTypes.find(
      (x: IPerformanceBonusType) => x.id === id
    );

    if (performanceBonusType) setSelectedRow(performanceBonusType);
  };

  const [selectedRow, setSelectedRow] = useState<IPerformanceBonusType | null>(null);
  const handleAddTypeClick = () => {
    setSelectedRow(createPerformanceBonusType(0, 0, 0, 0));
  };

  const handleAddTypeDialogClose = () => {
    setSelectedRow(null);
  };

  const getDisplayValue = (data: any, id: number) => {
    if (!data) return id;
    if (!Array.isArray(data)) return id;
    let item = data.find((x) => x.id == id);
    if (!item) return id;
    return item.name || item.id;
  };

  // Map data
  const rows: IPerformanceBonusType[] = [];
  if (performanceBonusTypes)
    performanceBonusTypes.forEach((x: IPerformanceBonusType) => {
      rows.push(
        createPerformanceBonusType(
          x.id,
          x.numberOfOrders,
          x.amount,
          getDisplayValue(cityTypesState, x.cityFk),
          handleEditClick,
          handleDeleteClick
        )
      );
    });

  return (
    <TableView
      title="Перформанс Бонуси"
      addButtonText="Додади бонус"
      onAddButtonClick={handleAddTypeClick}
      onRefreshClick={() => refetch()}
      headers={headerCells}
      rows={rows}
      render={createRow}
      isLoading={isLoading || isFetching}
      addDialog={
        <PerformanceBonusForm
          title={selectedRow?.id ? "Промени бонус" : "Додади бонус"}
          addButtonText={selectedRow?.id ? "Зачувај" : "Додади"}
          open={selectedRow ? true : false}
          row={selectedRow?.id ? selectedRow : null}
          onClose={handleAddTypeDialogClose}
          onSuccess={() => refetch()}
        />
      }
    />
  );
};

export default PerformanceBonusPage;
