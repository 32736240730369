import {
  Button,
  CircularProgress,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { AddCircle, Refresh, Warning } from "@material-ui/icons";
import SystemConnectionsFormRow from "@shared/components/SystemConnectionsFormRow/SystemConnectionsFormRow";
import { useConfirmationDialog } from "@shared/context/ConfirmationDialogContext";
import { ICreateConnectionRequestDto } from "@shared/services/auth/dtos/createConnectionRequestDto";
import { IExternalSystemConnectionDto } from "@shared/services/auth/dtos/externalSystemConnectionDto";
import { ExternalSystem } from "@shared/services/auth/enums/externalSystem";
import {
  addExternalConnection,
  getExternalConnectionsByUserId,
  removeExternalConnection,
  updateExternalConnection,
} from "@shared/services/auth/system-connections.service";
import { IEmployeeDto } from "@shared/services/employees/dtos/employeeDto";
import { useQuery } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { FC, memo, useState } from "react";

interface Props {
  employee: IEmployeeDto;
}

const EmployeeSystemConnectionsTab: FC<Props> = ({ employee }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const confirmationDialog = useConfirmationDialog();

  const [systemConnections, setSystemConnections] = useState<IExternalSystemConnectionDto[]>([]);
  const { error, refetch, isLoading, isFetching } = useQuery<IExternalSystemConnectionDto[], Error>(
    ["system-connections-", employee.id],
    () => getExternalConnectionsByUserId(employee.id),
    {
      onSuccess: (data) => {
        setSystemConnections(data);
      },
    }
  );

  const handleDeleteClick = (item: IExternalSystemConnectionDto) => {
    if (item && item.id != null && item.id !== undefined) {
      confirmationDialog.open({
        icon: <Warning fontSize="large" style={{ color: "orange" }} />,
        title: "Избриши поврзување",
        body: `Дали сте сигурни дека сакате да го избришете поврзувањето ${item.internalSystemUserId} - ${item.externalSystemUserId}`,
        onConfirm: () => handleDelete(item),
        onDeny: () => confirmationDialog.close(),
      });
    } else {
      const newSystemConnections = systemConnections.filter((x) => x !== item);
      setSystemConnections(newSystemConnections);
    }
  };

  const handleDelete = (item: IExternalSystemConnectionDto) => {
    confirmationDialog.setLoading(true);

    removeExternalConnection(item.id)
      .then(() => {
        enqueueSnackbar(
          `Успешно го избришавте поврзувањето ${item.internalSystemUserId} - ${item.externalSystemUserId}`,
          {
            variant: "success",
            autoHideDuration: 6000,
          }
        );

        refetch();

        confirmationDialog.close();
      })
      .catch((error: any) => {
        enqueueSnackbar(`Неуспешно бришење на поврзувањето: ${error.message}`, {
          variant: "error",
          persist: true,
          preventDuplicate: true,
          anchorOrigin: { horizontal: "center", vertical: "bottom" },
          action: function onDismiss(key) {
            return <Button onClick={() => closeSnackbar(key)}>OK</Button>;
          },
        });
        confirmationDialog.close();
      });
  };

  const handleAdd = (request: ICreateConnectionRequestDto) => {
    addExternalConnection(request)
      .then(() => {
        enqueueSnackbar("Успешно ги запишавте податоците", {
          variant: "success",
          autoHideDuration: 6000,
        });

        refetch();
      })
      .catch((err) => {
        enqueueSnackbar(`Неуспешно ги запишавте податоците: ${err.message}`, {
          variant: "error",
          persist: true,
          preventDuplicate: true,
          anchorOrigin: { horizontal: "center", vertical: "bottom" },
          action: function onDismiss(key) {
            return <Button onClick={() => closeSnackbar(key)}>OK</Button>;
          },
        });
      });
  };

  const handleUpdate = (connectionId: number, request: ICreateConnectionRequestDto) => {
    updateExternalConnection(connectionId, request)
      .then(() => {
        enqueueSnackbar("Успешно ги запишавте податоците", {
          variant: "success",
          autoHideDuration: 6000,
        });
        refetch();
      })
      .catch((err) => {
        enqueueSnackbar(`Неуспешно ги запишавте податоците: ${err.message}`, {
          variant: "error",
          persist: true,
          preventDuplicate: true,
          anchorOrigin: { horizontal: "center", vertical: "bottom" },
          action: function onDismiss(key) {
            return <Button onClick={() => closeSnackbar(key)}>OK</Button>;
          },
        });
      });
  };

  const handleAddNew = () => {
    const emptyEmployee: IExternalSystemConnectionDto = {
      systemId: ExternalSystem.KlikniJadiLegacy,
      internalSystemUserId: employee.id,
      externalSystemUserId: 0,
    };

    setSystemConnections((state) => [...state, emptyEmployee]);
  };

  return (
    <TableContainer component={Paper}>
      <Table size="small" aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell align="left" key="system">
              Систем
            </TableCell>
            <TableCell align="left" key="externalId">
              Внатрешен ID
            </TableCell>
            <TableCell align="left" key="externalId">
              Надворешен ID
            </TableCell>
            <TableCell
              title="Додади нов додаток"
              align="center"
              padding="checkbox"
              key="globaladdnewdeduction"
            >
              <IconButton onClick={handleAddNew}>
                <AddCircle color="secondary" />
              </IconButton>
            </TableCell>
            <TableCell
              title="Освежи податоци"
              align="center"
              padding="checkbox"
              key="globalrefetch"
            >
              <IconButton onClick={() => (!isFetching || !isLoading ? refetch() : null)}>
                {isFetching || isLoading ? <CircularProgress size={25} /> : <Refresh />}
              </IconButton>
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {isLoading ? (
            <TableRow>
              <TableCell colSpan={12} style={{ padding: "20px" }} align="center">
                <Typography align="center" variant="h6">
                  Се вчитува
                </Typography>
              </TableCell>
            </TableRow>
          ) : error ? (
            <TableRow>
              <TableCell colSpan={12} style={{ padding: "20px", color: "red" }} align="center">
                <Typography align="center" variant="h6">
                  {error}
                </Typography>
              </TableCell>
            </TableRow>
          ) : systemConnections && systemConnections.length > 0 ? (
            systemConnections.map((row, i) => (
              <SystemConnectionsFormRow
                key={i}
                onDeleteItem={handleDeleteClick}
                onAddItem={handleAdd}
                onUpdateItem={handleUpdate}
                item={row}
              />
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={12} style={{ padding: "20px" }} align="center">
                <Typography align="center" variant="h6">
                  Не се пронајдени податоци
                </Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default memo(EmployeeSystemConnectionsTab);
