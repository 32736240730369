import DriverCard from "./DriverCard";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ExpandMore } from "@material-ui/icons";
import { IDriverDto } from "@shared/services/drivers/dtos/driverDto";
import { IRoutePointDto } from "@shared/services/orders/dtos/orders/routePointDto";
import { FC, memo, useEffect, useState } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    gap: "10px 5px",
  },
  "& .MuiAccordionDetails-root": {
    padding: 0,
  },
}));

interface DriversListProps {
  drivers: IDriverDto[];
  emptyStateText: string;
  title: string;
  expanded?: boolean;
  selectedDriverIds: number[];
  onDriverSelect: (driverId: number, checked: boolean) => void;
  isGroupSelected?: boolean;
  onGroupSelect?: (checked: boolean) => void;
}

const DriversList: FC<DriversListProps> = ({
  drivers,
  selectedDriverIds,
  onDriverSelect,
  isGroupSelected,
  onGroupSelect,
  emptyStateText,
  expanded = false,
  title,
}) => {
  const classes = useStyles();

  const [expandedState, setExpandedState] = useState(false);

  useEffect(() => {
    setExpandedState(!!expanded);
  }, [expanded]);

  const handleExpandClick = () => {
    setExpandedState((state) => !state);
  };

  return (
    <>
      <Accordion elevation={0} expanded={expandedState} style={{ margin: 0 }}>
        <AccordionSummary
          style={{ padding: 0 }}
          IconButtonProps={{
            style: {
              marginRight: 2,
            },
            onClick: handleExpandClick,
          }}
          expandIcon={<ExpandMore />}
          aria-label="Expand"
          aria-controls="additional-actions1-content"
          id="additional-actions1-header"
        >
          <Checkbox
            inputProps={{ style: { opacity: 1 } }}
            style={{ padding: 0, height: "18px", width: "18px", marginRight: "8px" }}
            checked={isGroupSelected}
            onChange={(event) => onGroupSelect(event.currentTarget.checked)}
          />

          <Typography style={{ paddingRight: "10px" }} variant="body1" component="div">
            {title} {` [${drivers && drivers.length}]`}
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ padding: 0 }}>
          {drivers && drivers.length > 0 ? (
            <Box display="flex" flexDirection="row" flexWrap="wrap" className={classes.root}>
              {drivers
                .sort((a, b) => a.employeeId - b.employeeId)
                .map((driver, index) => (
                  <div key={index} style={{ position: "relative" }}>
                    <div style={{ position: "absolute", top: -10, left: -5, zIndex: 999 }}>
                      <Checkbox
                        title="Следи на мапа"
                        key={`driver_checkbox_${driver.userId}`}
                        inputProps={{ style: { opacity: 1 } }}
                        style={{ padding: 0, height: "18px", width: "18px" }}
                        checked={selectedDriverIds.includes(driver?.employeeId)}
                        onChange={(event) => {
                          onDriverSelect(driver.employeeId, event.currentTarget.checked);
                        }}
                        name={driver.userId}
                      />
                    </div>
                    <DriverCard
                      title={`Види детални информации за ${driver.firstName} ${driver.lastName} со идентификациски број ${driver.employeeId}`}
                      key={`driver_card_${driver.userId}`}
                      driver={driver}
                    />
                  </div>
                ))}
            </Box>
          ) : (
            <Typography variant="body2" align="center">
              {emptyStateText}
            </Typography>
          )}
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default memo(DriversList, (prev, next) => JSON.stringify(prev) === JSON.stringify(next));
