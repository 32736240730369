import { object, string, ref, number, date } from "yup";

export const EmployeeGeneralInfoValidationSchema = object({
  accountNumber: string().required("Задолжително"),
  bankName: string().required("Задолжително"),
  address: string().required("Задолжително"),
  salaryType: number().required("Задолжително"),
  sickPayDay: number().required("Задолжително"),
  minimalContribution: number().required("Задолжително"),
  employeeNumber: number().required("Задолжително"),
  embg: string().required("Задолжително"),
  employmentContractNumber: string().required("Задолжително"),
  employmentDate: date().required("Задолжително"),
});
