import { Box, InputAdornment, TextField, createStyles, makeStyles } from "@material-ui/core";
import { IEmployeeSalaryConfigurationDto } from "@shared/services/salary-configuration/dtos/employee-salary-configuration/employeeSalaryConfigurationDto";
import { useFormik } from "formik";
import { FC } from "react";

const useStyles = makeStyles((theme) =>
  createStyles({
    form: {
      minWidth: theme.spacing(144),
    },
    formControl: {
      width: "30%",
      margin: theme.spacing(1),
    },
  })
);

interface PerformanceEarningsFormProps {
  formik: ReturnType<typeof useFormik<IEmployeeSalaryConfigurationDto>>;
}

const PerformanceEarningsForm: FC<PerformanceEarningsFormProps> = ({ formik }) => {
  const classes = useStyles();

  return (
    <Box display="flex" flexDirection="column" className={classes.form}>
      <Box>
        <TextField
          variant="outlined"
          className={classes.formControl}
          id="ratePerHour"
          name="ratePerHour"
          label="Стапка по час"
          InputProps={{
            endAdornment: <InputAdornment position="start">мкд/ч</InputAdornment>,
          }}
          type="number"
          value={formik.values.ratePerHour}
          onChange={formik.handleChange}
          error={formik.touched.ratePerHour && Boolean(formik.errors.ratePerHour)}
          helperText={formik.touched.ratePerHour && formik.errors.ratePerHour}
        />

        <TextField
          variant="outlined"
          className={classes.formControl}
          id="ratePerHourNightShift"
          name="ratePerHourNightShift"
          label="Стапка по час (ноќна смена)"
          InputProps={{
            endAdornment: <InputAdornment position="start">мкд/ч</InputAdornment>,
          }}
          type="number"
          value={formik.values.ratePerHourNightShift}
          onChange={formik.handleChange}
          error={
            formik.touched.ratePerHourNightShift && Boolean(formik.errors.ratePerHourNightShift)
          }
          helperText={formik.touched.ratePerHourNightShift && formik.errors.ratePerHourNightShift}
        />
      </Box>

      <Box>
        <TextField
          variant="outlined"
          className={classes.formControl}
          id="ratePerPickUp"
          name="ratePerPickUp"
          label="Стапка по пикап"
          InputProps={{
            endAdornment: <InputAdornment position="start">мкд</InputAdornment>,
          }}
          type="number"
          value={formik.values.ratePerPickUp}
          onChange={formik.handleChange}
          error={formik.touched.ratePerPickUp && Boolean(formik.errors.ratePerPickUp)}
          helperText={formik.touched.ratePerPickUp && formik.errors.ratePerPickUp}
        />

        <TextField
          variant="outlined"
          className={classes.formControl}
          id="ratePerKmToPickUp"
          name="ratePerKmToPickUp"
          label="Стапка по пикап (по КМ)"
          InputProps={{
            endAdornment: <InputAdornment position="start">мкд/км</InputAdornment>,
          }}
          type="number"
          value={formik.values.ratePerKmToPickUp}
          onChange={formik.handleChange}
          error={formik.touched.ratePerKmToPickUp && Boolean(formik.errors.ratePerKmToPickUp)}
          helperText={formik.touched.ratePerKmToPickUp && formik.errors.ratePerKmToPickUp}
        />

        <TextField
          variant="outlined"
          className={classes.formControl}
          id="ratePerMinuteToPickUp"
          name="ratePerMinuteToPickUp"
          label="Стапка по пикап (по минута)"
          InputProps={{
            endAdornment: <InputAdornment position="start">мкд</InputAdornment>,
          }}
          type="number"
          value={formik.values.ratePerMinuteToPickUp}
          onChange={formik.handleChange}
          error={
            formik.touched.ratePerMinuteToPickUp && Boolean(formik.errors.ratePerMinuteToPickUp)
          }
          helperText={formik.touched.ratePerMinuteToPickUp && formik.errors.ratePerMinuteToPickUp}
        />
      </Box>

      <Box>
        <TextField
          variant="outlined"
          className={classes.formControl}
          id="ratePerDropOff"
          name="ratePerDropOff"
          label="Стапка по достава"
          InputProps={{
            endAdornment: <InputAdornment position="start">мкд</InputAdornment>,
          }}
          type="number"
          value={formik.values.ratePerDropOff}
          onChange={formik.handleChange}
          error={formik.touched.ratePerDropOff && Boolean(formik.errors.ratePerDropOff)}
          helperText={formik.touched.ratePerDropOff && formik.errors.ratePerDropOff}
        />

        <TextField
          variant="outlined"
          className={classes.formControl}
          id="ratePerKmToDropOff"
          name="ratePerKmToDropOff"
          label="Стапка по достава (по КМ)"
          InputProps={{
            endAdornment: <InputAdornment position="start">мкд/км</InputAdornment>,
          }}
          type="number"
          value={formik.values.ratePerKmToDropOff}
          onChange={formik.handleChange}
          error={formik.touched.ratePerKmToDropOff && Boolean(formik.errors.ratePerKmToDropOff)}
          helperText={formik.touched.ratePerKmToDropOff && formik.errors.ratePerKmToDropOff}
        />

        <TextField
          variant="outlined"
          className={classes.formControl}
          id="ratePerMinuteToDropOff"
          name="ratePerMinuteToDropOff"
          label="Стапка по достава (по минута)"
          InputProps={{
            endAdornment: <InputAdornment position="start">мкд</InputAdornment>,
          }}
          type="number"
          value={formik.values.ratePerMinuteToDropOff}
          onChange={formik.handleChange}
          error={
            formik.touched.ratePerMinuteToDropOff && Boolean(formik.errors.ratePerMinuteToDropOff)
          }
          helperText={formik.touched.ratePerMinuteToDropOff && formik.errors.ratePerMinuteToDropOff}
        />
      </Box>
    </Box>
  );
};

export default PerformanceEarningsForm;
