import httpService from "../http.service";
import { IPayrollAllEmployeePositionsSummaryDto } from "./dtos/payrollAllEmployeePositionsSummaryDto";
import { IPayrollDayDriverRoutesResponseDto } from "./dtos/payrollDayDriverRoutesResponse";
import { IPayrollDaySummaryDto } from "./dtos/payrollDaySummaryDto";
import { IPayrollEmployeePositionSummaryDto } from "./dtos/payrollEmployeePositionSummaryDto";
import { IPayrollPeriodBonusConditionsDto } from "./dtos/payrollPeriodBonusConditionsDto";
import { IBonusPenaltyDto } from "./dtos/payrollPeriodDayOverviewDto";
import { IPayrollPeriodSumDto } from "./dtos/payrollPeriodSumDto";
import { ICreateBonusPenaltyDto } from "./dtos/request/createBonusPenaltyDto";
import { IDeleteBonusPenaltyDto } from "./dtos/request/deleteBonusPenaltyDto";
import { IPayrollDayDriverRoutePointsQueryDto } from "./dtos/request/payrollDayDriverRoutePointsQueryDto";
import { IPayrollDayFiltersDto } from "./dtos/request/payrollDayFiltersDto";
import { IPayrollEmployeeOverviewFiltersDto } from "./dtos/request/payrollEmployeeOverviewFiltersDto";
import { IPayrollEmployeeOverviewQueryDto } from "./dtos/request/payrollEmployeeOverviewQueryDto";
import { IPayrollPeriodFiltersDto } from "./dtos/request/payrollPeriodFiltersDto";
import { IPayrollPeriodQueryDto } from "./dtos/request/payrollPeriodQueryDto";
import { IUpdateDropOffKmDto } from "./dtos/request/updateDropoffKmDto";
const controllerUrl = "/api/payroll";

export const getPayrollAllEmployeePositionsSummary = (
  filters: IPayrollEmployeeOverviewFiltersDto
) => {
  return httpService.post<
    IPayrollAllEmployeePositionsSummaryDto,
    IPayrollEmployeeOverviewFiltersDto
  >(controllerUrl + "/all-employee-positions-summary", filters);
};


export const getDailyCashAllEmployeePositionsSummary = (
  filters: IPayrollEmployeeOverviewFiltersDto
) => {
  return httpService.post<
    IPayrollAllEmployeePositionsSummaryDto,
    IPayrollEmployeeOverviewFiltersDto
  >(controllerUrl + "/dailyCash/all-employee-positions-summary", filters);
};

export const getPayrollEmployeePositionsSummaries = (
  filters: IPayrollEmployeeOverviewFiltersDto
) => {
  return httpService.post<IPayrollEmployeePositionSummaryDto[], IPayrollEmployeeOverviewFiltersDto>(
    controllerUrl + `/employee-positions-summaries`,
    filters
  );
};

export const getPayrollEmployeesOverview = (query: IPayrollEmployeeOverviewQueryDto) => {
  return httpService.post<IPayrollEmployeeOverviewQueryDto, IPayrollEmployeeOverviewQueryDto>(
    controllerUrl + "/employees-overview",
    query
  );
};


export const getDailyCashEmployeesOverview = (query: IPayrollEmployeeOverviewQueryDto) => {
  return httpService.post<IPayrollEmployeeOverviewQueryDto, IPayrollEmployeeOverviewQueryDto>(
    controllerUrl + "/dailyCash/employees-overview",
    query
  );
};

export const getPayrollPeriod = (employeeId: number, query: IPayrollPeriodQueryDto) => {
  return httpService.post<IPayrollPeriodQueryDto, IPayrollPeriodQueryDto>(
    controllerUrl + `/period/${employeeId}`,
    query
  );
};
export const getDailyCashPeriod = (employeeId: number, query: IPayrollPeriodQueryDto) => {
  return httpService.post<IPayrollPeriodQueryDto, IPayrollPeriodQueryDto>(
    controllerUrl + `dailyCash/period/${employeeId}`,
    query
  );
};

export const addBonusPenalty = (employeeId: number, createBonusPenalty: ICreateBonusPenaltyDto) => {
  return httpService.post<IBonusPenaltyDto, ICreateBonusPenaltyDto>(
    controllerUrl + `/period/${employeeId}/bonus-penalty/add`,
    createBonusPenalty
  );
};

export const deleteBonusPenalty = (
  employeeId: number,
  deleteBonusPenalty: IDeleteBonusPenaltyDto
) => {
  return httpService.post<IBonusPenaltyDto, IDeleteBonusPenaltyDto>(
    controllerUrl + `/period/${employeeId}/bonus-penalty/delete`,
    deleteBonusPenalty
  );
};

export const getPayrollPeriodBonusConditions = (
  employeeId: number,
  filters: IPayrollPeriodFiltersDto
) => {
  return httpService.post<IPayrollPeriodBonusConditionsDto, IPayrollPeriodFiltersDto>(
    controllerUrl + `/period/${employeeId}/bonus-conditions`,
    filters
  );
};

export const getPayrollPeriodSum = (employeeId: number, filters: IPayrollPeriodFiltersDto) => {
  return httpService.post<IPayrollPeriodSumDto, IPayrollPeriodFiltersDto>(
    controllerUrl + `/period/${employeeId}/sum`,
    filters
  );
};

export const getDailyCashPeriodSum = (employeeId: number, filters: IPayrollPeriodFiltersDto) => {
  return httpService.post<IPayrollPeriodSumDto, IPayrollPeriodFiltersDto>(
    controllerUrl + `dailyCash/period/${employeeId}/sum`,
    filters
  );
};
export const getPayrollDaySummary = (employeeId: number, filters: IPayrollDayFiltersDto) => {
  return httpService.post<IPayrollDaySummaryDto, IPayrollDayFiltersDto>(
    controllerUrl + `/day/${employeeId}/summary`,
    filters
  );
};

export const getPayrollForDriver = (employeeId: number) => {
  return httpService.get<IPayrollPeriodSumDto>(
    controllerUrl + `/driver/${employeeId}`
  )
}

export const getPayrollDayDriverRoutePoints = (
  employeeId: number,
  query: IPayrollDayDriverRoutePointsQueryDto
) => {
  return httpService.post<
    IPayrollDayDriverRoutePointsQueryDto,
    IPayrollDayDriverRoutePointsQueryDto
  >(controllerUrl + `/day/${employeeId}/driver-route-points`, query);
};

export const getPayrollDayDriverRoutesForDriver = (
  employeeId: number,
  day: string
) => {
  return httpService.post<
    IPayrollDayDriverRoutesResponseDto,
    IPayrollDayDriverRoutePointsQueryDto
  >(controllerUrl + `/day/driver/${employeeId}/driver-routes/${day}`);
};

export const getBonusesAndPenaltiesForEmployee = (
  employeeId: number,
  day: string
) => {
  return httpService.post<
    IBonusPenaltyDto[]
  >(controllerUrl + `/day/${employeeId}/bonuses-penalties/${day}`);
};


export const getPayrollDayDriverRoutePointsForDriver = (
  employeeId: number,
  day: string
) => {
  return httpService.post<
    IPayrollDayDriverRoutePointsQueryDto,
    IPayrollDayDriverRoutePointsQueryDto
  >(controllerUrl + `/day/driver/${employeeId}/driver-route-points/${day}`);
};


// export const getPayrollDayDriverSummary = (
//   employeeId: number,
//   day: string
// ) => {
//   return httpService.post<
//     IPayrollDaySummaryDto,
//     IPayrollDayFiltersDto
//   >(controllerUrl + `/day/driver/${employeeId}/summary/${day}`);
// };

export const UpdateDropOffKm = (
  employeeId: number,
  updateDropOffKmDto: IUpdateDropOffKmDto
) => {
  const apiUrl = `${controllerUrl}/updateDropOfKm`;
  return httpService.post<{ data: string }>(apiUrl, updateDropOffKmDto);
};


