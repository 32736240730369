import useRoleSalaryConfigDetailsFormik from "../hooks/useRoleSalaryConfigDetailsFormik";
import {
  Box,
  Button,
  CardActions,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import { IRoleSalaryConfigurationDto } from "@shared/services/salary-configuration/dtos/role-salary-configurations/roleSalaryConfigurationDto";
import { getEmployeePositions } from "@shared/services/settings/employee-positions.service";
import { useQuery } from "@tanstack/react-query";
import { FC, useEffect } from "react";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "end",
    },
    subPosition: {
      minWidth: theme.spacing(24),
    },
    actionCancel: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      color: theme.palette.error.main,
    },
  })
);

interface AddSubRoleSalaryConfigFormProps {
  parentRoleSalaryConfig: IRoleSalaryConfigurationDto;
  onSubmit: (values: IRoleSalaryConfigurationDto) => void;
  onCancel: () => void;
}

const AddSubRoleConfigForm: FC<AddSubRoleSalaryConfigFormProps> = ({
  parentRoleSalaryConfig,
  onSubmit,
  onCancel,
}) => {
  const classes = useStyles();

  const formik = useRoleSalaryConfigDetailsFormik({ onSubmit: (values) => onSubmit(values) });

  useEffect(() => {
    formik.setValues(parentRoleSalaryConfig);
  }, [parentRoleSalaryConfig]);

  const { data: employeeSubPositions, isLoading } = useQuery(
    ["getEmployeePositions"],
    getEmployeePositions, // should be changed after BE is finished
    {
      onSuccess: (data) => {
        formik.setValues({
          ...formik.values,
          employeePositionFK: data.find(() => true)?.id ?? -1, // should be changed after BE is finished
        });
      },
    }
  );

  if (isLoading) return <CircularProgress />;

  return (
    <CardActions className={classes.root}>
      <form>
        <FormControl variant="outlined" className={classes.subPosition}>
          <InputLabel id="employeeSubPositionFKLabel">Нова под-позиција</InputLabel>
          <Select
            id="employeePositionFK" // should be changed after BE is finished
            name="employeePositionFK" // should be changed after BE is finished
            value={formik.values.employeePositionFK} // should be changed after BE is finished
            labelId="employeeSubPositionFKLabel"
            label="Нова под-позиција"
            onChange={formik.handleChange}
            error={formik.touched.employeePositionFK && Boolean(formik.errors.employeePositionFK)} // should be changed after BE is finished
          >
            {!employeeSubPositions.length && (
              <MenuItem value={-1}>
                <em>Нема под-позиции</em>
              </MenuItem>
            )}
            {employeeSubPositions.map((employeeSubPosition) => (
              <MenuItem key={employeeSubPosition.id} value={employeeSubPosition.id}>
                {employeeSubPosition.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </form>

      <Box>
        <Button
          variant="text"
          color="secondary"
          className={classes.actionCancel}
          onClick={() => {
            formik.resetForm();
            onCancel();
          }}
        >
          Откажи
        </Button>
        <Button variant="contained" color="primary" onClick={formik.submitForm}>
          Додади
        </Button>
      </Box>
    </CardActions>
  );
};

export default AddSubRoleConfigForm;
