import { Avatar, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Contacts as ContactsIcon } from "@material-ui/icons";
import CustomPopupAutocomplete from "@shared/components/CustomFormControls/CustomPopupAutocomplete";
import { IDriverDto } from "@shared/services/drivers/dtos/driverDto";
import { isDriverBusy, isDriverFree, isDriverPaused } from "@shared/utils/drivers/driverStatus";
import clsx from "clsx";
import { FC, memo } from "react";

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    gap: theme.spacing(1),
    alignItems: "stretch",
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    flexWrap: "nowrap",
  },
  nameContainer: {
    display: "flex",
    alignItems: "center",
    flexGrow: 1,
  },
  statusLabel: {
    width: "5px",
    flexGrow: 0,
    flexShrink: 0,
  },
  freeLabel: {
    backgroundColor: "green",
  },
  busyLabel: {
    backgroundColor: "orange",
  },
  pausedLabel: {
    backgroundColor: "red",
  },
}));

interface DriverSelectProps {
  drivers: IDriverDto[];
  onChange: (employeeId: number) => void;
}

const DriverSelect: FC<DriverSelectProps> = ({ drivers, onChange }) => {
  const classes = useStyles();

  const sendBackToAlgorithmOption = {
    employeeId: null,
    firstName: "Врати на обработка",
  } as IDriverDto;

  const options: IDriverDto[] = [sendBackToAlgorithmOption, ...sortDriversByStatus(drivers)];

  return (
    <CustomPopupAutocomplete
      options={options}
      noOptionsText="Нема возачи"
      onChange={(driver: IDriverDto) => onChange(driver.employeeId)}
      getOptionLabel={(driver: IDriverDto) => `${driver.firstName} ${driver.lastName}`}
      buttonIcon={<ContactsIcon />}
      renderOption={(driver: IDriverDto) => (
        <Grid container className={classes.gridContainer}>
          {/* Status label */}
          <Grid
            item
            className={clsx(classes.statusLabel, {
              [classes.freeLabel]: isDriverFree(driver),
              [classes.pausedLabel]: isDriverPaused(driver),
              [classes.busyLabel]: isDriverBusy(driver),
            })}
          />

          {/* Driver profile picture */}
          <Grid item>
            {driver.imageSrc && (
              <Avatar alt={`${driver.firstName} ${driver.lastName}`} src={driver.imageSrc} />
            )}
          </Grid>

          {/* Driver name and surname */}
          <Grid item className={classes.nameContainer}>
            <Typography color="textPrimary" noWrap>
              {driver.firstName} {driver.lastName}
            </Typography>
          </Grid>
        </Grid>
      )}
    />
  );
};

const sortDriversByStatus = (drivers: IDriverDto[]) => {
  return [...drivers].sort((a, b) => {
    const status = {
      Free: 1,
      Busy: 2,
      Paused: 3,
    };

    const statusA = isDriverFree(a)
      ? status["Free"]
      : isDriverPaused(a)
      ? status["Paused"]
      : status["Busy"];

    const statusB = isDriverFree(b)
      ? status["Free"]
      : isDriverPaused(b)
      ? status["Paused"]
      : status["Busy"];

    return statusA - statusB;
  });
};

export default memo(DriverSelect);
