import ErrorFallback from "../../../../error";
import AreaTypes from "./Area/Area";
import CityTypes from "./City/City";
import Company from "./Company/Company";
import DeliveryTypes from "./Delivery/Delivery";
import EmployeePositionTypes from "./EmployeePosition/EmployeePosition";
import OrderRejectReasonTypes from "./OrdersRejectReason/OrdersRejectReason";
import Parameters from "./Parameters/Parameters";
import PaymentType from "./Payment/Payment";
import RoleTypes from "./Role/Role";
import RoleSalaryConfig from "./RoleSalary/RoleSalaryConfig";
import RoleSalaryConfigDetails from "./RoleSalary/RoleSalaryConfigDetails";
import PerformanceBonusPage from "./Salaries/pages/PerformanceBonusPage";
import VehicleTypes from "./Vehicle/Vehicle";
import { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

const Settings: FC = () => {
  return (
    <Routes>
      <Route errorElement={<ErrorFallback />}>
        <Route path="vehicle" element={<VehicleTypes />} />
        <Route path="role" element={<RoleTypes />} />
        <Route path="payment" element={<PaymentType />} />
        <Route path="delivery" element={<DeliveryTypes />} />
        <Route path="order" element={<OrderRejectReasonTypes />} />
        <Route path="role-salary-config" element={<RoleSalaryConfig />} />
        <Route path="role-salary-config/:id" element={<RoleSalaryConfigDetails />} />
        <Route path="bonus" element={<PerformanceBonusPage />} />
        <Route path="area" element={<AreaTypes />} />
        <Route path="employee-positions" element={<EmployeePositionTypes />} />
        <Route path="cities" element={<CityTypes />} />
        <Route path="parameters" element={<Parameters />} />
        <Route path="comapnies" element={<Company />} />

        <Route path="*" element={<SettingsFallback />} />
      </Route>
    </Routes>
  );
};

const SettingsFallback = () => <Navigate to="vehicle" />;

export default Settings;
