import { FC, useEffect, useState } from "react";
import {
  DialogActions,
  DialogContent,
  makeStyles,
  Dialog,
  Divider,
  Grid,
  ListItem,
  Avatar,
  ListItemAvatar,
  ListItemText,
  ListItemIcon,
  Typography,
  List,
  Button,
  Box,
  Accordion,
  AccordionDetails,
} from "@material-ui/core";
import DialogTitle from "@shared/components/Dialog/DialogTitle";
import DriversMap from "./DriversMap";
import { IMapDriver, IMapOrder } from "@shared/components/Map/models/models";
import {
  IRoutePoint,
  getDriverAndOrderRoutePoints,
  modifyDriverRoutePoints,
  generateGroupOrdersAsync,
  groupOrdersAsync,
  PackageOrderGuid,
  IFullOrderDto,
  getGroupOrdersAsync,
  updateGroupOrdersAsync,
} from "@shared/services/order.service";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import { getPickupTime } from "@shared/utils/orders/orderTime";
import dayjs from "dayjs";
import { Storefront } from "@material-ui/icons";
import ColoredButton from "@shared/components/ColoredButton/ColoredButton";
import clsx from "clsx";
import { grey } from "@material-ui/core/colors";
import { useSnackbar } from "notistack";
// import { getDriverActiveRoute, updateDriverRoute } from "@shared/services/drivers.service";
import { useMemoSelector } from "@shared/hooks";
// import { IFilterState } from "../../Map";
import { selectAllDrivers } from "@App/modules/Logistics/store/features/drivers/driverSelectors";
import { useDispatch } from "react-redux";
import { ShortRoutePoint, IOrderDto, getOrderDetailsAsync } from "@shared/services/order.service";
import { getActiveOrdersAsyncThunk } from "../../store/features/orders/ordersThunk";
import { getAllDriversRoutesAsync } from "../../store/features/driversRoutes/driversRoutesThunk";
import OrderCard from "../Orders/OrderCard";
import AccordionSummary from "../Accordion/AccordionSummary";
import { selectOrderById } from "../../store/features/orders/orderSelectors";
import GeneratePackageMap from "./GeneratePackageMap";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: 10,
  },
  container: {
    height: "75vh",
  },
  dialogContent: {
    padding: "18px 24px 8px 24px",
    height: "600px",
  },
  avatar: {
    objectFit: "contain",
  },
  listItem: {
    backgroundColor: theme.palette.background.paper,
    border: `1px dashed ${theme.palette.secondary.main}`,
    marginTop: "-2px",
  },
  disabled: {
    backgroundColor: grey[300],
  },
}));

const draggingCustomStyle = (style: React.CSSProperties | undefined) => {
  if (style.transform) {
    const axisLockY =
      "translate(0px" + style.transform.slice(style.transform.indexOf(","), style.transform.length);

    return {
      ...style,
      transform: axisLockY,
    };
  }
  return style;
};

export interface UpdatePackageDialogProps {
  orderId1: number;
  orderGroupId?: string;
  onClose?: () => void;
}

const UpdatePackageDialog: FC<UpdatePackageDialogProps> = ({ orderId1, orderGroupId, onClose }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [driverRoutePoints, setDriverRoutePoints] = useState<ShortRoutePoint[]>([]);
  const [orders, setOrders] = useState<PackageOrderGuid[]>([]);
  const [ordersObjects, setOrderObjects] = useState<IOrderDto[]>([]);

  const order1 = useMemoSelector((state) => selectOrderById(state, orderId1));

  useEffect(() => {
    const fn = async () => {
      var ordersObjectsL: IOrderDto[] = [];

      const response = await getGroupOrdersAsync(orderGroupId);
      const orders = response.orders;
      const ordersObjects = await Promise.all(
        orders.map(async (o) => await getOrderDetailsAsync(o.orderId.toString()))
      );
      ordersObjectsL = ordersObjects;
      const response2 = await getOrderDetailsAsync(orderId1.toString());
      ordersObjectsL.push(response2);
      orders.push({ orderId: orderId1, externalOrderNumber: response2.id.toString() });

      const routePoints = response.routePoints;
      routePoints.push(
        { orderId: orderId1, isPickup: true },
        { orderId: orderId1, isPickup: false }
      );
      const routePoints2 = routePoints.map((routePoint) => {
        const order = ordersObjectsL.find((o) => {
    
          return o.id === routePoint.orderId;
        });
       
        return { ...routePoint, order: order };
      });

      setOrderObjects(ordersObjectsL);
      setDriverRoutePoints(routePoints2);
      setOrders(orders.map((x) => ({ ...x, packageGuid: response.packageGuid })));
    };
    fn();
  }, [orderId1, orderGroupId]);

  const handleDragEnd = (result: DropResult) => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    if (destination.index === source.index) {
      return;
    }

    const old_index = source.index;
    const new_index = destination.index;

    const _points = [...driverRoutePoints];

    const [el] = _points.splice(old_index, 1);
    _points.splice(new_index, 0, el);

    setDriverRoutePoints(_points);
  };

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const handleSubmit = () => {
    const routePoints = driverRoutePoints;
    const packagePost = {
      orders: orders,
      routePoints: routePoints,
    };
    const promise = updateGroupOrdersAsync(orders[0].packageGuid, packagePost);
    promise
      .then(() => {
        enqueueSnackbar("Рутата е успешно зачувана.", {
          variant: "success",
          autoHideDuration: 6000,
        });

        onClose?.();
      })
      .catch((error) => {
        enqueueSnackbar(`Грешка: ${error.message}`, {
          variant: "error",
          persist: true,
          preventDuplicate: true,
          anchorOrigin: { horizontal: "left", vertical: "bottom" },
          action: function onDismiss(key) {
            return <Button onClick={() => closeSnackbar(key)}>OK</Button>;
          },
        });

        onClose?.();
      });
  };

  return (
    <Dialog open fullWidth maxWidth="xl" onClose={onClose}>
      <DialogTitle onClose={onClose}>
        {orderGroupId !== null ? "Групирај нарачки" : "Промени рута"}
      </DialogTitle>

      <Divider />

      <DialogContent className={classes.dialogContent}>
        {
          <Grid style={{ height: "100%" }} container>
            <Grid xs={8} style={{ height: "100%" }} item>
              <GeneratePackageMap ordersI={ordersObjects} routePointsI={driverRoutePoints} />
            </Grid>
            <Grid xs={4} item>
              <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="driver-route-points">
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      style={{ height: "100%" }}
                    >
                      {driverRoutePoints.map((routePoint, index) => (
                        <Draggable
                          key={routePoint.orderId.toString() + routePoint.isPickup.toString()}
                          draggableId={
                            routePoint.orderId.toString() + routePoint.isPickup.toString()
                          }
                          index={index}
                          isDragDisabled={Boolean(false)}
                        >
                          {(provided) => (
                            <ListItem
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={draggingCustomStyle(provided.draggableProps.style)}
                            >
                              <OrderCard
                                pickup={routePoint.isPickup ? "PU" : "DO"}
                                order={routePoint.order}
                              />
                              <Typography
                                variant="body1"
                                style={{
                                  marginRight: "1rem",
                                  fontWeight: "bold",
                                }}
                              ></Typography>

                              <ListItemIcon>
                                <DragHandleIcon />
                              </ListItemIcon>
                            </ListItem>
                          )}
                        </Draggable>
                      ))}

                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </Grid>
          </Grid>
        }
      </DialogContent>
      <DialogActions>
        <ColoredButton color="success" onClick={handleSubmit}>
          Зачувај
        </ColoredButton>

        <ColoredButton color="error" onClick={onClose}>
          Затвори
        </ColoredButton>
      </DialogActions>
    </Dialog>
  );
};

export default UpdatePackageDialog;
