import { createStyles, makeStyles } from "@material-ui/core";

const useFormStyles = makeStyles((theme) =>
  createStyles({
    form: {
      minWidth: theme.spacing(96),
    },
    formControl: {
      width: "45%",
      margin: theme.spacing(1),
    },
  })
);

export default useFormStyles;
