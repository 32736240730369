/**
 * Group objects of array by a given property
 * @param data an array of objects
 * @param key the key (or property accessor) to group by
 * @source https://gist.github.com/robmathers/1830ce09695f759bf2c4df15c29dd22d
 * @returns An object where the key is the value of the property, and the value is an array of objects that share that property
 */
export const groupBy = <T>(data: T[], key: keyof T) => {
  // reduce runs this anonymous function on each element of `data` (the `item` parameter,
  // returning the `storage` parameter at the end
  return data.reduce((storage, item) => {
    var group = item[key].toString();

    if (!storage[group]) {
      storage[group] = [];
    }

    storage[group].push(item);

    return storage;
  }, {}); // {} is the initial value of the storage
};
