import DriverTooltipContent from "../Drivers/DriverTooltipContent";
import RoutePoint from "./RoutePoint";
import { Avatar, Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { IMapDriver, IMapOrder } from "@shared/components/Map/models/models";
import ClickableTooltip from "@shared/components/Tooltip/ClickableTooltip";
import { IDriverDto } from "@shared/services/drivers/dtos/driverDto";
import { IDriverRouteResponseDto } from "@shared/services/orders/dtos/orders/driverRouteResponseDto";
import { IRoutePointDto } from "@shared/services/orders/dtos/orders/routePointDto";
import { OrderStatus } from "@shared/services/orders/enums/orderStatus";
import * as React from "react";
import { memo } from "react";

const useStyles = makeStyles((theme) => ({
  sectionTitle: {
    display: "flex",
    margin: "10px 0 15px 0",
  },
  break: {
    flexBasis: "100%",
    height: 0,
  },
}));

interface Props {
  data: IDriverRouteResponseDto;
  drivers: IDriverDto[];
  orders: IMapOrder[];
}

const DriverRouteComponent: React.FC<Props> = ({ data, drivers, orders }) => {
  const classes = useStyles();

  const getOrderDriver = (data: IDriverRouteResponseDto): IDriverDto => {
    let driver = drivers.find((x) => x.employeeId === data.employeeId);
    return driver;
  };

  const getOrder = (data: IRoutePointDto): IMapOrder => {
    let order = orders.find((x) => x.id === data?.orderId);
    return order;
  };

  const getOrderTardiness = (orderId: number) => {
    return data?.ordersTardiness?.[orderId];
  };

  const isRouteActive = data?.routePoints?.some(
    (routePoint) => routePoint && routePoint.orderStatus !== OrderStatus.Delivered
  );
  const activeRoutePoints = isRouteActive
    ? data?.routePoints?.filter((rp) => rp?.orderStatus !== OrderStatus.Delivered)
    : data?.routePoints;

  return (
    <>
      <div style={{ marginBottom: "5px" }}>
        <div style={{ display: "flex" }}>
          <ClickableTooltip
            arrow={true}
            placement="right"
            type="driver"
            style={{ marginTop: "15px" }}
            content={<DriverTooltipContent driver={getOrderDriver(data)} />}
          >
            <Avatar
              style={{
                width: "40px",
                height: "40px",
              }}
              variant="square"
              title={`${data.firstName} ${data.lastName} ја носи нарачката.`}
              alt={`${data.firstName} ${data.lastName}`}
              src={getOrderDriver(data)?.imageSrc}
            >
              {`${data.firstName[0]}${data.lastName[0]}`}
            </Avatar>
          </ClickableTooltip>

          <div style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
            {activeRoutePoints?.map((routePoint, index) => (
              <RoutePoint
                key={index}
                order={getOrder(routePoint)}
                routePoint={routePoint}
                previousPoint={
                  index > 0 && data.routePoints[index - 1] ? data.routePoints[index - 1] : null
                }
                finish={index === data.routePoints.length - 1}
                orderTardiness={getOrderTardiness(routePoint.orderId)}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
export default DriverRouteComponent;
