import DayjsUtils from "@date-io/dayjs";
import { KeyboardTimePicker, MuiPickersUtilsProvider, TimePickerProps } from "@material-ui/pickers";
import { FC, memo } from "react";

export interface Props extends TimePickerProps {
  errorMessage?: string;
  touched?: boolean;
}

const CustomDateTimeField: FC<Props> = ({
  format = "HH:mm",
  errorMessage,
  touched,
  value,
  ...rest
}) => {
  return (
    <MuiPickersUtilsProvider utils={DayjsUtils}>
      <KeyboardTimePicker
        DialogProps={{
          style: {
            zIndex: 2001,
          },
        }}
        inputVariant="outlined"
        format={format}
        error={touched && Boolean(errorMessage)}
        value={value}
        {...rest}
      />
    </MuiPickersUtilsProvider>
  );
};

export default memo(CustomDateTimeField);
