import { getDropOffTime, getPickupTime } from "./orderTime";
import { IDriverRouteResponseDto } from "@shared/services/orders/dtos/orders/driverRouteResponseDto";
import { IOrderDto } from "@shared/services/orders/dtos/orders/orderDto";
import { OrderStatus } from "@shared/services/orders/enums/orderStatus";
import dayjs from "dayjs";

/**
 * Check whether the order is dispatched.
 * An order is dispatched when it's assigned to a driver, and it's status is either assigned, picked-up or dropped-off
 * @param order The order object
 * @returns Whether the order is dispatched
 */
export const isOrderDispatched = (order: IOrderDto): boolean => {
  return (
    (order.orderStatus == OrderStatus.Assigned ||
      order.orderStatus == OrderStatus.PickedUp ||
      order.orderStatus == OrderStatus.Delivered) &&
    !!order.employeeFk
  );
};

/**
 * Check if the order pick-up is late
 * @param order The driver route point
 * @returns Whether the order pick-up is late
 */
export const isOrderPickUpLate = (order: IDriverRouteResponseDto) => {
  let result = order.routePoints
    .map((x) => {
      if (
        x.isPickup &&
        x.orderStatus == OrderStatus.Assigned &&
        dayjs.utc(getPickupTime(x)).local().diff(dayjs(), "second") <= 0
      ) {
        return x;
      } else {
        return undefined;
      }
    })
    .filter((x) => x);

  return result.length > 0;
};

/**
 * Check if the order drop-off is late
 * @param order The driver route point
 * @returns Whether the order drop-off is late
 */
export const isOrderDropOffLate = (order: IDriverRouteResponseDto) => {
  let result = order.routePoints
    .map((x) => {
      if (
        !x.isPickup &&
        (x.orderStatus == OrderStatus.Assigned || x.orderStatus == OrderStatus.PickedUp) &&
        dayjs.utc(getDropOffTime(x)).local().diff(dayjs(), "second") <= 0
      ) {
        return x;
      } else {
        return undefined;
      }
    })
    .filter((x) => x);

  return result.length > 0;
};

/**
 * Check if the order is both late on pick-up and on drop-off
 * @param order The driver route point object
 * @returns Whether the order is completely late
 */
export const isOrderCompletelyLate = (order: IDriverRouteResponseDto) => {
  return isOrderPickUpLate(order) && isOrderDropOffLate(order);
};

/**
 * Check if the order is not late at all and is still in delivery
 * @param order The driver route point object
 * @returns Whether the order is normal
 */
export const isOrderNormal = (order: IDriverRouteResponseDto) => {
  return (
    !isOrderDropOffLate(order) &&
    !isOrderPickUpLate(order) &&
    order.routePoints.some(
      (r) => r.orderStatus === OrderStatus.Assigned || r.orderStatus === OrderStatus.PickedUp
    )
  );
};

/**
 * Check if the order is delivered
 * @param order The driver route point object
 * @returns Whether the order is delivered
 */
export const isOrderDelivered = (order: IDriverRouteResponseDto) => {
  return (
    order.routePoints.length > 0 &&
    !order.routePoints.some(
      (r) => r.orderStatus === OrderStatus.Assigned || r.orderStatus === OrderStatus.PickedUp
    )
  );
};
