import Resizable from "./Resizable";
import CustomDndContext from "./components/DnD/CustomDndContext";
import Drivers from "./components/Drivers/Drivers";
import NotActiveDrivers from "./components/Drivers/NotActiveDrivers";
import DriversRoutes from "./components/DriversRoutes/DriversRoutes";
import Filters from "./components/Filters/Filters";
import DriversMap from "./components/Map/DriversMap";
import { withMapData } from "./components/MapProvider";
import Orders from "./components/Orders/Orders";
import { Grid } from "@material-ui/core";
import { selectIsAppInitializing } from "@shared/store/commonSelectors";
import { FC } from "react";
import { useSelector } from "react-redux";

// const Loader = () => {
//   const isInitializing = useSelector(selectIsAppInitializing);

//   return <h1>Loading: {isInitializing.toString()}</h1>;
// };

// const DataViewer = () => {
//   const state = useMemoSelector((state) => state.appState.logistics);

//   return <pre style={{ overflow: "auto", width: "100%" }}>{JSON.stringify(state, null, 2)}</pre>;
// };

const LogisticsPage: FC = () => {
  const isInitializing = useSelector(selectIsAppInitializing);

  if (isInitializing) {
    return (
      <div
        style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}
      >
        <h1 style={{ fontSize: "50px" }}>Loading...</h1>
      </div>
    );
  }

  return (
    <CustomDndContext>
      <Grid container style={{ height: "100%", maxHeight: "100vh" }}>
        <Resizable direction="vertical">
          <Grid item xs={12} style={{ height: "100%", overflow: "auto", maxHeight: "100vh" }}>
            <Grid container direction="column" spacing={0}>
              {/*
              Global filters row
              > City select
              > Delivery type select
              > Route group select
              > Sorting
              > Search
              > Start algorithm button
            */}
              <Grid item>
                <Filters />
              </Grid>

              {/* 
              Drivers accordion
              > Driver groups (Active, paused, missing)
              > Filters, search
              > Driver card with hover effect
              > Driver group counts
            */}

              <Grid item>
                <Drivers />
              </Grid>

              {/* Unassigned orders / orders that require some action */}
              <Grid item>
                <Orders />
              </Grid>
              <Grid item>
                <NotActiveDrivers />
              </Grid>
              {/* Drivers routes */}
              <Grid item>
                <DriversRoutes />
              </Grid>
            </Grid>
          </Grid>
        </Resizable>

        <Grid item xs>
          <DriversMap />
        </Grid>
      </Grid>
    </CustomDndContext>
  );
};

export default withMapData(LogisticsPage);

