// Logistics panel data
export const selectIsLogisticsInitializing = ({ appState }: IGlobalState) => {
  const { drivers, driversRoutes, orders } = appState.logistics;

  return (
    (drivers.data.length === 0 && drivers.status === "loading") ||
    (driversRoutes.data.length === 0 && driversRoutes.status === "loading") ||
    (orders.data.length === 0 && orders.status === "loading")
  );
};

// Base types
export const selectIsAppConfigInitializing = ({ sharedState }: IGlobalState) => {
  const {
    cityTypes,
    deliveryTypes,
    employeePositionTypes,
    orderRejectReasons,
    paymentTypes,
    vehicleTypes,
  } = sharedState;

  return (
    (cityTypes.data.length === 0 && cityTypes.status === "loading") ||
    (deliveryTypes.data.length === 0 && deliveryTypes.status === "loading") ||
    (employeePositionTypes.data.length === 0 && employeePositionTypes.status === "loading") ||
    (orderRejectReasons.data.length === 0 && orderRejectReasons.status === "loading") ||
    (paymentTypes.data.length === 0 && paymentTypes.status === "loading") ||
    (vehicleTypes.data.length === 0 && vehicleTypes.status === "loading")
  );
};

// All the loading state from the app
export const selectIsAppInitializing = (state: IGlobalState) => {
  return selectIsAppConfigInitializing(state) || selectIsLogisticsInitializing(state);
};
