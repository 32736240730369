import { CircularProgress, Grid, Typography } from "@material-ui/core";
import { FC } from "react";

const Loading: FC = () => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography align="center" variant="h6">
          Се вчитува
        </Typography>
      </Grid>
      <Grid item xs={12}>
      <div style={{ display: "flex", justifyContent: "center", marginTop: 2, marginBottom: 2 }}>
          <CircularProgress />
        </div>
      </Grid>
    </Grid>
  );
};

export default Loading;
