// import DriversRoutesFilter from "./FilterFields/DriversRoutesFilter";
// import Sort from "./FilterFields/Sort";
// import Search from "./FilterFields/Search";
// import CityFilter from "./FilterFields/CityFilter";
// import DeliveryTypeFilter from "./FilterFields/DeliveryTypeFilter";
// import StartAlgorithmButton from "./StartAlgorithmButton";
// import ResetFiltersButton from "./FilterFields/ResetFiltersButton";
import AlgorithmSpinner from "./AlgorithmSpinner";
import AlgorithmControl from "@App/modules/Map/components/AlgorithmControl";
import DispatcherShiftControl from "@App/modules/Map/components/DispatcherShift/DispatcherShiftControl";
import { Grid, Paper, Tabs, makeStyles } from "@material-ui/core";
import { FC, memo } from "react";
import React from "react";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
  algorithmControlsContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  tabContainer: {
    flexGrow: 1,
  },
  tabRoot: {
    textTransform: "none",
    minWidth: 0,
    marginRight: theme.spacing(2),
    padding: theme.spacing(1),
    borderBottom: `2px solid transparent`,
    "&$selected": {
      borderBottomColor: "#18ba9b",
    },
  },
  selected: {
    transition: "font-size 0.3s",
    fontWeight: "bold",
    fontSize: "17.5px",
  },
  root: {
    "&:hover": {
      fontWeight: "bold",
    },
  },
}));

const Filters: FC = () => {
  const classes = useStyles();

  const [selectedTab, setSelectedTab] = React.useState(0);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue);
  };

  return (
    <Paper square className={classes.paper}>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid container item xs spacing={2}>
          <Grid item xs={5} md={6} className={classes.tabContainer}>
            <DispatcherShiftControl />
          </Grid>
          <Grid item xs={12} md={6} className={classes.algorithmControlsContainer}>
            <AlgorithmSpinner />
            <AlgorithmControl />
          </Grid>

          {selectedTab === 0 && <Grid item></Grid>}

          {selectedTab === 1 && <Grid item></Grid>}

          {selectedTab === 2 && <Grid item></Grid>}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default memo(Filters);

