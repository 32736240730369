import { FC, ReactNode, memo } from "react";
import DialogTitle from "../Dialog/DialogTitle";
import DialogContent from "../Dialog/DialogContent";
import DialogActions from "../Dialog/DialogActions";
import { Dialog, Button, DialogProps, CircularProgress } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() =>
  createStyles({
    dialogContent: {
      minWidth: "290px",
    },
  })
);

export interface ConfirmationDialogProps {
  icon?: ReactNode;
  title?: ReactNode | string;
  body: ReactNode | string;
  showDividers?: boolean;
  showCloseButton?: boolean;
  showConfirmButton?: boolean;
  showDenyButton?: boolean;
  maxWidth?: DialogProps["maxWidth"];
  confirmText?: string;
  denyText?: string;
  isLoading?: boolean;
  onConfirm?: () => void;
  onDeny?: () => void;
  /** Override skip functionality */
  forceShow?: boolean;
}

const ConfirmationDialog: FC<ConfirmationDialogProps> = (props) => {
  const {
    icon,
    title,
    body,
    confirmText = "Да",
    denyText = "Не",
    onConfirm,
    onDeny,
    showDividers,
    showCloseButton,
    showConfirmButton = true,
    showDenyButton = true,
    maxWidth = false,
    isLoading = false,
  } = props;

  const classes = useStyles();

  return (
    <Dialog data-testid="confirmation-dialog" maxWidth={maxWidth} scroll="paper" open>
      {title || icon || showCloseButton ? (
        <DialogTitle icon={icon} onClose={showCloseButton ? onDeny : undefined}>
          {title}
        </DialogTitle>
      ) : null}

      <DialogContent className={classes.dialogContent} dividers={showDividers}>
        {body}
      </DialogContent>

      <DialogActions>
        {showConfirmButton ? (
          <Button
            data-testid="confirm-button"
            color="primary"
            onClick={onConfirm}
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress size={25} /> : confirmText}
          </Button>
        ) : null}

        {showDenyButton ? (
          <Button data-testid="deny-button" color="secondary" onClick={onDeny}>
            {denyText}
          </Button>
        ) : null}
      </DialogActions>
    </Dialog>
  );
};

export default memo(ConfirmationDialog);
