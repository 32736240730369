import { Avatar, CircularProgress, Typography } from "@material-ui/core";
import { Theme, makeStyles } from "@material-ui/core/styles";
import { MeetingRoom } from "@material-ui/icons";
import { logout } from "@shared/services/auth/auth.service";
import { FC, useEffect } from "react";

const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
    display: "inline-flex",
  },
  spinner: {
    marginTop: theme.spacing(2),
  },
}));

const Logout: FC = () => {
  const classes = useStyles();

  useEffect(() => {
    setTimeout(() => logout(), 1000);
  }, []);

  return (
    <>
      <Avatar className={classes.avatar}>
        <MeetingRoom />
      </Avatar>

      <Typography component="h1" variant="h5">
        Се одјавувате
      </Typography>

      <CircularProgress className={classes.spinner} disableShrink />
    </>
  );
};

export default Logout;
