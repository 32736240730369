import React, { FC, useState } from "react";
import {
  AppBar,
  BottomNavigation,
  IconButton,
  BottomNavigationAction,
  Toolbar,
} from "@material-ui/core";
import {
  Assignment,
  AddAlarm,
  MeetingRoom,
  AccountBalance,
  Home,
  EuroSymbol,
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { useConfirmationDialog } from "@shared/context/ConfirmationDialogContext";
import ScheduleAsDriver from "../../../App/Scheduler/modules/ScheduleAsDriver/ScheduleAsDriverApp";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  appBar: {
    top: "auto",
    bottom: 0,
    background: "transparent",
    boxShadow: "none",
  },
  navigation: {
    width: "100%",
    position: "fixed",
    bottom: 0,
    left: 0,
    zIndex: theme.zIndex.appBar,
  },
  action: {
    fontSize: "2.5vw",
    padding: "8px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "3.5vw",
    },
    "&.MuiBottomNavigationAction-root": {
      minWidth: "30px",
    },
  },
  label: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "3.5vw",
    },
  },
  selected: {
    color: "green",
  },
}));

const Navigation: FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const confirmationDialog = useConfirmationDialog();

  const [selectedItem, setSelectedItem] = useState<string>("");

  const handleLogout = () => {
    confirmationDialog.close();
    confirmationDialog.open({
      icon: <MeetingRoom fontSize="large" style={{ color: "orange" }} />,
      title: "Дали сте сигурни?",
      body: "Доколку сте сеуште на смена, ќе добивате нарачки иако сте одјавени",
      confirmText: "Одјави се",
      denyText: "Откажи",
      onConfirm: () => {
        confirmationDialog.close();
        navigate("/auth/logout");
      },
      onDeny: () => confirmationDialog.close(),
    });
  };

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        <BottomNavigation showLabels className={classes.navigation} component="nav">
          <BottomNavigationAction
            icon={<Home />}
            onClick={() => {
              setSelectedItem("shift");
              navigate("/drivers/shift");
            }}
            className={selectedItem === "shift" ? classes.selected : ""}
            classes={{
              root: classes.action,
              label: classes.label,
            }}
          />
          <BottomNavigationAction
            icon={<Assignment />}
            onClick={() => {
              setSelectedItem("orders");
              navigate("/drivers/orders");
            }}
            className={selectedItem === "orders" ? classes.selected : ""}
            classes={{
              root: classes.action,
              label: classes.label,
            }}
          />
          <BottomNavigationAction
            icon={<AccountBalance />}
            onClick={() => {
              setSelectedItem("paycheck");
              navigate("/drivers/paycheck");
            }}
            className={selectedItem === "paycheck" ? classes.selected : ""}
            classes={{
              root: classes.action,
              label: classes.label,
            }}
          />
          <BottomNavigationAction
            icon={<EuroSymbol />}
            onClick={() => {
              setSelectedItem("revenue");
              navigate("/drivers/revenue");
            }}
            className={selectedItem === "revenue" ? classes.selected : ""}
            classes={{
              root: classes.action,
              label: classes.label,
            }}
          />
          <BottomNavigationAction
            icon={<AddAlarm />}
            onClick={() => {
              setSelectedItem("schedule");
              navigate("/drivers/schedule");
            }}
            className={selectedItem === "schedule" ? classes.selected : ""}
            classes={{
              root: classes.action,
              label: classes.label,
            }}
          />
          <BottomNavigationAction
            icon={<MeetingRoom />}
            onClick={handleLogout}
            classes={{
              root: classes.action,
              label: classes.label,
            }}
          />
        </BottomNavigation>
      </Toolbar>
    </AppBar>
  );
};

export default Navigation;

