import TimeTooltip from "./TimeTooltip";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { IRoutePointDto } from "@shared/services/orders/dtos/orders/routePointDto";
import { greenMarkerIcon, redMarkerIcon } from "@shared/utils/defaultMarkerIcons";
import { Icon } from "leaflet";
import { IOrderDto, } from "@shared/services/order.service";
import { FC, memo, useMemo } from "react";
import { Marker, Polyline, Popup } from "react-leaflet";
import { useMemoSelector } from "@shared/hooks";
import { selectPaymentTypeById } from "@shared/store/selectors/sharedStateSelectors";

const useStyles = makeStyles((theme) => ({
  pickUpMarker: {
    borderRadius: "50%",
  },
  onlineBorder: {
    borderRadius: "50%",
    border: '4.5px solid',
    borderColor: theme.palette.secondary.main,
  },
  cashBorder: {
    orderRadius: "50%",
    border: '4.5px solid'
  },
}));

const isOnlinePayment = (paymentTypeName) => {
  const cleanName = paymentTypeName.toLowerCase();
  return (
    cleanName.includes("online") || cleanName.includes("онлајн") | cleanName.includes("картичка")
  );
};

interface UnassignedOrderMarkerProps {
  order: IOrderDto;
}


const UnassignedOrderMarker: FC<UnassignedOrderMarkerProps> = ({ order }) => {
  const classes = useStyles();

  const paymentType = useMemoSelector((state) => selectPaymentTypeById(state, order.paymentTypeFK));
  const isOnline = isOnlinePayment(paymentType?.name);

  const borderClass = isOnline ? classes.onlineBorder : classes.cashBorder;

  const partnerLogoMarkerIcon = useMemo(
    () =>
      new Icon({
        iconUrl: order?.partnerImageSrc,
        className: `${classes.pickUpMarker} ${borderClass}`,
        iconSize: [32, 32],
        iconAnchor: [16, 16],
      }),
    [order?.partnerImageSrc, borderClass, classes.pickUpMarker]
);


  return (
    <div>
      <Marker
        position={{
          lat: order.pickUpLatitude,
          lng: order.pickUpLongitude,
        }}
        icon={order?.partnerImageSrc ? partnerLogoMarkerIcon : redMarkerIcon}
      >
        {(order.pickUpTime || order.estimatedPickupTime) && (
          <TimeTooltip
            routePoint={
              {
                isPickup: true,
                orderTime: order.pickUpTime,
                estimatedTime: order.pickUpTime,
              } as IRoutePointDto
            }
          />
        )}
        {/* <UnassignedOrderMarkerPopup order={order} isPickup={true} /> */}
      </Marker>

      <Marker
        position={{
          lat: order.dropOffLatitude,
          lng: order.dropOffLongitude,
        }}
        icon={greenMarkerIcon}
      >
        {(order.dropOffTime || order.estimatedDropOffTime) && (
          <TimeTooltip
            routePoint={
              {
                isPickup: false,
                orderTime: order.dropOffTime,
                estimatedTime: order.dropOffTime, // TODO: Show estimation from API
              } as IRoutePointDto
            }
          />
        )}
        {/* <UnassignedOrderMarkerPopup order={order} isPickup={false} /> */}
      </Marker>

      <Polyline
        positions={[
          { lat: order.pickUpLatitude, lng: order.pickUpLongitude },
          { lat: order.dropOffLatitude, lng: order.dropOffLongitude },
        ]}
        color="red"
        pathOptions={{
          weight: 1,
        }}
      >
        <Popup>
          <Typography variant="body1" component="span">
            <strong>Број на нарачка: </strong>
          </Typography>
          {order?.displayOrderNumber ? (
            <Typography variant="body1" component="span">
              {order.id} - {order.displayOrderNumber} 
            </Typography>) : (
          <Typography variant="body1" component="span">
            {order.id} - {order.externalOrderNumber}
          </Typography>
          )}
        </Popup>
      </Polyline>
    </div>
  );
};

export default memo(UnassignedOrderMarker);
