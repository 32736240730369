import {
  Avatar,
  Button,
  CircularProgress,
  Divider,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { LockOutlined } from "@material-ui/icons";
import ErrorMessage from "@shared/components/ErrorMessage/ErrorMessage";
import {
  decodeToken,
  isUserDriver,
  login,
  setAccessToken,
  startTokenChecker,
} from "@shared/services/auth/auth.service";
import { IUserManagerResponseDto } from "@shared/services/auth/dtos/userManagerResponseDto";
import { IApiErrorResponseData } from "@shared/services/http.client";
import { AxiosError } from "axios";
import { useFormik } from "formik";
import { ChangeEvent, FC, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { LoginSchema } from "./schemas/LoginSchema";

const useStyles = makeStyles((theme) => ({
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
    display: "inline-flex",
  },
  form: {
    margin: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  divider: {
    width: "100%",
  },
  contentFooter: {
    paddingTop: theme.spacing(1),
  },
}));

const initialValues = {
  userName: "",
  password: "",
};

const Login: FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [loginError, setLoginError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (values: typeof initialValues) => {
    if (loginError) setLoginError("");
    setIsLoading(true);

    login(values)
      .then((response: IUserManagerResponseDto) => {
        setIsLoading(false);

        if (!response.accessToken) {
          return setLoginError("Access token not found");
        }

        setAccessToken(response.accessToken);

        startTokenChecker();

        // ! TODO: Implement redirect urls after login, Fallback: /app/employees
        const { IsDriver } = decodeToken(response.accessToken);

        if (isUserDriver(IsDriver)) {
          navigate("/drivers/shift");
        } else {
          navigate("/app/logistics");
        }
      })
      .catch((error: AxiosError<IApiErrorResponseData>) => {
        if (error?.response?.status === 400) {
          formik.setFieldValue("password", "");
          formik.setTouched({
            password: false,
          });
        }

        setIsLoading(false);
        setLoginError(error.response?.data?.message || "Unexpected error occured");
      });
  };

  const handleChange = (e: ChangeEvent<unknown>) => {
    if (loginError) setLoginError("");

    handleFormikChange(e);
  };

  const { handleChange: handleFormikChange, ...formik } = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: handleSubmit,
    validateOnChange: false,
  });

  return (
    <>
      <Avatar className={classes.avatar}>
        <LockOutlined />
      </Avatar>

      <Typography component="h1" variant="h5">
        Најави се
      </Typography>

      <form className={classes.form} onSubmit={formik.handleSubmit} noValidate>
        <TextField
          variant="outlined"
          margin="normal"
          error={formik.touched.userName && Boolean(formik.errors.userName)}
          helperText={formik.touched.userName && formik.errors.userName}
          value={formik.values.userName}
          required
          fullWidth
          id="userName"
          label="Корисничко име"
          onChange={handleChange}
          name="userName"
          autoComplete="userName"
          autoFocus
        />

        <TextField
          variant="outlined"
          margin="normal"
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
          value={formik.values.password}
          required
          fullWidth
          onChange={handleChange}
          name="password"
          label="Лозинка"
          type="password"
          id="password"
          autoComplete="current-password"
        />

        {loginError ? <ErrorMessage error={loginError} /> : null}

        {isLoading ? (
          <CircularProgress size={25} className={classes.submit} />
        ) : (
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Најави се
          </Button>
        )}
      </form>

      <Divider className={classes.divider} />

      <div className={classes.contentFooter}>
        <Link to="/auth/forgot-password">
          Ја заборавивте лозинката?
        </Link>
      </div>
    </>
  );
};

export default Login;
