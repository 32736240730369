import { Button, Paper, Typography, createStyles, makeStyles } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { FC, useEffect, useState } from "react";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2),
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    heading: {
      color: theme.palette.grey[500],
      textTransform: "capitalize",
    },
  })
);

interface PayrollSelectionProps {
  selectionData: any[];
  currentSelection: any;
  heading: string;
  earnings: number;
  ordersCount: number;
  onSelectionChange: (newSelection: any) => void;
}

const PayrollSelection: FC<PayrollSelectionProps> = ({
  selectionData = [],
  currentSelection,
  heading = "",
  earnings = 0,
  ordersCount = 0,
  onSelectionChange,
}) => {
  const classes = useStyles();
  const [currentSelectionIndex, setCurrentSelectionIndex] = useState<number>(null);
  const [backDisabled, setBackDisabled] = useState<boolean>(true);
  const [nextDisabled, setNextDisabled] = useState<boolean>(true);

  useEffect(() => {
    if (!selectionData?.length || !currentSelection) return;

    const index = selectionData.findIndex((p) => p === currentSelection);
    setCurrentSelectionIndex(index);
    setBackDisabled(index <= 0);
    setNextDisabled(index >= selectionData.length - 1);
  }, [selectionData, currentSelection]);

  const onBack = () => {
    if (backDisabled) return;
    onSelectionChange(selectionData[currentSelectionIndex - 1]);
  };

  const onNext = () => {
    if (nextDisabled) return;
    onSelectionChange(selectionData[currentSelectionIndex + 1]);
  };

  return (
    <Paper square className={classes.root}>
      <Typography variant="subtitle2" align="center" className={classes.heading}>
        {heading}
      </Typography>

      <div style= {{display:"flex", justifyContent:"space-around"}}>
        <Button size="small" disabled={backDisabled} onClick={onBack}>
          <ArrowBackIosIcon />
        </Button>
        <Typography variant="h5" align="center">
          {earnings} ден
        </Typography>
        <Typography variant="h5" align="center">
          {ordersCount} нарачки
        </Typography>
        <Button size="small" disabled={nextDisabled} onClick={onNext}>
          <ArrowForwardIosIcon />
        </Button>
      </div>
    </Paper>
  );
};

export default PayrollSelection;
