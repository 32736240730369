import { getAllAreas } from "@shared/services/settings/area.service";
import { useQuery } from "@tanstack/react-query";
import { FC } from "react";
import { GeoJSON } from "react-leaflet";
import { FeatureGroup, LayersControl } from "react-leaflet";

const AreasGeoJSON: FC = () => {
  const { data: areas } = useQuery(["areaTypes"], getAllAreas, {
    refetchInterval: 15 * 60 * 1000, // 15 minutes
  });

  return (
    <>
      {areas?.map((area) => (
        <LayersControl.Overlay key={area.id} name={`Зона: ${area.name}`}>
          <FeatureGroup>
            <GeoJSON data={JSON.parse(area.polygonData)} style={{ color: area.color || "red" }} />
          </FeatureGroup>
        </LayersControl.Overlay>
      ))}
    </>
  );
};

export default AreasGeoJSON;
