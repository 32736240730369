import { FC, memo } from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

import IconButton, { IconButtonProps } from "@material-ui/core/IconButton";

export type ColorTypes =
  | "primary"
  | "secondary"
  | "error"
  | "success"
  | "warning"
  | "default"
  | "inherit"
  | "info";

export type ColoredIconButtonProps = Omit<IconButtonProps, "color"> & { color?: ColorTypes };

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    success: {
      color: theme.palette.success.main,
    },
    error: {
      color: theme.palette.error.main,
    },
    warning: {
      color: theme.palette.warning.main,
    },
    info: {
      color: theme.palette.info.main,
    },
  })
);

const ColoredIconButton: FC<ColoredIconButtonProps> = ({
  children,
  color = "default",
  ...props
}) => {
  const classes = useStyles();

  const className = clsx(classes?.[color], props.className);

  const colorProp =
    ["default", "inherit", "primary", "secondary"].indexOf(color) > -1
      ? (color as "default" | "inherit" | "primary" | "secondary")
      : undefined;

  return (
    <IconButton {...props} color={colorProp} className={className}>
      {children}
    </IconButton>
  );
};

export default memo(ColoredIconButton);
