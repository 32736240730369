import React, { useState, useEffect } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) =>
  createStyles({
    drawerIcon: {
      [theme.breakpoints.up("sm")]: {
        paddingLeft: "24px",
        paddingRight: "24px",
      },
    },
    nested: {
      paddingLeft: theme.spacing(2),
    },
  })
);

export interface DrawerListMenuProps {
  text: string;
  icon: React.ReactNode;
  isDrawerOpen: boolean;
  onClick?: () => void;
}

const DrawerListMenu: React.FC<DrawerListMenuProps> = (props) => {
  const { text, icon, isDrawerOpen, onClick, children } = props;

  const classes = useStyles();

  const [isOpen, setIsOpen] = useState(false);
  const handleClick = () => {
    setIsOpen(!isOpen);

    if (typeof onClick === "function") onClick();
  };

  useEffect(() => {
    if (!isDrawerOpen) setIsOpen(false);
  }, [isDrawerOpen]);

  return (
    <>
      <ListItem
        title={text}
        button
        className={classes.drawerIcon}
        onClick={handleClick}
      >
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={text} />
        {isOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItem>

      <Collapse
        in={isOpen}
        timeout="auto"
        unmountOnExit
        className={classes.nested}
      >
        <List component="div" disablePadding>
          {children}
        </List>
      </Collapse>
    </>
  );
};

export default DrawerListMenu;
