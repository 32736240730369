import { mkMK } from "./locales";
import { darkTheme, lightTheme } from "./theme";
import { CssBaseline, ThemeProvider, createTheme } from "@material-ui/core";
import { useMemoSelector } from "@shared/hooks";
import React from "react";

export interface ThemeWrapperProps {
  children: React.ReactNode;
}

const ThemeWrapper: React.FC<ThemeWrapperProps> = ({ children }) => {
  const { isDarkMode, animations, borderRadius } = useMemoSelector(
    ({ appState }) => appState.preferences.theme
  );

  const theme = createTheme(isDarkMode ? darkTheme : lightTheme, mkMK);

  if (animations === false) {
    // Disable animations
    theme.transitions.create = () => "none";
  }

  theme.shape.borderRadius = borderRadius;

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};
export default ThemeWrapper;
