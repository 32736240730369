import { NewOrderValidationSchema } from "../../schemas/NewOrderValidationSchema";
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  TextField,
  createStyles,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CustomDateTimePicker from "@shared/components/CustomFormControls/CustomDateTimePicker";
import CustomSelect from "@shared/components/CustomFormControls/CustomSelect";
import ErrorMessage from "@shared/components/ErrorMessage/ErrorMessage";
import DraggableMarker from "@shared/components/Map/LeafletMap/DraggableMarker";
import LeafletMap from "@shared/components/Map/LeafletMap/LeafletMap";
import { IMapOrder } from "@shared/components/Map/models/models";
import { useMemoSelector } from "@shared/hooks";
import { ExternalSystem } from "@shared/services/auth/enums/externalSystem";
import { translateExternalSystem } from "@shared/services/auth/system-connections.service";
import { IOrderRequestDto } from "@shared/services/orders/dtos/orders/orderRequestDto";
import { createOrderAsync, updateOrderAsync } from "@shared/services/orders/orders.service";
import dayjs, { Dayjs } from "dayjs";
import { useFormik } from "formik";
import { LatLng } from "leaflet";
import { isNumber } from "lodash";
import { useSnackbar } from "notistack";
import * as React from "react";
import { SyntheticEvent, memo, useEffect, useState } from "react";

const useStyles = makeStyles(() =>
  createStyles({
    dialogContent: {
      minWidth: "290px",
    },
    formControl: {
      margin: "8px 0",
    },
  })
);

const initialValues: IOrderRequestDto = {
  clientId: "",
  externalOrderNumber: "",
  displayOrderNumber:"",
  restaurant: {
    id: "",
    address: "",
    latitude: 41.9981,
    longitude: 21.4254,
    logo: null,
    name: "",
    phoneNumbers: [],
    time: dayjs().add(10, "minutes").toISOString(),
  },
  customer: {
    address: {
      apartment: "",
      entranceCode: "",
      id: 0,
      street: "",
      streetNumber: "",
    },
    formattedAddress: "",
    id: "",
    latitude: 41.9981,
    longitude: 21.4254,
    name: "",
    phoneNumber: "",
    time: dayjs().add(30, "minutes").toISOString(),
  },
  orderDetails: {
    deliveryPrice: 0,
    isContactless: false,
    orderItems: [],
    originalPriceWithDiscount: 0,
    priceWithDiscount: 0,
    priorityPrice: 0,
    driverComment: "",
  },
  paymentTypeId: 0,
  deliveryTypeId: 0,
  isPriority: false,
};

interface Props {
  order?: IMapOrder;
  onClose: () => void;
}

const CreateOrderFormDialog: React.FC<Props> = ({ onClose, order }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [error, setError] = useState("");

  const cancel = () => {
    formik.resetForm();
    setError("");
    onClose();
  };

  const { deliveryTypes, paymentTypes } = useMemoSelector(({ sharedState }) => ({
    deliveryTypes: sharedState.deliveryTypes?.data,
    paymentTypes: sharedState.paymentTypes?.data,
  }));

  const ok = (order?: IMapOrder) => {
    if (order && order.id != null && order.id != undefined) {
      updateOrderAsync(order.id, {
        deliveryTypeId: formik.values.deliveryTypeId,
        paymentTypeId: formik.values.paymentTypeId,
        isPriority: formik.values.isPriority,
        driverComment: formik.values?.orderDetails?.driverComment,
        customer: {
          formattedAddress: formik.values.customer.formattedAddress,
          latitude: formik.values.customer.latitude,
          longitude: formik.values.customer.longitude,
          time: formik.values.customer.time,
        },
        restaurant: {
          address: formik.values.restaurant.address,
          latitude: formik.values.restaurant.latitude,
          longitude: formik.values.restaurant.longitude,
          time: formik.values.restaurant.time,
        },
      })
        .then((response) => {
          // Reset formik
          formik.setSubmitting(false);
          formik.resetForm();

          // Show notification
          enqueueSnackbar("Успешно зачувано", { variant: "success" });

          // Close the dialog
          onClose();
        })
        .catch((error) => {
          formik.setSubmitting(false);
          setError(error.response?.data?.message);
        });
    } else {
      createOrderAsync({
        ...formik.values,
        orderDetails: {
          ...formik.values.orderDetails,
          orderItems: [
            {
              foodAdditions: "Кечап, мајонез",
              foodName: "Сендвич",
              originalPrice: 300,
              price: 400,
              quantity: 2,
            },
          ],
        },
      })
        .then((response) => {
          // Reset formik
          formik.setSubmitting(false);
          formik.resetForm();

          // Show notification
          enqueueSnackbar("Успешно додадено", { variant: "success" });

          // Close the dialog
          onClose();
        })
        .catch((error) => {
          formik.setSubmitting(false);
          setError(error.response?.data?.message);
        });
    }
  };

  useEffect(() => {
    if (!order || (order && !order.id)) return;

    const newInitialValues = {
      clientId: order.clientID,
      deliveryTypeId: order.deliveryTypeFK,
      externalOrderNumber: order.externalOrderNumber,
      isPriority: order.isPriority,
      paymentTypeId: order.paymentTypeFK,
      restaurant: {
        id: order.partnerID,
        address: order.pickUpAddress,
        latitude: order.pickUpLatitude,
        longitude: order.pickUpLongitude,
        name: order.partnerName,
        time: dayjs.utc(order.pickUpTime).local().toISOString(),
        phoneNumbers: [],
        logo: "",
      },
      orderDetails: {
        driverComment: order?.driverComment,
      },
      customer: {
        formattedAddress: order.dropOffAddress,
        latitude: order.dropOffLatitude,
        longitude: order.dropOffLongitude,
        time: dayjs.utc(order.dropOffTime).local().toISOString(),
      },
    } as typeof initialValues;

    setValues(newInitialValues);
  }, [order]);

  const {
    setValues,
    handleChange: handleFormikChange,
    ...formik
  } = useFormik({
    initialValues,
    validationSchema: NewOrderValidationSchema,
    onSubmit: () => {
      ok(order);
    },
    enableReinitialize: true,
  });

  const handleChange = (event: SyntheticEvent) => {
    // Reset errors on input change
    if (error) setError("");

    handleFormikChange(event);
  };

  const handleMarkerPositionChange = (e: LatLng, markerId: string) => {
    switch (markerId) {
      case "pickUp":
        formik.setFieldValue("restaurant.latitude", e.lat);
        formik.setFieldValue("restaurant.longitude", e.lng);
        break;
      case "dropOff":
        formik.setFieldValue("customer.latitude", e.lat);
        formik.setFieldValue("customer.longitude", e.lng);
        break;
    }
  };

  const handlePhoneNumbersChange = (value: string[]) => {
    formik.setFieldValue("restaurant.phoneNumbers", value);
  };

  return (
    <Dialog onClose={cancel} maxWidth={false} scroll="paper" open>
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>{order && order.id ? "Измени нарачка" : "Креирај нарачка"}</DialogTitle>

        <DialogContent className={classes.dialogContent} dividers={true}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={2}>
              {order && order.id ? null : (
                <>
                  <TextField
                    name="externalOrderNumber"
                    label="Надворешен број на нарачка"
                    variant="outlined"
                    size="small"
                    className={classes.formControl}
                    value={formik.values.externalOrderNumber}
                    onChange={handleChange}
                    error={
                      formik.touched.externalOrderNumber &&
                      Boolean(formik.errors.externalOrderNumber)
                    }
                    helperText={
                      formik.touched.externalOrderNumber && formik.errors.externalOrderNumber
                    }
                    autoComplete="off"
                    fullWidth
                    required
                  />
                </>
              )}
              {order && order.id ? null : (
                <>
                  <CustomSelect
                    label="Клиент"
                    value={formik.values.clientId || ""}
                    errorMessage={formik.errors.clientId}
                    touched={formik.touched.clientId}
                    onChange={(e) => formik.setFieldValue("clientId", e)}
                    size="small"
                    variant="outlined"
                    selectOptions={Object.values(ExternalSystem)
                      .filter((value) => !isNumber(value))
                      .map((value) => ({
                        text: translateExternalSystem(ExternalSystem[value]),
                        value: ExternalSystem[value],
                      }))}
                    multiple={false}
                    required
                    id="client_select"
                  />
                </>
              )}
              {order && order.id ? null : (
                <>
                  <TextField
                    name="restaurant.id"
                    label="ИД на ресторан"
                    type="number"
                    variant="outlined"
                    size="small"
                    disabled={!!(order && order.id)}
                    className={classes.formControl}
                    value={formik.values.restaurant.id}
                    onChange={handleChange}
                    error={formik.touched.restaurant?.id && Boolean(formik.errors.restaurant?.id)}
                    helperText={formik.touched.restaurant?.id && formik.errors.restaurant?.id}
                    autoComplete="off"
                    fullWidth
                    required
                  />
                </>
              )}
              <TextField
                name="restaurant.address"
                label="Адреса на ресторан"
                variant="outlined"
                size="small"
                className={classes.formControl}
                value={formik.values.restaurant.address}
                onChange={handleChange}
                error={
                  formik.touched.restaurant?.address && Boolean(formik.errors.restaurant?.address)
                }
                helperText={formik.touched.restaurant?.address && formik.errors.restaurant?.address}
                autoComplete="off"
                fullWidth
                required
              />
              <TextField
                name="restaurant.latitude"
                label="Геолокација на ресторан (ширина)"
                variant="outlined"
                size="small"
                disabled
                className={classes.formControl}
                value={formik.values.restaurant.latitude}
                onChange={handleChange}
                error={
                  formik.touched.restaurant?.latitude && Boolean(formik.errors.restaurant?.latitude)
                }
                helperText={
                  formik.touched.restaurant?.latitude && formik.errors.restaurant?.latitude
                }
                autoComplete="off"
                fullWidth
                required
              />
              <TextField
                name="restaurant.longitude"
                label="Геолокација на ресторан (должина)"
                variant="outlined"
                size="small"
                disabled
                className={classes.formControl}
                value={formik.values.restaurant.longitude}
                onChange={handleChange}
                error={
                  formik.touched.restaurant?.longitude &&
                  Boolean(formik.errors.restaurant?.longitude)
                }
                helperText={
                  formik.touched.restaurant?.longitude && formik.errors.restaurant?.longitude
                }
                autoComplete="off"
                fullWidth
                required
              />
              <CustomDateTimePicker
                label="Време на подигање"
                value={formik.values.restaurant.time}
                format="DD.MM.YYYY HH:mm"
                style={{ marginRight: "5px" }}
                handleDateChange={(date: Dayjs, value?: string) => {
                  formik.setFieldValue("restaurant.time", date);
                }}
                required
              />
              {order && order.id ? null : (
                <>
                  <TextField
                    name="restaurant.logo"
                    label="Лого на ресторан"
                    variant="outlined"
                    size="small"
                    className={classes.formControl}
                    value={formik.values.restaurant.logo}
                    disabled={!!(order && order.id)}
                    onChange={handleChange}
                    error={
                      formik.touched.restaurant?.logo && Boolean(formik.errors.restaurant?.logo)
                    }
                    helperText={formik.touched.restaurant?.logo && formik.errors.restaurant?.logo}
                    autoComplete="off"
                    fullWidth
                  />
                </>
              )}
              {order && order.id ? null : (
                <>
                  <TextField
                    name="restaurant.name"
                    label="Име на ресторан"
                    variant="outlined"
                    size="small"
                    className={classes.formControl}
                    value={formik.values.restaurant.name}
                    onChange={handleChange}
                    error={
                      formik.touched.restaurant?.name && Boolean(formik.errors.restaurant?.name)
                    }
                    helperText={formik.touched.restaurant?.name && formik.errors.restaurant?.name}
                    autoComplete="off"
                    fullWidth
                  />
                </>
              )}
              {order && order.id ? null : (
                <>
                  <TextField
                    name="restaurant.phoneNumbers"
                    label='Контакт телефон за ресторан (броеви оделени со ",")'
                    variant="outlined"
                    size="small"
                    className={classes.formControl}
                    value={formik.values.restaurant.phoneNumbers}
                    disabled={!!(order && order.id)}
                    onChange={(event) => {
                      let phoneNumbers = event.currentTarget.value.split(",");
                      handlePhoneNumbersChange(phoneNumbers);
                    }}
                    error={
                      formik.touched.restaurant?.phoneNumbers &&
                      Boolean(formik.errors.restaurant?.phoneNumbers)
                    }
                    helperText={
                      formik.touched.restaurant?.phoneNumbers &&
                      formik.errors.restaurant?.phoneNumbers
                    }
                    autoComplete="off"
                    fullWidth
                  />
                </>
              )}
              {order && order.id ? null : (
                <>
                  <TextField
                    name="customer.id"
                    label="ИД на клиент"
                    variant="outlined"
                    type="number"
                    size="small"
                    className={classes.formControl}
                    disabled={!!(order && order.id)}
                    value={formik.values.customer.id}
                    onChange={handleChange}
                    error={formik.touched.customer?.id && Boolean(formik.errors.customer?.id)}
                    helperText={formik.touched.customer?.id && formik.errors.customer?.id}
                    autoComplete="off"
                    fullWidth
                    required
                  />
                </>
              )}
              {deliveryTypes ? (
                <CustomSelect
                  touched={formik.touched.deliveryTypeId}
                  selectOptions={deliveryTypes.map((x) => {
                    return { value: x.id, text: x.name };
                  })}
                  onChange={(value) => {
                    formik.setFieldValue("deliveryTypeId", value);
                  }}
                  multiple={false}
                  value={formik.values.deliveryTypeId}
                  errorMessage={formik.errors.deliveryTypeId}
                  id="deliveryTypeId"
                  size="small"
                  inputProps={{
                    className: classes.formControl,
                  }}
                  required
                />
              ) : null}
              {paymentTypes ? (
                <CustomSelect
                  touched={formik.touched.paymentTypeId}
                  selectOptions={paymentTypes.map((x) => {
                    return { value: x.id, text: x.name };
                  })}
                  onChange={(value) => {
                    formik.setFieldValue("paymentTypeId", value);
                  }}
                  multiple={false}
                  value={formik.values.paymentTypeId}
                  errorMessage={formik.errors.paymentTypeId}
                  id="paymentTypeId"
                  size="small"
                  inputProps={{
                    className: classes.formControl,
                  }}
                  required
                />
              ) : null}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formik.values.isPriority}
                    onChange={handleChange}
                    id="isPriority"
                    name="isPriority"
                  />
                }
                label="Приоритетна"
              />
              <ErrorMessage error={error} />
            </Grid>
            <Grid item md={2}>
              <TextField
                name="customer.formattedAddress"
                label="Форматирана адреса на клиент"
                variant="outlined"
                size="small"
                className={classes.formControl}
                value={formik.values.customer.formattedAddress}
                onChange={handleChange}
                error={
                  formik.touched.customer?.formattedAddress &&
                  Boolean(formik.errors.customer?.formattedAddress)
                }
                helperText={
                  formik.touched.customer?.formattedAddress &&
                  formik.errors.customer?.formattedAddress
                }
                autoComplete="off"
                fullWidth
                required
              />
              <TextField
                name="customer.latitude"
                label="Геолокација на клиент (ширина)"
                variant="outlined"
                size="small"
                disabled
                className={classes.formControl}
                value={formik.values.customer.latitude}
                onChange={handleChange}
                error={
                  formik.touched.customer?.latitude && Boolean(formik.errors.customer?.latitude)
                }
                helperText={formik.touched.customer?.latitude && formik.errors.customer?.latitude}
                autoComplete="off"
                fullWidth
              />
              <TextField
                name="customer.longitude"
                label="Геолокација на клиент (должина)"
                variant="outlined"
                size="small"
                disabled
                className={classes.formControl}
                value={formik.values.customer.longitude}
                onChange={handleChange}
                error={
                  formik.touched.customer?.longitude && Boolean(formik.errors.customer?.longitude)
                }
                helperText={formik.touched.customer?.longitude && formik.errors.customer?.longitude}
                autoComplete="off"
                fullWidth
              />
              <CustomDateTimePicker
                label="Време на достава"
                value={formik.values.customer.time}
                format="DD.MM.YYYY HH:mm"
                style={{ marginRight: "5px" }}
                handleDateChange={(date: Dayjs, value?: string) => {
                  formik.setFieldValue("customer.time", date);
                }}
                required
              />
              {order && order.id ? null : (
                <TextField
                  name="customer.name"
                  label="Име на клиент"
                  variant="outlined"
                  size="small"
                  className={classes.formControl}
                  value={formik.values.customer.name}
                  onChange={handleChange}
                  error={formik.touched.customer?.name && Boolean(formik.errors.customer?.name)}
                  helperText={formik.touched.customer?.name && formik.errors.customer?.name}
                  autoComplete="off"
                  fullWidth
                />
              )}
              {order && order.id ? null : (
                <>
                  <TextField
                    name="customer.phoneNumber"
                    label="Контакт број на клиент"
                    variant="outlined"
                    size="small"
                    className={classes.formControl}
                    value={formik.values.customer.phoneNumber}
                    onChange={handleChange}
                    error={
                      formik.touched.customer?.phoneNumber &&
                      Boolean(formik.errors.customer?.phoneNumber)
                    }
                    helperText={
                      formik.touched.customer?.phoneNumber && formik.errors.customer?.phoneNumber
                    }
                    autoComplete="off"
                    fullWidth
                  />
                </>
              )}
              {order && order.id ? null : (
                <>
                  <TextField
                    name="orderDetails.originalPriceWithDiscount"
                    label="Оригинална цена со попуст"
                    variant="outlined"
                    size="small"
                    className={classes.formControl}
                    value={formik.values.orderDetails.originalPriceWithDiscount}
                    onChange={handleChange}
                    error={
                      formik.touched.orderDetails?.originalPriceWithDiscount &&
                      Boolean(formik.errors.orderDetails?.originalPriceWithDiscount)
                    }
                    helperText={
                      formik.touched.orderDetails?.originalPriceWithDiscount &&
                      formik.errors.orderDetails?.originalPriceWithDiscount
                    }
                    autoComplete="off"
                    fullWidth
                  />
                  <TextField
                    name="orderDetails.priceWithDiscount"
                    label="Цена со попуст"
                    variant="outlined"
                    size="small"
                    className={classes.formControl}
                    value={formik.values.orderDetails.priceWithDiscount}
                    onChange={handleChange}
                    error={
                      formik.touched.orderDetails?.priceWithDiscount &&
                      Boolean(formik.errors.orderDetails?.priceWithDiscount)
                    }
                    helperText={
                      formik.touched.orderDetails?.priceWithDiscount &&
                      formik.errors.orderDetails?.priceWithDiscount
                    }
                    autoComplete="off"
                    fullWidth
                  />
                  <TextField
                    name="orderDetails.deliveryPrice"
                    label="Цена за достава"
                    variant="outlined"
                    size="small"
                    className={classes.formControl}
                    value={formik.values.orderDetails.deliveryPrice}
                    onChange={handleChange}
                    error={
                      formik.touched.orderDetails?.deliveryPrice &&
                      Boolean(formik.errors.orderDetails?.deliveryPrice)
                    }
                    helperText={
                      formik.touched.orderDetails?.deliveryPrice &&
                      formik.errors.orderDetails?.deliveryPrice
                    }
                    autoComplete="off"
                    fullWidth
                  />
                  <TextField
                    name="orderDetails.priorityPrice"
                    label="Цена за приоритет"
                    variant="outlined"
                    size="small"
                    className={classes.formControl}
                    value={formik.values.orderDetails.priorityPrice}
                    onChange={handleChange}
                    error={
                      formik.touched.orderDetails?.priorityPrice &&
                      Boolean(formik.errors.orderDetails?.priorityPrice)
                    }
                    helperText={
                      formik.touched.orderDetails?.priorityPrice &&
                      formik.errors.orderDetails?.priorityPrice
                    }
                    autoComplete="off"
                    fullWidth
                  />
                </>
              )}

              <TextField
                fullWidth
                variant="outlined"
                size="small"
                name="orderDetails.driverComment"
                label="Коментар"
                value={formik.values?.orderDetails?.driverComment}
                onChange={handleChange}
                error={
                  formik.touched?.orderDetails?.driverComment &&
                  Boolean(formik.errors?.orderDetails?.driverComment)
                }
                helperText={
                  formik.touched?.orderDetails?.driverComment &&
                  formik.errors?.orderDetails?.driverComment
                }
                multiline
                rows={6}
                autoComplete="off"
                style={{ marginTop: "8px" }}
              />

              {order && order.id ? null : (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formik.values.orderDetails.isContactless}
                      onChange={handleChange}
                      id="orderDetails.isContactless"
                      name="orderDetails.isContactless"
                    />
                  }
                  label="Без контактно"
                />
              )}
            </Grid>
            {order && order.id ? null : (
              <>
                <Grid item md={2}>
                  <TextField
                    name="customer.address.street"
                    label="Улица на клиент"
                    variant="outlined"
                    size="small"
                    className={classes.formControl}
                    value={formik.values.customer.address.street}
                    onChange={handleChange}
                    error={
                      formik.touched.customer?.address?.street &&
                      Boolean(formik.errors.customer?.address?.street)
                    }
                    helperText={
                      formik.touched.customer?.address?.street &&
                      formik.errors.customer?.address?.street
                    }
                    autoComplete="off"
                    fullWidth
                  />
                  <TextField
                    name="customer.address.streetNumber"
                    label="Број на адреса на клиент"
                    variant="outlined"
                    size="small"
                    className={classes.formControl}
                    value={formik.values.customer.address.streetNumber}
                    onChange={handleChange}
                    error={
                      formik.touched.customer?.address?.streetNumber &&
                      Boolean(formik.errors.customer?.address?.streetNumber)
                    }
                    helperText={
                      formik.touched.customer?.address?.streetNumber &&
                      formik.errors.customer?.address?.streetNumber
                    }
                    autoComplete="off"
                    fullWidth
                  />
                  <TextField
                    name="customer.address.apartment"
                    label="Број на апартман на клиент"
                    variant="outlined"
                    size="small"
                    className={classes.formControl}
                    value={formik.values.customer.address.apartment}
                    onChange={handleChange}
                    error={
                      formik.touched.customer?.address?.apartment &&
                      Boolean(formik.errors.customer?.address?.apartment)
                    }
                    helperText={
                      formik.touched.customer?.address?.apartment &&
                      formik.errors.customer?.address?.apartment
                    }
                    autoComplete="off"
                    fullWidth
                  />
                  <TextField
                    name="customer.address.entranceCode"
                    label="Број на влез на клиент"
                    variant="outlined"
                    size="small"
                    className={classes.formControl}
                    value={formik.values.customer.address.entranceCode}
                    onChange={handleChange}
                    error={
                      formik.touched.customer?.address?.entranceCode &&
                      Boolean(formik.errors.customer?.address?.entranceCode)
                    }
                    helperText={
                      formik.touched.customer?.address?.entranceCode &&
                      formik.errors.customer?.address?.entranceCode
                    }
                    autoComplete="off"
                    fullWidth
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12} md={order && order.id ? 8 : 6}>
              <LeafletMap defaultLocation={{ latitude: 41.9981, longitude: 21.4254 }}>
                <DraggableMarker
                  center={
                    new LatLng(
                      formik.values.restaurant.latitude,
                      formik.values.restaurant.longitude
                    )
                  }
                  popupLabel="Подигање"
                  onMarkerPositionChanged={(e) => handleMarkerPositionChange(e, "pickUp")}
                />

                <DraggableMarker
                  center={
                    new LatLng(formik.values.customer.latitude, formik.values.customer.longitude)
                  }
                  popupLabel="Достава"
                  onMarkerPositionChanged={(e) => handleMarkerPositionChange(e, "dropOff")}
                />
              </LeafletMap>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {formik.isSubmitting ? (
            <CircularProgress size={25} />
          ) : (
            <Button type="submit" color="primary">
              Зачувај
            </Button>
          )}
          <Button color="secondary" onClick={cancel}>
            Откажи
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
export default memo(CreateOrderFormDialog);
