import EditRouteDialog from "../Map/EditRouteDialog";
import {
  Avatar,
  ButtonGroup,
  Grid,
  Icon,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import {
  AlarmOn,
  Autorenew,
  Block,
  DoneAll,
  Email,
  HelpOutlineOutlined,
  LocationDisabled,
  LocationOffOutlined,
  LocationOnOutlined,
  Phone,
  PlaylistAddCheck,
  Replay,
  Storefront,
  Warning,
} from "@material-ui/icons";
import ColoredButton from "@shared/components/ColoredButton/ColoredButton";
import CustomDateTimeField from "@shared/components/CustomFormControls/CustomDateTimeField";
import CustomMenuSelect, {
  SelectOption,
} from "@shared/components/CustomFormControls/CustomMenuSelect";
import OrderLogCompactTable from "@shared/components/OrderLogCompactTable/OrderLogCompactTable";
import TextWithIcon from "@shared/components/TextWithIcon/TextWithIcon";
import { useConfirmationDialog } from "@shared/context/ConfirmationDialogContext";
import { useMemoSelector } from "@shared/hooks";
import { translateExternalSystem } from "@shared/services/auth/system-connections.service";
import {
  executeAlgorithmManually,
  forceCloseActiveDriverRoute,
  requestPingDriverLocation,
  requestStartGPSService,
  requestStopGPSService,
} from "@shared/services/drivers/drivers.service";
import { IDriverDto } from "@shared/services/drivers/dtos/driverDto";
import { IOrderDto } from "@shared/services/orders/dtos/orders/orderDto";
import { OrderStatus } from "@shared/services/orders/enums/orderStatus";
import {
  changeOrderStatus,
  resetOrderManuallyAsync,
  translateOrderStatus,
} from "@shared/services/orders/orders.service";
import {
  endDriverWorkShiftAsync,
  insertUnlimitedPauseAsync,
} from "@shared/services/settings/workshift.service";
import { endPauseAsync, insertPauseAsync } from "@shared/services/settings/workshift.service";
import { cutString } from "@shared/utils/cutString";
import { getVehicleIcon } from "@shared/utils/getVehicleIcon";
import dayjs from "dayjs";
import { useModal } from "mui-modal-provider";
import { useSnackbar } from "notistack";
import { FC, Fragment, memo } from "react";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      "& th, & td": {
        padding: "0 5px",
        fontSize: "12px",
      },
    },
    image: {
      height: "10rem",
      width: "10rem",
      margin: "auto",
    },
    sectionTitle: {
      marginBottom: theme.spacing(2),
      display: "block",
    },
    button: {
      fontSize: "10px",
      padding: "2px 4px",
    },
    endShiftButton: {
      marginTop: theme.spacing(1),
    },
    buttonStartIcon: {
      margin: 0,
    },
  })
);

export interface Props {
  driver: IDriverDto;
}

const DriverTooltipContent: FC<Props> = ({ driver }) => {
  const classes = useStyles();
  const { vehicleTypes, orderRejectReasons, paymentTypes, deliveryTypes } = useMemoSelector(
    ({ sharedState }) => ({
      vehicleTypes: sharedState.vehicleTypes.data,
      orderRejectReasons: sharedState.orderRejectReasons.data,
      paymentTypes: sharedState.paymentTypes.data,
      deliveryTypes: sharedState.deliveryTypes.data,
    })
  );

  const { enqueueSnackbar } = useSnackbar();
  const vehicleTypeName = () => {
    return vehicleTypes?.find((x) => x.id === driver.vehicleTypeFk)?.name;
  };

  const handlePutDriverOnPause = (date) => {
    if (!date) {
      insertUnlimitedPauseAsync(driver.employeeId)
        .then(() => {
          enqueueSnackbar("Возачот е паузиран.", {
            variant: "success",
            autoHideDuration: 6000,
          });
        })
        .catch((error) => {
          enqueueSnackbar(`Неуспешно започната пауза: ${error?.message}.`, {
            variant: "error",
            autoHideDuration: 6000,
          });
        });
      return;
    }
  
    const selectedTime = dayjs(date);
    const currentTime = dayjs();
  
    if (selectedTime.isBefore(currentTime)) {
      enqueueSnackbar("Времето мора да биде во иднината", {
        variant: "error",
        autoHideDuration: 6000,
      });
      return;
    }
  
    const maximumPauseTime = currentTime.add(1, 'hour');
  
    if (selectedTime.isAfter(maximumPauseTime)) {
      enqueueSnackbar("Максималното време за пауза е 1 час!", {
        variant: "error",
        autoHideDuration: 6000,
      });
      return;
    }
  
    const until = selectedTime.toISOString();
  
    insertPauseAsync(driver.employeeId, until)
      .then(() => {
        enqueueSnackbar("Возачот е паузиран.", {
          variant: "success",
          autoHideDuration: 6000,
        });
      })
      .catch((error) => {
        enqueueSnackbar(`Неуспешно започната пауза: ${error?.message}.`, {
          variant: "error",
          autoHideDuration: 6000,
        });
      });
  };
  

  const onEndDriverPauseClick = () => {
    endPauseAsync(driver.employeeId)
      .then(() => {
        enqueueSnackbar("Паузата на возачот е успешно завршена.", {
          variant: "success",
          autoHideDuration: 6000,
        });
      })
      .catch((error) => {
        enqueueSnackbar(
          `Неуспешно завршена пауза: ${error?.response?.data?.message || error?.message}.`,
          {
            variant: "error",
            autoHideDuration: 6000,
          }
        );
      });
  };

  const confirmationDialog = useConfirmationDialog();

  const handleCancelDriversOrder = (order: IOrderDto, rejectReason: SelectOption<number>) => {
    confirmationDialog.open({
      icon: <Warning fontSize="large" style={{ color: "orange" }} />,
      title: "Додели нарачка",
      body: `Дали сте сигурни дека сакате да ја тргнете нарачката ${order.id} од возачот ${driver.firstName} ${driver.lastName} поради ${rejectReason.name}?`,
      onConfirm: () => executeCancelDriversOrder(order, rejectReason),
      onDeny: () => confirmationDialog.close(),
    });
  };

  const executeCancelDriversOrder = (order: IOrderDto, rejectReason: SelectOption<number>) => {
    if (driver && driver.employeeId && order && order.id && rejectReason && rejectReason.id) {
      confirmationDialog.setLoading(true);

      const request = { driverId: driver.employeeId, orderRejectReasonFK: rejectReason.id };
      resetOrderManuallyAsync(order.id, request)
        .then(() => {
          enqueueSnackbar("Нарачката е успешно тргната од возачот.", {
            variant: "success",
            autoHideDuration: 6000,
          });
        })
        .catch((e) => {
          enqueueSnackbar(
            `Грешка при ресетирање на нарачката: ${e?.response?.data?.message || e?.message}`,
            {
              variant: "error",
              autoHideDuration: 6000,
            }
          );
        })
        .finally(() => {
          confirmationDialog.close();
        });
    } else {
      console.error("This should never happen :)");
    }
  };

  const handleOrderStatusChange = (order: IOrderDto, orderStatus: OrderStatus) => {
    if (!order?.id) return;

    confirmationDialog.setLoading(true);

    changeOrderStatus(order.id, { status: orderStatus })
      .then(() => {
        enqueueSnackbar(
          `Статусот на нарачката е успешно променет во: ${translateOrderStatus(orderStatus)}`,
          {
            variant: "success",
            autoHideDuration: 6000,
          }
        );

        confirmationDialog.close();
      })
      .catch((e) => {
        enqueueSnackbar(
          `Грешка при промена на статусот на нарачката: ${
            e?.response?.data?.message || e?.message
          }`,
          {
            variant: "error",
            autoHideDuration: 6000,
          }
        );

        confirmationDialog.close();
      });
  };

  const onRequestDriverLocation = () => {
    requestPingDriverLocation(driver.userId)
      .then(() => {
        enqueueSnackbar("Успешно пратено известување до возачот да испрати локација.", {
          variant: "success",
          autoHideDuration: 6000,
        });
      })
      .catch((e) => {
        enqueueSnackbar(`Неуспешно испратено известување: ${e.message}`, {
          variant: "error",
          autoHideDuration: 6000,
        });
      });
  };

  const onRequestDriverTurnOnGPS = () => {
    requestStartGPSService(driver.userId)
      .then(() => {
        enqueueSnackbar("Успешно пратено известување до возачот да вклучи GPS.", {
          variant: "success",
          autoHideDuration: 6000,
        });
      })
      .catch((e) => {
        enqueueSnackbar(`Неуспешно испратено известување: ${e.message}`, {
          variant: "error",
          autoHideDuration: 6000,
        });
      });
  };

  const onRequestDriverTurnOffGPS = () => {
    requestStopGPSService(driver.userId)
      .then(() => {
        enqueueSnackbar("Успешно пратено известување до возачот да исклучи GPS.", {
          variant: "success",
          autoHideDuration: 6000,
        });
      })
      .catch((e) => {
        enqueueSnackbar(`Неуспешно пратено известување: ${e.message}`, {
          variant: "error",
          autoHideDuration: 6000,
        });
      });
  };

  const stopDriverWorkShift = () => {
    confirmationDialog.setLoading(true);

    endDriverWorkShiftAsync(driver.employeeId)
      .then(() => {
        confirmationDialog.close();

        enqueueSnackbar("Успешно ја завршивте смената на возачот.", {
          variant: "success",
          autoHideDuration: 6000,
        });
      })
      .catch((e) => {
        confirmationDialog.close();

        enqueueSnackbar(`Неуспешно ја завршивте смената на возачот: ${e.message}`, {
          variant: "error",
          autoHideDuration: 6000,
        });
      });
  };

  const onStopDriverWorkShiftClick = () => {
    confirmationDialog.open({
      icon: <Warning fontSize="large" style={{ color: "orange" }} />,
      title: "Заврши смена?",
      body: `Дали сте сигурни дека сакате да ја завршите смената на вработениот ${driver.firstName} ${driver.lastName}?`,
      onConfirm: () => stopDriverWorkShift(),
      onDeny: () => confirmationDialog.close(),
    });
  };

  const executeAlgorithm = () => {
    confirmationDialog.setLoading(true);

    executeAlgorithmManually(driver.employeeId)
      .then(() => {
        confirmationDialog.close();

        enqueueSnackbar("Успешно го извршивте алгоритамот.", {
          variant: "success",
          autoHideDuration: 6000,
        });
      })
      .catch((e) => {
        confirmationDialog.close();

        enqueueSnackbar(`Неуспешно го извршивте алгоритамот: ${e.message}`, {
          variant: "error",
          autoHideDuration: 6000,
        });
      });
  };

  const handleExecuteAlgorithm = () => {
    confirmationDialog.open({
      icon: <Warning fontSize="large" style={{ color: "orange" }} />,
      title: "Изврши алгоритам?",
      body: `Дали сте сигурни дека сакате да го извршите алгоритамот?`,
      onConfirm: () => executeAlgorithm(),
      onDeny: () => confirmationDialog.close(),
    });
  };

  const closeDriverRoute = () => {
    confirmationDialog.setLoading(true);

    forceCloseActiveDriverRoute(driver.employeeId)
      .then(() => {
        confirmationDialog.close();

        enqueueSnackbar("Успешно ја затворивте рутата на возачот.", {
          variant: "success",
          autoHideDuration: 6000,
        });
      })
      .catch((e) => {
        confirmationDialog.close();

        enqueueSnackbar(
          `Неуспешно ја затворивте рутата на возачот: ${e?.response?.data?.message || e?.message}`,
          {
            variant: "error",
            autoHideDuration: 6000,
          }
        );
      });
  };

  const handleCloseRouteManually = () => {
    confirmationDialog.open({
      icon: <Warning fontSize="large" style={{ color: "orange" }} />,
      title: "Затвори рута?",
      body: `Дали сте сигурни дека сакате да ја затворите рутата на возачот?`,
      onConfirm: () => closeDriverRoute(),
      onDeny: () => confirmationDialog.close(),
    });
  };

  const { showModal } = useModal({ disableAutoDestroy: true });
  const handleChangeRouteManually = () => {
    const routeEditModal = showModal(EditRouteDialog, {
      selectedOrderId: null,
      selectedDriverId: driver.employeeId,
      onClose: () => routeEditModal.destroy(),
    });
  };

  return (
    <>
      {driver ? (
        <>
          <Grid spacing={2} container>
            <Grid md={3} item style={{ overflowWrap: "anywhere" }}>
              <Typography
                className={classes.sectionTitle}
                variant="body1"
                component="span"
              >{`${driver.firstName} ${driver.lastName}`}</Typography>
              <Link href={`mailto:${driver.email}`}>
                <TextWithIcon icon={<Email color="primary" />}>{driver.email}</TextWithIcon>
              </Link>
              <Link href={`tel:${driver.phoneNumber}`}>
                <TextWithIcon icon={<Phone color="primary" />}>
                  <>{driver.phoneNumber}</>
                </TextWithIcon>
              </Link>
              <TextWithIcon icon={<AlarmOn color="primary" />} title="Почеток на смена">
                {dayjs.utc(driver.workShiftStartTime).local().format("DD.MM.YYYY HH:mm")}
              </TextWithIcon>
              <TextWithIcon
                icon={<Icon color="primary">{getVehicleIcon(vehicleTypeName())}</Icon>}
                title="Тип на возило"
              >
                {vehicleTypeName()}
              </TextWithIcon>
              {driver?.pauseStatus?.startTime ? (
                <>
                  <TextWithIcon icon={<HelpOutlineOutlined color="primary" />} title="Статус">
                    На пауза до:{" "}
                    {driver?.pauseStatus?.endTime
                      ? dayjs.utc(driver?.pauseStatus?.endTime).local().format("HH:mm")
                      : "--:--"}
                  </TextWithIcon>

                  <ColoredButton
                    variant="outlined"
                    color="warning"
                    size="small"
                    className={classes.endShiftButton}
                    startIcon={<Block />}
                    onClick={onEndDriverPauseClick}
                  >
                    Заврши пауза
                  </ColoredButton>
                  <br />
                </>
              ) : (
                <>
                  {driver.orders.length > 0 ? (
                    <TextWithIcon icon={<HelpOutlineOutlined color="error" />} title="Статус">
                      Зафатен
                    </TextWithIcon>
                  ) : (
                    <TextWithIcon icon={<HelpOutlineOutlined color="primary" />} title="Статус">
                      Слободен
                    </TextWithIcon>
                  )}

                  <CustomDateTimeField
                    errorMessage=""
                    onChange={(e) => {
                      handlePutDriverOnPause(e);
                    }}
                    touched={false}
                    format="HH:mm"
                    ampm={false}
                    minutesStep={5}
                    value={null}
                    clearable
                    clearLabel="Unlimited"
                    placeholder="Започни пауза"
                  />
                </>
              )}
              <ColoredButton
                variant="outlined"
                color="error"
                size="small"
                className={classes.endShiftButton}
                startIcon={<Block />}
                onClick={onStopDriverWorkShiftClick}
              >
                Заврши смена
              </ColoredButton>
              <br />
              <br />
              <ButtonGroup variant="contained" size="small" disableElevation>
                <ColoredButton
                  title="Пушти сервис за локација"
                  color="primary"
                  classes={{ startIcon: classes.buttonStartIcon }}
                  className={classes.button}
                  startIcon={<LocationOnOutlined />}
                  onClick={onRequestDriverTurnOnGPS}
                />
                <ColoredButton
                  title="Исклучи сервис за локација"
                  color="primary"
                  classes={{ startIcon: classes.buttonStartIcon }}
                  className={classes.button}
                  startIcon={<LocationOffOutlined />}
                  onClick={onRequestDriverTurnOffGPS}
                />
                <ColoredButton
                  title="Побарај сегашна локација"
                  color="primary"
                  classes={{ startIcon: classes.buttonStartIcon }}
                  className={classes.button}
                  startIcon={<LocationDisabled />}
                  onClick={onRequestDriverLocation}
                />
              </ButtonGroup>
              <br />
              <ColoredButton
                variant="contained"
                color="primary"
                size="small"
                className={classes.button}
                startIcon={<Autorenew />}
                onClick={handleExecuteAlgorithm}
                disableElevation
              >
                Алгоритам
              </ColoredButton>{" "}
              <ColoredButton
                variant="contained"
                color="primary"
                size="small"
                className={classes.button}
                startIcon={<DoneAll />}
                onClick={handleCloseRouteManually}
                disableElevation
              >
                Затвори рута
              </ColoredButton>
              <br />
              <ColoredButton
                variant="contained"
                color="primary"
                size="small"
                className={classes.button}
                startIcon={<DoneAll />}
                onClick={handleChangeRouteManually}
                disableElevation
              >
                Промени рута
              </ColoredButton>
            </Grid>
            <Grid md={9} item>
              <div className={classes.sectionTitle}>
                <Typography variant="body1" component="span">
                  Нарачки
                </Typography>
              </div>
              <TableContainer className={classes.root}>
                <Table stickyHeader={true} size="small" aria-label="collapsible table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left" key="orderStatus">
                        Статус
                      </TableCell>
                      <TableCell align="left" key="externalOrderNumber">
                        Број на нарачка
                      </TableCell>
                      <TableCell align="left" key="clientID">
                        Клиент
                      </TableCell>
                      <TableCell align="left" key="partner">
                        Партнер
                      </TableCell>
                      <TableCell align="left" key="customerNumber">
                        Корисник
                      </TableCell>
                      <TableCell align="left" key="pickUpAddress">
                        Подигање
                      </TableCell>
                      <TableCell align="left" key="dropOffAddress">
                        Достава
                      </TableCell>
                      <TableCell align="left" key="pickUpTime">
                        Од
                      </TableCell>
                      <TableCell align="left" key="dropOffTime">
                        До
                      </TableCell>
                      <TableCell align="left" key="paymentTypeFk">
                        Плаќање
                      </TableCell>
                      <TableCell align="left" key="deliveryTypeFk">
                        Достава
                      </TableCell>
                      <TableCell align="center" key="removeOrder">
                        &nbsp;
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {driver && driver.orders && driver.orders.length > 0 ? (
                      driver.orders.map((order) => (
                        <TableRow key={order.id}>
                          <TableCell>{translateOrderStatus(order.orderStatus)}</TableCell>
                          {order.displayOrderNumber ? (
                            <TableCell title={order.displayOrderNumber}>
                              {order.displayOrderNumber}
                            </TableCell>
                          ) : (
                            <TableCell title={order.externalOrderNumber}>
                              {cutString(order.externalOrderNumber, 3)}
                            </TableCell>
                          )}
                          <TableCell>
                            {translateExternalSystem(parseInt(order?.clientID))}
                          </TableCell>
                          <TableCell>
                            <Avatar
                              src={order?.partnerImageSrc}
                              style={{ width: "30px", height: "30px", margin: "auto" }}
                              title={order.partnerName}
                            >
                              <Storefront />
                            </Avatar>
                          </TableCell>
                          <TableCell>{order.customerNumber}</TableCell>
                          <TableCell>{order.pickUpAddress}</TableCell>
                          <TableCell>{order.dropOffAddress}</TableCell>
                          <TableCell>
                            {dayjs.utc(order.pickUpTime).local().format("DD.MM.YYYY HH:mm")}
                          </TableCell>
                          <TableCell>
                            {dayjs.utc(order.dropOffTime).local().format("DD.MM.YYYY HH:mm")}
                          </TableCell>
                          <TableCell>
                            {paymentTypes?.find((x) => x.id === order.paymentTypeFK)?.name}
                          </TableCell>
                          <TableCell>
                            {deliveryTypes?.find((x) => x.id === order.deliveryTypeFK)?.name}
                          </TableCell>
                          <TableCell>
                            <ButtonGroup variant="contained" size="small" disableElevation>
                    
                              <CustomMenuSelect
                                onSelected={(rejectReason: SelectOption<number>) => {
                                  handleCancelDriversOrder(order, rejectReason);
                                }}
                                title="Ресетирај нарачка"
                                classes={{ startIcon: classes.buttonStartIcon }}
                                startIcon={<Replay fontSize="small" />}
                                selectoptions={orderRejectReasons}
                                color="warning"
                                dense
                              />
                            </ButtonGroup>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell align="center" colSpan={11}>
                          Нема активни нарачки
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>

              <br />

              {driver?.orders?.map((order) => (
                <Fragment key={order.id}>
                  {order.displayOrderNumber ? (
                    <OrderLogCompactTable
                      orderId={order.id}
                      displayOrderNumber={order.displayOrderNumber}
                    />
                  ) : (
                    <OrderLogCompactTable
                      orderId={order.id}
                      externalOrderNumber={order.externalOrderNumber}
                    />
                  )}
                  <br />
                </Fragment>
              ))}
            </Grid>
          </Grid>
        </>
      ) : null}
    </>
  );
};

export default memo(DriverTooltipContent);

