export const cutString = (data: string, numberOfCharacters: number) => {
  if (!data || (data && !data.length)) {
    return data;
  }

  if (data.length <= numberOfCharacters) {
    return data;
  }

  return data
    .substr(data.length - numberOfCharacters, numberOfCharacters)
    .padStart(numberOfCharacters, "0");
};