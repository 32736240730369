import { object, string, number, date } from "yup";

export const NewOrderValidationSchema = object({
  // clientId: number().required(),
  // externalOrderNumber: string().required("Задолжително"),
  restaurant: object({
    // id: number().required("Задолжително"),
    address: string().required("Задолжително"),
    latitude: number().required("Задолжително"),
    longitude: number().required("Задолжително"),
    time: date().required("Задолжително"),
  }),
  customer: object({
    // id: number().required("Задолжително"),
    formattedAddress: string().required("Задолжително"),
    latitude: number().required("Задолжително"),
    longitude: number().required("Задолжително"),
    time: date().required("Задолжително"),
  }),
  paymentTypeId: number().not([0], "Задолжително").required("Задолжително"),
  deliveryTypeId: number().not([0], "Задолжително").required("Задолжително"),
});
