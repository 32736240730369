import httpService from "../http.service";
import { IPerformanceBonusesDto } from "./dtos/performance-bonuses/performanceBonusesDto";
import { IPerformanceBonusesRequestDto } from "./dtos/performance-bonuses/performanceBonusesRequestDto";

export const getAllPerformanceBonusTypes = () => {
  return httpService.get<IPerformanceBonusesDto[]>("/api/performance-bonuses");
};

export const getPerformanceBonusType = (id: number) => {
  return httpService.get<IPerformanceBonusesDto>(`/api/performance-bonuses/${id}`);
};

export const addNewPerformanceBonusType = (request: IPerformanceBonusesRequestDto) => {
  return httpService.post<IPerformanceBonusesDto, IPerformanceBonusesRequestDto>(
    `/api/performance-bonuses`,
    request
  );
};

export const updatePerformanceBonusType = (id: number, request: IPerformanceBonusesRequestDto) => {
  return httpService.put<IPerformanceBonusesDto, IPerformanceBonusesRequestDto>(
    `/api/performance-bonuses/${id}`,
    request
  );
};

export const removePerformanceBonusType = (id: number) => {
  return httpService.delete<void>(`/api/performance-bonuses/${id}`);
};
