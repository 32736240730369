import { getAccessToken, logout } from "@shared/services/auth/auth.service";
import axios, { AxiosError } from "axios";

export interface IApiErrorResponseData {
  message: string;
  stackTrace: string;
  innerError: IApiErrorResponseData;
}

const httpClient = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL ?? "",
});

httpClient.interceptors.request.use((request) => {
  const token = getAccessToken();
  request.headers.setAuthorization(`Bearer ${token}`, true);
  return request;
});

httpClient.interceptors.response.use(
  (response) => response,
  (error: AxiosError<IApiErrorResponseData>) => {
    if (error?.response?.status === 401) logout();
    throw error;
  }
);

export default httpClient;
